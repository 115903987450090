import React, { useContext, useEffect, useState } from 'react'

import {AppContext} from '../../../context/AppContext'

import {
	dateConvert
} from '../../../utilites/appfunctions'

import './NotificationItem.scss'
import './NotificationItemMedia.scss'
import {useHistory} from "react-router-dom";

function NotificationItem({notification}){

	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang

	const [item, setItem] = useState(null)

	const history = useHistory()

	const convertNote = data => {
		let type 
		if(data.type === 'document.content.edited' || data.type === 'document.name'){
			type = 'user'
		} else if (data.type === 'document.link'){
			type = 'doc'
		} else if (data.type === 'document.status'){
			type = 'doc'
		} else if (data.type === 'query.create' || 'query.reply' || 'query.mention' || 'query.resolved' || 'comment.create' || 'comment.mention' || 'comment.reply'){
			type = 'user'
		}
		return {
			type : type,
			date: dateConvert(data.created_at, 'ago'),
			sender: data.sender,
			document: {
				...data.payload.document,
				name: data.payload.document.name,
				file: data.payload.document.file_type,
				status: data.payload.document.status
			},
			project: data.payload.project ? {
				name: data.payload.project.name
			} : null,
			viewed : data.viewed,
			note: data.type
		}
	}

	useEffect(() => {
		setItem(convertNote(notification))
	}, [])
	if (item === null){
		return false
	}
	return (
		<>
			{item.type === 'user' ? (
				<div className={"notifications-list-item notifications-list-item-user " + (activeLang === 'ar' ? 'notifications-list-item-arabic' : '')}>
					<div className="notifications-list-item-image">
						<img src={item.sender?.picture} alt="" className="notifications-list-item-avatar"/>
					</div>
						<div className="notifications-list-item-info">
							<div className="notification-owner-name-wrapper">
								<div className="notification-owner-name">{item.sender?.first_name + ' ' + item.sender?.last_name}</div>
								<div className="notification-owner-do">
									{lang.notificationsDos[item.note]}
									<div className="notification-owner-changed" onDoubleClick={(event) => {
									history.push(`/document/${item.document._id}?referer=projects&docname=${item.document.files[item.document.files.length - 1].filename}`)
									}}>{item.document.name}
									</div>
								</div>

							</div>
						</div>
					<div className="notification-date">{item.date}</div>
					{!item.viewed ? <span className="notification-new"></span> : ''}
				</div>
			) : (
				<div className={"notifications-list-item notifications-list-item-file " + (activeLang === 'ar' ? 'notifications-list-item-arabic' : '')}>
					<div className="notifications-list-item-image">
						<img src={require(`../../../assets/img/files/${item.document.file}.svg`)} alt="" className="notifications-list-item-icon"/>
					</div>
						<div className="notifications-list-item-info">
							<div className="notification-file-name" onDoubleClick={(event) => {
								history.push(`/document/${item.document._id}?referer=projects&docname=${item.document.files[item.document.files.length - 1].filename}`)
							}}>{item.document.name}</div>
							<div className="notification-file-description">
								<div className="notification-file-do">{lang.notificationsDos[item.note]}</div>
								{item.project ? (
									<div className="notification-file-project">{item.project.name}</div>
								) : (
									<div className="notification-file-status">
										<span className={item.document.status.toLowerCase().replace('_', ' ')}></span>
										{/* {lang.statuses[notification.status.toLowerCase()]} */}
										{item.document.status.toLowerCase().replace('_', ' ')}
									</div>
								)}
							</div>
						</div>
					<div className="notification-date">{notification.date}</div>
					{!item.viewed ? <span className="notification-new"></span> : ''}
				</div>
			)}
		</>
	)
}

export default NotificationItem