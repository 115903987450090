import React, { useContext, useState } from 'react'

import { Editor } from '@tinymce/tinymce-react';

import Button from '../Button/Button'


import { AppContext } from '../../context/AppContext'
import { NavContext } from '../../context/NavContext'

import {ReactComponent as Close} from '../../assets/img/close.svg'
import {ReactComponent as Upload} from '../../assets/img/download.svg'


import './Signing.scss'
import './SigningMedia.scss'

function Signing(){

	const isMobile = useContext(AppContext).isMobile
	const activeLang = useContext(AppContext).activeLang
	const lang = useContext(AppContext).lang
	const context = useContext(NavContext)

	let [saveCircle, setSaveCircle] = useState(false)

	let [activeMain, setActiveMain] = useState(0)

	let [signatureInput, setSignatureInput] = useState('')
	let [initialsInput, setInitialsInput] = useState('')

	return (
		<div className="signing">
			<div className={"my-signature-popup-wrapper " + (context && context.isOpenMySignaturePopup  ? 'my-signature-popup-wrapper-active' : '')}  onClick={event => {
					if(event.target.classList.contains('my-signature-popup-wrapper-active')){
						context.setIsOpenMySignaturePopup(false)
					}
				}}>
				<div className="my-signature-popup">
					<div className="my-signature-header">
						<div className="my-signature-title">My signatures</div>
						<Close onClick={() => context.setIsOpenMySignaturePopup(false)}/>
					</div>
					<div className="my-signature-body">
						<div className="my-signature-body-title">Please create your signature by choosing one of the three options.</div>
						<div className="my-signature-menu">
							<div className={"menu-item " + (activeMain === 0 ? 'menu-item-active' : '')} onClick={() => setActiveMain(0)}>DRAWN STYLE</div>
							<div className={"menu-item " + (activeMain === 1 ? 'menu-item-active' : '')} onClick={() => setActiveMain(1)}>TYPED STYLE</div>
							<div className={"menu-item " + (activeMain === 2 ? 'menu-item-active' : '')} onClick={() => setActiveMain(2)}>upload signature</div>
						</div>
						<div className="my-signature-content">
							<div className="signature-wrapper">
								<div className="title">Signature</div>
								<div className="signature-input  signature-input-full">
									{activeMain === 2 ? (
										<div className="upload-wrapper">
											<input type="file" id="signature"/>
											<label htmlFor="signature" className="upload-btn">
												<Upload/>
												<span>Upload</span>
											</label>
										</div>
									) : (
										<>
											<div className="input-nav">
												<Close/>
												<span></span>
											</div>
											<input type="text" value={signatureInput} onChange={event => setSignatureInput(event.target.value)}/>
										</>
									)}
								</div>
								<div className="signature-btn" onClick={() => {
									if(activeMain === 0){
										setSignatureInput('')
									} else {
										return false
									}
								}}>{activeMain === 1 ? 'Change font' : (activeMain === 2 ? 'Remove' : 'Clear')}</div>
							</div>
							<div className="signature-wrapper">
								<div className="title">Initials</div>
								<div className="signature-input">
									{activeMain === 2 ? (
										<div className="upload-wrapper">
											<input type="file" id="initials"/>
											<label htmlFor="signature" className="upload-btn">
												<Upload/>
												<span>Upload</span>
											</label>
									</div>
									) : (
										<>
											<div className="input-nav">
												<Close/>
												<span></span>
											</div>
											<input type="text" value={initialsInput} onChange={event => setInitialsInput(event.target.value)}/>
										</>
									)}
								</div>
								{activeMain === 1 ? '' : <div className="signature-btn" onClick={() => setInitialsInput('')}>{activeMain === 2 ? 'Remove' : 'Clear'}</div>}
							</div>
						</div>
						<div className="my-signature-save">
							<div className={"runner " + (saveCircle === 1 ? 'runner-active' : '')} onClick={() => setSaveCircle(saveCircle === 1 ? 0 : 1)}>
								<div className="runner-circle"></div>
							</div>
							<span>To save time, do you want your initials applied on all pages?</span>
						</div>
						<div className="my-signature-nav">
							<div className="my-signature-nav-text">
								By clicking Save, I have read and understood the  <span>Terms of Use.</span>  I agree my electronic signature and initials shall have the same force and effect as my written signature or initial.
							</div>
							<div className="my-signature-btns">
								<Button color={'white'} padding={12} margin={10} onClick={() => {
									context.setIsOpenMySignaturePopup(false)
								}}>{lang.cancel}</Button>
								<Button color={'blue'} padding={12} disabled={signatureInput.length > 0 ? false : true} onClick={() => {
									context.setIsOpenMySignaturePopup(false)
									context.setMySignature(true)
								}}>{lang.save}</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="signing-body">
				<Editor
					initialValue={lang.editorInitial}
					init={{
						height: isMobile ? 'calc(100vh - 60px)' : 1270,
						width: 840,
						menubar: false,
						directionality: activeLang === 'ar' ? 'rtl' : 'ltr',
						content_css: '/editor.css',
						toolbar: false
					}}
					onInit={() => {
						window.document.querySelector('iframe').contentWindow.document.querySelector('#tinymce').setAttribute('contenteditable', false)
					}}
				/>
			</div>
		</div>
	)
}

export default Signing
