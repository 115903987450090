import React, {useState, useEffect, useRef} from 'react';
import {
		BrowserRouter as Router,
		Switch,
		Route,
		// Link,
		Redirect,
	} from "react-router-dom";
import history from './utilites/history.js';

import {AppContext} from './context/AppContext'

import Login from './components/Login/Login'
import Nav from './components/Nav/Nav'
import Dashboard from './components/Dashboard/Dashboard'
import Search from './components/Dashboard/Search/Search'
import Notifications from './components/Dashboard/Notifications/Notifications'
import Profile from './components/Dashboard/Profile/Profile'
import Companies from './components/Companies/Companies'
import Projects from './components/Projects/Projects';
import Templates from './components/Templates/Templates'
import Document from './components/Document/Document'
import Details from './components/Details/Details';
import Timeline from './components/Timeline/Timeline';
import Settings from './components/Settings/Settings'
import Signing from './components/Signing/Signing'
import Reset from './components/Reset/Reset'

import {isLogin, login} from './utilites/auth'
import { lang, getLang} from './utilites/lang'
import { randomColor } from './utilites/appfunctions.js';

import './App.scss';
import Protection from './components/Protection/Protection.jsx';
import {AuthRoute} from "./authRoutes";
import {roles} from "./settings/roles";
import Loader from "./components/Loader/Loader";


const axios = require('axios').default;

function App() {
	let [load, setLoad] = useState(false)
	let [isLoginPage, setIsLoginPage] = useState(null)
	let [isUserAuth, setIsUserAuth] = useState(null);
	let inputSearch = useRef(null)
	let [user, setUser] = useState(null)
	let [isMobile, setIsMobile] = useState(window.document.documentElement.clientWidth <= 600)
	let [isActivePageNavMobile, setIsActivePageNavMobile] = useState(false)
	let [isActiveMobileSearch, setIsActiveMobileSearch] = useState(false)

	let [activeLang, setActiveLang] = useState(getLang() || 'en')

	useEffect(() => {
		async function getUser(){
			setUser(await axios.get('/api/v1/account').then(response => {
				return {
					...response.data.data,
					picture: response.data.data.picture,
					bgc: randomColor(),
					title: response.data.data.title === 'undefined' ? '' : response.data.data.title,
					company: response.data.data.company === 'undefined' ? '' :  response.data.data.company
				}
			}))
		}
		if(isLoginPage !== null){
			getUser()
		}
		setLoad(true)
	}, [isLoginPage])

	const resizeHandler = () => {
		setIsMobile(window.document.documentElement.clientWidth <= 600)
	}

	useEffect(  () => {
		async function checkLogin(){
			const isAuth =  await isLogin();
			if(!window.location.pathname.includes('share')){
				setIsLoginPage(!isAuth)
				setIsUserAuth(isAuth)
			}

		}

		async function signUp(){
			await login('', '');
			setIsLoginPage(false)
			history.push("/dashboard")
		}

		// setUser(userData) //get user
		//checkLogin();
		signUp();

		window.addEventListener('resize', resizeHandler)
		return () => window.removeEventListener('resize', resizeHandler)
	},[])
	return (
		<AppContext.Provider value={{user: user || null, setIsLoginPage: setIsLoginPage, setUser: setUser, lang: lang[activeLang], activeLang: activeLang, setActiveLang: setActiveLang, isMobile: isMobile, isActivePageNavMobile: isActivePageNavMobile, setIsActivePageNavMobile : setIsActivePageNavMobile, isActiveMobileSearch: isActiveMobileSearch, setIsActiveMobileSearch: setIsActiveMobileSearch}}>
			<Router history={history}>
				<Loader isVisible={isLoginPage === null}/>
				<div className={"application " + (activeLang === 'ar' ? 'application-arabic' : '')} style={{opacity: load ? 1 : 0}}>
					<Nav isLoginPage={isLoginPage} inputSearch={inputSearch}>
							<Switch>
								<Route exact path='/login'>
									<Login header={lang[activeLang].signIn} title={lang[activeLang].loginTitle}
										   mode='login' setIsLoginPage={setIsLoginPage}/>
								</Route>
								<Route exact path='/recovery'>
									<Login header={lang[activeLang].recovery} title={lang[activeLang].recoveryTitle}
										   mode='recovery'/>
								</Route>
								<Route exact path='/account/reset-password/:token'>
									<Reset/>
								</Route>
								<AuthRoute
									exact
									path="/dashboard/search/:searchstring"
									component={Search}
									inputSearch={inputSearch}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/dashboard/notifications"
									component={Notifications}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/dashboard/profile"
									component={Profile}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/dashboard"
									component={Dashboard}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/companies"
									component={Companies}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/companies/:id"
									component={Companies}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/projects"
									component={Projects}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/projects/:id"
									component={Projects}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/projects/new"
									component={Projects}
									newProject={true}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/templates"
									component={Templates}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/templates/:id"
									component={Templates}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/document/details/:id"
									component={Details}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/document/timeline/:id"
									component={Timeline}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/document/:id"
									component={Document}
									isLoginPage={isLoginPage}
								/>

								{/* <AuthRoute
									exact
									path="/protection/:link"
									component={Protection}
									isLoginPage={isLoginPage}
								/> */}
								<AuthRoute
									exact
									path="/setting"
									component={Settings}
									roleAccess={[roles.admin, roles.superAdmin]}
									isLoginPage={isLoginPage}
								/>
								<AuthRoute
									exact
									path="/signing"
									component={Signing}
									isLoginPage={isLoginPage}
								/>
								<Route path="/protection/:link">
									<Protection/>
								</Route>
								<Route exact path='/share/:link'>
									<Document/>
								</Route>
								 <Redirect to="/dashboard"/>
							</Switch>
					</Nav>
				</div>
			</Router>
		</AppContext.Provider>
	)
}

// const userData = {
// 	id: 12,
// 	avatar: 'test_avatar.png',
// 	firstName: 'Brendon',
// 	lastName: 'Davis',
// 	title: 'Senior Project Manager',
// 	email: 'brendon.davis@lawfirm.com',
// 	password: '12345678',
// 	company: 'Tesla'
// }


export default App;
