import React, { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import Button from '../Button/Button'
import CalendarComponent from '../CalendarComponent/CalendarComponent'

import {ReactComponent as Calendar} from '../../assets/img/calendar.svg'

import {AppContext} from '../../context/AppContext'

import {
	downloadTimelineDocument,
	getDocumentTimeline
} from '../../utilites/api'

import {
	dateConvert
} from '../../utilites/appfunctions'

import './Timeline.scss'
import './TimelineMedia.scss'
import {month} from "../../utilites/month";

function Timeline(props){

	const {id} = useParams()
	const  {lang, activeLang, isMobile} = useContext(AppContext)
	const docName = new URLSearchParams(props.location.search).get("docname");
	const referer = new URLSearchParams(props.location.search).get("referer");
	const groupId =  new URLSearchParams(props.location.search).get("groupid");

	const [timeline, setTimeline] = useState([])
	const [timelineConst, setTimelineConst] = useState([])
	const [timelineFilter, setTimelineFilter] = useState(3)
	const [isOpenCalendar, setIsOpenCalendar] = useState(false)
	let convertedList = []
	let viewDate = ''



	const convertTimeline = data => {
		return data.filter(item => !(item.type === 'document.content.edited')).sort((item, next) => item.created_at - next.created_at).map(item => {
			return {
				...item,
				date: dateConvert(item.created_at, 'sd')
			}
		})
	}
	const convertListToPdf = timeline => {
		let currentHeight = 0;
		let tmp = []
		timeline.map((time, index) => {
			currentHeight += 105
			if(time.date !== viewDate && index === 0) {
				currentHeight += 55
			} else if(time.date !== viewDate && index !== 0){
				currentHeight += 95
			}

			if(currentHeight > 1200 || timeline.length - 1 === index){
				convertedList.push(tmp)
				tmp = []
				currentHeight = currentHeight - 1200
			}

			viewDate = time.date
			tmp.push(time)
		})
		viewDate = ''
	}


	const filterTimeline = (data, flag, date = false) => {
		return data.filter(item => {
			let now = new Date()
			if(flag === 0){
				if(new Date(item.created_at).getDay() === now.getDay() && now.getTime() - new Date(item.created_at).getTime() < (24*3600*1000)){
					return item
				}
				else {
					return false
				}
			} else if (flag === 1){
				if(now.getTime() - new Date(item.created_at).getTime() < (7*24*3600*1000)){
					return item
				}
				else {
					return false
				}
			} else if (flag === 2){
				if(now.getTime() - new Date(item.created_at).getTime() < (31*24*3600*1000)){
					return item
				}
				else {
					return false
				}
			} else if (flag === 'time'){
				if(new Date(item.created_at).getDay() === new Date(date).getDay()){
					return item
				} else {
					return false
				}
			} else {
				return item
			}
		})
	}

	const changeCalendar = value => {
		setTimeline(filterTimeline(timelineConst, 'time', value))
	}

	useEffect(() => {
		setTimeline(filterTimeline(timelineConst, timelineFilter))
	}, [timelineFilter])

	useEffect(() => {
		console.log(timeline)
	}, [timeline])

	useEffect(() => {
		async function getTimelineData(){
			getDocumentTimeline(id)
			.then(res => {
				// console.log(res)
				setTimeline(convertTimeline(res.timeline))
				setTimelineConst(convertTimeline(res.timeline))
			})
		}
		getTimelineData()
	}, [])
	return (
		<div className={"timeline " + (activeLang === 'ar' ? 'timeline-arabic' : '')}>
			<div className="timeline-header">
				<div className="back-link">
					<Link to={'/document/' + id + window.location.search} className="back-btn">
						<svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2.45446 9.64586L24.0908 9.64586" stroke="#2E384D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M11.3408 18.0908L1.29537 9.59082L11.3408 1.09082" stroke="#2E384D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</Link>
					<span>{lang.timeline}</span>
				</div>
				<div className="timeline-filters">
					<Button color={'white'} padding={12} margin={10} style={timelineFilter === 0 ? {} : {borderColor: '#BFC5D2', color: '#BFC5D2', background: '#ffffff'}} onClick={() => setTimelineFilter(prev => prev === 0 ? 3 : 0)}>{lang.today}</Button>
					<Button color={'white'} padding={12} margin={10} style={timelineFilter === 1 ? {} : {borderColor: '#BFC5D2', color: '#BFC5D2', background: '#ffffff'}} onClick={() => setTimelineFilter(prev => prev === 1 ? 3 : 1)}>{isMobile ? '1 W' : lang.week}</Button>
					<Button color={'white'} padding={12} margin={10} style={timelineFilter === 2 ? {} : {borderColor: '#BFC5D2', color: '#BFC5D2', background: '#ffffff'}} onClick={() => setTimelineFilter(prev => prev === 2 ? 3 : 2)}>{isMobile ? '1 M' : lang.month}</Button>
					<Button color={'white'} padding={12} margin={10} style={timelineFilter === 3 ? {} : {borderColor: '#BFC5D2', color: '#BFC5D2', background: '#ffffff'}} onClick={() => setTimelineFilter(prev => prev === 3 ? 3 : 3)}>{isMobile ? 'All' : lang.allTime}</Button>
					<span className={"calendar-wrapper " + (isOpenCalendar ? 'calendar-wrapper-active' : '')} onClick={event => event.target.classList.contains('calendar-wrapper') || event.target.parentElement.classList.contains('calendar-wrapper') || event.target.parentElement.parentElement.classList.contains('calendar-wrapper') ? setIsOpenCalendar(prev => !prev) : false}>
						<Calendar/>
						{isOpenCalendar ? <CalendarComponent style={{display: isOpenCalendar ? 'flex' : 'none'}} changeCalendar={changeCalendar} /> : ''}
					</span>
				</div>
                <div className="timeline-prints">
                    {/* <Button color={'white'} padding={12} margin={10} onClick={async () => {
                        await downloadTimelineDocument(id, referer, groupId, docName, activeLang, false);

                    }}>{isMobile ? 'Print' : lang.print}</Button> */}
                    <Button onClick={async () => {
                        await downloadTimelineDocument(id, referer, groupId, docName, activeLang, true);
                    }} color={'white'} padding={12}>{isMobile ? lang.download : lang.downloadReport}</Button>

                </div>
			</div>
			<div className="timeline-body">
				{convertListToPdf(timeline)}
				{ convertedList.map((timeline, indexTimeline) => {
					return ( <div className={"pdf-page"}> { timeline.map((time, indexTime) => {
						return (
							<>

								{/* {index === 0 ? (
								<>
									<div className="timeline-date">{time.date}</div>
									<span className="visible-line"></span>
								</>
							) : ''} */}
								{(function () {
									if(time.date !== viewDate && (indexTime === 0 && indexTimeline === 0)){
										viewDate = time.date
										return (
											<>
												<div className="timeline-date">{time.date}</div>
												<span className="visible-line"></span>
											</>
										)
									} else if (time.date !== viewDate){
										viewDate = time.date
										return (
											<>
												<span className="visible-line"></span>
												<span className="visible-line"></span>
												<div className="timeline-date">{time.date}</div>
												<span className="visible-line"></span>
											</>
										)
									}
								}())}
								<div className="timeline-item">
									<div className="timeline-item-header">
										<span className="timeline-time">{dateConvert(time.created_at, 't')}</span>
										<div className="timeline-data">
											<img src={time.owner.picture} alt=""/>
											{isMobile ? (
												<div className="mobile-wrapper">
													<span className="timeline-owner-name">{time.owner.first_name + ' ' + time.owner.last_name}</span>
													{(() => {
														if(time.type === 'comment.create'){
															return (
																<div className="timeline-commented">{lang.timelineDos[time.type]}</div>
															)
														} else if (time.type === 'createdVersion'){
															return (
																<div className="timeline-created-version">{lang.timelineDos[time.type]}<span>{time.edit}</span> of this document</div>
															)
														} else if (time.type === 'document.name'){
															return (
																<div className="timeline-changed-name">{lang.timelineDos[time.type]}<span>{time.payload.name}</span></div>
															)
														} else if (time.type === 'query.create'){
															return (
																<div className="timeline-issue">{lang.timelineDos[time.type]}</div>
															)
														} else if (time.type === 'query.resolved'){
															return (
																<div className="timeline-resolve-comment">{lang.timelineDos[time.type]}</div>
															)
														} else if (time.type === 'document.published'){
															return (
																<div className="timeline-change-status">{'changed document'}</div>
															)
														} else if (time.type === 'document.status'){
															return <div className="timeline-change-status">{lang.timelineDos[time.type]}<span>{time.payload.status}</span></div>
														}
													})()}
												</div>
											) : (
												<>
													<span className="timeline-owner-name">{time.owner.first_name + ' ' + time.owner.last_name}</span>
													{(() => {
														if(time.type === 'comment.create'){
															return (
																<div className="timeline-commented">{lang.timelineDos[time.type]}</div>
															)
														} else if (time.type === 'createdVersion'){
															return (
																<div className="timeline-created-version">{lang.timelineDos[time.type]}<span>{time.edit}</span> of this document</div>
															)
														} else if (time.type === 'document.name'){
															return (
																<div className="timeline-changed-name">{lang.timelineDos[time.type]}<span>{time.payload.name}</span></div>
															)
														} else if (time.type === 'query.create'){
															return (
																<div className="timeline-issue">{lang.timelineDos[time.type]}</div>
															)
														} else if (time.type === 'query.resolved'){
															return (
																<div className="timeline-resolve-comment">{lang.timelineDos[time.type]}</div>
															)
														} else if (time.type === 'document.published'){
															return (
																<div className="timeline-change-status">{'changed document'}</div>
															)
														} else if (time.type === 'document.status'){
															return <div className="timeline-change-status">{lang.timelineDos[time.type]}<span>{time.payload.status}</span></div>
														}
													})()}
												</>
											)}
										</div>
										{time.type !== 'changeName' && time.type !== 'changedStatus' ? (
											<Link to={'/document/' + id + window.location.search} className="link-view">View</Link>
										) : ''}
									</div>
									{time.type === 'createdVersion' ? (
										<span className="timeline-preview">preview</span>
									) : ''}
								</div>
								{indexTimeline !== convertedList.length - 1 || (indexTime !== timeline.length - 1 && indexTimeline === convertedList.length - 1)  ? (
									<span className="visible-line"></span>
								) : ''}

							</>
						)
					})}
					</div>)
				}) }
			</div>
		</div>
	)
}


export default Timeline