import React, { useContext, useState, useEffect } from 'react'

import Button from '../../Button/Button'
import Push from '../../Push/Push'


import {AppContext} from '../../../context/AppContext'


import {ReactComponent as Close} from '../../../assets/img/close.svg'
import {ReactComponent as Arrow} from '../../../assets/img/arrow.svg'
import {ReactComponent as Search} from '../../../assets/img/search.svg'
import {ReactComponent as Plus} from '../../../assets/img/plus.svg'

import generator from 'generate-password-browser'

import {
	userSearch,
	shareDocument,
	linkUserToDoc,
	unlinkUserFromDoc,
	shareTemplate, getSettings
} from '../../../utilites/api'

import {
	addCollaboratorToDocument, 
	removeCollaboratorFromDocument,
	openCollabTypeSelectDocument,
	changeCollabTypeDocument
} from '../../../utilites/table'

import './ShareFiles.scss'
import './ShareFilesMedia.scss'
import { randomColor } from '../../../utilites/appfunctions'

function ShareFiles({share, setShare, setPush, setPushText, pushText, item, setItem, document = null, setDocument, doc, folders = false, detectFolder = () => false, path = '/', isTemplate = false}){
	const [securityType, setSecurityType] = useState(0)
	const [securiryListIsOpen, setSecurityListIsOpen] = useState(false)
	const [copyLink, setCopyLink] = useState(false)
	const [link, setLink] = useState('')
	const [isVisibleLink, setIsVisibleLink] = useState(true)
	const [password, setPassword] = useState('')

	const [collabSearch, setCollabSearch] = useState('')
	const [collabSearchResults, setCollabSearchResults] = useState([])
	const [settings, setSettings] = useState([])
	const { lang, activeLang} = useContext(AppContext)


	const securityTypeList = [
		lang.restricted,
		lang.passwordProtected
	]

	const collabTypesList = [
		'Viewer',
		'Editor',
	]

	const createLink = () => {
		if(item){
			if(item.documents.filter(item => item.isSelect)[0]){
				let doc = item.documents.filter(item => item.isSelect)[0]
				return {
					id : doc.id,
					filename : isTemplate ? doc.filename : doc.files[doc.files.length - 1].filename,
					doc: doc
				}
			}
		}
		if(item?.favDoc?.filter(item => item.isSelect)[0]){
			let doc = item.favDoc.filter(item => item.isSelect)[0]
			return {
				id : doc.id,
				filename : isTemplate ? doc.filename : doc.files[doc.files.length - 1].filename,
				doc: doc
			}
		}
	}

	useEffect(() => {
		if(collabSearch.length > 0){
			userSearch(collabSearch)
			.then(res => {
				setCollabSearchResults(res.map(item => {
					return {
						...item,
						fullName: item.first_name + ' ' + item.last_name,
						email : item.email,
						id: item._id,
						avatar: item.picture,
						bgc: randomColor()
					}
				}).filter(item => {
					if(document){
						if(document.users.map(item => item.user_id).includes(item._id)){
							return false
						} else {
							return true
						}
					} else {
						if(doc.users.map(item => item.user_id).includes(item._id)){
							return false
						} else {
							return true
						}
					}
				}))
			})
		}
	}, [collabSearch])

	// useEffect(() => {
	// 	setLink(createLink())
	// }, [list])

	useEffect(() => {
		getSettings().then(res => setSettings(res))
		console.log(settings)
	},[])
	useEffect(() => {

		async function getSet() {
			getSettings().then(res => console.log(res))
		}
		getSet().then(res => console.log(res))
		setIsVisibleLink(false)
		if(document || createLink() || doc){
			let pass = generator.generate({
				length: 8,
				numbers: true,
				symbols: true,
				uppercase: false
			})
			setPassword(pass)
			if(securityType === 0){
				if(isTemplate){
					setLink(`${window.location.origin}/document/${document ? document.id : createLink().id}?referer=templates&groupid=${document ? '' : createLink().doc.group_id}&docname=${document ? document.files[document.files.length - 1]?.filename : createLink().doc.filename}`)
					setIsVisibleLink(true)
				} else {
					setLink(`${window.location.origin}/document/${document ? document.id : createLink().id}?referer=projects&groupid=${document ? '' : createLink().doc.details.filter(item => item.field.name === 'Project').length > 0 ? createLink().doc.details.filter(item => item.field.name === 'Project')[0].field._id : createLink().doc.details.filter(item => item.field.name === 'Company')[0].field._id}&docname=${document ? document.files[document.files.length - 1]?.filename : createLink().doc.files[createLink().doc.files.length - 1]?.filename}`)
					setIsVisibleLink(true)
				}
			} else if (securityType === 1) {
				if(isTemplate){
					shareTemplate(createLink().id, createLink().filename, securityType === 1 && pass.length > 0 ? pass : null)
					.then(res => {
						setLink(res.link.replace('-template', '') + '&isTemplate=true')
						setIsVisibleLink(true)
					})
				} else {
					shareDocument(document ? document.id : (doc ? doc.id : createLink().id),document ? document.files[document.files.length - 1]?.filename : (doc ? doc.files[doc.files.length - 1]?.filename : createLink().filename), securityType === 1 && pass.length > 0 ? pass : null)
					.then(res => {
						const linkTest = res.link.split('/share-file')
						const link2 = linkTest[0].replace(linkTest[0], settings.url)
						const link3 = link2 + '/share-file' + linkTest[1]
						console.log('link', link3)
						setLink(link3.replace('-file', ''))
						setIsVisibleLink(true)
					})
				}
				// setIsVisibleLink(false)
			}
		}
	}, [securityType, share])
	return (
	<div className={"share-popup-wrapper " + (activeLang === 'ar' ? 'share-popup-wrapper-arabic ' : '') + (share ? 'share-popup-wrapper-active' : '')} onClick={(event) => {	
		if(event.target.classList.contains('share-popup-wrapper-active')){
			setShare(false)
		}
	}}>
		<div className="share-popup">
			<div className="share-popup-header">
				<div className="header-title">{lang.share}</div>
				<Close onClick={() => setShare(false)}/>
				{copyLink ? <Push top={22} padding={8}>{pushText}</Push> : ''}
			</div>
			<div className="share-popup-body">
				{isTemplate ? '' : (
					<>
						<div className="add-collab-search-wrapper" style={collabSearch.length > 0 ? {borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : {}}>
						<Search/>
						<input type="text" className="add-collab-search-input" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} placeholder={lang.searchCollabs} value={collabSearch} onChange={event => setCollabSearch(event.target.value)}/>
						<div className="collab-search-results" style={collabSearch.length === 0 ? {opacity: 0, visibility: 'hidden'} : {}}>
							{collabSearchResults.map((item, index) => {
								return (
									<div className="result-item" key={index} >
										<div className="result-item-left">
											{/* <img src={item.avatar} alt=""/> */}
											{item.avatar ? <img src={item.avatar} alt="" key={index}/> : (
												<div className="none-avatar" key={index} style={{backgroundColor: item.bgc}}>
													{((item.last_name ? item.first_name[0] : '') + (item.last_name ? item.last_name[0] : '')).toUpperCase()}
												</div>
											)}
											<div className="result-item-wrapper-name">
												<div className="item-name">{item.fullName}</div>
												<div className="item-email">{item.email}</div>
											</div>
										</div>
										<div className="result-item-add-collab" onClick={() => {
											if(document){
												setDocument(document => {
													return{
														...document,
														users: document.users.concat({...item, permission: 'viewer', isOpenSelect: false, user_id: item})
													}
												})
												linkUserToDoc(document.id, item.id, 'viewer')
											} else {
												addCollaboratorToDocument(setItem, {...item, permission: 'viewer', isOpenSelect: false, user_id: item}, doc.id, path)
												linkUserToDoc(doc.id, item.id, 'viewer')
											}
											// addCollaborator(setProjects, projects, index, setCollabSearchResults, collabSearchResults)
											// setCollabListForActiveProject(prev => prev.concat(item))
											setCollabSearch('')
										}}>
											<Plus/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
					<div className="collab-list">
						{(document ? document : doc) ? (document ? document : doc).users?.map((collab, index) => {
							if(collab.user_id === null || collab.user_id === undefined){
								return ('')
							}
							return (
								<div className="collab-list-item" key={index}>
									<div className="collab-list-item-left">
										{/* <img src={collab.avatar} alt=""/>
										<div className="collab-list-item-wrapper-name">
											<div className="item-name">{collab.name}</div>
											<div className="item-email">{collab.email}</div>
										</div> */}
										{collab.user_id?.picture ? <img src={collab.user_id.picture} alt="" key={index}/> : (
											<div className="none-avatar" key={index} style={{backgroundColor: collab.bgc}}>
												{((collab.user_id?.first_name ? collab.user_id.first_name[0] : '') + (collab.user_id?.last_name ? collab.user_id.last_name[0] : '')).toUpperCase()}
											</div>
										)}
										<div className="collab-list-item-wrapper-name">
											<div className="item-name">{collab.user_id?.first_name + ' ' + collab.user_id?.last_name}</div>
											<div className="item-email">{collab.email}</div>
										</div>
									</div>
									<div className="collab-list-item-right">
										{collab.permission === 'owner' ? (
											<div className="collab-type">{collab.permission[0].toUpperCase() + collab.permission.slice(1)}</div>
										) : (
											<div className="collab-type-select">
												<div className={"type-select-active "} onClick={() => {
														if(document){
															setDocument(document => {
																return {
																	...document,
																	users: document.users.map(item => {
																		if(item.user_id === collab.user_id){
																			return {
																				...item,
																				isOpenSelect: !item.isOpenSelect
																			}
																		}
																		return {
																			...item,
																			isOpenSelect: false
																		}
																	})
																}
															})
														} else {
															openCollabTypeSelectDocument(setItem, doc.id, collab.user_id, path)
														}
														
													}}>
														<div className="type-name">{collab.permission[0].toUpperCase() + collab.permission.slice(1)}</div>
														<Arrow/>
												</div>
												<div className={"type-select-list " + (collab.isOpenSelect ? 'type-select-list-open' : '')}>
													{collabTypesList.map((type, i) => {
														if(type.toLowerCase() === collab.permission){
															return false
														}
														return (
															<div className={"type-list-item "} key={i} onClick={event => {
																// changeCollabTypeDocument(setList, list, index, type.toLowerCase())
																if(document){
																	setDocument(document => {
																		return {
																			...document,
																			users: document.users.map(item => {
																				if(item.user_id === collab.user_id){
																					return {
																						...item,
																						permission: type.toLowerCase(),
																						isOpenSelect: false
																					}
																				}
																				return {
																					...item,
																					isOpenSelect: false
																				}
																			})
																		}
																	})
																	linkUserToDoc(document.id, collab.user_id._id, type.toLowerCase())
																} else {
																	changeCollabTypeDocument (setItem, doc?.id,  collab.user_id, type.toLowerCase(), path)
																}
															}}>
																{type[0].toUpperCase() + type.slice(1)}
															</div>
														)
													})}
												</div>
											</div>
										)}
										<div className={"collab-remove " + (collab.permission === 'owner' ? 'collab-remove-disable' : '')} onClick={() => {
											if(collab.permission === 'owner'){
												return false
											} else {
												if(document){
													setDocument(document => {
														return {
															...document,
															users: document.users.filter(user => !(user.user_id === collab.user_id))
														}
													})
												} else {
													removeCollaboratorFromDocument(setItem, doc?.id, collab.user_id, path)
												}
												
												unlinkUserFromDoc(document ? document.id : doc?.id, collab.user_id._id)
											}
										}}>
											<span></span>
										</div>
									</div>
								</div>
							)
						}) : ''}
					</div>
					</>
				)}
				
				<div className="share-collab">
					<div className="share-header"><span>{lang.getLink}</span></div>
					<div className="security-doc">
						<div className="security-wrapper-input">
							<div className="security-doc-header">{lang.security}</div>
								<div className="security-doc-select">
									<div className="select-active" onClick={() => {
											setSecurityListIsOpen(prev => !prev)
										}}>
										<span>{securityTypeList[securityType]}</span>
										<Arrow/>
									</div>
									<div className={"select-list " + (securiryListIsOpen ? 'select-list-active' : '')}>
										<div className="list-item" onClick={() => {
											setSecurityListIsOpen(false)
											setSecurityType(securityType === 0 ? 1 : 0)
										}}>
											{securityType === 0 ? securityTypeList[1] : securityTypeList[0]}
										</div>
									</div>
								</div>
							</div>
							{securityType === 1 ? (
								<div className="security-wrapper-input">
									<div className="security-doc-header">{lang.password}</div>
									<input type="text" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={password} onChange={event => setPassword(event.target.value)} readOnly={true} className="security-doc-pass"/>
								</div>
							) : ''}
						</div>
					<div className="link-wrapper">
						<input type="text" className="doc-link" value={isVisibleLink ? link : ''} onChange={() => {}}/>
						<Button color={'white'} padding={13} margin={0} onClick={() => {
							if(isVisibleLink){
								navigator.clipboard.writeText(link)
								setPushText(lang.linkCopiedTo)
								setCopyLink(true)
								setTimeout(() => {
									setCopyLink(false)
								}, 1200)
							}
						}}>{lang.copyLink}</Button>
						{/* <Button color={'blue'} padding={15} onClick={() => {
							shareDocument(document ? document.id : createLink().id,document ? document.files[document.files.length - 1]?.filename : createLink().filename, securityType === 1 && password.length > 0 ? password : null)
							.then(res => {
								setLink(res.link.replace('-file', ''))
								setIsVisibleLink(true)
							})
						}}>{'Get link'}</Button> */}
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

export default ShareFiles