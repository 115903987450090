// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .loader {
    top: 60px;
    height: calc(100vh - 60px); }
    .loader .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      list-style: none;
      margin-left: 16px; }
      .loader .loading li {
        height: 0;
        position: absolute;
        top: 50%;
        left: 0;
        width: 0;
        margin: 0;
        height: 10px;
        width: 10px;
        border: 3px solid #2b8ccd;
        border-radius: 100%;
        transform: transformZ(0);
        animation: LOADING 2s infinite; }
        .loader .loading li:nth-child(1n) {
          left: -20px;
          animation-delay: 0s; }
        .loader .loading li:nth-child(2n) {
          left: 0;
          animation-delay: 0.2s; }
        .loader .loading li:nth-child(3n) {
          left: 20px;
          animation-delay: 0.4s; } }
`, "",{"version":3,"sources":["webpack://./src/components/Loader/LoaderMedia.scss"],"names":[],"mappings":"AAAA;EACC;IAEC,SAAS;IACT,0BAA0B,EAAA;IAH3B;MAKE,aAAa;MACb,mBAAmB;MACnB,uBAAuB;MACvB,kBAAkB;MAClB,gBAAgB;MAChB,iBAAiB,EAAA;MAVnB;QAYG,SAAS;QACT,kBAAkB;QAClB,QAAQ;QACR,OAAO;QACP,QAAQ;QACR,SAAS;QACT,YAAY;QACZ,WAAW;QACX,yBAAyB;QACzB,mBAAmB;QACnB,wBAAwB;QACxB,8BAA8B,EAAA;QAvBjC;UAyBK,WAAW;UACX,mBAAmB,EAAA;QA1BxB;UA6BK,OAAO;UACP,qBAAqB,EAAA;QA9B1B;UAiCK,UAAU;UACV,qBAAqB,EAAA,EACtB","sourcesContent":["@media screen and (max-width: 600px){\n\t.loader{\n\n\t\ttop: 60px;\n\t\theight: calc(100vh - 60px);\n\t\t.loading{\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\t\t\tposition: relative;\n\t\t\tlist-style: none;\n\t\t\tmargin-left: 16px;\n\t\t\tli {\n\t\t\t\theight: 0;\n\t\t\t\tposition: absolute;\n\t\t\t\ttop: 50%;\n\t\t\t\tleft: 0;\n\t\t\t\twidth: 0;\n\t\t\t\tmargin: 0;\n\t\t\t\theight: 10px;\n\t\t\t\twidth: 10px;\n\t\t\t\tborder: 3px solid #2b8ccd;\n\t\t\t\tborder-radius: 100%;\n\t\t\t\ttransform: transformZ(0);\n\t\t\t\tanimation: LOADING 2s infinite;\n\t\t\t\t&:nth-child(1n) {\n\t\t\t\t  left: -20px;\n\t\t\t\t  animation-delay: 0s;\n\t\t\t\t}\n\t\t\t\t&:nth-child(2n) {\n\t\t\t\t  left: 0;\n\t\t\t\t  animation-delay: 0.2s;\n\t\t\t\t}\n\t\t\t\t&:nth-child(3n) {\n\t\t\t\t  left: 20px;\n\t\t\t\t  animation-delay: 0.4s;\n\t\t\t\t}\n\t\t\t  }\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
