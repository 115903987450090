import React, {useState, useEffect, useContext} from 'react'
import {Link, useLocation} from 'react-router-dom'

import {AppContext} from '../../../context/AppContext'

import {ReactComponent as DashboardIcon} from '../../../assets/img/menu/dashboard.svg'
import {ReactComponent as ProjectsIcon} from '../../../assets/img/menu/project.svg'
import {ReactComponent as CompaniesIcon} from '../../../assets/img/menu/companies.svg'
import {ReactComponent as TemplatesIcon} from '../../../assets/img/menu/templates.svg'
import {ReactComponent as SettingIcon} from '../../../assets/img/menu/setting.svg'

import './Menu.scss'
import './MenuMedia.scss'
import {roles} from "../../../settings/roles";
import {getUserRole} from "../../../utilites/token/getUserRole";


function Menu({navView, setNavView, isDocumentPage}) {

    let lang = useContext(AppContext).lang
    let activeLang = useContext(AppContext).activeLang

    let isMobile = useContext(AppContext).isMobile
	let userRole = getUserRole();
    let location = useLocation()
    let [nav, setNav] = useState([
        {
            icon: <DashboardIcon/>,
            name: lang.dashboard,
            link: '/dashboard',
            active: true
        },
        {
            icon: <ProjectsIcon/>,
            name: lang.projects,
            link: '/projects',
            active: false
        },
        {
            icon: <CompaniesIcon/>,
            name: lang.companies,
            link: '/companies',
            active: false
        },
        {
            icon: <TemplatesIcon/>,
            name: lang.templates,
            link: '/templates',
            active: false
        },
        {
            icon: <SettingIcon/>,
            name: lang.settings,
            link: '/setting',
            active: false,
            visibleFor: [roles.admin, roles.superAdmin]
        },
    ])


    let [activeIndex, setActiveIndex] = useState(0)


    useEffect(() => {
        setNav([{
            icon: <DashboardIcon/>,
            name: lang.dashboard,
            link: '/dashboard',
            active: location.pathname.includes('/dashboard')
        },
            {
                icon: <ProjectsIcon/>,
                name: lang.projects,
                link: '/projects',
                active: location.pathname.includes('/projects')
            },
            {
                icon: <CompaniesIcon/>,
                name: lang.companies,
                link: '/companies',
                active: location.pathname.includes('/companies')
            },
            {
                icon: <TemplatesIcon/>,
                name: lang.templates,
                link: '/templates',
                active: location.pathname.includes('/templates')
            },
            {
                icon: <SettingIcon/>,
                name: lang.settings,
                link: '/setting',
                active: location.pathname.includes('/setting'),
                visibleFor: [roles.admin, roles.superAdmin]
            }])
        //eslint-disable-next-line
    }, [activeLang])

    useEffect(() => {
        setNav(nav.map((item, index) => {
            if (location.pathname.includes(item.link)) {
                setActiveIndex(index * (isMobile ? 20 : 56))
                item.active = true
            } else {
                item.active = false
            }
            return item;
        }))
        //eslint-disable-next-line
    }, [])
    const updateNav = id => {
        setActiveIndex(id * (isMobile ? 20 : 56))
        setNav(nav.map((item, index) => {
            if (id === index) {
                item.active = true
            } else {
                item.active = false
            }
            return item;
        }))
    }

    useEffect(() => {
        if (location.pathname.includes('/dashboard') || location.pathname.includes('/setting')) {
            setNavView(true)
        } else {
            setNavView(false)
        }
        setNav(nav.map((item, index) => {
            if (location.pathname.includes(item.link) || location.search.includes(item.link.replace('/', ''))) {
                setActiveIndex(index * (isMobile ? 20 : 56))
                item.active = true
            } else {
                item.active = false
            }
            return item;
        }))
        //eslint-disable-next-line
    }, [location])

    return (
        <div
            className={"application-nav " + (!navView ? 'application-nav-reduced ' : '') + (isDocumentPage ? 'application-nav-document ' : '') + (activeLang === 'ar' ? 'application-nav-arabic' : '')}>
            {nav.map((item, index) => {
            	if(item.visibleFor && !item.visibleFor.includes(userRole)) {
            		return;
				}
                return (
						<Link to={item.link} className={"nav-item " + (item.active ? 'nav-item-active' : '')} key={index}
							  onClick={() => updateNav(index)}>
							{item.icon}
							<span className="nav-item-name">{item.name}</span>
						</Link>
                )
            })}
            <div className="nav-active-flag"
                 style={isMobile ? {[activeLang === 'ar' ? 'right' : 'left']: activeIndex + '%'} : {top: activeIndex}}></div>
        </div>
    )
}

export default Menu