import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { AppContext } from '../../../context/AppContext'

import searchIcon from '../../../assets/img/search.svg'
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg'
import {ReactComponent as CloseIcon} from '../../../assets/img/close.svg'

import {
	documentSearch
} from '../../../utilites/api'

import {
	addSearchItem,
	getRecentSearch
} from '../../../utilites/localstorage'

import {
	format
} from '../../../utilites/appfunctions'

import './SearchBar.scss'
import './SearchBarMedia.scss'


function SearchBar({setNotificationsIsOpen, setProfilePopupIsOpen, inputSearch}){
	let [search, setSearch] = useState('')
	let [searchResults, setSearchResults] = useState([])
	let [searchResultsIsOpen, setSearchResultsIsOpen] = useState(false)
	let [clearSearch, setClearSearch] = useState(false)

	const {isMobile, lang, activeLang, isActiveMobileSearch, setIsActiveMobileSearch} = useContext(AppContext)

	const history = useHistory()

	const searchConvert = data => {
		let parent;
		return data.data.filter(item => item).map(item => {
			if(item.details && (item.details.filter(detail => detail.field.name === 'Company')[0] || item.details.filter(detail => detail.field.name === 'Project')[0])){
				parent = item.details.filter(detail => detail.field.name === 'Company')[0].value ? item.details.filter(detail => detail.field.name === 'Company')[0].value : item.details.filter(detail => detail.field.name === 'Project')[0].value
			}
			return {...item, parent: parent,  name: item.name, file: format(item.file_type) || 'other', company: ''}
			
		}).filter(item => {
			if(!item.details.filter(detail => detail.field.name === 'Company')[0]?.value && !item.details.filter(detail => detail.field.name === 'Project')[0]?.value){
				return false
			}
			return true
		}).slice(0, 10)
	}

	useEffect(() => {
		setNotificationsIsOpen(false)
		setProfilePopupIsOpen(false)
		//request search
		if(search.length > 0){
			setClearSearch(true)
			documentSearch(search)
			.then((res) => {
				setSearchResults(searchConvert(res))
			})
			setSearchResultsIsOpen(true)
			// setSearchResults({
			// 	current : [
			// 		{
			// 			file: 'spe',
			// 			company: 'CRYTEK',
			// 			name: 'Project Recontruction Evaluation Proposal V2.1'
			// 		},
			// 		{
			// 			file: 'spe',
			// 			company: 'CRYTEK',
			// 			name: 'NDA v12'
			// 		}
			// 	],
			// 	old : [
			// 		'Crytek Contract Clause 1(a)',
			// 		'Adventure Park',
			// 		'Adventure Park'
			// 	]
			// })
		} else {
			setClearSearch(false)
			setSearchResultsIsOpen(false)
		}
		//eslint-disable-next-line
	}, [search])

	return (
		<>
			<div className={"search-bar-wrapper " + (searchResultsIsOpen ? 'search-bar-wrapper-active ' : '') + (isActiveMobileSearch ? 'search-bar-wrapper-active-mobile ' : '') + (activeLang === 'ar' ? 'search-bar-wrapper-arabic' : '')} onMouseLeave={() => setSearchResultsIsOpen(false)}>
				<div className="search-bar-input-wrapper">
					<img src={searchIcon} alt="search" className="search-icon"/>
					<input ref={inputSearch} type="text" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} className="search-input" placeholder={lang.search} value={search}
						   onChange={event => setSearch(event.target.value)}
						   onBlur={() => setSearchResultsIsOpen(false)}
						   onKeyDown={event => {
						if(event.keyCode === 13 && search.length > 0){
							history.push(`/dashboard/search/${search}`)
							addSearchItem(search)
							setSearchResultsIsOpen(false)
						}
					}}/>
					<button className={"search-clear " + (clearSearch ? 'search-clear-active' : '')} onClick={() => {
						setSearch('')
						setSearchResults([])
						}}>{lang.clearSearch}</button>
				</div>
				{searchResultsIsOpen ? (
					<div className="search-bar-results">
						<div className="search-bar-results-current">
							{searchResults ? searchResults.map((item, index) => {
								return (
									<div className="search-bar-result-item" key={index} onClick={() => {
										history.push(`/document/${item._id}?referer=${window.location.pathname.replace('/', '')}&groupid=${''}&docname=${item.files.pop().filename}`)
									}}>
										<img src={require(`../../../assets/img/files/${item.file}.svg`)} alt="search-logo" className="search-result-logo"/>
										<span className="search-result-company">{item.parent}</span>
										<span className="search-result-name">{item.name}</span>
									</div>
								)
							}) : ''}
						</div>
						{getRecentSearch().length > 0 ? (
							<div className="search-bar-results-old">
								<div className="search-bar-results-old-header">
									{lang.resultsSearch}
								</div>
								{getRecentSearch().map((item, index) => {
									return (
										<div className="search-bar-result-item" key={index} onClick={() => {
												setSearchResultsIsOpen(false)
												history.push(`/dashboard/search/${item}`)
												setSearch('')
											}}>
											<img src={require(`../../../assets/img/search.svg`)} alt="search-logo" className="search-result-logo"/>
											<span className="search-result-name">{item}</span>
										</div>
									)
								})}
							</div>
						) : ''}
					</div>
				) : ''}
				{isMobile && !isActiveMobileSearch ? (
					<SearchIcon onClick={() => {
						setNotificationsIsOpen(false)
						setProfilePopupIsOpen(false)
						setIsActiveMobileSearch(true)
					}}/>
				) : (
					<>
						{isMobile ? <CloseIcon  onClick={() => {
							setIsActiveMobileSearch(false)
							setSearch('')
						}}/> : ''}
					</>
					)}
			</div>
		</>
	)
}

export default SearchBar