import React, {useContext, useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import { AppContext } from '../../../context/AppContext.js'

import Button from '../../Button/Button'
import Push from '../../Push/Push'

import {resetToken, deleteUserAvatar, sendEmailNotification, updateProfile} from '../../../utilites/api'

import {ReactComponent as Check} from "../../../assets/img/check.svg";
import {ReactComponent as Delete} from "../../../assets/img/close.svg";

import download from '../../../assets/img/download.svg'

import './Profile.scss'
import './ProfileMedia.scss'

function Profile (){
	const history = useHistory()
	let [push, setPush] = useState(false)
	const [pushText, setPushText] = useState(false)
	const [message, setMessage] = useState(null);
	let context = useContext(AppContext)
	let lang = context.lang
	let activeLang = context.activeLang
	let user = context.user
	let setUser = context.setUser
	let [inputs, setInputs] = useState({
		first_name: '',
		last_name : '',
		title: '',
		password: '',
		company: '',
		picture: false
	})


	const [isError, setIsError] = useState(false)

	let [defaultInputs, setDefaultsInputs] = useState(null)
	let [isValid, setIsValid] = useState(false);
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

	let file = useRef()
	let form = useRef()

	const inputControl = input => {
		setInputs({
			...inputs,
			[input.name] : input.value
		})
	}

	useEffect(() => {
		setInputs(user ? {
			first_name: user.first_name,
			last_name : user.last_name,
			title: user.title,
			company: user.company,
			picture : false
		} : null)
		setDefaultsInputs(user ? {
			first_name: user.first_name,
			last_name : user.last_name,
			title: user.title,
			company: user.company,
			picture: false
		} : null)
		setIsCheckboxChecked(user ? user.sendEmailNotification : null);
	}, [user])


	useEffect(()=> {
		if(message){
			setTimeout(()=> {
				setMessage(null);
			}, 5000)
		}
	}, [message])


	const changeNotifications =  async (isCheckboxChecked) => {
		const response = await sendEmailNotification("sendEmailNotification", isCheckboxChecked);
		if (response.status === 200) {
			setIsCheckboxChecked(isCheckboxChecked);
		}
	}

	const checkRequiredFields = () => {
		for (const input in inputs) {
			if(input !== 'picture' && inputs[input] === "") {
				return false
			}
		}
		return true
	}

	useEffect(()=> {
		setIsValid(checkRequiredFields());
	}, [inputs])

	return (
		<div className={"profile-content " + (activeLang === 'ar' ? 'profile-content-arabic' : '')}>
			{push ? <Push padding={10} top={20} isError={isError}>{pushText}</Push> : ''}
			<div className="profile-header">
				{lang.profileEdit}
			</div>
			{user && inputs ? (
				<div className="profile-body">
					{message ?
						<div className="notification-change-password">
							<div
								className={"recovery-submited recovery-submited-active"}>
							<span className="recovery-submited-check">
								<svg width="9" height="8" viewBox="0 0 9 8" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd"
										  d="M8.53509 1.51907L3.51095 6.92089C3.18737 7.26879 2.66352 7.26879 2.34077 6.92089L0.242063 4.66531C-0.0806876 4.31741 -0.0806876 3.75418 0.242063 3.40628C0.565641 3.05927 1.08949 3.05927 1.41224 3.40628L2.92586 5.03368L7.36492 0.260926C7.68767 -0.0869755 8.21152 -0.0869755 8.53509 0.260926C8.85867 0.607939 8.85867 1.17117 8.53509 1.51907Z"
										  fill="white"/>
								</svg>

							</span>

								{lang.recoveryPush}</div>
						</div>
					: ""}
					<div className="profile-avatar">
						{user.picture ? (
							<img src={user.picture} className="user-avatar" alt="avatar"/>
						) : (
							<div className="none-avatar user-avatar" style={{backgroundColor: user.bgc}}>
								{((inputs.first_name && inputs.first_name.length > 0 ? inputs.first_name[0] : '') + (inputs.last_name && inputs.last_name.length > 0 ? inputs.last_name[0] : '')).toUpperCase()}
							</div>
						)}
						{user.picture ? <Delete className="delete-avatar" onClick={() => {
							setUser(user => {
								return {
									...user,
									picture: null
								}
							})
							deleteUserAvatar()
							.then(res => {
								setPushText('PROFILE UPDATED')
								setIsError(false)
								setPush(true)
								setTimeout(() => {
									setPush(false)
								}, 1200)
							})
						}}/> : ''}
						<form ref={form}>
							<input type="file" id="avatar" ref={file}  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} onChange={() => {
								var img = new Image();
								var url = window.URL || window.webkitURL;
								img.onload = function() {      
									var width = this.width;
									var height = this.height;
									if(width < 800 && height < 800 && file.current.files[0].size * 0.0000010 < 2 && (file.current.files[0].type === 'image/jpeg' || file.current.files[0].type === 'image/png')){
										updateProfile(inputs, file.current.files[0])
										.then(res => {
											if(res){
												setPushText('PROFILE UPDATED')
												setIsError(false)
												setPush(true)
												setTimeout(() => {
													setPush(false)
												}, 1200)
												form.current.reset()
												setUser({...inputs, picture : res.picture ? res.picture.split('?')[0] + '?v=' + Date() : undefined, login: user.login, bgc: user.bgc})
											} else {
												console.log('error')
											}
										})
									} else {
										setPushText('Please use PNG/JPEG up to 800x800 px')
										setIsError(true)
										setPush(true)
										setTimeout(() => {
											setPush(false)
										}, 1200)
									}
								}
								img.src = url.createObjectURL(file.current.files[0])
							}}/>
						</form>
						<label htmlFor="avatar" className="user-avatar-upload">
							<img src={download} alt="download"/>
							<span>{lang.uploadNewPhoto}</span>
						</label>
					</div>
					<div className="profile-inputs">
						<div className="profile-input-wrapper">
							<div className="profile-input-title">{lang.firstName}</div>
							<input type="text" className="profile-input" required name="first_name"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.first_name} onChange={event => inputControl(event.target)}/>
						</div>
						<div className="profile-input-wrapper">
							<div className="profile-input-title">{lang.lastName}</div>
							<input type="text" className="profile-input" required name="last_name"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.last_name} onChange={event => inputControl(event.target)}/>
						</div>
						<div className="profile-input-wrapper">
							<div className="profile-input-title">{lang.title}</div>
							<input type="text" className="profile-input" required name="title"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.title} onChange={event => inputControl(event.target)}/>
						</div>
						<div className="profile-input-wrapper">
							<div className="profile-input-title">{lang.emailAddress}</div>
							<input type="text" required className="profile-input profile-input-email"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={user.login}/>
						</div>
						<div className="profile-input-wrapper">
							<div className="profile-input-title">{lang.company}</div>
							<input type="text" required className="profile-input" name="company"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.company} onChange={event => inputControl(event.target)}/>
						</div>
						<div className="profile-input-wrapper">
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.changePassword}</div>
								<button className="profile-change-password-button" onClick={()=> {
									resetToken(user.login).then((res)=> {
										if(res.result === 'ok') {
											setMessage(lang.passwordChangeUrlText);
										}
									})
								}}>{lang.sendPasswordLink}</button>
							</div>
						</div>
					</div>

					<div style={{display: "flex", alignItems: "center"}}>
						<div onClick={() => changeNotifications(!isCheckboxChecked)}
							 className={"checkbox " + (isCheckboxChecked ? 'checkbox-active' : '')}>
							<Check />
						</div>

						<p className="profile-checkbox-text">
							Send email notifications
						</p>
					</div>

					<div className="profile-btns">
						<Button color={'white'} margin={10} padding={12} onClick={() => {
							setInputs({...defaultInputs, picture: false})
							setDefaultsInputs({...defaultInputs, picture: false})
							form.current.reset()
							history.push('/dashboard')
						}}>{lang.cancel}</Button>
						<Button color={'blue'} padding={12} disabled={!isValid} onClick={async () => {
							setDefaultsInputs({...inputs, picture: false })
							setInputs({...inputs, picture: false })
							console.log(inputs);
							updateProfile(inputs, file.current.files[0], false)
							.then(res => {
								if(res){
									setPushText('PROFILE UPDATED')
									setIsError(false)
									setPush(true)
									setTimeout(() => {
										setPush(false)
									}, 1200)
									form.current.reset()
									setUser({...res, picture : res.picture ? res.picture.split('?')[0] + '?v=' + Date() : undefined, login: user.login, bgc: user.bgc})
								} else {
									console.log('error')
								}
							})
						}}>{lang.save}</Button>
					</div>
				</div>
			) : ''}
		</div>
	)
}
export default Profile
