import React, {useContext} from 'react'

import {AppContext} from '../../context/AppContext'

import './Loader.scss'
import './LoaderMedia.scss'

function Loader({isVisible, isFull = false}){

	const {isMobile} = useContext(AppContext)

	return (
		<div className="loader" style={{display: (isVisible ? 'flex' : 'none'), top : (isFull ? 0 : (isMobile ? 60 : 80)), height: (isFull ? '100vh' : `calc(100vh - ${isMobile ? 60 : 80}px)`)}}>
			<ul className="loading">
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div>
	)
}

export default Loader