import React, { useContext } from 'react'

import { AppContext } from '../../../context/AppContext'

import edit from '../../../assets/img/edit.svg'
import sendComment from '../../../assets/img/send-comment.svg'

import {ReactComponent as Setting} from '../../../assets/img/setting.svg'
import {ReactComponent as Close} from '../../../assets/img/close.svg'
import {ReactComponent as Chat} from '../../../assets/img/chat.svg'

import DocManageItem from '../DocManage/DocManageItem/DocManageItem'

import './CommentsMobile.scss'

function CommentsMobile({isOpen, setIsOpen, activeItem, activeComment, openMenuComment, document, setDocument, editor, editorRef, setActiveManage, searchString, reloadLine, createAnnotation, setIsOpenFullQueries, setSearch}){


	const user = useContext(AppContext).user
	const activeLang = useContext(AppContext).activeLang
	const lang = useContext(AppContext).lang

	return (
		<>
			<div className={"comments-mobile-header " + (isOpen ? 'comments-mobile-header-active '  : '')  + (activeLang === 'ar' ? 'comments-mobile-header-arabic' : '')}>
				<div className="name-popup-wrapper">
					<Chat/>
					<span>Comments</span>
				</div>
				<Close onClick={() => setIsOpen(false)}/>
			</div>
			<div className={"comments-mobile-body " + (isOpen ? 'comments-mobile-body-active ' : '')  + (activeLang === 'ar' ? 'comments-mobile-body-arabic' : '')}>
				<div className="comments-list">
					{document.comments.length > 0 ? document.comments.map((item, index) => {
						return (
							<DocManageItem
								data={item}
								index={index}
								type={'comments'}
								setDocument={setDocument}
								document={document}
								editor={editor}
								activeItem={activeItem}
								setActiveManage={setActiveManage}
								editorRef={editorRef}
								searchString={searchString}
								key={index}
								reloadLine={reloadLine}
								createAnnotation={createAnnotation}
								setIsOpenFullQueries={setIsOpenFullQueries}
								setSearch={setSearch}
							/>
						)
					}) : (
						<div className="manage-empty">
							{lang.noComments}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default CommentsMobile