import React, { useState, useContext, useRef, useEffect } from 'react'
import {AppContext} from '../../context/AppContext'

import {
	addToArchive
} from '../../utilites/appfunctions'

import {ReactComponent as SettingIcon} from '../../assets/img/setting.svg'
import {ReactComponent as Plus} from '../../assets/img/plus.svg'

import './TabSettings.scss'
import './TabSettingsMedia.scss'

function TabSettings({
						 setList,
						 isVisibleDeleteFiles,
						 setIsVisibleDeleteFiles,
						 setPush,
						 setPushText,
						 setChangeName,
						 deleteListItem,
						 setAddCollab = () => false,
						 setMoveToProject = () => false,
						 restoreListItem = () => false,
						 item = {},
						 setItem
					 }) {

	const { lang, activeLang, isMobile} = useContext(AppContext)
	const [isOpen, setIsOpen] = useState(false)
	const options = useRef()

	const checkClickHandler = event => {
		if(options.current){
			if(!event.target.isEqualNode(options.current) && !event.target.closest('.' + options.current.className)){
				setIsOpen(false)
			}
		}
	}

	useEffect(() => {
		document.addEventListener('click', checkClickHandler)
		return () => document.removeEventListener('click' ,checkClickHandler)
	}, [])

	return (
		<div className={"setting-wrapper " + (activeLang === 'ar' ? 'setting-wrapper-arabic' : '')} ref={options}>
			<SettingIcon className={"setting-btn " + (isOpen ? 'setting-btn-active' : '')} onClick={() => {
				setIsOpen(prev => !prev)
			}}/>
			<div className={"tab-setting " + (isOpen ? 'tab-setting-active' : '')}>
				<svg version="1.1" id="setting-arrow" viewBox="0 0 490.787 490.787">
					<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006
						c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083
						c-0.086,0.089-0.173,0.176-0.262,0.262L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085
						C368.236,489.664,365.511,490.792,362.671,490.787z"/>
					<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256
						c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
						L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C368.236,489.664,365.511,490.792,362.671,490.787z"/>
				</svg>
				{isMobile && window.location.pathname.includes('projects') ? (
					<div className="tab-setting-item plus"  onClick={() => {
						setIsOpen(false)
						setAddCollab(true)
					}}>
						Add collaborators
					</div>
				) : ''}
				<div className="tab-setting-item" onClick={() => {
					setIsOpen(false)
					setChangeName(true)
					}}>{lang.rename}
				</div>
				{window.location.pathname.includes('projects') ? (
					<div className="tab-setting-item" onClick={() => {
						setIsOpen(false)
						setMoveToProject(true)
						}}>{'Connect to company'}</div>
				) : ''}
				<div className="tab-setting-item" 
					onClick={() => {
						setIsOpen(false)
						if(item.archived){
							addToArchive(setList, setItem, item, setPush, setPushText)
							restoreListItem(item.id);
						} else {
							addToArchive(setList, setItem, item, setPush, setPushText)
							deleteListItem(item.id)
						}
					}}
				>{item.archived ? lang.putBack : lang.archive}</div>
				<div className="tab-setting-item" onClick={() => {
					setIsOpen(false)
					setIsVisibleDeleteFiles(prev => !prev)
					}}>
						{activeLang === 'ar' ? `deleted files ${isVisibleDeleteFiles ? lang.hide : lang.show}` : `${isVisibleDeleteFiles ? lang.hide : lang.show} deleted files`}
					</div>
			</div>
		</div>
	)
}

export default TabSettings