import { useState } from 'react'

export default function useSelect (defaultVal, values = []){
	const [select, setSelect] = useState({isOpen: false, value : defaultVal, valuesList: values})	

	const openClose = (value) => {
		setSelect(prev => {
			return {
				...prev,
				isOpen: value !== undefined ? value : !prev.isOpen
			}
		})
	}

	const changeValue = newValue => {
		setSelect(prev => {
			return {
				...prev,
				value: newValue,
				isOpen: false
			}
		})
	}

	const setValues = data => {
		setSelect (prev => {
			return {
				...prev, 
				valuesList: data
			}
		})
	}

	return {
		active: select.isOpen,
		value: select.value,
		valuesList: select.valuesList,
		openClose: openClose,
		changeValue: changeValue,
		setValues: setValues
	}
}