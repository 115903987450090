// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-all-wrapper {
  display: flex;
  justify-content: center; }
  .notifications-all-wrapper .notifications-all {
    display: flex;
    flex-direction: column;
    width: 730px;
    background-color: #fff; }
    .notifications-all-wrapper .notifications-all .notifications-all-header {
      height: 66px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EEF3F5;
      padding: 0px 25px;
      color: #8798AD;
      font-size: 0.65rem;
      line-height: 0.75rem;
      letter-spacing: 1.21333px;
      text-transform: uppercase; }
    .notifications-all-wrapper .notifications-all .notifications-all-list {
      display: flex;
      flex-direction: column;
      padding: 0px 25px; }
      .notifications-all-wrapper .notifications-all .notifications-all-list .notifications-list-item .notification-new {
        top: 8px;
        right: 1px; }
  .notifications-all-wrapper-arabic .notifications-all .notifications-all-header {
    flex-direction: row-reverse; }
  .notifications-all-wrapper-arabic .notifications-all .notifications-all-list .notifications-list-item .notification-new {
    top: 8px;
    right: unset;
    left: 0px; }
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Notifications/Notifications.scss"],"names":[],"mappings":"AAAA;EACC,aAAa;EACb,uBAAuB,EAAA;EAFxB;IAIE,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB,EAAA;IAPxB;MASG,YAAY;MACZ,WAAW;MACX,aAAa;MACb,mBAAmB;MACnB,8BAA8B;MAC9B,gCAAgC;MAChC,iBAAiB;MACjB,cAAc;MACd,kBAAkB;MAClB,oBAAoB;MACpB,yBAAyB;MACzB,yBAAyB,EAAA;IApB5B;MAuBG,aAAa;MACb,sBAAsB;MACtB,iBAAiB,EAAA;MAzBpB;QA4BK,QAAQ;QACR,UAAU,EAAA;EAKb;IAGE,2BAA2B,EAAA;EAH7B;IAQI,QAAQ;IACR,YAAY;IACZ,SAAS,EAAA","sourcesContent":[".notifications-all-wrapper{\n\tdisplay: flex;\n\tjustify-content: center;\n\t.notifications-all{\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\twidth: 730px;\n\t\tbackground-color: #fff;\n\t\t.notifications-all-header{\n\t\t\theight: 66px;\n\t\t\twidth: 100%;\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: space-between;\n\t\t\tborder-bottom: 1px solid #EEF3F5;\n\t\t\tpadding: 0px 25px;\n\t\t\tcolor: #8798AD;\n\t\t\tfont-size: 0.65rem;\n\t\t\tline-height: 0.75rem;\n\t\t\tletter-spacing: 1.21333px;\n\t\t\ttext-transform: uppercase;\n\t\t}\n\t\t.notifications-all-list{\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tpadding: 0px 25px;\n\t\t\t.notifications-list-item{\n\t\t\t\t.notification-new{\n\t\t\t\t\ttop: 8px;\n\t\t\t\t\tright: 1px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n\t&-arabic{\n\t\t.notifications-all{\n\t\t\t.notifications-all-header{\n\t\t\t\tflex-direction: row-reverse;\n\t\t\t}\n\t\t\t.notifications-all-list{\n\t\t\t\t.notifications-list-item{\n\t\t\t\t\t.notification-new{\n\t\t\t\t\t\ttop: 8px;\n\t\t\t\t\t\tright: unset;\n\t\t\t\t\t\tleft: 0px;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
