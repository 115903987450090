// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .upload-files-popup-wrapper {
    z-index: 4;
    height: calc(var(--vh, 1vh) * 100);
    height: -webkit-fill-available; }
    .upload-files-popup-wrapper .upload-files-popup {
      width: 100%;
      height: 100%; }
      .upload-files-popup-wrapper .upload-files-popup .upload-popup-header {
        padding-left: 18px;
        padding-right: 18px; }
        .upload-files-popup-wrapper .upload-files-popup .upload-popup-header .header-title {
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 0.9rem; }
      .upload-files-popup-wrapper .upload-files-popup .upload-popup-body {
        padding: 15px;
        padding-top: 30px;
        display: flex;
        height: calc(100% - 70px);
        padding-bottom: 20px;
        flex-direction: column;
        align-items: flex-start; }
        .upload-files-popup-wrapper .upload-files-popup .upload-popup-body .upload-btns {
          position: absolute;
          bottom: 20px;
          left: 15px;
          width: calc(100% - 30px); }
          .upload-files-popup-wrapper .upload-files-popup .upload-popup-body .upload-btns button {
            width: 50%; }
    .upload-files-popup-wrapper-arabic .upload-files-popup .upload-popup-body {
      align-items: flex-end; }
    .upload-files-popup-wrapper-active {
      opacity: 1;
      visibility: visible; }
      .upload-files-popup-wrapper-active .upload-files-popup {
        transform: translateY(0px); } }
`, "",{"version":3,"sources":["webpack://./src/components/Popups/UploadFiles/UploadFilesMedia.scss"],"names":[],"mappings":"AAAA;EACC;IACC,UAAU;IACV,kCAAkC;IAClC,8BAA+B,EAAA;IAHhC;MAKE,WAAW;MACX,YAAY,EAAA;MANd;QAQG,kBAAkB;QAClB,mBAAmB,EAAA;QATtB;UAWI,gBAAgB;UAChB,kBAAkB;UAClB,mBAAmB,EAAA;MAbvB;QAiBG,aAAa;QACb,iBAAiB;QACjB,aAAa;QACb,yBAAyB;QACzB,oBAAoB;QACpB,sBAAsB;QACtB,uBAAuB,EAAA;QAvB1B;UAyBI,kBAAkB;UAClB,YAAY;UACZ,UAAU;UACV,wBAAwB,EAAA;UA5B5B;YA8BK,UAAU,EAAA;IAKb;MAGE,qBAAqB,EAAA;IAIxB;MACC,UAAU;MACV,mBAAmB,EAAA;MAFnB;QAIC,0BAA0B,EAAA,EAC1B","sourcesContent":["@media screen and (max-width: 600px){\n\t.upload-files-popup-wrapper{\n\t\tz-index: 4;\n\t\theight: calc(var(--vh, 1vh) * 100);\n\t\theight:  -webkit-fill-available;\n\t\t.upload-files-popup{\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t\t.upload-popup-header{\n\t\t\t\tpadding-left: 18px;\n\t\t\t\tpadding-right: 18px;\n\t\t\t\t.header-title{\n\t\t\t\t\tfont-weight: 500;\n\t\t\t\t\tfont-size: 0.75rem;\n\t\t\t\t\tline-height: 0.9rem;\n\t\t\t\t}\n\t\t\t}\n\t\t\t.upload-popup-body{\n\t\t\t\tpadding: 15px;\n\t\t\t\tpadding-top: 30px;\n\t\t\t\tdisplay: flex;\n\t\t\t\theight: calc(100% - 70px);\n\t\t\t\tpadding-bottom: 20px;\n\t\t\t\tflex-direction: column;\n\t\t\t\talign-items: flex-start;\n\t\t\t\t.upload-btns{\n\t\t\t\t\tposition: absolute;\n\t\t\t\t\tbottom: 20px;\n\t\t\t\t\tleft: 15px;\n\t\t\t\t\twidth: calc(100% - 30px);\n\t\t\t\t\tbutton{\n\t\t\t\t\t\twidth: 50%;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\t&-arabic{\n\t\t\t.upload-files-popup{\n\t\t\t\t.upload-popup-body{\n\t\t\t\t\talign-items: flex-end;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\t&-active{\n\t\t\topacity: 1;\n\t\t\tvisibility: visible;\n\t\t\t.upload-files-popup{\n\t\t\t\ttransform: translateY(0px);\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
