// const COMMENT_TYPE = 'comment'
// const COMMENT_SELECTOR = '#tinymce .comment-active'
// const COMMENT_SELECTOR_ACTIVE = '.comment-active'
import html2canvas from 'html2canvas';
import { 
	randomColor, 	
	dateConvert } from './appfunctions'
import { month } from './month'
import {
	addCommentToDocument,
	addReplyToComment,
	updateTemplate,
	updateDocument, addCommentToTemplate
} from './api'
export const removeActiveItemsInEditor = editor => {
	editor.querySelectorAll('.mce-annotation').forEach(element => {
		element.classList.remove('comment-active')
		element.classList.remove('query-active')
	});
}

export const activateItemInEditor = (editor, type, activateId) => {
	removeActiveItemsInEditor(editor)
	if(type === 'comments'){
		let element = editor.querySelector(`#tinymce span[data-mce-annotation-uid="${activateId}"]`)
		if(element){
			element.classList.add('comment-active')
		}
	} else if (type === 'queries'){
		let element = editor.querySelector(`#tinymce span[data-mce-annotation-uid="${activateId}"]`)
		if(element){
			element.classList.add('query-active')
		}
	}
}

const textDetect = (text) => {
	console.log(text)
	return ''
}

const addItemToEditor = (editor, text, type, lang) => {
	// console.log(text.replace(/\s{2}/g, ` &nbsp;`), text.length)
	// let range = editor.selection.createRange()
	// console.log(range)
	textDetect(text)
	if(editor.querySelector('#tinymce').innerHTML.includes(text.toString().replace(/\s{2}/g, ` &nbsp;`))){
		editor.querySelector('#tinymce').innerHTML = editor.querySelector('#tinymce').innerHTML.replace( text.toString().replace(/\s{2}/g, ` &nbsp;`), `<span class="${type} ${lang}" data-mce-annotation-uid=${null}>${text.toString()}</span>`)
		return true
	}
	return false
}

const addIdToNewItemToEditor = (editor, id, setDocument, setActiveManage) => {
	const elements = editor.querySelectorAll(`#tinymce span[data-mce-annotation-uid="null"]`)
	if(elements.length){
		elements.forEach((element) => {
			element.setAttribute('data-mce-annotation-uid', id)
			addEventsToItemToEditor(editor, setDocument, setActiveManage)
		})
	}
}

const removeItemFromEditor = (editor, id) => {
	if(editor.querySelector(`#tinymce span[data-mce-annotation-uid="${id}"]`)){
		const itemsWithTags = editor.querySelectorAll(`#tinymce span[data-mce-annotation-uid="${id}"]`);
		for(let i = 0; i < itemsWithTags.length; i++){
			editor.querySelector('#tinymce').innerHTML = editor.querySelector('#tinymce').innerHTML.replace(itemsWithTags[i].outerHTML, itemsWithTags[i].textContent)
		}
	}
}

export const addEventsToItemToEditor = (editor, setDocument, setActiveManage, user) => {
	editor.querySelectorAll('.mce-annotation').forEach(element => {
		element.addEventListener('click', (element) => {
			const type = element.target.getAttribute('data-mce-annotation');
			if(type === 'comment' && !user?.external){
				setActiveManage(1)
				activeItemManage(element.target.getAttribute('data-mce-annotation-uid'), 'comments', setDocument, editor)
			} else if(type === 'query'){
				setActiveManage(0)
				activeItemManage(element.target.getAttribute('data-mce-annotation-uid'), 'queries', setDocument, editor)
			}
		})
	})
}

export const activeItemManage = (id, type, setDocument, editor) => {
	let disableItems = (type === 'comments' ? 'queries' : 'comments')

	setDocument(document => {
		return {
			...document,
			[disableItems] : document[disableItems].map(item => ({...item, active: false})),
			[type] : document[type].map(item => {
				if(id === item.id){
					if(!item.active){
						activateItemInEditor(editor, type, id)
					} else {
						removeActiveItemsInEditor(editor)
					}
					return {
						...item,
						active: !item.active,
						isOpenMenu: false
					}
				}
				return {
					...item,
					active: false
				}
			})
		}
	})
}

export const resetActiveItems = (editor, setDocument) => {
	removeActiveItemsInEditor(editor)
	setDocument(document => {
		return {
			...document,
			queries : document.queries.map(item => ({...item, active: false})),
			comments : document.comments.map(item => ({...item, active: false}))
		}
	})
}

export const addItemManage = (editor, select, type, setDocument, user, lang = 'en') => {
	let disableItems = (type === 'comment' ? 'comments' : 'queries')
	let activateItems = (type === 'query' ? 'queries' : 'comments')
	removeActiveItemsInEditor(editor)
	setDocument(document => {
		return {
			...document,
			[disableItems] : document[disableItems].map(item => ({...item, active: false})),
			[activateItems] : document[activateItems].map(item => ({...item, active: false})).concat({
				owner: {
					id: user._id,
					name: user.first_name,
					lastName: user.last_name,
					avatar: user.picture || undefined,
					bgc: randomColor()
				},
				active: true,
				createdDate: new Date().getDate() + ' ' + month[new Date().getMonth()].name,
				id: null,
				reply: [],
				status: 'on'
			})
		}
	})
}

export const deleteItemManage = (editor, setDocument, id, setActiveManage) => {
	removeActiveItemsInEditor(editor)
	removeItemFromEditor(editor, id)
	addEventsToItemToEditor(editor, setDocument, setActiveManage)
	setDocument(document => {
		return {
			...document,
			queries : document.queries.map(item => ({...item, active: false})).filter(item => {
				return item.id !== id;

			}),
			comments : document.comments.map(item => ({...item, active: false})).filter(item => {
				return item.id !== id;
			})
		}
	})
}

export const openSettingManageItem = (id, type, setDocument) => {
	setDocument(document => {
		return {
			...document,
			[type]: document[type].map((item, index) => {
				if(id === index){
					return {
						...item,
						isOpenMenu : !item.isOpenMenu
					}
				} 
				return {
					...item,
					isOpenMenu : false
				}
			})
		}
	})
}

const addReplyToFront = (data, setDocument, type) => {
	let disableItems = (type === 'comments' ? 'queries' : 'comments')
	setDocument(document => {
		return {
			...document,
			[disableItems] : document[disableItems].map(item => ({...item, active: false})),
			[type]: document[type].map(item => {
				if(item.id === data._id || item.id === null){
					return {
						...item,
						id: data._id,
						reply: [{
							owner : {
								id: data.author._id,
								name: data.author.first_name,
								lastName: data.author.last_name,
								avatar: data.author.picture || undefined,
								bgc: randomColor()
							},
							createdDate : dateConvert(data.created_at, 'c'),
							text: data.text,
							id : data._id,
							isEdit: false,
							isFirst : true
						}].concat(data.replies.map(replyItem => {
							return {
								owner : {
									id: replyItem.author._id,
									name: replyItem.author.first_name,
									lastName: replyItem.author.last_name,
									avatar: replyItem.author.picture || undefined,
									bgc: randomColor()
								},
								createdDate : dateConvert(replyItem.created_at, 'c'),
								text: replyItem.text,
								id : replyItem._id,
								isEdit: false,
								isFirst : false
							}
						}))
					}
				}
				return item
			})
		}
	})
}

export const convertManageItem = (data) => {
	return data === undefined ? [] : data.filter(item => !item.deleted_at && item.author).map((item) => {
		// console.log(item.author)
		return {
			owner: {
				id: item.author._id,
				name: item.author.first_name,
				lastName: item.author.last_name,
				avatar: item.author.picture || undefined,
				external: item.author.external,
				bgc: randomColor()
			},
			active: false,
			createdDate : dateConvert(item.created_at, 'c'),
			selectText : item.text,
			status : item.status || 'off',
			reply: [{
				owner: {
					id: item.author._id,
					name: item.author.first_name,
					lastName: item.author.last_name,
					avatar: item.author.picture || undefined,
					external: item.author.external,
					bgc: randomColor()
				},
				createdDate : dateConvert(item.created_at, 'c'),
				text: item.text,
				id : item._id,
				isEdit: false,
				isFirst: true
			}].concat(item.replies.map((replyItem) => {
				return {
					owner: {
						id: replyItem.author._id,
						name: replyItem.author.first_name,
						lastName: replyItem.author.last_name,
						avatar: replyItem.author.picture || undefined,
						external: replyItem.author.external,
						bgc: randomColor()
					},
					createdDate : dateConvert(replyItem.created_at, 'c'),
					text: replyItem.text,
					id : replyItem._id,
					isEdit: false
				}
			})),
			id: item._id
		}
	})
}

export const editReplyComment = (setDocument, id, idReply, newReply = undefined, type) => {
	setDocument(document => {
		return {
			...document,
			[type]: document[type].map((item, index) => {
				if(id === index){
					return {
						...item,
						reply : item.reply.map((replyItem, i) => {
							if(idReply === i){
								return {
									...replyItem,
									isEdit: false,
									text: newReply || replyItem.text
								}
							}
							return {
								...replyItem,
								isEdit: false
							}
						})
					}
				} else {
					return item
				}
			})
		}
	})
}

export const setEditReply = (setDocument, id, idReply, type) => {
	setDocument(document => {
		return {
			...document,
			[type]: document[type].map((item, index) => {
				if(id === index){
					return {
						...item,
						reply : item.reply.map((replyItem, i) => {
							if(idReply === i){
								return {
									...replyItem,
									isEdit: !replyItem.isEdit
								}
							}
							return {
								...replyItem,
								isEdit: false
							}
						})
					}
				} else {
					return item
				}
			})
		}
	})
}

export const addReplyToCommentItem = async (comment, document, newCommentText, setDocument, editor, setActiveManage, type, mentions, isTemplate) => {
	let typeReply = (comment.reply.length === 0);
	let mentions_ids = mentions.map(item => {
		return item.id
	})
	return await (typeReply ? (!isTemplate ? addCommentToDocument(document.id, newCommentText, type, mentions_ids) : addCommentToTemplate(document.id, newCommentText, type, mentions_ids))
		: addReplyToComment(comment.id , newCommentText, type, mentions_ids))
	.then(res => {
		let response = res.data.data
		if(typeReply){
			addIdToNewItemToEditor(editor, response._id, setDocument, setActiveManage)
		}
		addReplyToFront(response, setDocument, type)
		return response
	})
}


export const saveDocument = async (searchString, id, editorRef, document, history = false, withImage = true) => {
	// removeActiveItemsInEditor(editor)
	let file = null;
	if(withImage){
		file = await html2canvas(window.document.body.querySelector('iframe').contentWindow.document.body, {height: 800, scale: 0.9, ignoreElements: (el) => el.tagName.toLowerCase() === 'img'})
			.then(canvas => {
				return canvas.toDataURL()
			})
			.catch(err => {})
	}
	let content = editorRef?.current?.editor?.getContent().replace(/<p><iframe (.|\n)*? data-html2canvas-ignore="true">(.|\n)*?<\/iframe><\/p>/g, '')
	if(searchString.referer === 'templates' && content){
		return await updateTemplate(document?.group_id, id, content, file)
	} else if (searchString.referer !== 'templates' && document.file === 'spe' && content) {
		return await updateDocument({content : content}, document?.id, file, history)
	}
}


export const changeQueryStatus = (setDocument, id, status) => {
	setDocument(document => {
		return {
			...document,
			queries: document.queries.map(item => {
				if(item.id === id){
					return {
						...item, 
						status: status,
						isOpenMenu: false
					}
				}
				return item
			})
		}
	})
}