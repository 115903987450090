import React, {useState, useRef, useEffect, useContext, useCallback} from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {AppContext} from '../../context/AppContext'

import Slider from "react-slick";

import {
	uploader
} from '../../utilites/uploader.js'

import { 
	addItemToFavoritesList,
	randomColor
} from '../../utilites/appfunctions.js'

import { 
	openCollabTypeSelect, 
	changeCollabType,
	removeCollab,
	addCollaborator, 
	createFolder, 
	deleteDoc, 
	onMove,
	moveToFolder,
} from '../../utilites/table.js'


import {
	getProjects,
	getProject,
	createProject,
	updateProject,
	deleteProject,
	restoreProject,
	unlinkDocToProject,
	// getUsers,
	getSettings,
	getDocument,
	downloadArchive,
	createFolderInProject,
	linkProjectToCompany,
	getCompanies,
	unlinkProjectFromCompany,
	userSearch
} from '../../utilites/api'

import {
	convertProject
} from '../../utilites/essence'

import {
	getConverter
} from '../../utilites/getconverter'

import useFileName from '../../hooks/useFileName'

import PageNav from '../Nav/PageNav/PageNav'
import Button from '../Button/Button'
import Uploader from './Uploader/Uploader'
import DocTable from '../DocTable/DocTable'
import Push from '../Push/Push'
import PlusNav from '../PlusNav/PlusNav'
import MobileOptions from '../MobileOptions/MobileOptions'
import Loader from '../Loader/Loader'
import ErrorPage from "../ErrorPage/ErrorPage";
import FileName from '../FileName/FileName'
import TabSettings from '../TabSettings/TabSettings'

import CreateNewFile from '../Popups/CreateNewFile/CreateNewFile'
import UploadFiles from '../Popups/UploadFiles/UploadFiles'
import ShareFiles from '../Popups/ShareFiles/ShareFiles'
import DeleteFile from '../Popups/DeleteFile/DeleteFile';

import {ReactComponent as ProjectsMenuIcon} from '../../assets/img/menu/project.svg'
import {ReactComponent as DocumentMenuIcon} from '../../assets/img/menu/templates.svg'
import {ReactComponent as Plus} from '../../assets/img/plus.svg'
import {ReactComponent as Close} from '../../assets/img/close.svg'
import drag from '../../assets/img/drag.svg'
import {ReactComponent as Check} from '../../assets/img/check.svg'
import {ReactComponent as Search} from '../../assets/img/search.svg'
import {ReactComponent as Arrow} from '../../assets/img/arrow.svg'

import './Projects.scss'
import './ProjectsMedia.scss'

function Projects ({newProject}){
	const [projects, setProjects] = useState([])
	const [project, setProject] = useState(null)

	const [isLoadComplete, setIsLoadComplete] = useState(true)
	const [isErrorPage, setIsErrorPage] = useState(false);

	const [push, setPush] = useState(false)
	const [pushText, setPushText] = useState('')
	const [pushError, setPushError] = useState(false)
	const userContext = useContext(AppContext).user

	const [user, setUser] = useState(false)
	const filename = useFileName(setProjects, setProject, project, updateProject)

	const {lang, activeLang, isMobile} = useContext(AppContext)

	const history = useHistory()
	const location = useLocation()
	const { id } = useParams()

	const files = useRef()
	const deletedRef = useRef()
	const newFolderName = useRef()
	const selectRef = useRef()
	const docOptionsRef = useRef()

	//popups
	const [addCollab, setAddCollab] = useState(false)
	const [moveToProject, setMoveToProject] = useState(false)
	const [createFile, setCreateFile] = useState(false)
	const [createFolderPopup, setCreateFolderPopup] = useState(false)
	const [share, setShare] = useState(false)
	const [isVisibleUploader, setIsVisibleUploader] = useState(true)
	const [moveTo, setMoveTo] = useState(false)

	//
	const [isVisibleDeleteFiles, setIsVisibleDeleteFiles] = useState(false)
	const [isFolderOpen, setIsFolderOpen] = useState({path: '/', prevPath: '/'})
	const [activeCount, setActiveCount] = useState(null)
	const [docFilter, setDocFilter] = useState(null)
	const [viewDocsType, setViewDocsType] = useState(0)
	const [onUploadFiles, setOnUploadFiles] = useState([])
	const [isOpenProjectNavMobile, setIsOpenProjectNavMobile] = useState(false)
	const [onDrag, setOnDrag] = useState(false)
	const [isConvert, setIsConvert] = useState(false)
	const [startUploadList, setStartUploadList] = useState([])

	const [activeMove, setActiveMove] = useState(null)
	const [isOpenMoveFolder, setIsOpenMoveFolders] = useState({path: '/', prevPath: '/'})
	const [moveUp, setMoveUp] = useState(false)

	const [collabSearch, setCollabSearch] = useState('')
	const [collabSearchResults, setCollabSearchResults] = useState([])
	// let [isVisibleUploader, setIsVisibleUploader] = useState(true)
	const [deleteFile, setDeleteFile] = useState(false)
	const [fileToDelete, setFileToDelete] = useState(null)
	// let [viewDocsType, setViewDocsType] = useState(0)
	// let newFolderName = useRef()
	// let [needUpdateProjectList, setNeedUpdateProjectList] = useState(false)

	let [actualFormats, setActualFormats] = useState([])
	let [actualTypes, setActualTypes] = useState([])
	let [companies, setCompanies] = useState([])
	const collabTypeList = [
		'Contributor',
		'Viewer'
	]
	// const updateProjectList = () => {
	// 	setNeedUpdateProjectList(!needUpdateProjectList);
	// }

	const detectFolder = (id) => {
		let detectionFolder;
		let detectionFolderDataFolders = [];
		project.folders.map(item => {
			if(item.id === id){
				detectionFolder = item
			}
			if(item.parent_id === id){
				detectionFolderDataFolders = detectionFolderDataFolders.concat(item)
			}
		})
		return {item: detectionFolder || '/', folders: detectionFolderDataFolders}
	}

	const onEnterPress = (e) => {
		if (e.keyCode === 13 && e.shiftKey === false && newFolderName.current.value.length > 0) {
			setCreateFolderPopup(false)
			createFolderInProject(projects.filter(item => item.active)[0].id, newFolderName.current.value, isFolderOpen.path === '/' ? projects.filter(item => item.active)[0].id : isFolderOpen.path)
			.then(res => {
				createFolder(setProject, newFolderName, setPush, setPushText, res.data.data._id, res.data.data.parent_id === res.data.data.project_id ? undefined : res.data.data.parent_id)
			})
		}
	}

	const projectCreate = async (projects) => {
		let nameCount = projects.filter(item => item.name.includes('Untitled')).length
		createProject({
			name: nameCount === 0 ? 'Untitled' : 'Untitled (' + nameCount + ')',
		})
		.then(async res => {
			setProject(await convertProject(res.data.data))
			setProjects(() => projects.concat(res.data.data))
			if(getConverter(window.location.search).company){
				linkProjectToCompany(getConverter(window.location.search).company, res.data.data._id)
			}
			history.push(`/projects/${res.data.data._id}`)
			setPushText('NEW PROJECT CREATED')
			setPush(true)
			setTimeout(() => {
				setPush(false)
				filename.setIsChange(true)
			}, 1200)
		})
		if(newProject){
			setIsLoadComplete(false)
		}
	}

	const settings = {
		infinite: false,
		speed: 500,
		dots: false,
		draggable: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 750,
				settings: {
					slidesToShow: !isMobile ? 2 : 3,
					slidesToScroll: !isMobile ? 2 : 3,
				}
			}
		]
	}

	const onMoveCompany = id => {
		setCompanies(companies => {
			return companies.map(item => {
				if(id === item.id){
					return {
						...item,
						move: true
					}
				}
				return {
					...item,
					move: false
				}
			})
		})
	}

	useEffect(() => {
		setIsOpenMoveFolders(isFolderOpen)
	}, [isFolderOpen])

	useEffect(() => {
		if(collabSearch.length > 0){
			userSearch(collabSearch)
			.then(res => {
				// console.log(res)
				setCollabSearchResults(res.map(item => {
					return {
						...item,
						fullName: (item.first_name || '') + ' ' + (item.last_name || ''),
						first_name: item.first_name || '',
						last_name: item.last_name || '',
						email : item.email || '',
						avatar: item.picture || undefined,
						permission: 'viewer',
						isOpenSelect: false,
						bgc: randomColor(),
						id: item._id,
					}
				}).filter(item => {
					if(project?.users?.map(item => item.id).includes(item._id)){
						return false
					} else if(project.owner_id._id !== item._id) {
						return true
					}
				}))
			})
		}
	}, [collabSearch])

	useEffect(() => {
		if(isVisibleDeleteFiles){
			window.scrollBy({
				top: deletedRef.current?.offsetTop - 50,
				behavior: "smooth"
			})
		}
	}, [isVisibleDeleteFiles])

	useEffect(() => {
		// console.log(project)
	}, [project])

	useEffect(() => {
		setUser(userContext)
	}, [userContext])

	const getProjectData = (id) =>{
		// console.log(123213, user, id)
		user && getProject(id)
			.then(async res => {
				if(JSON.parse(localStorage.getItem('folder')) === null && id){
					localStorage.setItem('folder', JSON.stringify({path: '/', prevPath: '/', project: id}))
				}
				if(res.data._id === JSON.parse(localStorage.getItem('folder')).project){
					setIsFolderOpen(JSON.parse(localStorage.getItem('folder')) || {path: '/', prevPath: '/'})
				}else{
					localStorage.setItem('folder', JSON.stringify({path: '/', prevPath: '/', project: id}))
					setIsFolderOpen(JSON.parse(localStorage.getItem('folder')) || {path: '/', prevPath: '/'})
				}

				setProject(await convertProject(res.data, user))
				setIsLoadComplete(false)
			})
			.catch(error => {
				console.log('ERROR', {error, id})
				setIsErrorPage(true)
				setIsLoadComplete(false)
			})
	}

	const projectDetect = (projects) => {
		if(id && projects.length > 0){
			getProjectData(id)
		} else {
			if(projects.length > 0){
				const active = projects.find(item => {
					if(!item.deleted_at){
						return true
					}
					return false
				})
				if(active){
					getProjectData(active._id)
					history.push(`/projects/${active._id}`)
				} else {
					getProjectData(projects[0]._id)
					history.push(`/projects/${projects[0]._id}`)
				}
			}
		}
	}

	useEffect(() => {
		user && projectDetect(projects)
	}, [location, user])

  useEffect(() => {
        async function getAllProjects(){
            Promise.all([getProjects(), getSettings(), getCompanies()])
            .then(res => {
				setActualFormats(res[1].file_type_for_upload.filter(item => {
					if(item.active){
						return true
					} else {
						return false
					}
				}).map(item => item.name.toLowerCase()))
				setActualTypes(res[1].document_type_for_upload.filter(item => {
					if(item.active){
						return true
					} else {
						return false
					}
				}).map(item => item.name))
				setCompanies(res[2].data.filter(item => !item.deleted_at).map(item => ({id: item._id, name: item.name, move: false, projects: item.projects})))
				if(id === 'new'){
					projectCreate(res[0].data)
					return false
				}
				if(res[0].data.length === 0){
					setIsLoadComplete(false)
					history.push('/projects')
				} else {
					setProjects(res[0].data)
					projectDetect(res[0].data)
				}
            })
        }
		if (user) {
			getAllProjects()
		}
    }, [user])


	return (
		<div className={"projects " + (activeLang === 'ar' ? 'projects-arabic' : '')}>
			{isErrorPage ? <ErrorPage error={"error"}/> : ''}
			<Loader isVisible={isLoadComplete}/>
			{isLoadComplete === false && isErrorPage === false ? (
				<>
					<PageNav list={projects} listIcon={<ProjectsMenuIcon/>} page={lang.projects} createListItem={() => projectCreate(projects)} activeItem={project?.id}/>
					<div className={"projects-content "  + (projects.length === 0 ? 'projects-content-empty' : '')} onDragEnter={() => {
						setOnDrag(true)
					}}>
						{projects.length === 0 ? (
							<div className="projects-empty-wrapper">
								<ProjectsMenuIcon className="empty-icon"/>
								<div className="empty-title">{lang.welcomeToNewProject}</div>
								<Button plus={true} padding={16} color={'blue'} onClick={() => {
										projectCreate(projects)
									}}>{lang.newProject}</Button>
							</div>
						) : (
							<>
							<Uploader files={startUploadList} convert={isConvert} isVisible={isVisibleUploader} setIsVisible={setIsVisibleUploader}/>
							{push ? <Push top={0} padding={8} isError={pushError}>{pushText}</Push> : ''}
							<div className={"create-folder-popup-wrapper " + (createFolderPopup ? "create-folder-popup-wrapper-active" : '')} onClick={event => {
								if(event.target.classList.contains('create-folder-popup-wrapper-active')){
									setCreateFolderPopup(false)
								}
							}}>
								<div className="create-folder-popup">
									<div className="create-folder-popup-header">
										<div className="header-title">{lang.newFolder}</div>
										<Close onClick={() => setCreateFolderPopup(false)}/>
									</div>
									<div className="create-folder-popup-body">
										<div className="input-title">
											{lang.folderName}
										</div>
										<input ref={newFolderName} onKeyDown={(e)=> onEnterPress(e)} type="text" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} className="folder-name" defaultValue={'Untitled folder'}/>
										<div className="create-folder-btns">
											<Button color={'white'} padding={12} margin={10} onClick={() => setCreateFolderPopup(false)}>{lang.cancel}</Button>
											<Button color={'blue'} padding={12} onClick={() => {
												if(newFolderName.current.value.length > 0){
													setCreateFolderPopup(false)
													createFolderInProject(project.id, newFolderName.current.value, isFolderOpen.path === '/' ? project.id : isFolderOpen.path)
													.then(res => {
														createFolder(setProject, newFolderName, setPush, setPushText, res.data.data._id, res.data.data.parent_id === res.data.data.project_id ? undefined : res.data.data.parent_id)
													})
												}
											}}>{lang.create}</Button>
										</div>
									</div>
								</div>
							</div>
							<div className={"add-collab-popup-wrapper " + (addCollab ? 'add-collab-popup-wrapper-active' : '')} onClick={(event) => {
								if(event.target.classList.contains('add-collab-popup-wrapper-active')){
									setAddCollab(false)
								}
							}}>
								<div className="add-collab-popup">
									<div className="add-collab-popup-header">
										<div className="header-title">{lang.addRemoveCollab}</div>
										<Close onClick={() => setAddCollab(false)}/>
									</div>
									<div className="add-collab-popup-body">
										<div className="add-collab-search-wrapper" style={collabSearch.length > 0 ? {borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : {}}>
											<Search/>
											<input type="text" className="add-collab-search-input" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} placeholder={lang.searchCollabs} value={collabSearch} onChange={event => setCollabSearch(event.target.value)}/>
											<div className="collab-search-results" style={collabSearch.length === 0 ? {opacity: 0, visibility: 'hidden'} : {}}>
												{collabSearchResults.map((item, index) => {
													return (
														<div className="result-item" key={index} >
															<div className="result-item-left">
																{item.avatar ? <img src={item.avatar} alt="" key={index}/> : (
																	<div className="none-avatar" key={index} style={{backgroundColor: item.bgc}}>
																		{(item.first_name[0] + (item.last_name.length > 0 ? item.last_name[0] : '')).toUpperCase()}
																	</div>
																)}
																<div className="result-item-wrapper-name">
																	<div className="item-name">{item.fullName}</div>
																	<div className="item-email">{item.email}</div>
																</div>
															</div>
															<div className="result-item-add-collab" onClick={() => {
																addCollaborator(setProject, item)
																setCollabSearch('')
															}}>
																<Plus/>
															</div>
														</div>
													)
												})}
											</div>
										</div>
										<div className="collab-list">
											{project?.users?.map((collab, index) => {
												return (
													<div className="collab-list-item" key={index}>
														<div className="collab-list-item-left">
															{collab.avatar ? <img src={collab.avatar} alt="" key={index}/> : (
																<div className="none-avatar" key={index} style={{backgroundColor: collab.bgc}}>
																	{collab.first_name[0]?.toUpperCase() + ' ' + collab.last_name[0]?.toUpperCase()}
																</div>
															)}
															<div className="collab-list-item-wrapper-name">
																<div className="item-name">{collab.fullName}</div>
																<div className="item-email">{collab.email}</div>
															</div>
														</div>
														<div className="collab-list-item-right">
															{collab.permission === 'owner' ? (
																<div className="collab-type">{collab.permission[0].toUpperCase() + collab.permission.slice(1)}</div>
															) : (
																<div className="collab-type-select">
																	<div className={"type-select-active "} onClick={() => {
																			openCollabTypeSelect(setProject, collab.id)
																		}}>
																			<div className="type-name">{collab.permission[0].toUpperCase() + collab.permission.slice(1)}</div>
																			<Arrow/>
																	</div>
																	<div className={"type-select-list " + (collab.isOpenSelect ? 'type-select-list-open' : '')}>
																		{collabTypeList.map((type, i) => {
																			if(type.toLowerCase() === collab.permission){
																				return false
																			}
																			return (
																				<div className={"type-list-item "} key={i} onClick={() => {
																					changeCollabType(setProject, collab.id, type.toLowerCase())
																				}}>
																					{type[0].toUpperCase() + type.slice(1)}
																				</div>
																			)
																		})}
																	</div>
																</div>
															)}
															<div className={"collab-remove " + (collab.isOwner ? 'collab-remove-disable' : '')} onClick={() => {
																if(collab.isOwner){
																	return false
																} else {
																	removeCollab(setProject, collab.id)
																	//add remove collab
																}
															}}>
																<span></span>
															</div>
														</div>
													</div>
												)
											})}
										</div>
										<div className="collab-btns">
											<Button color={'white'} padding={30} margin={0} onClick={() => {
												setAddCollab(false)
											}}>{lang.done}</Button>
										</div>
									</div>
								</div>
							</div>
							<DeleteFile setPush={setPush} setPushText={setPushText} fileToDelete={fileToDelete} setDeleteFile={setDeleteFile} setItem={setProject} item={project} deleteDoc={deleteDoc} deleteFile={deleteFile} unlinkFunction={unlinkDocToProject}/>					
							<UploadFiles onUploadFiles={onUploadFiles} setOnUploadFiles={setOnUploadFiles} setPushError={setPushError} setPush={setPush} setPushText={setPushText} setItem={setProject} item={project} setIsVisibleUploader={setIsVisibleUploader} setIsConvert={setIsConvert} setStartUploadList={setStartUploadList} actualFormats={actualFormats} folder={isFolderOpen.path}/>
							<CreateNewFile createFile={createFile} setCreateFile={setCreateFile} setItem={setProject} item={project} tab={'projects'} setPush={setPush} setPushText={setPushText} setPushError={setPushError} folder={isFolderOpen.path}/>
							<ShareFiles
								share={share} 
								setShare={setShare} 
								setPush={setPush} 
								setPushText={setPushText} 
								pushText={pushText} 
								item={project}
								setItem={setProject} 
								doc={isFolderOpen.path === '/' ? (project.documents.filter(item => item.isSelect).length !== 1 ? project.favDoc.filter(doc => doc.isSelect)[0] : project.documents.filter(item => item.isSelect)[0]) : detectFolder(isFolderOpen.path).item.documents.filter(item => item.isSelect)[0]}
								folders={true} 
								detectFolder={detectFolder(isFolderOpen.path)} 
								path={isFolderOpen.path}
							/>
							<div className={"move-to-popup-wrapper " + (moveTo ? 'move-to-popup-wrapper-active' : '')} onClick={(event) => {
								if(event.target.classList.contains('move-to-popup-wrapper-active')){
									setMoveTo(false)
								}
							}}>
								<div className="move-to-popup">
									<div className="move-to-popup-header">
										<div className="header-title">{lang.moveTo}</div>
										<Close onClick={() => setMoveTo(false)}/>	
									</div>
									<div className="move-to-popup-body">
										<div className="move-to-breadcrumbs">
											<span>{activeLang === 'ar' ? ' < ' + lang.projects : lang.projects + ' > '} </span>
											<div className="breadcrumbs-active">{ '\u00A0' + project.name} {activeLang === 'ar' ? "\u00A0" : ''} </div>
											{activeMove ? <div className="breadcrumbs-active"> {activeLang === 'ar' ? activeMove  +  " < \u00A0": '\u00A0 > ' + activeMove }  </div> : ''}
										</div>
										<div className="move-to-list">
											{isOpenMoveFolder?.path !== '/' ? (
												<div className={"move-to-folder " + ((project.folders.find(item => item.onMove)?.id === isOpenMoveFolder.prevPath) || moveUp ? 'move-to-folder-active' : '')} 
													onDoubleClick={() => {
														setIsOpenMoveFolders({path: isOpenMoveFolder.prevPath, prevPath: detectFolder(isOpenMoveFolder.prevPath).item.parent_id === project.id || detectFolder(isOpenMoveFolder.prevPath).item.parent_id === undefined ? '/' : detectFolder(isOpenMoveFolder.prevPath).item.parent_id})
														setActiveMove('')
													}}
													onClick={() => {
														onMove(setProject, isOpenMoveFolder.prevPath)
														setActiveMove('...')
														setMoveUp(true)
													}}
												>
													<div className="name-wrapper">
														<div className="document-item-name">...</div>
													</div>
												</div>
											) : ''}
											{(isOpenMoveFolder.path === '/' ? project.folders.filter(item => (item.parent_id === undefined || item.parent_id === project.id) && !item.potentialMove)
												: detectFolder(isOpenMoveFolder.path).folders.filter(item => !item.potentialMove)).map((folder, index) => {
												return (
													<div className={"move-to-folder " + (folder.onMove ? 'move-to-folder-active' : '')} key={index}  onClick={() => {
														onMove(setProject, folder.id)
														setActiveMove(folder.onMove ? null : folder.name)
														setMoveUp(false)
													}} onDoubleClick={() => {
														setActiveMove('')
														setIsOpenMoveFolders({
															path: folder.id,
															prevPath: folder.parent_id === project.id || folder.parent_id === undefined ? '/' : folder.parent_id
														})
													}}>
														<div className="name-wrapper">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fillRule="evenodd" clipRule="evenodd" d="M9.17189 3.25001L4.33333 3.25C2.63368 3.25 1.25 4.60708 1.25 6.28847V18.7115C1.25 20.3929 2.63368 21.75 4.33333 21.75H19.6667C21.3663 21.75 22.75 20.3929 22.75 18.7115V8.90385L22.7448 8.72499C22.6508 7.12726 21.3056 5.86539 19.6667 5.86539L12.203 5.865L11.6356 4.74626C11.167 3.82566 10.2126 3.248 9.17189 3.25001ZM4.33333 4.75H9.17333C9.65289 4.74908 10.0879 5.01239 10.2983 5.42562L10.8911 6.5949C11.1369 7.07342 11.6301 7.36903 12.1651 7.36537L19.6667 7.36539C20.5443 7.36539 21.25 8.05748 21.25 8.90385V18.7115C21.25 19.5579 20.5443 20.25 19.6667 20.25H4.33333C3.45566 20.25 2.75 19.5579 2.75 18.7115V6.28847C2.75 5.44209 3.45566 4.75 4.33333 4.75ZM12 15C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15ZM17 14C17 14.5523 16.5523 15 16 15C15.4477 15 15 14.5523 15 14C15 13.4477 15.4477 13 16 13C16.5523 13 17 13.4477 17 14ZM8 15C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15Z" fill="#2E384D"/>
															</svg>

															<div className="document-item-name">{folder.name}</div>
														</div>
													</div>
												)
											})}
											{project.folders.filter(item => (item.parent_id === undefined || item.parent_id === project.id) && !item.potentialMove).length === 0
												&& detectFolder(isOpenMoveFolder.path).folders.filter(item => !item.potentialMove).length === 0 ?
												(
													<div className="folders-empty-wrapper">
														<div className="folders-empty-content">
															<ProjectsMenuIcon className="projects-icon"/>
															<div className="folders-empty-title">
																{lang.noFolders}
															</div>
														</div>
													</div>
												) : ''}
										</div>
										<div className="move-to-nav">
											<Button color={'white'} padding={12} margin={10} onClick={() => {
												setMoveTo(false)
											}}>{lang.cancel}</Button>
											<Button color={'blue'} padding={12} onClick={() => {
												let moveDoc = (isFolderOpen.path === '/' ? project.documents.filter(item => item.potentialMove) : detectFolder(isFolderOpen.path).item.documents.filter(item => item.potentialMove))
												let moveFolder = (isFolderOpen.path === '/' ? project.folders.filter(item => item.potentialMove) : detectFolder(isFolderOpen.path).folders.filter(item => item.potentialMove))
												let moveType;
												if(moveDoc.length > 0){
													moveDoc = moveDoc[0]
													moveType = 'documents'
												} else if (moveFolder.length > 0) {
													moveFolder = moveFolder[0]
													moveType = 'folders'
												}
												let idMoveToFolder = project.folders.filter(folder => folder.onMove)[0]
												moveToFolder(setProject, project, moveType === 'documents' ? moveDoc : moveFolder, idMoveToFolder || {id: '/'}, setPush, setPushText, isFolderOpen.path, moveType)
												setMoveTo(false)
											}}>Move</Button>
										</div>
									</div>
								</div>
							</div>
							<div className={"move-to-popup-wrapper " + (moveToProject ? 'move-to-popup-wrapper-active' : '')} onClick={(event) => {
								if(event.target.classList.contains('move-to-popup-wrapper-active')){
									setMoveToProject(false)
								}
							}}>
								<div className="move-to-popup">
									<div className="move-to-popup-header">
										<div className="header-title">{'Connect to company'}</div>
										<Close onClick={() => setMoveToProject(false)}/>	
									</div>
									<div className="move-to-popup-body">
										<div className="move-to-list">
											{companies.map((company, index) => {
												return (
													<div className={"move-to-folder " + (company.move ? 'move-to-folder-active' : '')} key={index}  onClick={() => {
														onMoveCompany(company.id)
													}}>
														<div className="name-wrapper">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fillRule="evenodd" clipRule="evenodd" d="M13.5408 9.18887L16.6925 8.80873L20.4338 11.1249V20.3553H22.3019V22.0042C16.0623 22.0042 7.9377 22.0042 1.69806 22.0042V20.3553H3.56647V2.98563L8.56085 1.99561L13.5408 4.84706V9.18887ZM17.0002 10.077V20.3968H19.3576V11.6592L17.0002 10.077ZM16.0002 20.3968V10.077L13.6429 10.3713V20.3968H16.0002ZM8.98629 3.71092V20.3968H12V5.41977L8.98629 3.71092ZM7.98629 20.3968V3.76641L4.98629 4.31875V20.3968H7.98629Z" fill="#2E384D"/>
															</svg>
															<div className="document-item-name">{company.name}</div>
														</div>
														{company.projects.includes(project.id) ? (
															<span className="includes"></span>
														) : ''}
													</div>
												)
											})}
										</div>
										<div className="move-to-nav">
											<Button color={'white'} padding={12} margin={10} onClick={() => {
												setMoveToProject(false)
											}}>{lang.cancel}</Button>
											<Button color={'blue'} padding={12} onClick={() => {
												if(companies.filter(company => company.move)[0]){
													if(companies.filter(company => company.move)[0].projects.includes(project.id)){
														unlinkProjectFromCompany(companies.filter(item => item.move)[0].id, project.id)
														setCompanies(companies => companies.map(item => item.move ? ({...item, move: false, projects: item.projects.filter(item => !(item === project.id))}) : item))
													} else {	
														linkProjectToCompany(companies.filter(item => item.move)[0].id, project.id)
														setCompanies(companies => companies.map(item => item.move ? ({...item, move: false, projects: item.projects.concat(project.id)}) : item))
													}
												}
												setMoveToProject(false)
											}}>Move</Button>
										</div>
									</div>
								</div>
							</div>
							<div className={"drag-and-drop-wrapper "  + (onDrag ? 'drag-and-drop-wrapper-active' : '')}>
								<label htmlFor="project-upload-file" className="drag-and-drop" onDragLeave={() => {
									setOnDrag(false)
								}} onDropCapture={async (event) => {
									event.preventDefault()
									setOnDrag(false)
									setOnUploadFiles(await uploader(event.dataTransfer.files))
								}} onDragOver={event => {
									event.preventDefault()
								}}>
								</label>
								<div className="drag-and-drop-content">
									<img src={drag} alt=""/>
									<div className="drag-title">{lang.dropYourFiles}</div>
								</div>
							</div>
								<div className="projects-breadcrumbs">
									<span>{activeLang === 'ar' ? ' < ' + lang.projects : lang.projects + ' > '} </span>
									<span className="breadcrumbs-active">{project.name} {activeLang === 'ar' ? "\u00A0" : ''} </span>
								</div>
								<div className="projects-content-header">
									<div className="projects-content-header-name">
										<ProjectsMenuIcon className="projects-icon"/>
										<div className="projects-content-header-name-wrapper">
											<div className="name-wrapper">
												<FileName filename={filename}/>
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
													updateProject({ favourite: !project.favourite}, project.id)
													addItemToFavoritesList(setProjects, project.id, setPushText, setPush)
													setProject(project => ({...project, favourite: !project.favourite}))
												}} style={{cursor: 'pointer'}}>
													<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" stroke="#8798AD" strokeWidth="2" strokeLinejoin="round" fill={project.favourite ? '#8798AD' : '#ffffff'}/>
												</svg>
											</div>
											<div className="last-edited">
												{activeLang === 'ar' ? (
													<>
														{project.updated +  ' • '  + project.edited} <span>:{lang.lastEdited}</span> 
													</>
												) : (
													<> 
														<span>{lang.lastEdited}:</span> {project.updated +  ' • ' + project.edited}
													</>
												)}
											</div>
										</div>
									</div>
									<div className="project-collab">
										<div className="project-collab-list">
											{project.owner_id?.picture ? <img src={project.owner_id?.picture} alt="" className="collab-list-item"/> : (
												<div className="none-avatar collab-list-item" style={{backgroundColor: project.owner_id?.bgc}}>
													{(project.owner_id?.first_name + (project.owner_id?.last_name > 0 ? project.owner_id?.last_name : '')).toUpperCase()}
												</div>
											)}

											{project?.users?.map((user, index) => {
												return (
													<>
														{user._id !== project.owner_id._id ? (
															<>
																{user.avatar ? <img src={user.avatar} alt="" className="collab-list-item" key={index}/> : (
																	<div className="none-avatar collab-list-item" key={index} style={{backgroundColor: user.bgc}}>
																		{user.first_name[0]?.toUpperCase() + ' ' + user.last_name[0]?.toUpperCase()}
																	</div>
																)}
															</>
														) : ""}
													</>
												)
											})}
											<span className="add-collab" onClick={() => setAddCollab(true)}><Plus/></span>
										</div>
										<TabSettings 
											list={projects} 
											setList={setProjects}
											isVisibleDeleteFiles={isVisibleDeleteFiles}
											setIsVisibleDeleteFiles={setIsVisibleDeleteFiles}
											setPush={setPush}
											setPushText={setPushText}
											setChangeName={filename.setIsChange}
											deleteListItem={deleteProject}
											restoreListItem={restoreProject}
											setAddCollab={setAddCollab}
											setMoveToProject={setMoveToProject}
											item={project}
											setItem={setProject}
										/>
									</div>
								</div>
								<PlusNav isOpenOptions={isOpenProjectNavMobile} setIsOpenOptions={setIsOpenProjectNavMobile}/>
								<MobileOptions isOpen={isOpenProjectNavMobile} setIsOpen={setIsOpenProjectNavMobile} isUpload={"project-upload-file"} isNewFile={setCreateFile} isNewFolder={setCreateFolderPopup} newFolderName={newFolderName} isDownload={true} isShare={setShare} line={3}/>
								<div className="project-content-body">
									<div className="project-count">
										{isMobile || window.document.documentElement.clientWidth < 850 ? (
											<Slider {...settings}>
												<div className={"project-count-item " + (activeCount === 0 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 0 ? null : 0)
														setDocFilter(activeCount === 0 ? null : 'draft')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'draft').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'draft').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/draft.svg`)} alt=""/>
															<span>{lang.draft}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 1 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 1 ? null : 1)
														setDocFilter( activeCount === 1 ? null :'review')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'review').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'review').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/review.svg`)} alt=""/>
															<span>{lang.review}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 2 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 2 ? null : 2)
														setDocFilter(activeCount === 2 ? null : 'approved')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'approved').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'approved').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/approved.svg`)} alt=""/>
															<span>{lang.approved}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 3 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 3 ? null : 3)
														setDocFilter(activeCount === 3 ? null : 'signed')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'signed').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'signed').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/signed.svg`)} alt=""/>
															<span>{lang.signed}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 4 ? 'project-count-item-active' : '')} onClick={() => setActiveCount(activeCount === 4 ? null : 4)}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'autoRenewing').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'autoRenewing').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/renewing.svg`)} alt=""/>
															<span>{lang.auto} <br/> {lang.renewing}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 5 ? 'project-count-item-active' : '')} onClick={() => setActiveCount(activeCount === 5 ? null : 5)}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'expiring').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'expiring').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/expiring.svg`)} alt=""/>
															<span>{lang.expiring}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 6 ? 'project-count-item-active' : '')} onClick={() => setActiveCount(activeCount === 6 ? null : 6)}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'expired').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'expired').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/expired.svg`)} alt=""/>
															<span>{lang.statuses.expired}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
											</Slider>
										) : (
											<>
												<div className="project-count-list">
													<div className={"project-count-item " + (activeCount === 0 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 0 ? null : 0)
														setDocFilter(activeCount === 0 ? null : 'draft')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'draft').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'draft').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/draft.svg`)} alt=""/>
															<span>{lang.draft}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 1 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 1 ? null : 1)
														setDocFilter( activeCount === 1 ? null :'review')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'review').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'review').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/review.svg`)} alt=""/>
															<span>{lang.review}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 2 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 2 ? null : 2)
														setDocFilter(activeCount === 2 ? null : 'approved')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'approved').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'approved').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/approved.svg`)} alt=""/>
															<span>{lang.approved}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 3 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 3 ? null : 3)
														setDocFilter(activeCount === 3 ? null : 'signed')
													}}>
														<div className="count-value">{isFolderOpen.path === '/' ? project.documents.filter(item => item.status === 'signed').length : detectFolder(isFolderOpen.path).item.documents.filter(item => item.status === 'signed').length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/signed.svg`)} alt=""/>
															<span>{lang.signed}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
												</div>
												<div className="project-count-list">
													<div className={"project-count-item " + (activeCount === 4 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 4 ? null : 4)
														setDocFilter(activeCount === 4 ? null : 'renewing')
													}}>
														<div className="count-value">{(isFolderOpen.path === '/' ? project : detectFolder(isFolderOpen.path).item).documents.filter(item => {
															let renewing = item.details.filter(detail => detail.field.name === 'Renewal Type')[0]
															let expiration = item.details.filter(detail => detail.field.name === 'Expiration Date')[0]
															if((renewing === undefined || renewing.value === null || renewing.value === 'non-automatic') && (expiration === undefined || expiration.value === null || expiration.value < Date.now())){
																return false
															} else {
																return true
															}
														}).length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/renewing.svg`)} alt=""/>
															<span>{lang.auto} <br/> {lang.renewing}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 5 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 5 ? null : 5)
														setDocFilter(activeCount === 5 ? null : 'expiring')
													}}>
														<div className="count-value">{(isFolderOpen.path === '/' ? project : detectFolder(isFolderOpen.path).item).documents.filter(item => {
															let expiration = item.details.filter(detail => detail.field.name === 'Expiration Date')[0]
															if ((expiration === undefined || expiration.value === null || expiration.value < Date.now() || expiration.value > Date.now() + 604800000)) {
																return false
															} else {
																return true
															}
														}).length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/expiring.svg`)} alt=""/>
															<span>{lang.expiring}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
													<div className={"project-count-item " + (activeCount === 6 ? 'project-count-item-active' : '')} onClick={() => {
														setActiveCount(activeCount === 6 ? null : 6)
														setDocFilter(activeCount === 6 ? null : 'expired')
													}}>
														<div className="count-value">{(isFolderOpen.path === '/' ? project : detectFolder(isFolderOpen.path).item).documents.filter(item => {
															let expiration = item.details.filter(detail => detail.field.name === 'Expiration Date')[0]
															if ((expiration === undefined || expiration.value === null || expiration.value > Date.now())) {
																return false
															} else {
																return true
															}
														}).length}</div>
														<div className="count-content">
															<img src={require(`../../assets/img/project/expired.svg`)} alt=""/>
															<span>{lang.statuses.expired}</span>
														</div>
														<div className="check">
															<Check/>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
									<div className="project-docs-nav">
										<div className="project-btns">
											<input ref={files} type="file" id="project-upload-file" multiple onChange={async () => {
												let uploadControl = uploader(files.current.files) 
												setOnUploadFiles(uploadControl)
												setIsOpenProjectNavMobile(false)
											}}/>
											<label htmlFor="project-upload-file" className="upload-btn">
												<Plus/>
												<span>{lang.uploadFiles}</span>
											</label>
											<Button padding={13} plus={true} color={'white'} margin={10} plusMargin={5} onClick={() => {
												setCreateFile(true)
											}}>{lang.newFile}</Button>
											<Button padding={13} plus={true} color={'white'} margin={10} plusMargin={5} onClick={() => {
												setCreateFolderPopup(true)
												newFolderName.current.value = 'Untitled folder'
												setTimeout(() => {
													newFolderName.current.focus()
													newFolderName.current.select()
												}, 200)
												}}>{lang.newFolder}</Button>
											<Button padding={13} color={'white'} margin={10} disabled={((isFolderOpen.path === '/' ? project.documents.filter(item => item.isSelect) : detectFolder(isFolderOpen.path).item.documents.filter(item => item.isSelect)).length === 1 ? false : true) && (project.favDoc.filter(doc => doc.isSelect).length === 1 ? false : true)} onClick={() => {
												setShare(true)
											}}>{lang.share}</Button>
											<Button padding={13} color={'white'} disabled={project.documents.filter(doc => doc.isSelect).length === 0 && project.folders.filter(folder => folder.isSelect).length === 0 ? true : false} onClick={() => {
												if(project.documents.filter(doc => doc.isSelect).length > 1){
													downloadArchive(project.documents.filter(doc => doc.isSelect).map(doc => doc._id))
												} else {
													getDocument(project.documents.filter(doc => doc.isSelect)[0].files[0].filename, true)
												}
											}}>{lang.download}</Button>
										</div>
										<div className="project-docs-view">
											<span>{lang.view}</span>
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setViewDocsType(0)}>
												<rect width="16" height="7" rx="2" fill={(viewDocsType === 0 && project.documents.length > 0) ? "#2E384D" : "#BFC5D2"}/>
												<rect y="9" width="16" height="7" rx="2" fill={(viewDocsType === 0 && project.documents.length > 0) ? "#2E384D" : "#BFC5D2"}/>
											</svg>
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setViewDocsType(1)}>
												<rect width="7" height="7" rx="2" fill={(viewDocsType === 1 && project.documents.length > 0) ? "#2E384D" : "#BFC5D2"}/>
												<rect x="9" width="7" height="7" rx="2" fill={(viewDocsType === 1 && project.documents.length > 0) ? "#2E384D" : "#BFC5D2"}/>
												<rect y="9" width="7" height="7" rx="2" fill={(viewDocsType === 1 && project.documents.length > 0) ? "#2E384D" : "#BFC5D2"}/>
												<rect x="9" y="9" width="7" height="7" rx="2" fill={(viewDocsType === 1 && project.documents.length > 0) ? "#2E384D" : "#BFC5D2"}/>
											</svg>
										</div>
									</div>
									{project.documents.length > 0 || project.folders.length > 0 || project.delDoc.length > 0 ? (
										<>
											{isFolderOpen.path === '/' ? (
												<div className="project-docs-list">
													{project.documents.filter(doc => user.favourites.documents.includes(doc._id)).length > 0 || project.delDoc.length > 0 ? (
														<>
															{project.documents.filter(doc => user.favourites.documents.includes(doc._id)).length > 0 ? (
																<>
																	<div onClick={() => console.log('doc', project.favDoc)} className="project-docs-list-header">{lang.favorites}</div>
																	<DocTable
																		// setNeedUpdateProjectList={updateProjectList}
																		documents={project.favDoc}
																		docFilter={docFilter}
																		groupTemplateId={project.id}
																		setItem={setProject}
																		itemPar={project}
																		setPush={setPush} selectRef={selectRef}
																		docOptionsRef={docOptionsRef}
																		setPushText={setPushText} type={'favDoc'}
																		folders={[]} isEdit={true}
																		viewDocsType={viewDocsType}
																		setFileToDelete={setFileToDelete}
																		setDeleteFile={setDeleteFile}
																		setMoveTo={setMoveTo} actualTypes={actualTypes}
																		setShare={setShare}
																	/>
																</>
															) : ''}
																<div className="project-docs-list-header">{lang.allFiles}</div>
																<DocTable 
																	// setNeedUpdateProjectList={updateProjectList}
																	documents={project.documents.filter(doc => !doc.isDelete)}
																	docFilter={docFilter}
																	groupTemplateId={project.id}
																	setItem={setProject}
																	itemPar={project}
																	isFolderOpen={isFolderOpen}
																	setIsFolderOpen={setIsFolderOpen}
																	selectRef={selectRef}
																	docOptionsRef={docOptionsRef} setPush={setPush}
																	setPushText={setPushText} setShare={setShare}
																	folders={project.folders.filter(item => (item.parent_id === undefined || item.parent_id === project.id) && !item.deleted_at)}
																	type={'documents'} actualTypes={actualTypes}
																	isEdit={true} setMoveTo={setMoveTo}
																	viewDocsType={viewDocsType}
																	setFileToDelete={setFileToDelete}
																	setDeleteFile={setDeleteFile}
																/>
															{project.delDoc.length > 0 && isVisibleDeleteFiles ? (
																<>
																	<div className="project-docs-list-header" ref={deletedRef}>{lang.deletedFiles}</div>
																	<DocTable
																		// setNeedUpdateProjectList={updateProjectList}
																		documents={project.delDoc}
																		docFilter={docFilter}
																		groupTemplateId={project.id}
																		setItem={setProject}
																		itemPar={project}
																		setPush={setPush}
																		selectRef={selectRef}
																		docOptionsRef={docOptionsRef}
																		setPushText={setPushText} type={'delDoc'}
																		folders={project.folders.filter((folder) => folder.deleted_at)}
																		isEdit={true}
																		viewDocsType={viewDocsType}
																		setFileToDelete={setFileToDelete}
																		setShare={setShare}
																		setDeleteFile={setDeleteFile}
																		setMoveTo={setMoveTo}
																		actualTypes={actualTypes}
																	/>
																</>
															) : ''}
														</>
													) : (
														<DocTable 
															// setNeedUpdateProjectList={updateProjectList}
															documents={project.documents}
															docFilter={docFilter} 
															setItem={setProject}
															itemPar={project}
															groupTemplateId={project.id}
															setPush={setPush} isFolderOpen={isFolderOpen}
															setIsFolderOpen={setIsFolderOpen}
															selectRef={selectRef} docOptionsRef={docOptionsRef}
															setPushText={setPushText} setShare={setShare}
															folders={project.folders.filter(item => (item.parent_id === undefined || item.parent_id === project.id) && !item.deleted_at)}
															type={'documents'} actualTypes={actualTypes}
															isEdit={true} viewDocsType={viewDocsType}
															setMoveTo={setMoveTo}
															setFileToDelete={setFileToDelete}
															setDeleteFile={setDeleteFile}
														/>
													)}
												</div>
											) : <DocTable 
													// setNeedUpdateProjectList={updateProjectList}
													documents={detectFolder(isFolderOpen.path).item.documents}
													docFilter={docFilter} setItem={setProject}
													itemPar={project} setPush={setPush}
													isFolderOpen={isFolderOpen}
													groupTemplateId={project.id}
													setIsFolderOpen={setIsFolderOpen} selectRef={selectRef}
													docOptionsRef={docOptionsRef} setPushText={setPushText}
													parentFolder={detectFolder(isFolderOpen.prevPath).item.parent_id}
													setShare={setShare}
													folders={detectFolder(isFolderOpen.path).folders.filter(item => !item.deleted_at)}
													type={'documents'} isEdit={true}
													viewDocsType={viewDocsType} setMoveTo={setMoveTo}
													actualTypes={actualTypes}
													setFileToDelete={setFileToDelete}
													setDeleteFile={setDeleteFile}
												/>
											}
										</>
									) : (
										<div className="project-docs-list-empty">
											<div className="docs-empty-content">
												<DocumentMenuIcon/>
												<div className="docs-empty-title">
													{lang.emptyProject}
												</div>
											</div>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</>
			) : ''}
			
		</div>
	)
}

export default Projects