import React, { useState, useEffect, useContext } from 'react'
import {Link} from 'react-router-dom'

import {AppContext} from '../../../context/AppContext'

import NotificationItem from '../../Dashboard/NotificationItem/NotificationItem'

import notification from '../../../assets/img/notification.svg'

import {
	getNotifications,
	viewNotifications
} from '../../../utilites/api'

import './NotificationsNav.scss'
import './NotificationsNavMedia.scss'

function NotificationsNav({notificationsIsOpen, setNotificationsIsOpen, setProfilePopupIsOpen}) {
	let [notifications, setNotifications] = useState([])
	let [newNotifications, setNewNotifications] = useState(false)

	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang

	useEffect(() => {
		if(notificationsIsOpen){
			viewNotifications()
		}
	}, [notificationsIsOpen])

	useEffect(() => {
		getNotifications(10)
			.then(res => {
				if(res.data.find(item => !item.viewed)){
					setNewNotifications(true)
				}
				setNotifications(res.data)
			})
	},[])

	return (
		<div className={"notifications-nav-wrapper " + (activeLang === 'ar' ? 'notifications-nav-wrapper-arabic' : '')}>
			<div className="notifications-icon-wrapper" onClick={() => {
					setNotificationsIsOpen(prev => !prev)
					setProfilePopupIsOpen(false)
				}}>
				<img src={notification} alt="notification" className="notification-icon"/>
				{newNotifications ? <span className="notification-label"></span> : ''}
			</div>
			<div className={"notifications " + (notificationsIsOpen ? 'notifications-active' : '')}>
				<svg version="1.1" id="arrow" viewBox="0 0 490.787 490.787">
					<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006
						c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083
						c-0.086,0.089-0.173,0.176-0.262,0.262L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085
						C368.236,489.664,365.511,490.792,362.671,490.787z"/>
					<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256
						c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
						L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C368.236,489.664,365.511,490.792,362.671,490.787z"/>
				</svg>
				<div className="notifications-header">
					<span className="notifications-header-count">{lang.notifications} ({notifications?.length})</span>
					<Link to='/dashboard/notifications' className="notifications-header-all" onClick={() => setNotificationsIsOpen(false)}>{lang.viewAll}</Link>
				</div>
				{notifications?.length ?
				<div className="notifications-list">
					{notifications?.map((item, index) => {
						//eslint-disable-next-line
						// return false
						return <NotificationItem notification={item} key={index}/>
					})}
				</div>
				: ""}
			</div>
		</div>
	)
}

export default NotificationsNav