import {
	deleteSettingsDetailsField
} from './api'
export const changeEditItemDetails = (setDetails, id) => {
	setDetails(details => {
		return details.map(item => {
			if(item.id === id){
				return {
					...item,
					isEdit: !item.isEdit
				}
			}
			return {
				...item, 
				isEdit: false
			}
		})
	})
}

export const changeItemDetails = (setDetails, id, newVal) => {
	setDetails(details => {
		return details.map(item => {
			if(item.id === id){
				return {
					...item,
					name: newVal
				}
			}
			return item
		})
	})
}

export const deleteItemDetails = (setDetails, id) => {
	deleteSettingsDetailsField(id)
	setDetails(details => {
		return details.filter(item => {
			if(item.id === id){
				return false
			}
			return true
		})
	})
}

export const addNewField = (setDetails, sub_group, name, group, id, fieldType = 'string') => {
	setDetails(details => {
		return details.concat({
				sub_group: sub_group, 
				name: name, 
				group: group,
				field_type: fieldType,
				id: id,
				editable: true
			})
	})
}