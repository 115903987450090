// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  left: 0px;
  top: 80px;
  height: calc(100vh - 80px);
  background-color: #fff;
  z-index: 13; }
  .loader .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    list-style: none; }
    .loader .loading li {
      height: 0;
      position: absolute;
      top: 50%;
      left: 0;
      width: 0;
      margin: 0;
      height: 10px;
      width: 10px;
      border: 3px solid #2b8ccd;
      border-radius: 100%;
      transform: transformZ(0);
      animation: LOADING 2s infinite; }
      .loader .loading li:nth-child(1n) {
        left: -20px;
        animation-delay: 0s; }
      .loader .loading li:nth-child(2n) {
        left: 0;
        animation-delay: 0.2s; }
      .loader .loading li:nth-child(3n) {
        left: 20px;
        animation-delay: 0.4s; }

@keyframes LOADING {
  0% {
    transform: scale(0.5);
    background: #2b8ccd; }
  50% {
    transform: scale(1);
    background: white; }
  100% {
    transform: scale(0.5);
    background: #2b8ccd; } }
`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.scss"],"names":[],"mappings":"AAAA;EACC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,YAAY;EACZ,SAAS;EACT,SAAS;EACT,0BAA0B;EAC1B,sBAAsB;EACtB,WAAW,EAAA;EAVZ;IAYE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB,EAAA;IAhBlB;MAkBG,SAAS;MACT,kBAAkB;MAClB,QAAQ;MACR,OAAO;MACP,QAAQ;MACR,SAAS;MACT,YAAY;MACZ,WAAW;MACX,yBAAyB;MACzB,mBAAmB;MACnB,wBAAwB;MACxB,8BAA8B,EAAA;MA7BjC;QA+BK,WAAW;QACX,mBAAmB,EAAA;MAhCxB;QAmCK,OAAO;QACP,qBAAqB,EAAA;MApC1B;QAuCK,UAAU;QACV,qBAAqB,EAAA;;AAM1B;EACC;IACE,qBAAqB;IACrB,mBAAmB,EAAA;EAErB;IACE,mBAAmB;IACnB,iBAAiB,EAAA;EAEnB;IACE,qBAAqB;IACrB,mBAAmB,EAAA,EAAA","sourcesContent":[".loader{\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: fixed;\n\twidth: 100vw;\n\tleft: 0px;\n\ttop: 80px;\n\theight: calc(100vh - 80px);\n\tbackground-color: #fff;\n\tz-index: 13;\n\t.loading{\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tposition: relative;\n\t\tlist-style: none;\n\t\tli {\n\t\t\theight: 0;\n\t\t\tposition: absolute;\n\t\t\ttop: 50%;\n\t\t\tleft: 0;\n\t\t\twidth: 0;\n\t\t\tmargin: 0;\n\t\t\theight: 10px;\n\t\t\twidth: 10px;\n\t\t\tborder: 3px solid #2b8ccd;\n\t\t\tborder-radius: 100%;\n\t\t\ttransform: transformZ(0);\n\t\t\tanimation: LOADING 2s infinite;\n\t\t\t&:nth-child(1n) {\n\t\t\t  left: -20px;\n\t\t\t  animation-delay: 0s;\n\t\t\t}\n\t\t\t&:nth-child(2n) {\n\t\t\t  left: 0;\n\t\t\t  animation-delay: 0.2s;\n\t\t\t}\n\t\t\t&:nth-child(3n) {\n\t\t\t  left: 20px;\n\t\t\t  animation-delay: 0.4s;\n\t\t\t}\n\t\t  }\n\t}\n}\n\n@keyframes  LOADING {\n\t0%   { \n\t  transform: scale(0.5);\n\t  background: #2b8ccd;\n\t}\n\t50%   { \n\t  transform: scale(1);\n\t  background: white;\n\t}\n\t100% { \n\t  transform: scale(0.5);\n\t  background: #2b8ccd;\n\t}\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
