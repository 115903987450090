import {ReactComponent as Close} from "../../../assets/img/close.svg";
import {ReactComponent as Check} from "../../../assets/img/check.svg";
import Button from "../../Button/Button";
import {updateDocumentStatus} from "../../../utilites/api";
import React, {useContext} from "react";
import {AppContext} from "../../../context/AppContext";
import "./TenderSubmitPopup.scss";

export const TenderToOtherPopup = ({isOpenChangeTenderPopup, setIsOpenChangeTenderPopup, setDocument, document, selectedStatus}) => {
    const activeLang = useContext(AppContext).activeLang
    const lang = useContext(AppContext).lang

    return (
        <div className={"tender-popup-wrapper " + (isOpenChangeTenderPopup ? 'tender-popup-wrapper-active' : '')}
             onClick={event => {
                 if (event.target.classList.contains('tender-popup-wrapper-active')) {
                     setIsOpenChangeTenderPopup(false)
                 }
             }}>
            <div className="tender-popup">
                <div className="tender-header">
                    <div className="header-title">{lang.inTender}</div>
                    <Close onClick={() => setIsOpenChangeTenderPopup(false)}/>
                </div>
                <div className="tender-popup-body">
                    <div className="tender-popup-body-title">{lang.statusInTenderToOther}
                        <br/>
                    </div>
                    <div className="tender-nav">
                        <div className="dont-ask">
                        </div>
                        <div className="tender-btns">
                            <Button color={'white'} padding={12} margin={10} onClick={() => {
                                setIsOpenChangeTenderPopup(false)
                            }}>{lang.cancel}</Button>
                            <Button color={'blue'} padding={12} onClick={() => {
                                setIsOpenChangeTenderPopup(false)
                                setDocument({
                                    ...document,
                                    isOpenStatusSelect: false,
                                    status: selectedStatus,
                                    isOpenOptions: false
                                })
                                updateDocumentStatus(document.id, selectedStatus)
                            }}>{lang.confirm}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}