import React, {useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import isTokenValid from "./utilites/token/checkUserToken";
import { roles } from "./settings/roles";
import {getUserRole} from "./utilites/token/getUserRole";
import history from "./utilites/history";
import {isLogin, logout} from "./utilites/auth";

export const AuthRoute =  ({ component: Component, roleAccess= Object.values(roles), isLoginPage, ...rest }) => {
    let isAuth = isTokenValid();
    let userRole = getUserRole();

    if(isLoginPage) {
        logout();
        history.push("/login");
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuth && roleAccess.includes(userRole) ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                        }}
                    />
                )
            }
        />
    );
}