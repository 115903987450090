import React, { useState, useEffect, useRef } from 'react'

import './Push.scss'
import './PushMedia.scss'

function Push({children, top, padding, isError = false}) {
	let [view, setView] = useState(false)
	let push = useRef()
	useEffect(() => {
		setView(true)
		setTimeout(() => {
			setView(false)
		}, 1000)
	}, [])
	return (
		<div ref={push} className={"push " + (view ? 'push-active ' : '') + (isError ? 'push-error' : '')} style={{paddingLeft: padding, paddingRight: padding, top: top, left : push.current ? `calc(50% - ${push.current.offsetWidth / 2}px)` : 'calc(50% - 74px)'}}>
			<span className="check">
				<svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M8.53509 1.51907L3.51095 6.92089C3.18737 7.26879 2.66352 7.26879 2.34077 6.92089L0.242063 4.66531C-0.0806876 4.31741 -0.0806876 3.75418 0.242063 3.40628C0.565641 3.05927 1.08949 3.05927 1.41224 3.40628L2.92586 5.03368L7.36492 0.260926C7.68767 -0.0869755 8.21152 -0.0869755 8.53509 0.260926C8.85867 0.607939 8.85867 1.17117 8.53509 1.51907Z" fill="white"/>
				</svg>
			</span>
			{children}
		</div>
	)
}

export default Push