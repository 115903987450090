import React, {useState, useEffect, useContext, useRef} from 'react'

import { useHistory, useParams, useLocation } from 'react-router-dom'

import useFileName from '../../hooks/useFileName'


import PageNav from '../Nav/PageNav/PageNav'
import FileName from  '../FileName/FileName'
import Button from '../Button/Button'
import DocTable from '../DocTable/DocTable'
import Push from '../Push/Push'
import PlusNav from '../PlusNav/PlusNav'
import MobileOptions from '../MobileOptions/MobileOptions'
import Loader from '../Loader/Loader'
import TabSettings from '../TabSettings/TabSettings'

import ShareFiles from '../Popups/ShareFiles/ShareFiles'
import DeleteFile from '../Popups/DeleteFile/DeleteFile'

import { 
	addDocuments,
	deleteDoc,
} from '../../utilites/table'

import {
	docConvert
} from '../../utilites/appfunctions'

import {
	getTemplates,
	createTemplateGroup,
	updateTemplateGroup,
	deleteTemplateGroup,
	deleteTemplate,
	test, getDocument, getDocumentTemplates, getTemplateGroup, getProject, getTemplatesWithFiles, restoreTemplateGroup
} from '../../utilites/api'

import {AppContext} from '../../context/AppContext'

import {ReactComponent as TemplatesMenuIcon} from '../../assets/img/menu/templates.svg'

import './Templates.scss'
import './TemplatesMedia.scss'
import {convertTemplate} from "../../utilites/essence";

function Templates(){
	let [isLoadComplete, setIsLoadComplete] = useState(true)
	let [template, setTemplate] = useState(null)

	let [isVisibleDeleteFiles, setIsVisibleDeleteFiles] = useState(false)

	
	let [templates, setTemplates] = useState([])
	let [isActive, setIsActive] = useState(false)

	let filename = useFileName(setTemplates, setTemplate, template, updateTemplateGroup)

	
	let [deleteFile, setDeleteFile] = useState(false)
	let [fileToDelete, setFileToDelete] = useState(null)

	let [share, setShare] = useState(false)

	let [push, setPush] = useState(false)
	let [pushText, setPushText] = useState('')
	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang
	let user = useContext(AppContext).user

	const history = useHistory()
	const location = useLocation()
	const { id } = useParams()

	const deletedRef = useRef()

	let [isOpenTemplatesNavMobile, setIsOpenTemplatesNavMobile] = useState(false)

	const TemplateGroupCreate = async () => {
		let nameCount = templates.filter(item => item.name.includes('Untitled')).length
		createTemplateGroup(nameCount === 0 ? 'Untitled' : 'Untitled (' + nameCount + ')')
		.then(async res => {
			setTemplate(await convertTemplate(res.data.data))
			setTemplates(() => templates.concat(res.data.data))

			history.push(`/templates/${res.data.data._id}`)

			setIsActive(true)
			setPushText('NEW TEMPLATE CREATED')
			setPush(true)
			setTimeout(() => {
				setPush(false)
				filename.setIsChange(true)
			}, 1200)
		})
	}

	const convertTemplates = (data) => {
		let active = window.location.search.split('=').pop()
		let activeId = data.find((item) => {
			if(!item.deleted_at){
				return true
			}
		})
		return data.map((template, index) => {
      return {
				id: template._id,
				_id: template._id,
				name: template.name,
				active: active ? (active === template._id ? true : false) : (activeId?._id === template._id ? true : false),
				archived: template.deleted_at ? true : false,
				deleted_at: template.deleted_at,
				documents: [ ]
			}
		})
	}

	const getTemplateData = id => {
		getTemplatesWithFiles(id)
			.then(async res => {
				// console.log(res)
				setTemplate(await convertTemplate(res))
				setIsLoadComplete(false)
			})
	}

	const templateDetect = (templates) => {
		if(id && templates.length > 0){
			getTemplateData(id)
		} else {
			if(templates.length > 0){
				const active = templates.find(item => {
					if(!item.deleted_at && !item.archived){
						return true
					}
					return false
				})
				if(active){
					getTemplateData(active._id)
					history.push(`/templates/${active._id}`)
				} else {
					getTemplateData(templates[0]._id)
					history.push(`/templates/${templates[0]._id}`)
				}
			}
		}
	}

	useEffect(() => {
		async function getTemplatesData(){
			getTemplates()
			.then(async result => {
				//templateDetect(await convertTemplates(result.data))
				setIsActive(result.data.length === 0 ? false : true)
				if(result.data.length === 0){
					setIsLoadComplete(false)
					history.push('/templates')
				} else {
					setTemplates(await convertTemplates(result.data))
					templateDetect(result.data)
				}
			})
			// test()
			// .then(res => {
			// 	console.log(res)
			// })
		}
		getTemplatesData()
	}, [])

	useEffect(() => {
		if(isVisibleDeleteFiles){
			window.scrollBy({
				top: deletedRef.current?.offsetTop - 50,
				behavior: "smooth"
			})
		}
	}, [isVisibleDeleteFiles])

	useEffect(() => {
		templateDetect(templates)
	}, [location])

	return (
		<div className={"templates " + (activeLang === 'ar' ? 'templates-arabic' : '')}>
			<Loader isVisible={isLoadComplete}/>
			{isLoadComplete === false ? (
				<>
					<PageNav activeItem={template?.id} setList={setTemplates} list={templates} listIcon={<TemplatesMenuIcon/>} page={lang.templates} createListItem={TemplateGroupCreate}/>
					<div className={"templates-content " + (templates.length === 0 ? 'templates-content-empty' : '')}>
						{templates.length === 0 ? (
							<div className="templates-empty-wrapper">
								<TemplatesMenuIcon className="empty-icon"/>
								<div className="empty-title">{lang.welcomeToNewTemplate}</div>
								<Button plus={true} padding={16} color={'blue'} onClick={() => {
									TemplateGroupCreate()
								}}>{lang.newTemplate}</Button>
							</div>
						) : (
							<>
								{push ? <Push top={0} padding={8}>{pushText}</Push> : ''}
								<DeleteFile
									setPush={setPush}
									setPushText={setPushText}
									fileToDelete={fileToDelete}
									setDeleteFile={setDeleteFile}
									setItem={setTemplate}
									item={template}
									deleteDoc={deleteDoc}
									deleteFile={deleteFile}
									unlinkFunction={deleteTemplate}/>
								<ShareFiles
									share={share}
									setShare={setShare}
									setPush={setPush}
									setPushText={setPushText}
									pushText={pushText}
									item={template}
									setItem={setTemplate}
									list={templates}
									setList={setTemplates}
									isActive={isActive}
									doc={templates.find(item => item.isSelect)}
									path={'/'}
									isTemplate={true}/>
								<div className="template-breadcrumbs">
									{activeLang === 'ar' ? ' < ' + lang.templates : lang.templates + ' > '}
									<span className="breadcrumbs-active">{isActive && template.name}  {activeLang === 'ar' ? "\u00A0" : ''} </span>
								</div>
								<div className="template-content-header">
									<div className="template-content-header-name">
										<TemplatesMenuIcon className="template-icon"/>
										<FileName filename={filename}/>
									</div>
									<TabSettings
										list={templates}
										setList={setTemplates}
										isVisibleDeleteFiles={isVisibleDeleteFiles}
										setIsVisibleDeleteFiles={setIsVisibleDeleteFiles}
										setPush={setPush}
										setPushText={setPushText}
										setChangeName={filename.setIsChange}
										restoreListItem={restoreTemplateGroup}
										deleteListItem={deleteTemplateGroup}
										setItem={setTemplate}
										item={template}
									/>
								</div>
								<PlusNav isOpenOptions={isOpenTemplatesNavMobile} setIsOpenOptions={setIsOpenTemplatesNavMobile}/>
								<MobileOptions isOpen={isOpenTemplatesNavMobile} setIsOpen={setIsOpenTemplatesNavMobile} isNewFile={() => addDocuments(setTemplate, template, [{name: 'Untitled'}], true, template.id)} isDownload={true} isShare={setShare}/>
								<div className="templates-btns">
									<Button plus={true} color={'white'} padding={14} margin={10} plusMargin={5}
											onClick={() => {
												addDocuments(setTemplate, template, [{name: 'Untitled'}], true, template.id).then(() => {
													let activeInput = window.document.getElementsByClassName('document-item-name-rename')[0]
													if(activeInput){
														activeInput.focus();
														activeInput.select();
													}
												})
											}}
									>{lang.newFile}</Button>
									<Button color={'white'} padding={13} margin={10} disabled={isActive && template?.documents?.filter(item => item.isSelect).length === 1 ? false : true} onClick={() => {
										setShare(true)
									}}>{lang.share}</Button>
								</div>
								{ isActive && template ? (
									<>
										<div className="template-docs-list-header">{lang.allFiles}</div>
										<DocTable
											documents={template.documents.filter(doc => !doc.isDelete)}
											setItem={setTemplate}
											itemPar={template}
											setList={setTemplates}
											setPush={setPush}
											setPushText={setPushText}
											pushText={pushText}
											templates={true}
											list={templates}
											type={'documents'}
											folders={[]}
											setFileToDelete={setFileToDelete}
											setDeleteFile={setDeleteFile}
											groupTemplateId={template.id}
										/>
										{ (template && template?.delDoc?.length > 0 && isVisibleDeleteFiles) ? (
											<>
												<div className="template-docs-list-header" ref={deletedRef}>{lang.deletedFiles}</div>
												<DocTable documents={template.delDoc}
														  setItem={setTemplate}
														  templates={true}
														  type={'delDoc'}
														  setList={setTemplates}
														  list={templates}
														  folders={[]}
														  setFileToDelete={setFileToDelete}
														  setDeleteFile={setDeleteFile}
														  groupTemplateId={template.id}/>
											</>
										) : ''}
									</>
								) : (
									<DocTable documents={ isActive && templates.filter(template => template.active ? template : false)[0] && templates.filter(template => template.active)[0].documents}  templates={true}  setList={setTemplates} list={templates} type={'documents'} folders={[]} setFileToDelete={setFileToDelete} setDeleteFile={setDeleteFile} groupTemplateId={ isActive && templates.filter(item => item.active ? true : false)[0].id}/>
								)}
							</>
						)}
					</div>
				</>
			) : ''}
			
		</div>
	)
}

export default Templates