import {
	uploadDocument,
	linkDocToProject,
	unlinkDocToProject,
	linkDocToCompany,
	createTemplate,
	createDocument,
	linkUserToProject,
	unlinkUserToProject,
	getUsers,
	linkDocToFolder,
	unlinkDocFromFolder,
	moveFolder,
	updateDocumentDetails,
	updateDocumentFavourite,
	linkUserToDoc
} from './api'

import {
	dateConvert,
	format
} from './appfunctions'
export async function addDocuments(setItem, item, newDocuments, convert = false, create = false, selectNewFile = false, isDocument = false, folder = '/', addOnly = false ) {
	let untitledCount = 0;
	let users = await getUsers().then(res => res.users)

	function addNewDocToTable(setItem, docs, isRename = false){
		setItem(item => {
			return {
				...item,
				documents: item.documents.map(doc => ({...doc, isOpenOptions: false})).concat(docs.map(doc => {
					if(!doc.edited_by){
						doc.edited_by = users.find(u => u._id === doc.owner_id)
					}
					return {
						...doc,
						id: doc._id,
						file: format(convert ? 'spe' : doc.file_type),
						name: selectNewFile ? "New file" : doc.name.replace(/\.[a-z]+\(/gi, '(').split('.')[0],
						expiry: null,
						type: 'Other',
						editedBy: {
							...doc.edited_by,
							name: doc.edited_by?.first_name
						},
						editedDate: '1 min ago',
						status: 'draft',
						isOpenStatusList : false,
						isOpenOptions: false,
						isOpenCalendar:false,
						isSelect: false,
						onDrag: false,
						isLinked : false,
						linkerId: null,
						isFavorite: false,
						isDelete: false,
						isRevertMenu: false,
						onMove: false,
						potentialMove: false,
						isOpenType: false,
						isRename: isRename,
						favoriteId: '',
						deleteId: '',
						linkedList : []
					}
				}))
			}
		})
	}

	function addNewDocToFolder(setItem, docs, folder){
		setItem(item => {
			return {
				...item,
				folders: item.folders.map(folderItem => {
					if(folderItem.id === folder){
						return {
							...folderItem,
							documents: 	folderItem.documents.map(doc => ({...doc, isOpenOptions: false})).concat(docs.map(doc => {
								if(!doc.edited_by){
									doc.edited_by = users.find(u => u._id === doc.owner_id)
								}
								return {
									...doc,
									id: doc._id,
									file: convert ? 'spe' : doc.file_type,
									name: doc.name.replace(/\.[a-z]+\(/gi, '('),
									expiry: '31 August 2021',
									type: 'Other',
									editedBy: {
										...doc.edited_by,
										name: doc.edited_by?.first_name
									},
									editedDate: '1 min ago',
									status: 'draft',
									isOpenStatusList : false,
									isOpenOptions: false,
									isOpenCalendar:false,
									isSelect: false,
									onDrag: false,
									isLinked : false,
									linkerId: null,
									isFavorite: false,
									isDelete: false,
									isRevertMenu: false,
									onMove: false,
									potentialMove: false,
									isOpenType: false,
									favoriteId: '',
									deleteId: '',
									linkedList : []
								}
							}))
						}
					}
					return folderItem
				})
			}
		})
	}

	if(addOnly){
		if(folder === '/'){
			addNewDocToTable(setItem, newDocuments)
			newDocuments.map(async (doc) => {
				if (window.location.pathname.includes('/companies')){
					await linkDocToCompany(item.id, doc._id)
				} else if (window.location.pathname.includes('/projects')){
					await linkDocToProject(item.id, doc._id)
				}
			})
		} else {
			addNewDocToFolder(setItem, newDocuments, folder)
			newDocuments.map(async (doc) => {
				await linkDocToFolder(item.id, folder, doc._id)
			})
		}
		return false
	}

	if(create === false){
		await uploadDocument(newDocuments.map(doc => doc.fileData), convert)
		.then(async res => {
			if(folder === '/'){
				addNewDocToTable(setItem, res.data.data)
				res.data.data.map(async (doc) => {
					if (window.location.pathname.includes('/companies')){
						await linkDocToCompany(item.id, doc._id)
					} else if (window.location.pathname.includes('/projects')){
						await linkDocToProject(item.id, doc._id)
					}
				})
			} else {
				addNewDocToFolder(setItem, res.data.data, folder)
				res.data.data.map(async (doc) => {
					await linkDocToFolder(item.id, folder, doc._id)
				})
			}
		})
	}
	if(create !== false){
		if(isDocument){
			return await createDocument( selectNewFile ? null : newDocuments[0]._id, selectNewFile ? "New File" :newDocuments[0].name, newDocuments[0].picture ? newDocuments[0].picture : newDocuments[0]?.file_url, isDocument, item.id)
			.then(res => {
				if(folder === '/'){
					addNewDocToTable(setItem, [res.data.data], true)
					if(isDocument === 'projects'){
						linkDocToProject(item.id, res.data.data._id, res.data.data.name )
					} else if (isDocument === 'companies'){
						linkDocToCompany(item.id, res.data.data._id, res.data.data.name )
					}
					return res
				} else {
					addNewDocToFolder(setItem.id, [res.data.data], folder)
					linkDocToFolder(item.id, folder, res.data.data._id)
					return res
				}
			})
			.catch(() => {
				return {
					error: true,
					text: 'The template has the status - draft'
				}
			})
			// return new Error(resultCreate)
		} else {
			item.documents.map(doc => {
				if(doc.name.includes('Untitled')){
					untitledCount++
				}
			})
			await createTemplate(create,  untitledCount === 0 ? 'Untitled' : 'Untitled (' + untitledCount + ')').
			then(res => {
				addNewDocToTable(setItem, [res.data.data], true)
			})
		}

	}
}

export function defaultDrag(setList, list) {
	setList(list.map(item => {
		return {
			...item, 
			documents: item.documents.map(doc => {
				return {
					...doc, 
					onDrag: false
				}
			})
		}
	}))
}

export function onMoveDoc (setItem, id, type, path = undefined){
	setItem(item => {
		return {
			...item,
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							potentialMove: true,
							isOpenOptions: false
						}
					} 
					else {
						return {
							...itemList, 
							potentialMove: false
						}
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										potentialMove: true,
										isOpenOptions: false
									}
								}
								return {
									...doc,
									potentialMove: false
								}
							})
						}
					}
					return itemList
				}
			}),
		}
	})
}

export function onMove (setItem, id){
	setItem(item => {
		return {
			...item,
			folders: item.folders.map(itemList => {
				if(id === itemList.id){
					return {
						...itemList, 
						onMove: true
					}
				} 
				else {
					return {
						...itemList, 
						onMove: false
					}
				}
			})
		}
	})
}

export function setDrag (setList, list, id) {
	setList(list.map(item => {
		if(item.active){
			return {
				...item,
				documents: item.documents.map((doc, index) => {
					if(id !== index){
						return {
							...doc, 
							onDrag: true
						}
					} 
					else {
						return {
							...doc, 
							onDrag: false
						}
					}
				})
			}
		}
		return item
	}))
}

export function addToFavorites (setItem, id, item, setPush, setPushText, isMobile = false, path){
	let tempId = Date()
	setItem(listItem => {
		return {
			...listItem, 
			[path === '/' || path === undefined ? 'documents' : 'folders']: (path === '/' || path === undefined ? listItem.documents : listItem.folders).map((itemList, index) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						if(!itemList.favorite === true){
							setPushText('ADDED TO FAVOURITES')
							setPush(true)
							setTimeout(() => {
								setPush(false)
							}, 1200)
						}
						updateDocumentFavourite(id, !itemList.favorite)
						return {
							...itemList,
							favorite: !itemList.favorite,
							favoriteId: !itemList.favorite === true ? tempId : '',
							isOpenType: false,
							isOpenStatusList: false
						}
					}
					return itemList
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									updateDocumentFavourite(id, !doc.favorite)
									return {
										...doc,
										favorite: !doc.favorite,
										favoriteId: !doc.favorite === true ? tempId : '',
										isOpenType: false,
										isOpenStatusList: false
									}
								}
								return doc
							})
						}
					}
					return itemList
				}
			}),
			favDoc: item.favorite ? listItem.favDoc.filter(favDocItem => !(favDocItem.id === id)) : listItem.favDoc.concat({...item, favoriteId:  tempId, favorite: true, isOpenType: false, isOpenStatusList: false, isOpenOptions: isMobile ? false : item.isOpenOptions})
		}
	})
}

export function selectDoc (setItem, id, type, path = undefined) {
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							isSelect: !itemList.isSelect,
							isOpenOptions: false,
							isOpenSelect: false,
							isOpenStatusList:false
						}
					}
					return {
						...itemList,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenStatusList:false
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										isSelect: !doc.isSelect,
										isOpenOptions: false,
										isOpenSelect: false,
										isOpenStatusList:false
									}
								}
								return {
									...doc,
									isOpenOptions: false,
									isOpenSelect: false,
									isOpenStatusList:false
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function selectAll (setItem, doit) {
	setItem(item => {
		return {
			...item,
			documents: item.documents.map((doc) => {
				return {
					...doc, 
					isSelect: doit,
					isOpenOptions: false,
					isOpenSelect: false,
					isOpenStatusList:false
				}
			}),
			folders : item.folders.map((folder) => {
				return {
					...folder, 
					isSelect: doit,
					isOpenOptions: false,
					isOpenSelect: false,
					isOpenStatusList:false
				}
			})
		}
	})
}

export function linkDoc(setItem, item, id, linkedId, type, path,  setPush, setPushText, setLinkedDocs) {
	setItem(item => {
		return {
			...item,
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							linkedList: itemList.linkedList.concat(linkedId),
							isRename: false,
							isOpenStatusList: false,
							isOpenOptions: false,
							isOpenSelect: false,
							isOpenType: false,
						}
					} else if (itemList.id === linkedId){
						return {
							...itemList, 
							isLinked: id
						}
					}
					return {
						...itemList,
						isRename: false,
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenType: false
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										linkedList: doc.linkedList.concat(linkedId),
										isOpenStatusList: false,
										isOpenOptions: false,
										isOpenSelect: false,
										isOpenType: false
									}
								} else if (doc.id === linkedId){
									return {
										...doc, 
										isLinked: id
									}
								}
								return {
									...doc,
									isRename: false,
									isOpenStatusList: false,
									isOpenOptions: false,
									isOpenSelect: false,
									isOpenType: false
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
	setLinkedDocs(item.documents.filter(item => item.isLinked))
	setPushText('FILE LINKED')
	setPush(true)
	setTimeout(() => {
		setPush(false)
	}, 1200)
}

export function unlinkDoc (setItem, id, idLinked){
	setItem(item => {
		return {
			...item,
			documents: item.documents.map((doc, index) => {
				if(idLinked === doc.id){
					return {
						...doc, 
						linked_documents: doc.linked_documents.filter(item => !(item === id)),
						linkedList: doc.linkedList.filter(item => !(item === id))
					}
				}
				return doc
			}).map((doc, index) => {
				if(id === doc.id){
					return {
						...doc,
						isLinked: null
					}
				}
				return doc
			})
		}
	})
}

export function closePopups(setList, list){
	setList(list.map(item => {
		if(item.active){
			return {
				...item, 
				documents: item.documents.map((doc, index) => {
					return {
						...doc, 
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false
						
					}
				}),
				favDoc: item.favDoc.map((doc, index) => {
					return {
						...doc, 
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false
						
					}
				}),
				delDoc: item.delDoc.map((doc, index) => {
					return {
						...doc, 
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false
						
					}
				})
			}
		}
		return item
	}))
}

export function changeStatus (setItem, id, type, isMobile, path, ref) {
	let isRevert = false;
	if(!isMobile){
		isRevert = (ref.querySelector('.status-select-list').getBoundingClientRect().top + ref.querySelector('.options-list').offsetHeight + 20) > window.document.documentElement.clientHeight
	}
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							isOpenStatusList: !itemList.isOpenStatusList,
							isOpenOptions: isMobile,
							isOpenSelect: false,
							isOpenType: false,
							isRevertMenu: isRevert,
						}
					}
					return {
						...itemList,
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenType: false
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										isOpenStatusList: !doc.isOpenStatusList,
										isOpenOptions: isMobile,
										isOpenSelect: false,
										isOpenType: false,
										isRevertMenu: isRevert,
									}
								}
								return {
									...doc,
									isOpenStatusList: false,
									isOpenOptions: false,
									isOpenSelect: false,
									isOpenType: false
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function setIsRename (setItem, id, type, flag = undefined, path) {
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							isRename: flag !== undefined ? flag : !itemList.isRename,
							isOpenStatusList: false,
							isOpenOptions: false,
							isOpenSelect: false,
							isOpenType: false
						}
					}
					return {
						...itemList,
						isRename: false,
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenType: false
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										isRename: flag !== undefined ? flag : !doc.isRename,
										isOpenStatusList: false,
										isOpenOptions: false,
										isOpenSelect: false,
										isOpenType: false
									}
								}
								return {
									...doc,
									isRename: false,
									isOpenStatusList: false,
									isOpenOptions: false,
									isOpenSelect: false,
									isOpenType: false
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function renameDoc(setItem, id, type, name, path){
	setItem(item => {
		return {
			...item,
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList,
							name: name,
							isRename: false
							
						}
					} else {
						return itemList
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										name: name,
										isRename: false
									}
								}
								return doc
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function openCalendar (setItem, id, type, ref, isMobile) {
	let isRevert = false;
	if(!isMobile){
		isRevert = (ref.querySelector('.calendar').getBoundingClientRect().top + ref.querySelector('.calendar').offsetHeight + 100) > window.document.documentElement.clientHeight
	}
	setItem(item => {
		return {
			...item, 
			[type]: item[type].map((doc, index) => {
				if(id === index){
					return {
						...doc, 
						isOpenCalendar: !doc.isOpenCalendar,
						isRevertOpenCalendar: isRevert, 
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenType: false
						
					}
				} else {
					return {
						...doc, 
						isOpenCalendar: false,
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenType: false
					}
				}
			})
		}
	})
}

export function changeExpiry (setItem, id, newExpiry, type) {
	const expiryTimestamp = new Date(newExpiry).getTime()
	setItem(item => {
		return {
			...item,
			[type]: item[type].map((doc, index) => {
				if(index === id){
					if(newExpiry){
						updateDocumentDetails(doc.id, doc.details.map(detail => detail.field.name === 'Expiration Date' ? {...detail, id: detail.field._id, value: new Date(newExpiry).getTime()} : {...detail, id: detail.field._id}))
					}
					return {
						...doc,
						expiry: newExpiry ? expiryTimestamp : doc.expiry,
						isOpenCalendar: false,
					}
				}
				return doc
			})
		}
	})
}

export function changeType (setItem, id, type, isMobile, path, ref) {
	let isRevert = false;
	if(!isMobile){
		isRevert = (ref.querySelector('.type-select-list').getBoundingClientRect().top + ref.querySelector('.options-list').offsetHeight + 50) > window.document.documentElement.clientHeight
	}
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							isOpenType: !itemList.isOpenType,
							isOpenStatusList: false,
							isOpenOptions: isMobile,
							isOpenSelect: false,
							isRevertMenu: isRevert,
						}
					}
					return {
						...itemList,
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenType: false
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										isOpenType: !doc.isOpenType,
										isOpenStatusList: false,
										isOpenOptions: isMobile,
										isOpenSelect: false,
										isRevertMenu: isRevert,
									}
								}
								return {
									...doc,
									isOpenStatusList: false,
									isOpenOptions: false,
									isOpenSelect: false,
									isOpenType: false
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function changeDocStatus (setItem, id, newStatus, type, path) {
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							status: newStatus,
							isOpenStatusList: false,
						}
					}
					return itemList
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								return {
									...doc, 
									status: newStatus,
									isOpenStatusList: false,
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function changeDocType (setItem, id, newType, type, path) {
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							type: newType,
							isOpenType: false,
						}
					}
					return itemList
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								return {
									...doc, 
									type: newType,
									isOpenType: false,
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
}


export function openOptions(setItem, id, type, ref, isMobile = false, path, war = null) {
	let isRevert = false;
	if(!isMobile){
		isRevert = (ref.querySelector('.options-list').getBoundingClientRect().top + ref.querySelector('.options-list').offsetHeight + 100) > window.document.documentElement.clientHeight
	}
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? type : 'folders']: (path === '/' || path === undefined ? item[type] : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							isOpenOptions: war !== null ? war : !itemList.isOpenOptions,
							isOpenSelect: false,
							isOpenStatusList:false,
							isRevertMenu: isRevert,
							isSelect : isMobile ? true : itemList.isSelect
							// isSelect: true
						}
					}
					return {
						...itemList,
						isOpenStatusList: false,
						isOpenOptions: false,
						isOpenSelect: false,
						isOpenType: false,
					}
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										isOpenOptions: war !== null ? war : !doc.isOpenOptions,
										isOpenSelect: false,
										isOpenStatusList:false,
										isRevertMenu: isRevert,
										isOpenType: false,
										isSelect : isMobile ? true : doc.isSelect
									}
								}
								return {
									...doc,
									isOpenStatusList: false,
									isOpenOptions: false,
									isOpenSelect: false,
									isOpenType: false
								}
							})
						}
					}
					return itemList
				}
			})
		}
	})
	if(!isMobile &&  (ref.querySelector('.options-list').getBoundingClientRect().top + ref.querySelector('.options-list').offsetHeight + 20) >  window.document.documentElement.clientHeight ){
		return true
	}
	return false
}

export function toDeleteDoc (list, id, setFileToDelete, setDeleteFile) {
	list.map(item => {
		if(item.active){
			item.documents.map((doc, index) => {
				if(id === doc.id){
					setFileToDelete({...doc})
				}
				return false
			})
		}
		return false
	})
	setDeleteFile(true)
}

export function deleteDoc (setItem, id, item, setDeleteFile, setPush, setPushText) {
	setItem(itemList => {
		return {
			...itemList,
			documents: itemList.documents.filter(doc => {
				if(id === doc.id){
					return false
				} 
				return doc
			}),
			favDoc: itemList.favDoc?.filter(doc => {
				if(id === doc.id){
					return false
				} 
				return doc
			}),
			delDoc: itemList.delDoc?.concat({...item, isDelete: true, isOpenOptions: false})
		}
	})
	setDeleteFile(false)
	setPushText('FILE DELETED')
	setPush(true)
	setTimeout(() => {
		setPush(false)
	}, 1200)
}


export function changeCollabTypeDocument (setItem, id, userId, newType, path) {
	setItem(item => {
		return {
			...item, 
			[path === '/' || path === undefined ? 'documents' : 'folders']: (path === '/' || path === undefined ? item.documents : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							users : itemList.users.map((collab) => {
								if(userId === collab.user_id){
									linkUserToDoc(id, collab.user_id._id, newType)
									return {
										...collab,
										permission: newType,
										isOpenSelect: false
									}
								}
								return collab
							})
						}
					}
					return itemList
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										users : doc.users.map((collab) => {
											if(userId === collab.user_id){
												linkUserToDoc(id, collab.user_id._id, newType)
												return {
													...collab,
													permission: newType,
													isOpenSelect: false
												}
											}
											return collab
										})
									}
								}
								return doc
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function changeCollabType (setItem, id, newType) {
	setItem(item => {
		return {
			...item,
			users: item.users.map(collab => {
				if(id === collab.id){
					linkUserToProject(item.id, collab.id, newType)
					return {
						...collab,
						permission: newType,
						isOpenSelect: false
					}
				}
				return collab
			})
		}
	})
}


export function removeCollab (setItem, id) {
	setItem(item => {
		unlinkUserToProject(item.id, id)
		return {
			...item,
			users: item.users.filter(collab => {
				if(collab.id === id){
					return false
				}
				return collab
			})
		}
	})
}


export function addCollaborator (setItem, newUser) {
	setItem(item => {
		linkUserToProject(item.id, newUser.id, 'viewer')
		return {
			...item ,
			users: item.users.concat(newUser)
		}
	})
}

export function addCollaboratorToDocument (setItem, user, id, path) {
	setItem(item => {
		return {
			...item,
			[path === '/' || path === undefined ? 'documents' : 'folders']: (path === '/' || path === undefined ? item.documents : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							users : itemList.users.concat(user)
						}
					}
					return itemList
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										users : doc.users.concat(user)
									}
								}
								return doc
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function removeCollaboratorFromDocument (setItem, id, collab, path) {
	setItem(item => {
		return {
			...item,
			[path === '/' || path === undefined ? 'documents' : 'folders']: (path === '/' || path === undefined ? item.documents : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							users : itemList.users.filter(user => {
								if(user.user_id === collab){
									return false
								} else {
									return true
								}
							})
						}
					}
					return itemList
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(id === doc.id){
									return {
										...doc, 
										users : doc.users.filter(user => {
											if(user.user_id === collab){
												return false
											} else {
												return true
											}
										})
									}
								}
								return doc
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function openCollabTypeSelect(setItem, id) {
	setItem(item => {
		return {
			...item,
			users: item.users.map(collab => {
				if(id === collab.id){
					return {
						...collab,
						isOpenSelect: !collab.isOpenSelect
					}
				}
				return {
					...collab,
					isOpenSelect: false
				}
			})
		}
	})
}

export function openCollabTypeSelectDocument(setItem, id, userId, path) {
	setItem(item => {
		return {
			...item,
			[path === '/' || path === undefined ? 'documents' : 'folders']: (path === '/' || path === undefined ? item.documents : item.folders).map((itemList) => {
				if(path === '/' || path === undefined){
					if(id === itemList.id){
						return {
							...itemList, 
							users : itemList.users.map(user => {
								if(user.user_id === userId){
									return {
										...user,
										isOpenSelect: !user.isOpenSelect
									}
								}
								return {
									...user,
									isOpenSelect: false
								}
							})
						}
					}
					return itemList
				} else {
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(doc.id === id){
									return {
										...doc,
										users : doc.users.map(user => {
											if(user.user_id === userId){
												return {
													...user,
													isOpenSelect: !user.isOpenSelect
												}
											}
											return {
												...user,
												isOpenSelect: false
											}
										})
									}
								}
								return doc
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function createFolder (setItem, newFolderName, setPush, setPushText, id, parent){
	setItem(item => {
		return {
			...item,
			folders: item.folders.concat({
				name: newFolderName.current.value,
				isSelect : false,
				onMove: false,
				isOpenOptions: false,
				isRename: false,
				documents : [],
				id: id,
				parent_id: parent
			})
		}
	})
	setPushText('NEW FOLDER CREATED')
	setPush(true)
	setTimeout(() => {
		setPush(false)
	}, 1200);
}

export function deletefolder (setItem, id){
	setItem(item => {
		return {
			...item,
			folders: item.folders.map(folder => {
				if( folder.id === id ){
					folder.deleted_at = 1
				}
				return {
					...folder,
					isOpenOptions: false
				}
			})
		}
	})
}

export function restoreFolder (setItem, id){
	setItem(item => {
		return {
			...item,
			folders: item.folders.map(folder => {
				if(folder.id === id) {
					folder.deleted_at = null;
				}
				return {
					...folder,
					isOpenOptions: false
				}
			})
		}
	})
}


export function selectFolder (setItem, id){
	setItem(item => {
		return {
			...item,
			folders: item.folders.map((folder) => {
				if(id === folder._id){
					return {
						...folder, 
						isSelect: !folder.isSelect
					}
				}
				return folder
			})
		}
	})
}

export function setIsRenameFolder (setItem, id, flag = undefined) {
	setItem(item => {
		return {
			...item,
			folders: item.folders.map((folder) => {
				if(id === folder._id){
					return {
						...folder,
						isRename: flag !== undefined ? flag : !folder.isRename,
						isOpenOptions: false
					}
				} else {
					return {
						...folder, 
						isRename: false,
						isOpenOptions: false,
					}
				}
			})
		}
	})
}


export function renameFolder(setItem, id, name){
	setItem(item => {
		return {
			...item,
			folders: item.folders.map((folder) => {
				if(id === folder._id){
					return {
						...folder,
						name: name,
						isRename: false
					}
				} else {
					return folder
				}
			})
		}
	})
}


export function openOptionsFolder (setItem, id, ref, isMobile = false) {
	let isRevert = false;
	if(!isMobile){
		isRevert = (ref.querySelector('.options-list').getBoundingClientRect().top + ref.querySelector('.options-list').offsetHeight + 20) > window.document.documentElement.clientHeight
	}
		setItem(item => {
			return {
				...item,
				folders: item.folders.map((folder) => {
					if(id === folder.id){
						return {
							...folder, 
							isOpenOptions: !folder.isOpenOptions,
							isRevertMenu: isRevert
						}
					} else {
						return {
							...folder, 
							isOpenOptions: false,
						}
					}
				})
			}
		})
	if(!isMobile &&  (ref.querySelector('.options-list').getBoundingClientRect().top + ref.querySelector('.options-list').offsetHeight + 20) >  window.document.documentElement.clientHeight ){
		return true
	}
	return false
}


export function moveToFolder(setItem, item, move, folderMove, setPush, setPushText, path, type){
	setItem(item => {
		return {
			...item,
			[type]: item[type].filter(item => !(item.id === move.id)),
			folders : item.folders.map((folder, index) => {
				if(type === 'documents'){
					if(folder.id === folderMove.id){
						setPushText(`FILE MOVED TO "${item.folders[index].name.toUpperCase()}"`)
						setPush(true)
						return {
							...folder, 
							documents: folder.documents.concat(move)
						}
					}
					if(folder.id === path){
						return {
							...folder, 
							documents: folder.documents.filter(doc => !(doc.id === move.id))
						}
					}
				} else if (type === 'folders'){
					if(folder.id === move.id){
						return {
							...folder,
							parent_id: folderMove.id === '/' ? undefined : folderMove.id
						}
					}
				}
				return folder
			}),
			documents : folderMove.id === '/' && type === 'documents' ? item.documents.concat(move) : item.documents.filter(item => !(item.id === move.id))
		}
	})
	if(type === 'documents'){
		if(folderMove.id === '/'){
			unlinkDocFromFolder(item.id, path, move.id)
			linkDocToProject(item.id, move.id)
		} else {
			linkDocToFolder(item.id, folderMove.id, move.id)
			if(path === '/'){
				unlinkDocToProject(item.id, move.id)
			} else {
				unlinkDocFromFolder(item.id, path, move.id)
			}
		}
	} else if (type === 'folders'){
		if(folderMove.id === '/'){
			moveFolder(item.id, move.id, item.id)
		} else {
			moveFolder(item.id, move.id, folderMove.id)
		}
	}
	setTimeout(() => {
		setPush(false)
	}, 1200)
}

export function convertDoc(setItem, id, type, path){
	setItem(item => {
		return {
			...item,
			[type] : item[type].map(itemList => {
				if(type === 'documents'){
					if(itemList.id === id){
						return {
							...itemList,
							file: 'spe',
							isOpenOptions: false 
						}
					}
					return itemList
				} else if( type === 'folders'){
					if(itemList.id === path){
						return {
							...itemList,
							documents: itemList.documents.map(doc => {
								if(doc.id === id){
									return {
										...doc,
										file: 'spe',
										isOpenOptions: false 
									}
								}
								return doc
							})
						}
					}
					return itemList
				}
			})
		}
	})
}

export function restoreDocTable (setItem, document){
	setItem(item => {
		return {
			...item,
			delDoc: item.delDoc.filter(doc => !(document._id === doc._id)),
			documents: item.documents.concat({
				...document,
				isOpenOptions: false,
				isDelete: false,
			}),
			favDoc: document.favorite ? item.favDoc.concat({
				...document,
				isOpenOptions: false
			}) : item.favDoc
		}
	})
}

export function docSort(setItem, field, flag){
	if(flag === 'd'){
		setItem(item => {
			return {
				...item,
				documents: field === 'edited_by' ? item.documents.sort((a, b) => a[field]?.first_name?.trim().toLowerCase() > b[field]?.first_name?.trim().toLowerCase() ? 1 : -1) : item.documents.sort((a, b) => a[field]?.toString().trim().toLowerCase() > b[field]?.toString().trim().toLowerCase() ? 1 : -1)
			}
		})
	} else {
		setItem(item => {
			return {
				...item,
				documents: field === 'edited_by' ? item.documents.sort((a, b) => a[field]?.first_name?.trim().toLowerCase() < b[field]?.first_name?.trim().toLowerCase() ? 1 : -1) : item.documents.sort((a, b) => a[field]?.toString().trim().toLowerCase() < b[field]?.toString().trim().toLowerCase() ? 1 : -1)
			}
		})
	}
}