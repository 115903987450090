// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.push {
  position: absolute;
  background: #DCF4DF;
  border-radius: 3.125px;
  height: 29px;
  padding: 0px 8px;
  top: 0px;
  left: calc(50% - 74px);
  display: flex;
  align-items: center;
  color: #5DB559;
  letter-spacing: 1.125px;
  font-size: 0.45rem;
  line-height: 0.55rem;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition-duration: .2s; }
  .push .check {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #5DB559;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }
  .push-error {
    background-color: #fdd1d1;
    color: #ff7d7d; }
    .push-error .check {
      display: none; }
  .push-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px); }
`, "",{"version":3,"sources":["webpack://./src/components/Push/Push.scss"],"names":[],"mappings":"AAAA;EACC,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,QAAQ;EACR,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,uBAAuB;EACvB,kBAAkB;EAClB,oBAAoB;EACpB,uBAAuB;EACvB,UAAU;EACV,kBAAkB;EAClB,4BAA4B;EAC5B,wBAAwB,EAAA;EAlBzB;IAoBE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB,EAAA;EAElB;IACC,yBAAwB;IAIxB,cAAc,EAAA;IALd;MAGC,aAAa,EAAA;EAIf;IACC,UAAU;IACV,mBAAmB;IACnB,0BAA0B,EAAA","sourcesContent":[".push{\n\tposition: absolute;\n\tbackground: #DCF4DF;\n\tborder-radius: 3.125px;\n\theight: 29px;\n\tpadding: 0px 8px;\n\ttop: 0px;\n\tleft: calc(50% - 74px);\n\tdisplay: flex;\n\talign-items: center;\n\tcolor: #5DB559;\n\tletter-spacing: 1.125px;\n\tfont-size: 0.45rem;\n\tline-height: 0.55rem;\n\tjustify-content: center;\n\topacity: 0;\n\tvisibility: hidden;\n\ttransform: translateY(-10px);\n\ttransition-duration: .2s;\n\t.check{\n\t\twidth: 15px;\n\t\theight: 15px;\n\t\tborder-radius: 50%;\n\t\tbackground-color: #5DB559;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tmargin-right: 8px;\n\t}\n\t&-error{\n\t\tbackground-color:#fdd1d1;\n\t\t.check{\n\t\t\tdisplay: none;\n\t\t}\n\t\tcolor: #ff7d7d;\n\t}\n\t&-active{\n\t\topacity: 1;\n\t\tvisibility: visible;\n\t\ttransform: translateY(0px);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
