import React, {useContext, useEffect, useState} from 'react'
import Button from '../../Button/Button'
import { addDocuments } from '../../../utilites/table.js'
import {AppContext} from '../../../context/AppContext'
import {ReactComponent as Check} from '../../../assets/img/check.svg'
import {ReactComponent as Close} from '../../../assets/img/close.svg'
import './UploadFiles.scss'
import './UploadFilesMedia.scss'

const UploadFiles = ({onUploadFiles, setOnUploadFiles,setPushError, setPush, setPushText, setItem, item, setIsVisibleUploader, setIsConvert, setStartUploadList, actualFormats, folder}) => {
	let [toConvert, setToConvert] = useState(false)
	
	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang

	const isSpeTypes = (onUploadFiles) => {
		return onUploadFiles.every((file) => file.type !== 'xlsx' && file.type !== 'xls' && file.type !== 'docx' && file.type !== 'doc' && file.type !== 'pdf' && file.type !== 'spe');
	}

	useEffect(() => {
		if (onUploadFiles.length > 0 && isSpeTypes(onUploadFiles)) {
			let fileController = false

			setStartUploadList(onUploadFiles.map(item => {
				// if(!actualFormats.includes(item.fileType.toLowerCase())){
				// 	fileController = true
				// }
				return {
					...item,
					isUpload:true
				}
			}))
			if(fileController){
				setPushText('Error format')
				setPushError(true)
				setPush(true)
				setIsVisibleUploader(false)
				setTimeout(() => {
					setPush(false)
					setPushError(false)
				}, 1200);
			} else {
				setIsVisibleUploader(true)
				addDocuments(setItem, item, onUploadFiles, false, false, false, folder)
					.then(() => {
						setPushError(false)
						setPushText(lang.fileUploadComplete)
						setPush(true)
						setIsVisibleUploader(false)
						setTimeout(() => {
							setPush(false)
						}, 1200);
					})
			}
			setOnUploadFiles([])
			setIsConvert(false)
		}
	}, [onUploadFiles.length])

	return (
		<div className={"upload-files-popup-wrapper " + (activeLang === 'ar' ? 'upload-files-popup-wrapper-arabic ' : '') + (onUploadFiles.length > 0 && !isSpeTypes(onUploadFiles) ? 'upload-files-popup-wrapper-active' : '')} onClick={event => {
			if(event.target.classList.contains('upload-files-popup-wrapper-active')){
				setOnUploadFiles([])
			}
		}}>
			<div className="upload-files-popup">
				<div className="upload-popup-header">
					<div className="header-title">{lang.convertFiles}</div>
					<Close onClick={() => setOnUploadFiles([])}/>
				</div>
				<div className="upload-popup-body">
					<div className="upload-popup-body-title">{lang.doYouWantConvert}</div>
					<div className="upload-popup-body-files-list">
						{onUploadFiles.map((file, index) => {

							return (
								<div className="list-item" key={index}>
									<img src={require(`../../../assets/img/files/${file.type}.svg`)} alt="" className="list-item-image"/>
									<div className="list-item-name">{file.name}</div>
								</div>
							)
						})}
					</div>
					<div className="to-convert" onClick={() => setToConvert(prev => !prev)}>
						<div className={"checkbox " + (toConvert ? 'checkbox-active' : '')}>
							<Check/>
						</div>
						<div className="to-convert-title">{lang.dontAskConvert}</div>
					</div>
					<div className="upload-btns">
						<Button color={'white'} padding={17} margin={10} onClick={async () => {
							let fileController = false
							
							setStartUploadList(onUploadFiles.map(item => {
								// console.log(item, actualFormats)
								console.log(item)
								// if(!actualFormats.includes(item.fileType)){
								// 	fileController = true
								// }
								return {
									...item,
									isUpload:true
								}
							}))
							// console.log(onUploadFiles);
							if(fileController){
								setPushText('Error format')
								setPushError(true)
								setPush(true)
								setIsVisibleUploader(false)
								setTimeout(() => {
									setPush(false)
									setPushError(false)
								}, 1200);
							} else {
								console.log(onUploadFiles)
								setIsVisibleUploader(true)
								addDocuments(setItem, item, onUploadFiles, false, false, false, folder)
								.then(() => {
									setPushError(false)
									setPushText(lang.fileUploadComplete)
									setPush(true)
									setIsVisibleUploader(false)
									setTimeout(() => {
										setPush(false)
									}, 1200);
								})
							}
							setOnUploadFiles([])
							setIsConvert(false)
						}}>{lang.noUpload}</Button>
						<Button color={'blue'} padding={19} onClick={async () => {
							let fileController = false
							setStartUploadList(onUploadFiles.map(item => {
								// if(!actualFormats.includes(item.type)){
								// 	fileController = true
								// }
								return {
									...item,
									isUpload:true
								}
							}))
							if(fileController){
								setPushText('Error format')
								setPushError(true)
								setPush(true)
								setIsVisibleUploader(false)
								setTimeout(() => {
									setPush(false)
									setPushError(false)
								}, 1200);
							} else {
								setIsVisibleUploader(true)
								addDocuments(setItem, item, onUploadFiles, true, false, false, folder)
								.then(() => {
									setPushError(false)
									setPushText(lang.fileUploadComplete)
									setPush(true)
									setIsVisibleUploader(false)
									setTimeout(() => {
										setPush(false)
									}, 1200);
								})
							}
							setOnUploadFiles([])
							setIsConvert(true)
						}}>{lang.yesConvert}</Button>
					</div>
				</div>
			</div>
		</div>
	)	
}

export default UploadFiles