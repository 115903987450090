import React, {useState, useEffect, useContext} from 'react'

import {AppContext} from '../../../context/AppContext'

import NotificationItem from '../NotificationItem/NotificationItem'
import Loader from '../../Loader/Loader'

import {
	getNotifications
} from '../../../utilites/api'

import './Notifications.scss'
import './NotificationsMedia.scss'

function Notifications(){
	let [notifications, setNotifications] = useState([])
	const [isLoadComplete, setIsLoadComplete] = useState(true)

	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang

	useEffect(() => {
		getNotifications(20)
		.then(res => {
			setNotifications(res.data)
			setIsLoadComplete(false)
		})
	},[])
	return (
		<>
			<Loader isVisible={isLoadComplete}/>
			{isLoadComplete === false ? (
				<>
					<div className={"notifications-all-wrapper " + (activeLang === 'ar' ? 'notifications-all-wrapper-arabic' : '')}>
						<div className="notifications-all">
							<div className="notifications-all-header">
								{lang.allNotifications}
							</div>
							<div className="notifications-all-list">
									{notifications.map((item, index) => {
										return <NotificationItem notification={item} key={index}/>
									})}
							</div>
						</div>
					</div>
				</>
			) : ''}
		</>
	)
}

export default Notifications 