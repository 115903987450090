import React, { useState, useContext, useEffect } from 'react'

import { AppContext } from '../../../context/AppContext' 

import { addItemManage } from '../../../utilites/document'

import {ReactComponent as ChatPlus} from '../../../assets/img/chat-plus.svg'
import {ReactComponent as HelpPlus} from '../../../assets/img/help-plus.svg'


function SelectBtns({isMobile, isVisibleSelectBtns, setIsVisibleSelectBtns, mouse, setManageFullView, editor, setDocument, document, setActiveManage, selectTextInEditor, setIsVisibleLine, editors, setIsOpenQueriesMobile, setIsOpenCommentsMobile}){



	let [isActiveSelectMain, setIsActiveSelectMain] = useState(0)
	let [topScroll, setTopScroll] = useState(0)
	let [blockWidth, setBlockWidth] = useState(0)

	let {lang, activeLang, user} = useContext(AppContext)

	useEffect(() => {
		// console.dir(editor?.documentElement.scrollTop)
		if(user && user.external){
			setBlockWidth(140)
			window.document.getElementsByClassName("document-body-select-btns")[0].firstChild.style.marginRight = 0
		}else{
			setBlockWidth(310)
		}
		console.log(mouse.y + 170)
		setTopScroll(window.document.getElementsByClassName("editor-wrapper")[0].scrollTop)
		console.log((+editor?.documentElement.scrollTop))
	}, [isVisibleSelectBtns])

	return (
		<div className={"document-body-select-btns " + (isVisibleSelectBtns ? 'document-body-select-btns-active' : '')} style={isMobile ? {top: mouse.y, left: `calc(50vw - ${window.document.querySelector('.document-body-select-btns') ? 
																																			window.document.querySelector('.document-body-select-btns').offsetWidth / 2 : 15}px)`} :
																																			{top: mouse.y + 170 - topScroll, left: mouse.x + 140, width:blockWidth}}>
			{isMobile ? (
				<>
					{isActiveSelectMain === 0 ? (
						<>
							<span onClick={() => {
								editor.execCommand("cut")
							}}>Cut</span>
							<span onClick={() => {
								editor.execCommand("copy");
							}}>Copy</span>
							<span onClick={() => {
								navigator.clipboard.readText().then(function(text){
									editor.execCommand( "insertHTML", false, text || "");
								});
							}}>Paste</span>
							<span onClick={() => {
								editors.selection.select(editors.getBody());
							}}>Select all</span>
							<svg width="25" height="25" viewBox="0 0 16 16" className="select-options" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setIsActiveSelectMain(1)}>
								<path fillRule="evenodd" clipRule="evenodd" d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z" fill={"#8798ad"}/>
							</svg>
						</>
					) : (
						<>
							<svg width="25" height="19" className="back" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setIsActiveSelectMain(0)}>
								<path d="M2.45446 9.64586L24.0908 9.64586" stroke="#2E384D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M11.3408 18.0908L1.29537 9.59082L11.3408 1.09082" stroke="#2E384D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<button onClick={() => {
								setIsVisibleSelectBtns(false)
								setActiveManage(0)
								setManageFullView(true)
								addItemManage(editor, selectTextInEditor, 'query', setDocument, user)
								setIsVisibleLine(false)
								setIsOpenQueriesMobile(true)
							}}>
							<HelpPlus/>
							<span>{lang.newQuery}</span>
							</button>
							{
								user?.external ? ' ' : (
									<button onClick={() => {
										setIsVisibleSelectBtns(false)
										addItemManage(editor, selectTextInEditor, 'comment', setDocument, user)
										setIsVisibleLine(false)
										setActiveManage(1)
										setManageFullView(true)
										setIsOpenCommentsMobile(true)
									}}>
										<ChatPlus/>
										<span>{lang.newComment}</span>
									</button>
								)
							}

						</>
					)}
				</>
			) : (
				<>
					<button onClick={() => {
						const selectedString = editors.selection.getContent({format: 'html'});
						const editorDom = window.document.querySelector('iframe').contentWindow.document;
						if (!selectedString.includes('data-mce-annotation-uid')) {
							editors.focus();
							setIsVisibleSelectBtns(false)
							addItemManage(editorDom, selectedString, 'query', setDocument, user)
							setIsVisibleLine(false)
							setActiveManage(0)
							setManageFullView(true)
							setTimeout(() => {
								window.document.getElementById("scrollItem").scrollIntoView()
								let activeInput = window.document.getElementsByClassName("new-comment-input")[window.document.getElementsByClassName("new-comment-input").length - 1]
								activeInput.focus()
								activeInput.select()
							}, 300)
						}
					}}>
						<HelpPlus/>
						<span>{lang.newQuery}</span>
					</button>
					{
						user?.external ? ' ' : (
							<button onClick={() => {
								const selectedString = editors.selection.getContent({format: 'html'});
								const editorDom = window.document.querySelector('iframe').contentWindow.document;
								if (!selectedString.includes('data-mce-annotation-uid')) {
									setIsVisibleSelectBtns(false)
									addItemManage(editorDom, selectedString, 'comment', setDocument, user)
									setIsVisibleLine(false)
									setActiveManage(1)
									setManageFullView(true)
									setTimeout(() => {
										window.document.getElementById("scrollItem").scrollIntoView()
										let activeInput = window.document.getElementsByClassName("new-comment-input")[window.document.getElementsByClassName("new-comment-input").length - 1]
										activeInput.focus()
										activeInput.select()
									}, 300)
								}
								}}>
								<ChatPlus/>
								<span>{lang.newComment}</span>
							</button>
						)
					}

				</>
			)}
		</div>
	)
}

export default SelectBtns