import React, { useState, useEffect } from 'react'


import {useParams, useHistory} from 'react-router-dom'

import Button from '../Button/Button'
import Push from '../Push/Push'

import {
	resetPassword,
	getResetToken
} from '../../utilites/api'

import './Reset.scss'

function Reset (){

	const [password, setPassword] = useState('')
	const [repeatPassword, setRepeatPassword] = useState('')
	const [push, setPush] = useState(false)
	const [pushText, setPushText] = useState('')

	const {token} = useParams()
	const history = useHistory()

	// const [userToken, setUserToken] = useState('')

	// useEffect(() => {
	// 	getResetToken()
	// 	.then(res => {
	// 		console.log(res)
	// 	})
	// }, [])
	return (
		<div className="reset">
			<div className="reset-body" onKeyDown={event => {
				if(event.keyCode === 13){
					if(password.length >= 8 && password === repeatPassword && /[~`\(\)_@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password)){
						resetPassword(password, token)
						.then(() => {
							history.push('/login')
						})
					} else if (password.length < 8){
						setPushText('The password is short')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
					} else if (/[~`\(\)_@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password) === false) {
						setPushText('Add a special character')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
					} else if (password !== repeatPassword){
						setPushText('Passwords don\'t match')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
					}
				}
			}}>
				{push ? <Push top={0} padding={8} isError={true}>{pushText}</Push> : ''}
				<div className="reset-body-header">Change password</div>
				<div className="input-wrapper">
					<div className="input-wrapper-header">
						<span className="input-title">{'New password'}</span>
					</div>
					<input type="password" className="app-input" autoComplete="off"  value={password} onChange={event => {
						setPassword(event.target.value)
					}} 
					tabIndex={1}
					/>
				</div>
				<div className="input-wrapper">
					<div className="input-wrapper-header">
						<span className="input-title">{'Repeat new password'}</span>
					</div>
					<input type="password" className="app-input" autoComplete="off"  value={repeatPassword} onChange={event => {
						setRepeatPassword(event.target.value)
					}} 
					tabIndex={2}
					/>
				</div>
				<Button color={'blue'} onClick={() => {
					if(password.length >= 8 && password === repeatPassword && /[~`\(\)_@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password)){
						resetPassword(password, token)
						.then(() => {
							history.push('/login')
						})
					} else if (password.length < 8){
						setPushText('The password is short')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
					} else if (/[~`\(\)_@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password) === false) {
						setPushText('Add a special character')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
					} else if (password !== repeatPassword){
						setPushText('Passwords don\'t match')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
					}
				}}>Confirm</Button>
			</div>
		</div>
	)
}

export default Reset