// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-mobile-btn {
  position: fixed;
  right: 15px;
  bottom: 75px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #2E5BFF;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.249344);
  z-index: 1; }
  .nav-mobile-btn svg path {
    fill: #fff; }
  .nav-mobile-btn-arabic {
    right: unset;
    left: 15px; }
`, "",{"version":3,"sources":["webpack://./src/components/PlusNav/PlusNav.scss"],"names":[],"mappings":"AAAA;EACC,eAAe;EACf,WAAW;EACX,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,+CAA+C;EAC/C,UAAU,EAAA;EAZX;IAeG,UAAU,EAAA;EAGZ;IACC,YAAY;IACZ,UAAU,EAAA","sourcesContent":[".nav-mobile-btn{\n\tposition: fixed;\n\tright: 15px;\n\tbottom: 75px;\n\twidth: 45px;\n\theight: 45px;\n\tborder-radius: 50%;\n\tbackground: #2E5BFF;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tbox-shadow: 0px 4px 5px rgba(0, 0, 0, 0.249344);\n\tz-index: 1;\n\tsvg{\n\t\tpath{\n\t\t\tfill: #fff;\n\t\t}\n\t}\n\t&-arabic{\n\t\tright: unset;\n\t\tleft: 15px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
