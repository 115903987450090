import React, {useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'

import {AppContext} from '../../../context/AppContext'

import {
	selectItemList
} from '../../../utilites/appfunctions'


import {
	getFolders
} from '../../../utilites/api'

import {ReactComponent as Plus} from '../../../assets/img/plus.svg'
import {ReactComponent as Burger} from '../../../assets/img/menu.svg'

import './PageNav.scss'
import './PageNavMedia.scss'


function PageNav({list, activeItem, listIcon, setIsEdit, page, createListItem, foldersHandler = () => false}){


	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang
	let [archiveTypesCollapsed, setArchiveTypesCollapsed] = useState(false);
	let isMobile = useContext(AppContext).isMobile
	let isActivePageNavMobile = useContext(AppContext).isActivePageNavMobile
	let setIsActivePageNavMobile = useContext(AppContext).setIsActivePageNavMobile
	let isActiveMobileSearch = useContext(AppContext).isActiveMobileSearch
	const [isTemplate, setIsTemplate] = useState(false);
	const user = useContext(AppContext).user

	const history = useHistory()

	const clickHandler = event => {
		if(!event.target.isEqualNode(document.querySelector('.page-nav-mobile-active')) && !event.target.closest('.page-nav-mobile-active')){
			setIsActivePageNavMobile(false)
		}
	}

	useEffect(() => {
		if(window.location.pathname.includes('/templates')){
			setIsTemplate(true)
		}else{
			setIsTemplate(false)
		}
		document.addEventListener('click', clickHandler)
		return () => document.removeEventListener('click', clickHandler)
	}, [])

	return (
		<nav className={"page-nav " + (isActivePageNavMobile && isMobile ? 'page-nav-mobile-active ' : '') + (activeLang === 'ar' ? 'page-nav-arabic' : '')} style={isActiveMobileSearch ? {opacity: 0} : {}}>
			{isMobile && !isActiveMobileSearch && (window.location.pathname.includes('/projects') || window.location.pathname.includes('/companies') || window.location.pathname.includes('/templates')) ? (
				<div className="page-nav-mobile-header">
					<Burger onClick={() => setIsActivePageNavMobile(prev => !prev)} className={isActivePageNavMobile ? 'burger-active' : ''}/>
				</div>
			) : ''}
			{!window.location.pathname.includes('/templates') ? (
				<>
					<div className="page-nav-header">
						{activeLang === 'ar' ? (
							<span> ({list.filter(item => item.favourite ? item : false).length}) {lang.favorites}</span>
						) : (
							<span>{lang.favorites} ({window.location.pathname.includes('/companies') ? user.favourites.companies.length : window.location.pathname.includes('/projects') ? user.favourites.projects.length : ''})</span>
						)}
					</div>
					<div className="page-list">
						{list.map((item, index) => {
							if((window.location.pathname.includes('/companies') && user.favourites.companies.includes(item._id)) || (window.location.pathname.includes('/projects') && user.favourites.projects.includes(item._id))){
								return (
									<div className={"page-list-item " + (item._id === activeItem ? 'page-list-item-active' : '')} 
										key={index} 
										onClick={() => {
											history.push(`/${page.toLowerCase()}/${item._id}`)
											// selectItemList(setList, list, item.id)
											// foldersHandler({path: '/', prevPath: '/'})
											// window.location.search = `id=${item.id}`
											if(isMobile){
												setIsActivePageNavMobile(false)
											}
											// window.history.pushState("object or string", "Title", window.location.pathname + `?id=${item.id}`)
											// if(window.location.pathname.includes('/companies')){
											// 	setIsEdit(false)
											// }

										}}
									>
										{listIcon}
										<span className="page-name">{item.name}</span>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow">
											<path fillRule="evenodd" clipRule="evenodd" d="M9.35166 4.72515L16.0848 11.4576C16.3843 11.7574 16.3843 12.2428 16.0848 12.5423L9.35166 19.2754C9.0521 19.5749 8.56666 19.5749 8.26711 19.2754L7.72481 18.7331C7.42506 18.4337 7.42506 17.9478 7.72481 17.6482L13.3733 12L7.72481 6.35161C7.42506 6.05225 7.42506 5.56657 7.72481 5.26707L8.26711 4.72476C8.56701 4.42501 9.0521 4.42501 9.35166 4.72515Z" />
										</svg>
									</div>
								)
							} else {
								return false
							}
						})}
					</div>
				</>
			) : ''}
			<div className="page-nav-header" onClick={()=> {

			}}>
				{activeLang === 'ar' ? (
					<span>({list.filter(item => !item.deleted_at && !item.archived).length}) {page}</span>
				) : (
					<span>{page} ({list.filter(item => !item.deleted_at && !item.archived).length})</span>
				)}
				<Plus onClick={() => createListItem()}/>
			</div>
			<div className="page-list">
				{list.filter(item => !item.deleted_at && !item.archived).map((item, index) => {
					return (
						<div 
							className={"page-list-item " + (item._id === activeItem || item.id === activeItem ? 'page-list-item-active' : '')}
							key={index} 
							onClick={() => {
								history.push(`/${page.toLowerCase()}/${!window.location.pathname.includes('/templates') ? item._id : item.id}`)
								// selectItemList(setList, list, item.id)
								// foldersHandler({path: '/', prevPath: '/'})
								if (isMobile) {
									setIsActivePageNavMobile(false)
								}
								// window.history.pushState("object or string", "Title", window.location.pathname + `?id=${item.id}`);
								// if (window.location.pathname.includes('/companies')) {
								// 	setIsEdit(false)
								// }
							}}
						>
							{listIcon}
							<span className="page-name">{item.name}</span>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
								 xmlns="http://www.w3.org/2000/svg" className="arrow">
								<path fillRule="evenodd" clipRule="evenodd"
									  d="M9.35166 4.72515L16.0848 11.4576C16.3843 11.7574 16.3843 12.2428 16.0848 12.5423L9.35166 19.2754C9.0521 19.5749 8.56666 19.5749 8.26711 19.2754L7.72481 18.7331C7.42506 18.4337 7.42506 17.9478 7.72481 17.6482L13.3733 12L7.72481 6.35161C7.42506 6.05225 7.42506 5.56657 7.72481 5.26707L8.26711 4.72476C8.56701 4.42501 9.0521 4.42501 9.35166 4.72515Z"/>
							</svg>
						</div>
					)
				})}
			</div>
			{list.filter(item => isTemplate ? item.archived : item.deleted_at).length > 0 ? (
				<>
					<div className={`page-nav-header archived ${archiveTypesCollapsed ? "collapsed" : ""}`}  onClick={()=> {
						setArchiveTypesCollapsed(prevState => !prevState);
					}}>
						{activeLang === 'ar' ? (
							<span> ({list.filter(item => !!item.archived).length}) {lang.archived}</span>
						) : (
							<span>{lang.archived} ({list.filter(item => isTemplate ? item.archived : item.deleted_at).length})</span>
						)}
					</div>
					{!archiveTypesCollapsed ?
						<div className="page-list">
							{list.map((item, index) => {
								if (isTemplate ? item.archived : item.deleted_at) {
									return (
										<div 
											className={"page-list-item " + (item._id === activeItem ? 'page-list-item-active' : '')}
											key={index} 
											onClick={() => {
												history.push(`/${page.toLowerCase()}/${item._id}`)
												// selectItemList(setList, list, item.id)
												// foldersHandler({path: '/', prevPath: '/'})
												if (isMobile) {
													setIsActivePageNavMobile(false)
												}
												// window.history.pushState("object or string", "Title", window.location.pathname + `?id=${item.id}`);
												// if (window.location.pathname.includes('/companies')) {
												// 	setIsEdit(false)
												// }
											}}
										>
											{listIcon}
											<span className="page-name">{item.name}</span>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
												xmlns="http://www.w3.org/2000/svg" className="arrow">
												<path fillRule="evenodd" clipRule="evenodd"
													d="M9.35166 4.72515L16.0848 11.4576C16.3843 11.7574 16.3843 12.2428 16.0848 12.5423L9.35166 19.2754C9.0521 19.5749 8.56666 19.5749 8.26711 19.2754L7.72481 18.7331C7.42506 18.4337 7.42506 17.9478 7.72481 17.6482L13.3733 12L7.72481 6.35161C7.42506 6.05225 7.42506 5.56657 7.72481 5.26707L8.26711 4.72476C8.56701 4.42501 9.0521 4.42501 9.35166 4.72515Z"/>
											</svg>
										</div>
									)
								} 
								return false
							})}
						</div>
					: ''}
				</>
				) : ''}
		</nav>
	)
}

export default PageNav