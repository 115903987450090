import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'

import Button from '../Button/Button'
import Push from '../Push/Push'

import {
	getUsers,
	deleteUser,
	createUser,
	getSettings,
	updateSettings,
	getSettingsDetails,
	createSettingsDetailsField,
	updateSettingsDetailsField,
	updateUser
} from '../../utilites/api'

import {
	changeEditItemDetails,
	deleteItemDetails,
	changeItemDetails,
	addNewField
} from '../../utilites/settings'

import {
	convertDetails
} from '../../utilites/essence'

import useSelect from '../../hooks/useSelect'

import {timezonesData} from '../../utilites/timezones'

import searchIcon from '../../assets/img/search.svg'
import {ReactComponent as Arrow} from '../../assets/img/arrow.svg'
import {ReactComponent as NonAvatar} from '../../assets/img/nonavatar.svg'
import {ReactComponent as Close} from '../../assets/img/close.svg'
import {ReactComponent as Check} from '../../assets/img/check.svg'
import {ReactComponent as Delete} from '../../assets/img/delete.svg'
import {ReactComponent as Wave} from '../../assets/img/wave.svg'
import {ReactComponent as Doc} from '../../assets/img/doc.svg'
import {ReactComponent as Plus} from '../../assets/img/plus.svg'
import {ReactComponent as Garb} from '../../assets/img/garb.svg'
import {ReactComponent as Pencel} from '../../assets/img/pencel.svg'
import {ReactComponent as EditUser} from '../../assets/img/edit-user.svg'
import eyeIcon from '../../assets/img/eye.svg'

import './Settings.scss'
import './SettingsMedia.scss'

function Settings(){
	let activeLang = useContext(AppContext).activeLang
	let lang = useContext(AppContext).lang

	let isMobile = useContext(AppContext).isMobile

	let [activeTab, setActiveTab] = useState(1)
	let [search, setSearch] = useState('')
	// let [filter, setFilter] = useState(null);
	let [users, setUsers] = useState([])
	let [settings, setSettings] = useState(null)
	let [details, setDetails] = useState(null)
	let [isLoadComplete, setIsLoadComplete] = useState(false)

	const [isOpenSelectEdit, setIsOpenSelectEdit] = useState(false)

	let [createUserPopup, setCreateUserPopup] = useState(false)
	let [editUserPopup, setEditUserPopup] = useState(false)
	let [inputs, setInputs] = useState({
		first_name: '',
		last_name : '',
		title: '',
		login: '',
		password: '',
		company: '',
		role: '',
		external: false,
	})
	let [isVisiblePassword, setIsVisiblePassword] = useState(false)
	let [isOpenWave, setIsOpenWave] = useState(false)
	const [push, setPush] = useState(false)
	const inputControl = input => {
		setInputs({
			...inputs,
			[input.name] : input.value
		})
	}

	const editControl = input => {
		setUserOnEdit({
			...userOnEdit,
			[input.name] : input.value
		})
	}

	const externalEditControl = (input) => {
		setInputs({
			...userOnEdit,
			[input.name] : input.checked
		})
	}

	let [timezones, setTimezones] = useState(timezonesData)
	let [isOpenTimeZones, setIsOpenTimeZones] = useState(false)
	let [isOpenTime, setIsOpenTime] = useState(false)


	let [timesList, setTimeList] = useState([
		{
			name: 1,
			active: true
		},
		{
			name: 5,
			active: false
		},
		{
			name: 10,
			active: false
		},
	])

	const collabTypesList = [
		'Admin(Super User)',
		'Template Collaborator',
		'Collaborator',
		'Contributor',
		'Admin'
	]

	const fieldType = useSelect('Free Text', ['Free Text', 'Date'])

	let [isOpenSelect, setIsOpenSelect] = useState(false)


	let [isOpenAddNewType, setIsOpenAddNewType] = useState(false)
	let [addNewType, setAddNewType] = useState('')
	let [addNewTypeFile, setAddNewTypeFile] = useState('')

	let [isOpenTypeToDelete, setIsOpenTypeToDelete] = useState(false)
	let [isOpenTypeUpload, setIsOpenTypeUpload] = useState(false)
	let [toDeleteFile, setToDeleteFile] = useState(null)

	let [isOpenAddDetails, setIsOpenAddDetails] = useState(false)
	let [addField, setAddField] = useState('')
	let [addFieldType, setAddFieldType] = useState(null)

	const [pushText, setPushText] = useState('All fields are required')
	const [role, setRole] = useState('')

	const [userOnEdit, setUserOnEdit] = useState({
		first_name: '',
		last_name : '',
		title: '',
		login: '',
		password: '',
		company: '',
		external: false,
	})

	const addToUpload = id => {
		setSettings({
			...settings,
			document_type_for_upload: settings.document_type_for_upload.map((item, index) => {
				if(id === index){
					return {
						...item,
						active: true
					}
				}
				return item
			})
		})
	}

	const removeFromUploads = id => {
		setSettings({
			...settings,
			document_type_for_upload: settings.document_type_for_upload.map((item, index) => {
				if(id === index){
					return {
						...item,
						active: false
					}
				}
				return item
			})
		})
	}

	const onSubmitCreateNewUser = async () => {
		return await createUser(inputs)
			.then(async (res) => {
				setUsers(convertUsers(await getUsers()))
				setCreateUserPopup(false)
			})
	}

	const onEnterPress = (e) => {
		if(e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			onSubmitCreateNewUser();
		}
	}

	const emailValidate = (email) => {
		if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
			return false
		}
		return true
	}


	const activeCheck = id => {
		setSettings({
			...settings,
			file_type_for_upload : settings.file_type_for_upload.map((type, index) => {
			if(id === index){
				return {
					...type,
					active: !type.active
				}
			}
			return type
		})})
	}

	const changeTimeZone = id => {
		setTimezones(timezones.map((time, index) => {
			if(index === id){
				return {
					...time,
					active: true,
				}
			} else {
				return {
					...time,
					active: false,
				}
			}
		}))
	}

	const changeTime = id => {
		setTimeList(timesList.map((time, index) => {
			if(id === index){
				setSettings(prev => ({...prev, min_waiting_before_disconnecting : time.name}))
				return {
					...time,
					active: true
				}
			} else {
				return {
					...time,
					active: false
				}
			}
		}))
	}

	const convertUsers = data => {
		return {
			...data,
			users: data.users.map(user => {
				return {
					...user,
					role: user.roles.pop().name.replace('-', ' ')
				}
			}).reverse()
		}
	}

	const [filterVar, setFilterVar] = useState({
		e: 'u',
		f: 'u',
		l: 'u',
		c: 'u',
		r: 'u'
	})

	const userFilter = (field, flag) => {
		if(flag === 'd'){
			setUsers(prev => {
				return {
					...prev, 
					users: prev.users.sort((a, b) => a[field].trim().toLowerCase() > b[field].trim().toLowerCase() ? 1 : -1)
				}
			})
		} else {
			setUsers(prev => {
				return {
					...prev, 
					users: prev.users.sort((a, b) => a[field].trim().toLowerCase() < b[field].trim().toLowerCase() ? 1 : -1)
				}
			})
		}
	}

	useEffect(() => {
		setRole('')
	}, [createUserPopup, editUserPopup])

	useEffect(() => {
		if(settings){
			updateSettings(settings)
		}
	}, [settings])

	useEffect(() => {
		async function getUsersWrapper(){
			Promise.all([getUsers(), getSettings(), getSettingsDetails()])
			.then(res => {
				console.log(res[1])
				setUsers(convertUsers(res[0]))
				setSettings(res[1])
				setIsLoadComplete(true)
				changeTimeZone(res[1].time_zone)
				setDetails(convertDetails(res[2]))
				// console.log(res[0].users)
			})
		}

		getUsersWrapper()
	}, [])

	return (
		<div className={"settings " + (activeLang === 'ar' ? 'settings-arabic' : '')}>
			<div className={"create-user-popup-wrapper " + (createUserPopup ? 'create-user-popup-wrapper-active' : '')} onClick={(event) => {
				if(event.target.classList.contains('create-user-popup-wrapper-active')){
					setCreateUserPopup(false)
				}
			}}>
				<div className="create-user-popup" onKeyDown={async event => {
					if(event.keyCode === 13){
						await createUser(inputs)
						.then(async (res) => {
							setUsers(convertUsers(await getUsers()))
							setCreateUserPopup(false)
						})
					}
				}}>
					<div className="create-user-popup-header">
						<div className="header-title">create new user</div>
						<Close onClick={() => setCreateUserPopup(false)}/>
						{push ? <Push top={0} padding={8} isError={true}>{pushText}</Push> : ''}
					</div>
					<div className="create-user-popup-body">
						<div className="profile-inputs">
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.firstName}</div>
								<input type="text" className="profile-input" name="first_name"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.first_name} onChange={event => inputControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.lastName}</div>
								<input type="text" className="profile-input" name="last_name"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.last_name} onChange={event => inputControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.title}</div>
								<input type="text" className="profile-input" name="title"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.title} onChange={event => inputControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.emailAddress}</div>
								<input type="text" className="profile-input" name="login"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.login} onChange={event => inputControl(event.target)}/>
							</div>
							{/* <div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.password}</div>
								<div className="profile-input-password">
									<input type={isVisiblePassword ? "text" : "password"}  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} name="password" className="profile-input" value={inputs.password}  onChange={event => inputControl(event.target)}/>
									<img src={eyeIcon} alt="eye" className="profile-input-eye" onClick={() => setIsVisiblePassword(prev => !prev)}/>
								</div>
							</div> */}
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.company}</div>
								<input type="text" className="profile-input" name="company"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.company} onChange={event => inputControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{'Role'}</div>
								<div className="role-type-select">
									<div className="select-active" onClick={() => setIsOpenSelect(prev => !prev)}>
										<span>{role}</span>
										<Arrow/>
									</div>
									<div className={"select-list " + (isOpenSelect ? 'select-list-open' : '')}>

										{users.roles?.map((collabItem, i) => {
											// if(collabItem.name === inputs.role || collabItem.name === 'admin'){
											// 	return false
											// }
											return (
												<div className={"select-list-item"} key={i} onClick={event => {
													event.stopPropagation()
													setIsOpenSelect(false)
													setInputs({
														...inputs,
														role: collabItem.name
													})
													setRole(collabItem.description)
												}}><span></span>{collabItem.description}</div>
											)
										})}
									</div>
								</div>
							</div>
							<div className={"profile-input-wrapper profile-input-checkbox " + (inputs.external ? 'profile-input-checkbox-active' : '')} onClick={() => {
								setInputs({
									...inputs,
									external : !inputs.external
								})
							}}
							onKeyDown={(e) => onEnterPress(e)}>
								<div className="checkbox">
									<Check/>
								</div>
								<span>{lang.external}</span>
								{/* <input type="checkbox" className="profile-input profile-checkbox" id='external' onKeyDown={(e) => onEnterPress(e)} name="external" value={inputs.external}  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.login} onChange={event => externalControl(event.target)}/>
								<label htmlFor="external">{lang.external}</label> */}
							</div>
						</div>
						<div className="profile-btns">
							<Button color={'white'} margin={10} padding={8} onClick={() => {
								setInputs({
									first_name: '',
									last_name : '',
									title: '',
									login: '',
									password: '',
									company: '',
									external: false,
								})
								setCreateUserPopup(false)
							}}>{lang.cancel}</Button>
							<Button color={'blue'} margin={0} padding={8} onClick={async () => {
								if(emailValidate(inputs.login)){
									setPush(true)
										setPushText('Enter valid email')
										setTimeout(() => {
											setPush(false)
										}, 1300)  
								} else if( inputs.first_name.length === 0 || 
									inputs.last_name.length === 0 || 
									inputs.title.length === 0 || 
									inputs.login.length === 0 ||
									inputs.company.length === 0 ||
									inputs.role.length === 0 ){
										setPushText('All fields are required')
										setPush(true)
										setTimeout(() => {
											setPush(false)
										}, 1300)  
								} else {
									await onSubmitCreateNewUser();
								}
							}}>{'Create'}</Button>
						</div>
					</div>
				</div>
			</div>
			<div className={"create-user-popup-wrapper " + (editUserPopup ? 'create-user-popup-wrapper-active' : '')} onClick={(event) => {
				if(event.target.classList.contains('create-user-popup-wrapper-active')){
					setEditUserPopup(false)
				}
			}}>
				<div className="create-user-popup" onKeyDown={async event => {
					if(event.keyCode === 13){
						await createUser(inputs)
						.then(async (res) => {
							setUsers(convertUsers(await getUsers()))
							setEditUserPopup(false)
						})
					}
				}}>
					<div className="create-user-popup-header">
						<div className="header-title">edit user</div>
						<Close onClick={() => setEditUserPopup(false)}/>
						{push ? <Push top={0} padding={8} isError={true}>{'All fields are required'}</Push> : ''}
					</div>
					<div className="create-user-popup-body">
						<div className="profile-inputs">
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.firstName}</div>
								<input type="text" className="profile-input" name="first_name"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={userOnEdit.first_name} onChange={event => editControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.lastName}</div>
								<input type="text" className="profile-input" name="last_name"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={userOnEdit.last_name} onChange={event => editControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.title}</div>
								<input type="text" className="profile-input" name="title"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={userOnEdit.title} onChange={event => editControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.emailAddress}</div>
								<input type="text" className="profile-input" name="login"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={userOnEdit.login} onChange={event => false}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{lang.company}</div>
								<input type="text" className="profile-input" name="company"  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} onKeyDown={(e) => onEnterPress(e)} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={userOnEdit.company} onChange={event => editControl(event.target)}/>
							</div>
							<div className="profile-input-wrapper">
								<div className="profile-input-title">{'Role'}</div>
								<div className="role-type-select">
									<div className="select-active" onClick={() => setIsOpenSelectEdit(prev => !prev)}>
										<span>{users.roles?.find((item) => {
											if(userOnEdit.role?.substr(0,6) === item.name?.substr(0,6)){
												return true
											}
										})?.description}</span>
										<Arrow/>
									</div>
									<div className={"select-list " + (isOpenSelectEdit ? 'select-list-open' : '')}>

										{users.roles?.map((collabItem, i) => {
											return (
												<div className={"select-list-item"} key={i} onClick={event => {
													event.stopPropagation()
													setIsOpenSelectEdit(false)
													setUserOnEdit({
														...userOnEdit,
														role: collabItem.name
													})
													setRole(collabItem.description)
												}}><span></span>{collabItem.description}</div>
											)
										})}
									</div>
								</div>
							</div>
							<div className={"profile-input-wrapper profile-input-checkbox " + (userOnEdit.external ? 'profile-input-checkbox-active' : '')} onClick={() => {
								setUserOnEdit({
									...userOnEdit,
									external : !userOnEdit.external
								})
							}}
							onKeyDown={(e) => onEnterPress(e)}>
								<div className="checkbox">
									<Check/>
								</div>
								<span>{lang.external}</span>
								{/* <input type="checkbox" className="profile-input profile-checkbox" id='external' onKeyDown={(e) => onEnterPress(e)} name="external" value={inputs.external}  dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputs.login} onChange={event => externalControl(event.target)}/>
								<label htmlFor="external">{lang.external}</label> */}
							</div>
						</div>
						<div className="profile-btns">
							<Button color={'white'} margin={10} padding={8} onClick={() => {
								setUserOnEdit({
									first_name: '',
									last_name : '',
									title: '',
									login: '',
									password: '',
									company: '',
									external: false,
								})
								setEditUserPopup(false)
							}}>{lang.cancel}</Button>
							<Button color={'blue'} margin={0} padding={8} onClick={async () => {
								if(emailValidate(userOnEdit.login)){
									setPush(true)
										setPushText('Enter valid email')
										setTimeout(() => {
											setPush(false)
										}, 1300)  
								} else if( userOnEdit.first_name.length === 0 || 
									userOnEdit.last_name.length === 0 || 
									userOnEdit.title.length === 0 || 
									userOnEdit.login.length === 0 ||
									userOnEdit.company.length === 0 ||
									userOnEdit.role.length === 0 ){
										setPushText('All fields are required')
										setPush(true)
										setTimeout(() => {
											setPush(false)
										}, 1300)  
								} else {
										updateUser(userOnEdit._id, userOnEdit)
										.then(async (res) => {
											setUsers(convertUsers(await getUsers()))
										})
										setEditUserPopup(false)
									}
							}}>{'Update'}</Button>
						</div>
					</div>
				</div>
			</div>
			<div className={"add-popup-wrapper " + (isOpenAddNewType ? 'add-popup-wrapper-active' : '')} onClick={(event) => {
				if(event.target.classList.contains('add-popup-wrapper-active')){
					setIsOpenAddNewType(false)
				}
			}}>
				<div className="add-popup">
					<div className="add-popup-header">
						<div className="header-title">ADD NEW TYPE</div>
						<Close onClick={() => setIsOpenAddNewType(false)}/>
					</div>
					<div className="add-popup-body">
						<div className="input-wrapper">
							<div className="input-title">
								TYPE name
							</div>
							<div className="input-line-wrap">
								<img src={require(`../../assets/img/files/other.svg`)} alt=""/>
								<span>*.</span>
								<input type="text" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={addNewType} onChange={event => setAddNewType(event.target.value)}/>
							</div>
						</div>
						<div className="add-btns">
							<Button color={'white'} padding={8} margin={10} onClick={() => {
								setIsOpenAddNewType(false)
								setAddNewType('')
								}}>{lang.cancel}</Button>
							<Button color={'blue'} padding={8} onClick={() => {
								setIsOpenAddNewType(false)
								setSettings({
									...settings,
									file_type_for_upload : settings.file_type_for_upload.concat({
										name: addNewType,
										active: true
									})
								})
								setAddNewType('')
							}}>Create</Button>
						</div>
					</div>
				</div>
			</div>
			<div className={"add-popup-wrapper " + (isOpenTypeUpload ? 'add-popup-wrapper-active' : '')} onClick={(event) => {
				if(event.target.classList.contains('add-popup-wrapper-active')){
					setIsOpenTypeUpload(false)
				}
			}}>
				<div className="add-popup">
					<div className="add-popup-header">
						<div className="header-title">Add new document type</div>
						<Close onClick={() => setIsOpenTypeUpload(false)}/>
					</div>
					<div className="add-popup-body">
						<div className="input-wrapper">
							<div className="input-title">
							Document Name
							</div>
							<div className="input-line-wrap">
								<Doc/>
								<span></span>
								<input type="text" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={addNewTypeFile} onChange={event => setAddNewTypeFile(event.target.value)}/>
							</div>
						</div>
						<div className="add-btns">
							<Button color={'white'} padding={8} margin={10} onClick={() => {
								setIsOpenTypeUpload(false)
								setAddNewTypeFile('')
							}}>{lang.cancel}</Button>
							<Button color={'blue'} padding={8} onClick={() => {
								setIsOpenTypeUpload(false)
								setAddNewTypeFile('')
								setSettings(prev => ({...prev, document_type_for_upload: settings.document_type_for_upload.concat({name: addNewTypeFile, active: true})}))
							}}>Create</Button>
						</div>
					</div>
				</div>
			</div>
			<div className={"delete-popup-wrapper " + (isOpenTypeToDelete ? 'delete-popup-wrapper-active' : '')} onClick={(event) => {
				if(event.target.classList.contains('delete-popup-wrapper-active')){
					setIsOpenTypeToDelete(false)
				}
			}}>
				<div className="delete-popup">
					<div className="delete-popup-header">
						<div className="header-title">Are you sure you want to delete this document type?</div>
						<Close onClick={() => setIsOpenTypeToDelete(false)}/>
					</div>
					<div className="delete-popup-body">
						{toDeleteFile ? (
							<div className="type-to-upload-item">
								<div className={'plus-wrapper-active'}>
									<Plus/>
								</div>
								<Doc className='doc'/>
								<div className="type-to-upload-name">{toDeleteFile.name}</div>
							</div>
						) : ''}
						<div className="delete-btns">
							<Button color={'white'} padding={8} margin={10} onClick={() => setIsOpenTypeToDelete(false)}>{lang.cancel}</Button>
							<Button color={'blue'} padding={8} onClick={() => {
								setIsOpenTypeToDelete(false)
								removeFromUploads(toDeleteFile.id)
							}} style={{backgroundColor: '#D63649', border: '#D63649'}}>Delete</Button>
						</div>
					</div>
				</div>
			</div>
			<div className={"add-popup-wrapper add-popup-wrapper-field " + (isOpenAddDetails ? 'add-popup-wrapper-active' : '')} onClick={(event) => {
				if(event.target.classList.contains('add-popup-wrapper-active')){
					setIsOpenAddDetails(false)
				}
			}}>
				<div className="add-popup">
					<div className="add-popup-header">
						<div className="header-title">ADD A NEW FIELD</div>
						<Close onClick={() => setIsOpenAddDetails(false)}/>
					</div>
					<div className="add-popup-body">
						<div className="input-wrapper">
							<div className="input-title">
								FIELD
							</div>
							<div className="input-line-wrap">
								<input type="text" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={addField} onChange={event => setAddField(event.target.value)}/>
							</div>
							<div className="field-type-select">
								<div className="select-active" onClick={() => fieldType.openClose()}>
									<span>{fieldType.value}</span>
									<Arrow/>
								</div>
								<div className={"select-list " + (fieldType.active ? 'select-list-active' : '')}>
									{fieldType.valuesList.filter(item => !(item === fieldType.value)).map((item, index) => {
										return (
											<div className="select-list-item" key={index} onClick={() => fieldType.changeValue(item)}>{item}</div>
										)
									})}
								</div>
							</div>
						</div>
						<div className="add-btns">
							<Button color={'white'} padding={8} margin={10} onClick={() => {
								setIsOpenAddDetails(false)
								setAddField('')	
							}}>{lang.cancel}</Button>
							<Button color={'blue'} padding={8} onClick={() => {
								setIsOpenAddDetails(false)
								createSettingsDetailsField(addFieldType.sub_group, addField, addFieldType.group, fieldType.value) //add type (string || date)
								.then(res => {
									addNewField(setDetails, addFieldType.sub_group, addField, addFieldType.group, res.data.data._id, fieldType.value)
								})
								setAddField('')
							}}>Create</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="settings-header">
				<div className={"settings-header-item " + (activeTab === 0 ? 'settings-header-item-active' : '')} onClick={() => setActiveTab(0)}>GLOBAL SETTINGS</div>
				<div className={"settings-header-item " + (activeTab === 1 ? 'settings-header-item-active' : '')} onClick={() => setActiveTab(1)}>USERS</div>
				<div className={"settings-header-item " + (activeTab === 2 ? 'settings-header-item-active' : '')} onClick={() => setActiveTab(2)}>FIELDS</div>
			</div>
			{isLoadComplete ? (
				<div className="settings-body">
					{activeTab === 1 ? (
						<>
							<div className="users-header">
								<span>All users</span>
								<div className="users-nav">
									<Button color={'blue'} padding={8} onClick={() => setCreateUserPopup(true)}>Create new user</Button>						
								</div>
							</div>
							<div className="users-body">
								<div className="users-table-header">
									<div className="header-item" onClick={()=> {
										setFilterVar(prev => {
											if(prev.e === 'u'){
												userFilter('login', 'd')
												return {
													...prev,
													e: 'd'
												}
											} else {
												userFilter('login', 'u')
												return {
													...prev,
													e: 'u'
												}
											}
										})
									}}>
										Email <Arrow/>
									</div>
									<div className="header-item"onClick={()=> {
										setFilterVar(prev => {
											if(prev.f === 'u'){
												userFilter('first_name', 'd')
												return {
													...prev,
													f: 'd'
												}
											} else {
												userFilter('first_name', 'u')
												return {
													...prev,
													f: 'u'
												}
											}
										})
									}}>
										Firstname <Arrow/>
									</div>
									<div className="header-item"onClick={()=> {
										setFilterVar(prev => {
											if(prev.l === 'u'){
												userFilter('last_name', 'd')
												return {
													...prev,
													l: 'd'
												}
											} else {
												userFilter('last_name', 'u')
												return {
													...prev,
													l: 'u'
												}
											}
										})
									}}>
										Lastname <Arrow/>
									</div>
									<div className="header-item"onClick={()=> {
										setFilterVar(prev => {
											if(prev.c === 'u'){
												userFilter('company', 'd')
												return {
													...prev,
													c: 'd'
												}
											} else {
												userFilter('company', 'u')
												return {
													...prev,
													c: 'u'
												}
											}
										})
									}}>
										Company <Arrow/>
									</div>
									<div className="header-item"onClick={()=> {
										userFilter('role')
										setFilterVar(prev => {
											if(prev.r === 'u'){
												userFilter('role', 'd')
												return {
													...prev,
													r: 'd'
												}
											} else {
												userFilter('role', 'u')
												return {
													...prev,
													r: 'u'
												}
											}
										})
									}}>
										Role <Arrow/>
									</div>
								</div>
								<div className="users-list">
									{users.users.map((user, index) => {
										return (
											<div className="user-item" key={index}>
												<div className="user-wrapper">
													{user.picture ? (
														<img src={user.picture} alt="" className={'user-avatar'}/>
													) : (
														<NonAvatar/>
													)}
												</div>
												<div className="users-data">
													<div className="user-data-item">{user.login}</div>
													<div className="user-data-item">{user.first_name}</div>
													<div className="user-data-item">{user.last_name}</div>
													<div className="user-data-item">{user.company}</div>
													<div className="user-data-item">{users.roles?.find((item) => {
														if(user.role?.substr(0,6) === item.name?.substr(0,6)){
															return true
														}
													})?.description}</div>
												</div>
												<button className="edit" onClick={() => {
													setUserOnEdit(user)
													setEditUserPopup(true)
												}}>
													<EditUser/>
												</button>
												<button onClick={async () => {
													deleteUser(user._id)
													.then(async () => {
														setUsers(await getUsers())
													})
												}}>
													<Delete/>
												</button>
											</div>
										)
									})}
								</div>
							</div>
						</>
					) : ''} 
					{activeTab === 0 ? (
						<>
							<div className="timezone-wrapper">
								<div className="select-wrapper">
									<div className="select-name">
										Time zone
									</div>
									<div className="timezone-select">
										<div className="timezone-active" onClick={() => {
												setIsOpenTimeZones(prev => !prev)
											}}>
											<span>{timezones.filter(time => time.active ? time : false)[0].text}</span>
											<Arrow/>
										</div>
										<div className={"timezone-list " + (isOpenTimeZones ? 'timezone-list-active' : '')}>
											{timezones.map((time, index) => {
												return (
													<div className="list-item" key={index} onClick={() => {
														changeTimeZone(index)
														setIsOpenTimeZones(false)
														setSettings(prev => ({...prev, time_zone: index}))
													}}>
														{time.text}
													</div>
												)
											})}
										</div>
									</div>
								</div>
							</div>
							<div className="types-list">
								<div className="types-list-header">
									<span>File types for upload</span>
									<Button color={'white'} plus={true} padding={8} onClick={() => setIsOpenAddNewType(true)}>Add new type</Button>
								</div>
								<div className="types-wrapper">
									{settings.file_type_for_upload.map((type, index) => {
										return (
											<div className="type-item" key={index} onClick={() => {
												activeCheck(index)
											}}>
												<div className={"checkbox " + (type.active ? 'checkbox-active' : '')}>
													<Check/>
												</div>
												<img src={require(`../../assets/img/files/${/(doc|xls|pdf)x?$/.test(type.name) ? type.name : 'other'}.svg`)} alt=""/>
												<span>.{type.name.toUpperCase()}</span>
											</div>
										)
									})}
								</div>
							</div>
							<div className="edit-mode">
								<div className={"edit-mode-wrapper " + (settings.edit_mode === "SINGLE-USER" ? 'edit-mode-wrapper-active' : '')}>
									<div className="edit-mode-name" onClick={() => setSettings(prev => ({...prev, edit_mode : 'SINGLE-USER'}))}>
										<div className="radio"> <span></span> </div>
										<span >single-edit USER MODE</span>
									</div>
									<div className="edit-mode-title">Minimal waiting before disconnecting a user:</div>
									<div className="time-select">
										<div className="time-active" onClick={() => {
												if(settings.edit_mode === "SINGLE-USER"){
													setIsOpenTime(prev => !prev)
												}
											}}>
											<span>{settings.min_waiting_before_disconnecting} min</span>
											<Arrow/>
										</div>
										<div className={"time-list " + (isOpenTime ? 'time-list-active' : '')}>
											{timesList.map((time, index) => {
												return (
													<div className="list-item" key={index} onClick={() => {
														changeTime(index)
														setIsOpenTime(false)
													}}>
														{time.name} min
													</div>
												)
											})}
										</div>
									</div>
								</div>
								<div className={"edit-mode-wrapper " + (settings.edit_mode === "MULTI-USER"  ? 'edit-mode-wrapper-active' : '')}>
									<div className="edit-mode-name" onClick={() => setSettings(prev => ({...prev, edit_mode : 'MULTI-USER'}))}>
										<div className="radio "><span></span> </div>
										<span >MULTI-edit USER MODE</span>
										<Wave onMouseEnter={() => setIsOpenWave(true)}
											onMouseLeave={() => setIsOpenWave(false)}			
										/>
										<div onMouseEnter={() => setIsOpenWave(true)} onMouseLeave={() => setIsOpenWave(false)} className={"projects-setting " + (isOpenWave ? 'projects-setting-active' : '')}>
											<svg version="1.1" id="setting-arrow" viewBox="0 0 490.787 490.787">
												<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006
													c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083
													c-0.086,0.089-0.173,0.176-0.262,0.262L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085
													C368.236,489.664,365.511,490.792,362.671,490.787z"/>
												<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256
													c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
													L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C368.236,489.664,365.511,490.792,362.671,490.787z"/>
											</svg>
											<a className="projects-setting-item" href="https://wave.codox.io">https://wave.codox.io</a>
										</div>
									</div>
									<input type="text" className="api-token" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} readOnly={settings.edit_mode === "SINGLE-USER" ? true : false} defaultValue={settings.edit_api_token} onBlur={event => {
										let newToken = event.target.value
										setSettings(prev => ({...prev, edit_api_token : newToken}))
									}}/>
								</div>
							</div>
							<div className="types-to-upload">
								<div className="types-to-upload-header">
									<span>Document Types</span>
									<Button color={'white'} plus={true} padding={8} onClick={() => setIsOpenTypeUpload(true)}>Add new type</Button>
								</div>
								<div className="types-upload-list">
									{settings.document_type_for_upload.map((item, index) => {
										return (
											<div className="type-to-upload-item" key={index}>
												<div className={"plus-wrapper " + (item.active ? 'plus-wrapper-active' : '')} onClick={() => {
													if(item.active){
														setToDeleteFile({...item, id : index})
														setIsOpenTypeToDelete(true)
													} else {
														addToUpload(index)
													}
												}}>
													<Plus/>
												</div>
												<Doc className='doc'/>
												<div className="type-to-upload-name">{item.name}</div>
											</div>
										)
									})}
								</div>
							</div>
							<div className="company-name">
								<div className="company-name-header">
									<span>Company name</span>
								</div>
								<input type="text" className="company-name-input" value={settings.company} onChange={ event => {
									let value = event.target.value
									setSettings(prev => {
										return {
											...prev,
											company: value
										}
									})
								}}/>
							</div>
							<div className="url-name">
								<div className="url-name-header">
									<span>Share URL</span>
								</div>
								<input type="text" className="url-name-input" value={settings.url} onChange={ event => {
									let value = event.target.value
									console.log('value', settings)
									setSettings(prev => {
										return {
											...prev,
											url: value
										}
									})
								}}/>
							</div>
						</>
					) : ''}
					{activeTab === 2 ? (
						<>
							<div className="section-header">
								<div className="title">Companies Details</div>
								<Button color={'white'} plus={true} padding={14} onClick={() => {
									setIsOpenAddDetails(true)
									setAddFieldType({
										group: 'company'
									})
								}}>Add a new field</Button>
							</div>
							<div className="section-body">
								{details.filter(item => item.group === 'company').map((item, index) => {
									return (
										<div className="section-body-item-wrapper" key={index}>
											<div className={"section-body-item-input-wrapper " + (item.isEdit ? 'section-body-item-input-wrapper-active' : '')}>
												<input type="text" value={item.name} readOnly={!item.isEdit} onChange={event => changeItemDetails(setDetails, item.id, event.target.value)}/>
												{item.isEdit ? <Check className={'complete'} onClick={() => {
													updateSettingsDetailsField(item.id, item)
													changeEditItemDetails(setDetails, item.id
													)}}/> : (item.editable ? <Pencel  onClick={() => changeEditItemDetails(setDetails, item.id)}/> : '')}
											</div>
											{item.isEdit ? <Garb onClick={() => deleteItemDetails(setDetails, item.id)}/> : ''}
										</div>
									)
								})}
							</div>
							<div className="section-header">
								<div className="title">Document Details / GENERAL INFORMATION</div>
								<Button color={'white'} plus={true} padding={14} onClick={() => {
									setIsOpenAddDetails(true)
									setAddFieldType({
										group: 'document',
										sub_group: 'general'
									})
								}}>Add a new field</Button>
							</div>
							<div className="section-body">
								{details.filter(item => item.sub_group === 'general' && item.group === 'document').map((item, index) => {
									return (
										<div className="section-body-item-wrapper" key={index}>
											<div className={"section-body-item-input-wrapper " + (item.isEdit ? 'section-body-item-input-wrapper-active' : '')}>
												<input type="text" value={item.name} readOnly={!item.isEdit} onChange={event => changeItemDetails(setDetails, item.id, event.target.value)}/>
												{item.isEdit ? <Check className={'complete'} onClick={() => {
													updateSettingsDetailsField(item.id, item)
													changeEditItemDetails(setDetails, item.id
													)}}/> : (item.editable ? <Pencel  onClick={() => changeEditItemDetails(setDetails, item.id)}/> : '')}
											</div>
											{item.isEdit ? <Garb onClick={() => deleteItemDetails(setDetails, item.id)}/> : ''}
										</div>
									)
								})}
							</div>
							<div className="section-header">
								<div className="title">Document Details / OPERATIONAL INFORMATION</div>
								<Button color={'white'} plus={true} padding={14}  onClick={() => {
									setIsOpenAddDetails(true)
									setAddFieldType({
										group: 'document',
										sub_group: 'operational'
									})
								}}>Add a new field</Button>
							</div>
							<div className="section-body">
								{details.filter(item => item.sub_group === 'operational' && item.group === 'document').map((item, index) => {
									return (
										<div className="section-body-item-wrapper" key={index}>
											<div className={"section-body-item-input-wrapper " + (item.isEdit ? 'section-body-item-input-wrapper-active' : '')}>
												<input type="text" value={item.name} readOnly={!item.isEdit} onChange={event => changeItemDetails(setDetails, item.id, event.target.value)}/>
												{item.isEdit ? <Check className={'complete'} onClick={() => {
													updateSettingsDetailsField(item.id, item)
													changeEditItemDetails(setDetails, item.id
													)}}/> : (item.editable ? <Pencel  onClick={() => changeEditItemDetails(setDetails, item.id)}/> : '')}
											</div>
											{item.isEdit ? <Garb onClick={() => deleteItemDetails(setDetails, item.id)}/> : ''}
										</div>
									)
								})}
							</div>
							<div className="section-header">
								<div className="title">Document Details / CONTENT INFORMATION</div>
								<Button color={'white'} plus={true} padding={14}  onClick={() => {
									setIsOpenAddDetails(true)
									setAddFieldType({
										group: 'document',
										sub_group: 'content'
									})
								}}>Add a new field</Button>
							</div>
							<div className="section-body">
								{details.filter(item => item.sub_group === 'content' && item.group === 'document').map((item, index) => {
									return (
										<div className="section-body-item-wrapper" key={index}>
											<div className={"section-body-item-input-wrapper " + (item.isEdit ? 'section-body-item-input-wrapper-active' : '')}>
												<input type="text" value={item.name} readOnly={!item.isEdit} onChange={event => changeItemDetails(setDetails, item.id, event.target.value)}/>
												{item.isEdit ? <Check className={'complete'} onClick={() => {
													updateSettingsDetailsField(item.id, item)
													changeEditItemDetails(setDetails, item.id
													)}}/> : (item.editable ? <Pencel  onClick={() => changeEditItemDetails(setDetails, item.id)}/> : '')}
											</div>
											{item.isEdit ? <Garb onClick={() => deleteItemDetails(setDetails, item.id)}/> : ''}
										</div>
									)
								})}
							</div>
						</>
					) : ''}
				</div>
			) : ''}
			
		</div>
	)
}


export default Settings
