
import { act } from 'react-dom/test-utils';
import {
	createProject,
	getFolders,
	getCompanyDetails
} from './api'

import {
	dateConvert,
	docConvert,
	randomColor
} from './appfunctions.js'

export const companyConvert = async (data) => {
	return {
		id: data._id,
		name: data.name,
		archived: !!data.deleted_at,
		favourite: data.favourite,
		documents: data.documents.map(doc => docConvert(doc)),
		favDoc : data.documents.filter(doc => doc.favorite).map(doc => docConvert(doc)),
		delDoc: data.documents_deleted.map(doc => docConvert(doc)),
		projects: data.projects,
		details: convertDetails(data.details),
	}
	// return data.map((company, index) => {
	// 	if(active){
	// 		window.history.pushState("object or string", "Title", window.location.pathname + `?id=${active}`)
	// 	} else {
	// 		window.history.pushState("object or string", "Title", window.location.pathname + `?id=${data[0]._id}`)
	// 	}
	// 	let details = [];
	// 	if(company._id === active || (!active && index === 0)) {
	// 		details = convertDetails(detailsData.details);
	// 	}
	// 	return {
	// 		id: company._id,
	// 		name: company.name,
	// 		active:  active ? (active === company._id) : (index === 0),
	// 		archived: !!company.deleted_at,
	// 		favorite: company.favourite,
	// 		documents: company.documents.map(doc => docConvert(doc)),
	// 		favDoc : company.documents.filter(doc => doc.favorite).map(doc => docConvert(doc)),
	// 		delDoc: company.documents_deleted.map(doc => docConvert(doc)),
	// 		projects: company.projects,
	// 		details: details,
	// 	}
	// })
}

export const convertTemplate = async (data) => {
	let tmpData = {};
	if(!data.templateGroup){
		tmpData = {templateGroup: data, templates: []};
		data = tmpData
	}
	return {
		id: data.templateGroup._id,
		name : data.templateGroup.name,
		archived: data.templateGroup.deleted_at ? true : false,
		favourite: data.templateGroup.favourite || false,
		folders: data.templateGroup.folders ? convertFolders(data.folders) : null,
		edited: data.templateGroup.edited_by
      ? data.templateGroup.edited_by.first_name + ' ' + data.templateGroup.edited_by.last_name
      : data.templateGroup.owner_id.first_name + ' ' + data.templateGroup.owner_id.last_name,
		updated: dateConvert(data.templateGroup.updated_at || data.templateGroup.created_at, 'dt'),
		owner: {
			...data.templateGroup.owner_id,
			bgc: randomColor()
		},
		documents: data.templates?.map(doc => docConvert(doc)),
		favDoc : data.templates?.filter(doc => doc.favorite).map(doc => docConvert(doc)),
		delDoc: data?.documents_deleted ? data?.documents_deleted?.map(doc => docConvert(doc)) : data?.templates?.filter(doc => doc.deleted_at)?.map(doc => docConvert(doc))
	}
}

export const convertProject = async (data, user = {}) => {
	// let foldersData  = await getFolders(data._id)
	// if(active){
	// 	window.history.pushState("object or string", "Title", window.location.pathname + `?id=${active}`)
	// } else {
	// 	window.history.pushState("object or string", "Title", window.location.pathname + `?id=${data[0]?._id}`)
	// }
	// let activeId = data.find((item) => {
	// 	if(!item.deleted_at){
	// 		return true
	// 	}
	// })
	return {
		...data,
		id: data._id,
		name : data.name,
		archived: data.deleted_at ? true : false,
		favourite: data.favourite || false,
		folders: convertFolders(data.folders),
		edited: data.edited_by ? data.edited_by.first_name + ' ' + data.edited_by.last_name : data.owner_id.first_name + ' ' + data.owner_id.last_name,
		updated: dateConvert(data.updated_at || data.created_at, 'dt'),
		owner: {
			...data.owner_id,
			bgc: randomColor()
		},
		users: data.users.filter(user => user.user_id).map(user => {
			return {
				...user.user_id,
				fullName: (user.user_id.first_name || '') + ' ' + (user.user_id.last_name || ''),
				first_name: user.user_id.first_name || '',
				last_name: user.user_id.last_name || '',
				email: user.user_id.login || '',
				avatar: user.user_id.picture || undefined,
				permission: user.permission || 'viewer',
				isOpenSelect: false,
				bgc: randomColor(),
				id: user.user_id._id,
			}
		}),
		documents: data.documents.map(doc => docConvert(doc)),
		favDoc : data.documents.filter(doc => user.favourites.documents.includes(doc._id)).map(doc => docConvert(doc)),
		delDoc: data.documents_deleted.map(doc => docConvert(doc))
	}
	// return data.map((project, index) => {
	// 	return {
	// 		...project,
	// 		id: project._id,
	// 		name : project.name,
	// 		// active: active ? (active === project._id ? true : false) : (activeId._id === project._id ? true : false),
	// 		archived: project.deleted_at ? true : false,
	// 		favorite: project.favourite || false,
	// 		lastEditDate: dateConvert(project.updated_at, 'dt'),
	// 		lastEditOwner: project.edited_by ? project.edited_by.first_name + ' ' + project.edited_by.last_name : project.owner_id ? project.owner_id.first_name + ' ' + project.owner_id.last_name : '',
	// 		collabList: project.users.filter(user => user.user_id).map(user => {
	// 			return {
	// 				...user.user_id,
	// 				fullName: user.user_id.first_name + ' ' + (user.user_id.last_name || ''),
	// 				name: user.user_id.first_name,
	// 				lastName: user.user_id.last_name === undefined ? '' : user.user_id.last_name,
	// 				email: user.user_id.login || '',
	// 				avatar: user.user_id ? (user.user_id.picture || undefined) : '',
	// 				collabType: user.permission,
	// 				isOpenSelect: false,
	// 				bgc: randomColor(),
	// 				id: user._id,
	// 				permission: user.permission || 'viewer'
	// 			}
	// 		}),
	// 		documents : project.documents.map(doc => docConvert(doc)),
	// 		folders: index === 0 && foldersData ? convertFolders(foldersData.data) : [],
	// 		favDoc : project.documents.filter(doc => doc.favorite).map(doc => docConvert(doc)),
	// 		delDoc: project.documents_deleted.map(doc => docConvert(doc))
	// 	}
	// })
}

export const projectCreate = async (projectsData = [], user, projects, setProjects, setPush, setPushText, newProject, setIsActive, filename, setIsLoadComplete) => {
	let nameCount = 0;
	(projectsData.length === 0 ? projects : projectsData).map(item => {
		if(item.name.includes('Untitled')){
			nameCount++
		}
	})
	createProject({
		name: nameCount === 0 ? 'Untitled' : 'Untitled (' + nameCount + ')',
	})
	.then(res => {
		setProjects((projectsData.length === 0 ? projects : projectsData)
		.map(item => {
			return {
				...item,
				active: false
			}
		})
		.concat({
			id: res.data.data._id,
			name: res.data.data.name,
			active: true,
			archived: false,
			favorite: false,
			lastEditDate: '10:21am • 09/06/2021 • ',
			lastEditOwner: user.first_name + ' ' + user.last_name,
			collabList:[
				{
					name: user.first_name + ' ' + user.last_name,
					email: user.login,
					avatar: user.picture,
					collabType: 'owner',
					isOpenSelect: false
				}
			],
			documents : [

			],
			folders: [

			],
			favDoc : [
				
			],
			delDoc: [

			]
		}))
		setPushText('NEW PROJECT CREATED')
		setPush(true)
		setIsActive(true)
		setTimeout(() => {
			setPush(false)
			filename.setIsChange(true)
		}, 1200)
	})
	if(newProject){
		setIsLoadComplete(false)
	}
}


export const convertFolders = (data) => {
	return data.map(folder => {
		return {
			...folder,
			isSelect : false,
			isOpenOptions: false,
			isRename: false,
			isRevertMenu: false,
			onMove: false,
			id: folder._id,
			documents : folder.documents.map(doc => docConvert(doc)),
		}
	})
}

export const convertDetails = data => {
	return data.map(item => ({...item, isEdit: false, id: item._id || item.field._id}))
}