export const langs = ['en', 'ar']


export const lang = {
	en: {
		documentManager: 'Document Manager',
		dashboard: 'Dashboard',
		projects: 'Projects',
		companies: 'Companies',
		templates: 'Templates',
		settings: 'Settings',
		notifications: 'notifications',
		viewAll: 'View all',
		editProfile: 'Edit profile',
		signOut: 'Sign out',
		signIn: 'Sign in',
		language: 'Language',
		search: 'Search',
		clearSearch: 'Clear search',
		resultsSearch: 'RECENT SEARCHES',
		recentProjects: 'RECENT PROJECTS',
		recentDocuments: 'RECENT DOCUMENTS',
		loginTitle: 'Please enter your credentials to proceed.',
		recovery: 'Password recovery',
		recoveryTitle: 'Please enter the email you’re using for your account.',
		emailAddress: 'EMAIL ADDRESS',
		password: 'PASSWORD',
		forgot: 'Forgot password?',
		backToLogin: 'Back to Login',
		submit: 'Submit',
		recoveryPush: 'SUBMITTED. PLEASE CHECK YOUR EMAIL.',
		statuses: {
			expired: 'Expired',
			signed: 'Signed'
		},
		notificationsDos : {
			'comment.create': 'created a comment on ',
			'comment.reply': 'commented on ',
			'comment.mention': 'user mentioned in a comment on ',
			'query.create': 'logged a query on ',
			'query.reply': 'replied in a query on ',
			'query.resolved': 'resolved a query on ',
			'query.mention': 'user mentioned in a query on ',
			'document.status': 'status changed to ',
			'document.content.edited': 'created new version for ',
			'document.link': 'was uploaded to ',
			'document.published': 'document published ',
			'document.name': 'changed the name of document ',
		},
		allNotifications: 'ALL NOTIFICATIONS',
		uploadNewPhoto: 'Upload new photo',
		firstName: 'FIRST NAME',
		lastName: 'LAST NAME',
		title: 'TITLE',
		company: 'Company',
		changePassword: 'Change password',
		sendPasswordLink: 'Send Password Reset Link',
		passwordChangeUrlText: 'Url for change password successful send to your email',
		newPassword: 'NEW PASSWORD',
		repeatNewPassword: 'REPEAT NEW PASSWORD',
		cancel: 'Cancel',
		save: 'Save',
		profileUpdate: 'PROFILE UPDATED',
		profileEdit: 'EDIT PROFILE',
		dropYourFiles: 'Drop your files here.',
		convertFiles: 'CONVERT FILES',
		doYouWantConvert: 'Do you want to convert these new files into Guardian (.spe) files? ',
		dontAskConvert: 'Don’t ask me to convert files again.',
		noUpload: 'No, upload',
		yesConvert: 'Yes, convert',
		fileConvertingComplete: 'FILE CONVERTING COMPLETE',
		fileUploadComplete: 'FILE UPLOAD COMPLETE',
		share: 'Share',
		shareWithCollab: 'Share with collaborators',
		manage: 'Manage',
		getLink: 'Get link',
		copyLink: 'Copy link',
		security: 'SECURITY',
		fileShared: 'FILE SHARED',
		linkCopiedTo: 'LINK COPIED TO CLIPBOARD',
		createNewFile: 'CREATE NEW FILE',
		create: 'Create',
		rename: 'Rename',
		delete: 'Delete',
		uploadFiles: 'Upload files',
		newFile: 'New file',
		newDocument: 'New Document',
		download: 'Download',
		restricted: 'Restricted (only collaborators)',
		passwordProtected: 'Password protected',
		newProject: 'New project',
		newTemplate: 'New template',
		newCompany: 'New company',
		details: 'Details',
		generalInfo: 'GENERAL INFORMATION',
		id: 'ID',
		estabilDate: 'Establishment Date',
		tradeNum: 'Trade License Number',
		regCountry: 'Registration Country',
		poa: 'POA',
		poaExp: 'POA Expiry',
		indemnVal: 'Professional Indemnity Value',
		indemnValExp: 'Professional Indemnity Value Expiry',
		updateSaved: 'UPDATES SAVED',
		edit: 'Edit',
		lastEdited: 'Last edited',
		draft: 'Draft',
		review: 'Review',
		approved: 'Approved',
		signed: 'Signed',
		archive: 'Archive',
		archived: 'Archived',
		putBack: 'Put back',
		type: 'Type',
		project: 'Project',
		openQueries: 'Open queries',
		comments: 'Comments',
		expiring: 'Expiring',
		autoRenewing: 'AUTO-RENEWING',
		newFolder: 'New folder',
		folderName: 'Folder name',
		addRemoveCollab: 'Add/remove collaborators',
		addedCollab: 'COLLABORATORS ADDED',
		confirm: 'Confirm',
		done: 'Done',
		deleteFile: 'DELETE FILE',
		wantDeleteFile: 'Are you sure you want to delete this file?',
		welcomeToNewProject: 'Welcome to the Project view. Add your first Project.',
		welcomeToNewTemplate: 'Welcome to the Template view. Add your first Template.',
		welcomeToNewCompany: 'Welcome to the Company view. Add your first Company.',
		deletedFiles: 'Deleted files',
		hide: 'Hide',
		show: 'Show',
		auto: 'Auto',
		renewing: 'renewing',
		view: 'View',
		favorites: 'Favourites',
		allFiles: 'All files',
		emptyProject: 'Your project is empty. Add your first document to your project.',
		searchResultsOnPage: 'SEARCH RESULTS',
		filters: 'Filters',
		documentType: 'DOCUMENT TYPE',
		clause: 'Clause',
		clauseText: 'Clause text',
		inTender: 'IN TENDER',
		statusToTender: 'Are you sure you want to change the document status to ',
		statusToTenderPartTwo: 'Queries will become anonymous and changes to the document will not be visible to external parties until the changes have been published.',
		statusInTenderToOther: 'Queries will no longer be anonymous and all changes will be viewable by external parties. Do you want to proceed?',
		statusInTenderConfirm: 'Queries will no longer be anonymous and all changes will be viewable by external parties. Do you want to proceed?',
		allPublishedTender: 'All published changes will become visible and queries will become anonymous.',
		dontAskTender: 'Don’t ask me again.',
		revert: 'REVERT',
		wantRevert: 'Are you sure you want to revert to Version ',
		publish: 'Publish',
		duplicate: 'Duplicate',
		moveTo: 'Move to',
		external: "External user",
		viewDetails: 'View details',
		timeline: 'Timeline',
		newQuery: 'New Query',
		newComment: 'New Comment',
		editorInitial: 'Initial Text',
		expandView: 'Expand view',
		totalQueries: 'Total queries',
		flagged: 'flagged',
		linkedTo: 'Linked to',
		makeIsResolved: 'Mark as resolved',
		unResolve: 'Unresolve',
		noQueries: 'No queries logged',
		noComments: 'No comments logged',
		noFolders: 'No folders to move',
		compareKey: 'Compare Key',
		lineAdded: 'This line was added to the current version.',
		lineRemoved: 'This line was removed from the current version.',
		lineChanged: 'Formatting was changed in the current version.',
		parties: 'PARTIES',
		autorities: 'SUPPLIER EXECUTION AUTHORITIES',
		contractType: 'CONTRACT TYPE',
		tags: 'TAGS',
		exec: 'EXECUTION',
		contractAuthor: 'CONTRACT AUTHOR',
		contractCurrency: 'CONTRACT CURRENCY',
		contractVal: 'CONTRACT VALUE',
		linkedDoc: 'LINKED DOCUMENTS',
		documents: 'documents',
		operInfo: 'OPERATIONAL INFORMATION',
		renType: 'RENEWAL TYPE',
		termLen: 'TERM LENGTH',
		expDate: 'EXPIRATION DATE',
		effDate: 'EFFECTIVE DATE',
		payTerm: 'PAYMENT TERM',
		contentInfo: 'CONTENT INFORMATION',
		pci: 'PCI',
		backCheck: 'BACKGROUND CHECK',
		divers: 'Supplier Diversity',
		exhib: 'NETWORK SECURITY EXHIBIT',
		today: 'Today',
		week: '1 week',
		month: '1 month',
		allTime: 'All time',
		print: 'Print Report',
		downloadReport: 'Download Report',
		collapseView: 'Collapse view',
		date: 'Date',
		query: 'Query',
		searchCollabs: 'Search for collaborators',
		searchQueries: 'Search queries',
		typeMessage: 'Type your message',
		timelineDos: {
			['comment.create'] : ' commented this document  ',
			['query.create'] : 'opened new query on this document',
			createdVersion : ' created this ',
			['document.name'] : ' changed document name to ',
			issue : ' opened an issue on ',
			['query.resolved'] : ' resolved a query',
			['document.status'] : ' changed status to '
		}

	},
	ar: {
		documentManager: 'حارس',
		dashboard: 'لوحة القيادة',
		projects: 'المشاريع',
		companies: 'الشركات',
		templates: 'القوالب',
		settings: 'الإعدادات',
		notifications: 'الإخطارات',
		viewAll: 'عرض الكل',
		editProfile: 'تحرير الملف الشخصي',
		signOut: 'خروج',
		signIn: 'تسجيل الدخول',
		language: 'اللغة',
		search: 'البحث',
		clearSearch: 'مسح البحث',
		resultsSearch: 'عمليات البحث الأخيرة',
		recentProjects: 'أحدث المشاريع',
		recentDocuments: 'المستندات الأخيرة',
		loginTitle: 'الرجاء إدخال بيانات الاعتماد الخاصة بك للمتابعة',
		recovery: 'استعادة كلمة السر',
		recoveryTitle: 'الرجاء إدخال البريد الإلكتروني الذي تستخدمه لحسابك',
		emailAddress: 'عنوان البريد الإلكتروني',
		password: 'كلمة المرور',
		forgot: 'هل نسيت كلمة المرور؟',
		backToLogin: 'العودة إلى تسجيل الدخول',
		submit: 'إرسال طلب',
		recoveryPush: 'تفقد بريدك الالكتروني من فضلك',
		statuses: {
			expired: 'منتهية الصلاحية',
			signed: 'التوقيع'
		},
		notificationsDos : {
			commented: 'علق على',
			logged: 'تسجيل دخول :استعلام',
			status: 'تم تغيير الحالة إلى',
			edited: 'محرر',
			uploaded: 'تم الرفع إلى',
			has: 'لديها'
		},
		allNotifications: 'كل الإخطارات',
		uploadNewPhoto: 'تحميل صورة جديدة',
		firstName: 'الاسم الأول',
		lastName: 'اسم العائلة',
		title: 'العنوان',
		company: 'الشركة',
		changePassword: 'تغيير كلمة المرور',
		sendPasswordLink: 'إرسال رابط إعادة تعيين كلمة السر',
		newPassword: 'كلمة المرور الجديدة',
		repeatNewPassword: 'تكرار كلمة المرور الجديدة',
		cancel: 'إلغاء',
		save: 'حفظ',
		profileUpdate: 'تحديث الملف الشخصي',
		profileEdit: 'تحرير ملف التعريف',
		dropYourFiles: 'أسقط ملفاتك هنا',
		convertFiles: 'تحويل الملفات',
		doYouWantConvert: 'spe تحويل الملفات إلى ملفات',
		dontAskConvert: 'لا تطلب مني تحويل الملفات مرة أخرى',
		noUpload: 'بدون تحميل',
		yesConvert: 'نعم ، قم بالتحويل',
		fileConvertingComplete: 'إكتمال تحويل الملف',
		fileUploadComplete: 'اكتمال رفع الملف',
		share: 'شارك',
		shareWithCollab: 'شارك مع المتعاونين',
		manage: 'إدارة',
		getLink: 'أحصل على الرابط',
		copyLink: 'نسخ الرابط',
		security: 'الأمان',
		fileShared: 'مشاركة الملف',
		linkCopiedTo: 'تم نسخ الارتباط إلى  الحافظة',
		createNewFile: 'إنشاء ملف جديد',
		create: 'إنشاء',
		rename: 'إعادة تسمية',
		delete: 'حذف',
		uploadFiles: 'تحميل الملفات',
		newFile: 'ملف جديد',
		newDocument: 'مستند جديد',
		download: 'تنزيل',
		restricted: 'مقيد (المتعاونون فقط)',
		passwordProtected: 'حماية كلمة المرور',
		newProject: 'مشروع جديد',
		newTemplate: 'مشروع جديد',
		newCompany: 'مشروع جديد',
		details: 'التفاصيل',
		generalInfo: 'معلومات عامة',
		id: 'الهوية',
		estabilDate: 'تاريخ التأسيس',
		tradeNum: 'رقم الرخصة التجارية',
		regCountry: 'بلد التسجيل',
		poa: 'توكيل',
		poaExp: 'انتهاء صلاحية التوكيل',
		indemnVal: 'قيمة التعويض المهني',
		indemnValExp: 'انتهاء صلاحية قيمة التعويض المهني',
		updateSaved: 'التحديثات المحفوظة',
		edit: 'تحرير',
		lastEdited: 'التعديل الأخير',
		draft: 'مسودة',
		review: 'مراجعة',
		approved: 'معتمد',
		signed: 'التوقيع',
		archive: 'الأرشيف',
		archived: 'مؤرشف',
		putBack: 'ضعها بالخلف',
		type: 'النوع',
		project: 'المشروع',
		openQueries: 'فتح الاستعلامات',
		comments: 'التعليقات',
		expiring: 'تنتهي الصلاحية',
		autoRenewing: 'التجديد التلقائي',
		newFolder: 'مجلد جديد',
		folderName: 'اسم المجلد',
		addRemoveCollab: 'إضافة / إزالة المتعاونين',
		addedCollab: 'متعاونون مضافون',
		confirm: 'تأكيد',
		done: 'تأكيد',
		deleteFile: 'حذف الملف',
		wantDeleteFile: 'هل أنت متأكد أنك تريد حذف هذا الملف؟',
		welcomeToNewProject: 'مرحباً بك في طريقة عرض المشروع. أضف مشروعك الأول',
		welcomeToNewTemplate: 'مرحباً بك في طريقة عرض المشروع. أضف مشروعك الأول',
		welcomeToNewCompany: 'مرحباً بك في طريقة عرض المشروع. أضف مشروعك الأول',
		deletedFiles: 'الملفات المحذوفة',
		hide: 'إخفاء',
		show: 'عرض',
		auto: 'تلقائي',
		renewing: 'التجديد',
		view: 'عرض',
		favorites: 'المفضلة',
		allFiles: 'كل الملفات',
		emptyProject: 'مشروعك فارغ. أضف مستندك الأول إلى مشروعك',
		searchResultsOnPage: 'نتائج البحث',
		filters: 'عوامل التصفية',
		documentType: 'نوع المستند',
		clause: 'الشرط',
		clauseText: 'نص جملة',
		inTender: 'في العطاء',
		statusToTender: 'هل أنت متأكد أنك تريد تغيير حالة المستند إلى',
		allPublishedTender: 'ستصبح جميع التغييرات المنشورة مرئية وستصبح الاستعلامات مجهولة',
		dontAskTender: 'لا تسألني مرة أخرى',
		revert: 'العودة',
		wantRevert: 'هل أنت متأكد أنك تريد العودة إلى الإصدار',
		publish: 'نشر',
		duplicate: 'مكرر',
		moveTo: 'الانتقال إلى',
		external: 'مستخدم خارجي',
		viewDetails: 'عرض التفاصيل',
		timeline: 'الجدول الزمني',
		newQuery: 'استعلام جديد',
		newComment: 'تعليق جديد',
		editorInitial: 'محرر البداية',
		expandView: 'توسيع العرض',
		totalQueries: 'إجمالي الاستعلامات',
		flagged: 'تم وضع علامة عليه',
		linkedTo: 'مرتبط بـ',
		makeIsResolved: 'وضع علامة تم الحل',
		noQueries: 'لم يتم تسجيل استعلامات',
		noComments: 'لا يوجد تعليقات مسجلة',
		compareKey: 'قارن المفتاح',
		lineAdded: 'تمت إضافة هذا السطر إلى الإصدار الحالي.',
		lineRemoved: 'تمت إزالة هذا السطر من الإصدار الحالي.',
		lineChanged: 'تم تغيير التنسيق في الإصدار الحالي.',
		parties: 'الأطراف',
		autorities: 'سلطات تنفيذ الموردين',
		contractType: 'نوع العقد',
		tags: 'العلامات',
		exec: 'التنفيذ',
		contractAuthor: 'مؤلف العقد',
		contractCurrency: 'عملة العقد',
		contractVal: 'قيمة العقد',
		linkedDoc: 'وثائق مرتبطة',
		operInfo: 'المعلومات التشغيلية',
		renType: 'نوع التجديد',
		termLen: 'طول المدة',
		expDate: 'تاريخ الإنتهاء',
		effDate: 'تاريخ النفاذ',
		payTerm: 'شروط الدفع',
		contentInfo: 'معلومات المحتوى',
		pci: 'بي آي سي',
		backCheck: 'Bفحص الخلفية',
		divers: 'تنوع الموردين',
		exhib: 'معرض أمن الشبكة',
		today: 'اليوم',
		week: 'أسبوع 1',
		month: 'شهر 1',
		allTime: 'كل الوقت',
		print: 'اطبع تقرير',
		downloadReport: 'تنزيل التقرير',
		collapseView: 'تصغير العرض',
		date: 'التاريخ',
		query: 'استعلام',
		searchCollabs: 'ابحث عن المتعاونين',
		searchQueries: 'استعلامات البحث',
		typeMessage: 'اكتب رسالتك',
		timelineDos: {
			comment : 'علق على',
			createdVersion : 'أنشأ هذا',
			changeName : 'تم تغيير اسم المستند إلى',
			issue : 'فتحت قضية على',
			resolveComment : 'حل تعليق',
			changedStatus : 'تغير الوضع إلى'
		}
	}
}

export function getLang (){
	return localStorage.getItem('lang')
}