import React, { useContext, useRef } from 'react'
import { Link } from 'react-router-dom'

import { AppContext } from '../../context/AppContext'

import {ReactComponent as Upload} from '../../assets/img/download.svg'
import {ReactComponent as Share} from '../../assets/img/share.svg'
import {ReactComponent as Plus} from '../../assets/img/plus.svg'
import {ReactComponent as DownloadMobile} from '../../assets/img/downloadmobile.svg'


import './MobileOptions.scss'
import { useEffect } from 'react'

function MobileOptions({isOpen, setIsOpen, isNewProject = false, isOne = true, isUpload = false, isNewFile = false, isNewFolder = false, newFolderName, isDownload = false, isShare = false, line = false}){
	const options = useRef()
	
	let isMobile = useContext(AppContext).isMobile

	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang

	useEffect(() => {
		document.querySelectorAll('.nav-mobile-item').forEach((elem, index) => {
			if(index === (line - 1)){
				elem.style.paddingBottom = '20px'
				elem.style.borderBottom = '1px solid #E4E7EF'
				elem.style.marginBottom = '10px'
			}
		})
		//eslint-disable-next-line
	}, [])

	return (
		<>
			{isMobile ? (
				<div className={"nav-mobile " + (activeLang === 'ar' ? 'nav-mobile-arabic ' : '') + (isOpen ? 'nav-mobile-active ' : '')} ref={options} onClick={event => {
					if(event.clientY < options.current.getBoundingClientRect().top){
						setIsOpen(false)
					}
				}}>
					{isNewProject ? (
						<Link to='/projects/new'>
							<div className={"nav-mobile-item " + (!isOne ? 'project' : '')} onClick={() => {
								setIsOpen(false)
							}}> <Plus className='plus'/> {lang.newProject}</div>
						</Link>
					) : ''}
					{isUpload ? <label htmlFor={isUpload} className="nav-mobile-item"> <Upload className={'upload'}/> {lang.uploadFiles}</label> : ''}
					{isNewFile ? <div className="nav-mobile-item" onClick={() => {
						setIsOpen(false)
						isNewFile(true)
					}}> <Plus className='plus'/> {lang.newFile}</div> : ''}
					{isNewFolder ? (
						<div className="nav-mobile-item" onClick={() => {
							setIsOpen(false)
							isNewFolder(true)
							newFolderName.current.value = 'Untitled folder'
							setTimeout(() => {
								newFolderName.current.focus()
								newFolderName.current.select()
							}, 100)
						}}> <Plus className='plus'/> {lang.newFolder}</div>
					) : ''}
					{isShare ? (
						<div className="nav-mobile-item"  onClick={() => {
							setIsOpen(false)
							isShare(true)
						}}> <Share/> {lang.share}</div>
					) : ''}
				</div>
			) : ''}
		</>
	)
}

export default MobileOptions