import {month} from './month'
import {getFolders, getCompanyDetails} from './api'
import {convertFolders, convertDetails} from './essence'
export async function selectItemList(setList, list, id){
	let foldersData;
	let detailsData;
	if(window.location.pathname.includes('/projects')){
		foldersData = await getFolders(id)
	} else if (window.location.pathname.includes('/companies')){
		detailsData = await getCompanyDetails(id)
	}
	setList(list.map((item) => {
		if(id === item.id){
			return {
				...item,
				active: true,
				folders: window.location.pathname.includes('/projects') ? convertFolders(foldersData.data) : [],
				details: window.location.pathname.includes('/companies') ? convertDetails(detailsData.details) : []
			}
		} 
		return {
			...item,
			active: false
		}
	}))
	return false
}

export function addItemToFavoritesList(setList, id, setPushText, setPush){
	setList(list => {
		return list.map(item => {
			if(id === item._id){
				if(!item.favourite){
					setPushText('ADDED TO FAVOURITES')
					setPush(true)
				}
				return {
					...item,
					favourite: !item.favourite
				}
			}
			return item
		})
	})
	setTimeout(() => {
		setPush(false)
	}, 1200)
	return false
}

export function changeName (setList, setItem, item, value) {
	if(setList){
		setList(list => {
			return list.map(listItem => {
				if(item.id === listItem._id){
					return {
						...listItem,
						name: value
					}
				}
				return listItem
			})
		})
	}
	setItem(item => ({...item, name: value}))
}

export function addToArchive(setList, setItem, item, setPush, setPushText) {
	setList(list => {
		return list.map(listItem => {
			if(item.id === listItem._id){
				if(!listItem.deleted_at){
					setPushText('ADDED TO ARCHIVED')
					setPush(true)
				}
				return {
					...listItem,
					archived: listItem.deleted_at ? false : true,
					deleted_at: listItem.deleted_at ? null : Date.now()
				}
			}
			return listItem
		})
	})
	setItem({
		...item,
		archived: item.archived ? false : true,
		deleted_at: item.archived ? null : Date.now()
	})
	setTimeout(() => {
		setPush(false)
	}, 1200)
}

export function dateConvert (data, flag) {
	if (data === null){
		return null
	}
	let tempDateY;
	let tempDateM;
	let tempDateD;
	let tempDate
	if (flag === 's'){
		if(!data.split('T')[0]){
			return data
		}
		[tempDateY, tempDateM, tempDateD] = data.split('T')[0].split('-')
		return tempDateD + ' ' + month[+tempDateM].name + ' ' + tempDateY
	} else if (flag === 'd'){
		tempDate = new Date(data)
		return tempDate.getDate() + ' ' + (tempDate.getMonth() !== null && month[tempDate.getMonth()]?.name ? month[tempDate.getMonth()].name : '')+ ' ' + tempDate.getFullYear()
	} else if (flag === 'sd'){
		tempDate = new Date(data)
		return tempDate.getDate() + ' ' + (tempDate.getMonth() !== null && month[tempDate.getMonth()]?.abbreviation ? month[tempDate.getMonth()].abbreviation : '') + ' ' + tempDate.getFullYear()
	} else if (flag === 'dt'){
		tempDate = new Date(data)
		return (tempDate.getHours() < 10 ? '0' + tempDate.getHours() : tempDate.getHours()) + ':' + (tempDate.getMinutes() < 10 ? '0' + tempDate.getMinutes() : tempDate.getMinutes()) + ' • ' + tempDate.getDate() + '/' + (tempDate.getMonth() + 1) + '/' + tempDate.getFullYear()
	} else if (flag === 'c'){
		tempDate = new Date(data)
		return tempDate.getDate() + ' ' + month[tempDate.getMonth()].name
	} else if (flag === 'ago'){
		let ago = Date.now() - new Date(data).getTime()
		let sec = ago / 1000
		let min = (sec / 60)
		let hours = (min / 60)
		let days = (hours / 24)
		if(min < 60){
			if(min < 0){
				min = 0
			}
			return `${Math.floor(min)} min ago`
		}
		if(hours < 24){
			return `${Math.floor(hours)} hours ago`
		}
		return `${Math.floor(days)} days ago`
	} else if (flag === 't'){
		tempDate = new Date(data)
		return tempDate.getHours() + ':' + tempDate.getMinutes()
	} else if (flag === 'ts'){
		tempDate = new Date(data)
		return tempDate.getTime()
	} else if (flag === 'dslash'){
		tempDate = new Date(data)
		return (tempDate.getDate() + '/' + (tempDate.getMonth() + 1) + '/' + tempDate.getFullYear())
	} else if (flag === 'expiry'){
		tempDate = new Date(data)
		let now = Date.now()

		let expiry = tempDate.getTime() - now
		let sec = expiry / 1000
		let min = (sec / 60)
		let hours = (min / 60)
		let days = Math.round(hours / 24)
		let month = Math.round(days / 30)
		let className
		let value
		let type
		// console.log(days, month)	
		if(days >= 0 && days <= 30){
			className = 'high'
			value = days
			//console.log(days, tempDate.getDay(), new Date(now).getDay())
			if(days === 0 && tempDate.getDay() === new Date(now).getDay()){
				type = 'TODAY'
			} else if (days === 0 && tempDate.getDay() - new Date(now).getDay() === 1){
				value = 1
				type = 'DAY'
			} else if (days === 1){
				type = 'DAY'
			} else if (days >= 2){
				type = 'DAYS'
			}
		} else if (month >= 1 && month <= 5) {
			className = 'middle'
			value = month
			if(month === 1){
				type = 'MO'
			} else if (month > 1){
				type = 'MO.S'
			}
		} else if (month >= 6){
			className = 'low'
			value = month
			type = 'MO.S'
		}
		// console.log({
		// 	class: className,
		// 	value: value,
		// 	type : type
		// })
		return {
			class: className,
			value: type === 'TODAY' ? '' : value,
			type : type
		}
	}
}

export function docConvert (doc, type = null){
	return {
		...doc,
		id: doc._id,
		file: format(doc.file_type) || 'spe',
		name: doc.name.split('.')[0],
		expiry: doc.details && doc.details.filter(detail => detail.field.name === 'Expiration Date')[0] ? doc.details.filter(detail => detail.field.name === 'Expiration Date')[0].value : "None",
		type: doc.type || 'Other',
		editedBy: doc.edited_by && typeof doc.edited_by  !== 'string' ? {...doc.edited_by, name: doc.edited_by?.first_name, lastName: doc.edited_by?.last_name, bgc: randomColor()} : !window.location.pathname.includes('templates') ? {...doc.owner_id, name: doc.owner_id?.first_name, lastName: doc.owner_id?.last_name, bgc: randomColor()} : {
			
		},
		favorite: doc.favorite,
		editedDate: dateConvert(doc.updated_at || doc.created_at, 'ago'),
		status: doc.status ? doc.status.toLowerCase().replace('_', ' ') : 'draft',
		users: doc.users?.map(item => {
			return {
				...item,
				isOpenSelect: false,
				bgc: randomColor()
			}
			return {
				...item,
				fullName: item.first_name + ' ' + item.last_name,
				email : item.email,
				id: item._id,
				avatar: item.picture,
				bgc: randomColor()
			}
		}),
		isOpenStatusList : false,
		isOpenOptions: false,
		isSelect: false,
		onDrag: false,
		isLinked : doc.parent_document,
		linkerId: null,
		isFavorite: false,
		isDelete: doc.deleted_at ? true : false,
		isRevertMenu: false,
		onMove: false,
		potentialMove: false,
		isOpenCalendar: false,
		isOpenType: false,
		isRename : false,
		favoriteId: '',
		deleteId: '',
		linkedList :  doc.linked_documents ? doc.linked_documents : []
	}
}

export function randomColor(){
	const colors = [
		'#84e1eb',
		'#d2d6df',
		'#71c56d',
		'#af88ff'
	]
	
	let rand = Math.floor(0 + Math.random() * (colors.length - 0))


	let result = colors[rand];
	return result
}

export function format (inner){
	if (inner === undefined){
		return 'spe'
	} else if (inner !== 'xlsx' && inner !== 'xls' && inner !== 'docx' && inner !== 'doc' && inner !== 'pdf' && inner !== 'spe'){
		return 'other'
	} else {
		return  inner
	}
}