import {decodeToken} from "./decodeToken";
import Cookies from 'universal-cookie';
import {logout} from "../auth";
const cookies = new Cookies();

export default function checkUserToken() {
    const sessionCookie = cookies.get('SESSION');
    if(!sessionCookie) {
        logout();
        return false;
    }
    if (localStorage.Authorization) {
        const decoded = decodeToken();
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            logout();
            return false;
        }
        return true;
    }
    return false;
}