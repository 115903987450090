import React, { useContext } from 'react'
import Button from '../../Button/Button'
import {AppContext} from '../../../context/AppContext'
import {ReactComponent as Close} from '../../../assets/img/close.svg'
import './DeleteFile.scss'
import './DeleteFileMedia.scss'

function DeleteFile({setPush, setPushText, fileToDelete, setDeleteFile, setItem, item, deleteDoc, deleteFile, unlinkFunction}){
	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang
	return (
		<div className={"delete-file-popup-wrapper " + (deleteFile ? 'delete-file-popup-wrapper-active ' : '') + (activeLang === 'ar' ? 'delete-file-popup-wrapper-arabic' : '')} onClick={(event) => {
			if(event.target.classList.contains('delete-file-popup-wrapper-active')){
				setDeleteFile(false)
			}
		}}>
			<div className="delete-file-popup">
				<div className="delete-file-popup-header">
					<div className="header-title">{lang.deleteFile}</div>
					<Close onClick={() => setDeleteFile(false)}/>
				</div>
				<div className="delete-file-popup-body">
					<div className="delete-file-question">{lang.wantDeleteFile}</div>
					<div className="delete-file-item">
						{fileToDelete ? (
							<>
								<img src={require(`../../../assets/img/files/${fileToDelete.file}.svg`)} alt=""/>
								<div className="delete-file-name">{fileToDelete.name}</div>
							</>
						) : ''}
					</div>
					<div className="delete-btns">
						<Button color={'white'} padding={12} margin={10} onClick={() => setDeleteFile(false)}>{lang.cancel}</Button>
						<Button color={'blue'} padding={12} onClick={() => {
							if(window.location.pathname.includes('/templates')){
								unlinkFunction(fileToDelete.group_id, fileToDelete.id)
							} else {
								unlinkFunction(item.id, fileToDelete.id)
							}
							if(fileToDelete) {
								deleteDoc(setItem, fileToDelete.id, fileToDelete, setDeleteFile, setPush, setPushText)
							}
						}}>{lang.delete}</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DeleteFile