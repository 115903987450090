import React, {useState, useRef, useEffect, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import CalendarComponent from '../CalendarComponent/CalendarComponent'
import {
	dateConvert,
	format
} from '../../utilites/appfunctions'
import {
	selectDoc,
	selectAll,
	linkDoc,
	openOptions,
	changeStatus,
	changeDocStatus,
	toDeleteDoc,
	addToFavorites,
	selectFolder,
	moveToFolder,
	onMoveDoc,
	changeType,
	changeDocType,
	openCalendar,
	changeExpiry,
	setIsRename,
	renameDoc,
	openOptionsFolder,
	deletefolder,
	setIsRenameFolder,
	renameFolder,
	convertDoc,
	addDocuments,
	unlinkDoc,
	restoreDocTable,
	restoreFolder,
	docSort
} from '../../utilites/table'

import {
	updateDocumentStatus,
	updateTemplateStatus,
	updateTemplateName,
	updateDocument,
	deleteFolderFromProject,
	updateFolderName,
	convertDocToSpe,
	updateDocumentType,
	cloneDocument,
	linkDocToProject,
	linkDocToDoc,
	unlinkDocFromDoc,
	getDocument,
	restoreDoc,
	restoreFolderInProject,
	getDocumentDetails, cloneTemplate, getPicture
} from '../../utilites/api'

import {AppContext} from '../../context/AppContext'
import {ReactComponent as Check} from '../../assets/img/check.svg'
import {ReactComponent as Download} from '../../assets/img/downloadmobile.svg'
import {ReactComponent as Share} from '../../assets/img/share.svg'
import {ReactComponent as Convert} from '../../assets/img/convert.svg'
// import {ReactComponent as Linked} from '../../assets/img/linked.svg'
import {ReactComponent as Arrow} from '../../assets/img/arrow.svg'
import './DocTable.scss'
import './DocTableMedia.scss'
import dislinked from "../../assets/img/dislinked.svg"
import {TenderSubmitPopup} from "../Document/Popups/TenderSubmitPopup";
import {TenderToOtherPopup} from "../Document/Popups/TenderToOtherPopup";
import html2canvas from "html2canvas";

function DocTable({setItem, itemPar, setList, setNeedUpdateProjectList, list, setPush, setPushText, docFilter = null, parentFolder = undefined, setShare = () => false,  selectRef, documents = [], isEdit, type, folders = [], templates, viewDocsType, setFileToDelete, setDeleteFile,  setMoveTo, groupTemplateId = false, setIsFolderOpen = () => false, isFolderOpen = {path: '/', prevPath: '/'}, actualTypes = []}){
	let statusList = [
		'review',
		'in tender',
		'approved',
		'signed',
		'draft',
	]
	let [isOpenTenderPopup, setIsOpenTenderPopup] = useState(false);
	let [isOpenChangeTenderPopup, setIsOpenChangeTenderPopup] = useState(false);
	let [document, setDocument] = useState(null);
	let [dontAsk, setDontAsk] = useState(false);
	let [selectedStatus, setSelectedStatus] = useState(null);

	const checkDocumentStatus = (newStatus, item) => {
		if (newStatus.toLowerCase() === 'in tender') {
			setSelectedStatus(newStatus);
			setIsOpenTenderPopup(true)
			setDocument(item);
			updateDocumentStatus(item.id, "in_tender");
			return false
		}
		// if (item.status === 'in tender') {
		// 	setSelectedStatus(newStatus);
		// 	setIsOpenChangeTenderPopup(true)
		// 	setDocument(item);
		// 	return false;
		// }
		updateDocumentStatus(item.id, newStatus);
	}

	if(window.location.pathname.includes('templates')){
		statusList = [
			'draft', 
			'published'
		]
	}
	const [dragFile, setDragFile] = useState(null)
	const [mouse, setMouse] = useState({})

	const drag = useRef()

	const history = useHistory()

	const { activeLang , lang, isMobile} = useContext(AppContext)

	const optionsDocRef = useRef([])

	const [filterVar, setFilterVar] = useState({
		n: 'u',
		s: 'u',
		t: 'u',
		f: 'u',
		u: 'u'
	})

	const [linkedDocs, setLinkedDocs] = useState([])


	useEffect(() => {
		optionsDocRef.current = optionsDocRef.current.slice(0, documents.length + folders.length)
		//eslint-disable-next-line
	}, [optionsDocRef])

	useEffect( () => {
		const tmp = documents ? documents.filter((item => item.isLinked)) : []
		setLinkedDocs(tmp)
		if(JSON.parse(localStorage.getItem('folder'))?.project !== itemPar?.id){
			setIsFolderOpen({path: '/', prevPath: '/'})
			localStorage.setItem('folder', JSON.stringify({path: '/', prevPath: '/', project: itemPar?.id}))
		}
	}, [itemPar])

	return(
		<>
		{/* {isOpenTenderPopup ?
				<TenderSubmitPopup
					isOpenTenderPopup={isOpenTenderPopup}
					setIsOpenTenderPopup={setIsOpenTenderPopup}
					setDocument={setDocument}
					setDontAsk={setDontAsk}
					dontAsk={dontAsk}
					document={document}
				/> : ""}
			{isOpenChangeTenderPopup ?
				<TenderToOtherPopup
					isOpenChangeTenderPopup={isOpenChangeTenderPopup}
					setIsOpenChangeTenderPopup={setIsOpenChangeTenderPopup}
					setDocument={setDocument}
					setDontAsk={setDontAsk}
					dontAsk={dontAsk}
					document={document}
					selectedStatus={selectedStatus}
				/> : ""} */}
		<div className={"documents-table-header " + (viewDocsType === 1 ? 'documents-table-header-block ' : '') + (templates ? 'documents-table-header-templates ' : '') + (activeLang === 'ar' ? 'documents-table-header-arabic' : '')}>
			<div className={"checkbox " + ((documents ? (documents.filter(doc => doc.isSelect).length > 0 ? 'checkbox-all ' : '') : '') + (folders ? (folders.filter(doc => doc.isSelect).length > 0 ? 'checkbox-all' : '') : ''))} onClick={() => {
				if(documents.filter(doc => doc.isSelect).length === 0 && folders.filter(folder => folder.isSelect).length === 0 ){
					selectAll(setItem, true)
				} else {
					selectAll(setItem, false)
				}
			}}>
				<span> </span>
			</div>
			<div className="table-header-item" onClick={() => {
				setFilterVar(prev => {
					if(prev.n === 'u'){
						docSort(setItem, 'name', 'd')
						return {
							...prev,
							n: 'd'
						}
					} else {
						docSort(setItem, 'name', 'u')
						return {
							...prev,
							n: 'u'
						}
					}
				})
			}}>
				<span>Title</span>
				<Arrow/>
			</div>
			<div className="table-header-item" onClick={() => {
				setFilterVar(prev => {
					if(prev.s === 'u'){
						docSort(setItem, window.location.pathname.includes('companies') ? 'status' : 'status', 'd')
						return {
							...prev,
							s: 'd'
						}
					} else {
						docSort(setItem, window.location.pathname.includes('companies') ? 'status' : 'status', 'u')
						return {
							...prev,
							s: 'u'
						}
					}
				})
			}}>
				<span>{window.location.pathname.includes('companies') ? 'Expiry' : 'Status'}</span>
				<Arrow/>
			</div>
			{!templates ? (
				<div className="table-header-item" onClick={() => {
					setFilterVar(prev => {
						if(prev.t === 'u'){
							docSort(setItem, 'type', 'd')
							return {
								...prev,
								t: 'd'
							}
						} else {
							docSort(setItem, 'type', 'u')
							return {
								...prev,
								t: 'u'
							}
						}
					})
				}}>
					<span>Type</span>
					<Arrow/>
				</div>
			) : ''}
			<div className="table-header-item" onClick={() => {
				setFilterVar(prev => {
					if(prev.f === 'u'){
						docSort(setItem, 'edited_by', 'd')
						return {
							...prev,
							f: 'd'
						}
					} else {
						docSort(setItem, 'edited_by', 'u')
						return {
							...prev,
							f: 'u'
						}
					}
				})
			}}>
				<span>Edited by</span>
				<Arrow/>
			</div>
			<div className="table-header-item" onClick={() => {
				setFilterVar(prev => {
					if(prev.u === 'u'){
						docSort(setItem, 'updated_at', 'd')
						return {
							...prev,
							u: 'd'
						}
					} else {
						docSort(setItem, 'updated_at', 'u')
						return {
							...prev,
							u: 'u'
						}
					}
				})
			}}>
				<span>Last edited</span>
				<Arrow/>
			</div>
		</div>
		<div className={"documents-list " + (viewDocsType === 1 ? 'documents-list-block ' : '')  + (activeLang === 'ar' ? 'documents-list-arabic' : '')}
			onMouseUp={() => {
				setDragFile(null)
			}} 
			onMouseMove={(event) => {
				if(dragFile){
					setMouse({
						x : event.clientX,
						y : event.clientY
					})
				}
			}}
			onMouseLeave={() => {
				setDragFile(null)
			}}
			>
			<div ref={drag} className={"drag-element " + (dragFile ? "drag-element-active" : '')} style={{left: (mouse.x ? mouse.x : 0) + 20 , top: (mouse.y ? mouse.y : 0)  - 15}}> 
				{dragFile ? (
					<>
						{dragFile.typeMove === 'folders' ? (
							<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M9.17189 3.25001L4.33333 3.25C2.63368 3.25 1.25 4.60708 1.25 6.28847V18.7115C1.25 20.3929 2.63368 21.75 4.33333 21.75H19.6667C21.3663 21.75 22.75 20.3929 22.75 18.7115V8.90385L22.7448 8.72499C22.6508 7.12726 21.3056 5.86539 19.6667 5.86539L12.203 5.865L11.6356 4.74626C11.167 3.82566 10.2126 3.248 9.17189 3.25001ZM4.33333 4.75H9.17333C9.65289 4.74908 10.0879 5.01239 10.2983 5.42562L10.8911 6.5949C11.1369 7.07342 11.6301 7.36903 12.1651 7.36537L19.6667 7.36539C20.5443 7.36539 21.25 8.05748 21.25 8.90385V18.7115C21.25 19.5579 20.5443 20.25 19.6667 20.25H4.33333C3.45566 20.25 2.75 19.5579 2.75 18.7115V6.28847C2.75 5.44209 3.45566 4.75 4.33333 4.75ZM12 15C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15ZM17 14C17 14.5523 16.5523 15 16 15C15.4477 15 15 14.5523 15 14C15 13.4477 15.4477 13 16 13C16.5523 13 17 13.4477 17 14ZM8 15C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15Z" fill="#2E384D"/>
							</svg>
						) : <img src={require(`../../assets/img/files/${dragFile.file}.svg`)} alt=""/>}
						<div className="name">{dragFile.name}</div>
					</>
				) : ''}
			</div>
			{/* <img src={require('../../assets/img/linked.png')} alt="" className={"drag-element-linked " style={{left: (mouse.x ? mouse.x : 0) + 20 , top: (mouse.y ? mouse.y : 0)  - 15}} + (linked ? "drag-element-linked-active" : '')} style={{left: (mouse.x ? mouse.x : 0) - 30 , top: (mouse.y ? mouse.y : 0) - 18}}/> */}
			{isFolderOpen && isFolderOpen.path !== '/' ? (
				<div className="document-item folder"
					onDoubleClick={() => {
						localStorage.setItem('folder', JSON.stringify({path: isFolderOpen.prevPath, prevPath: parentFolder === itemPar.id || parentFolder === undefined ? '/' : parentFolder, project: itemPar.id}))
						setIsFolderOpen({path: isFolderOpen.prevPath, prevPath: parentFolder === itemPar.id || parentFolder === undefined ? '/' : parentFolder})
					}}
					onMouseUp={() => {
						if(dragFile && window.location.pathname.includes('/projects')){
							moveToFolder(setItem, itemPar, dragFile, {id: isFolderOpen.prevPath}, setPush, setPushText, isFolderOpen.path, dragFile.typeMove)
						}
					}}
				>
					<div className="document-item-title">...</div>
				</div>
			) : ''}
			{!templates && folders ? folders.map((folder, index) => {
				return (
					<div 
						className={"document-item folder " + (dragFile && dragFile.id !== folder.id ? 'document-item-dragged' : '')} 
						key={index} 
						onMouseDown={event => {
							setDragFile({...folder, typeMove: 'folders'})
							setMouse({
								x : event.clientX,
								y : event.clientY
							})
						}}
						onMouseUp={() => {
							if(dragFile && window.location.pathname.includes('/projects')  && dragFile.id !== folder.id){
								moveToFolder(setItem, itemPar, dragFile, folder, setPush, setPushText, isFolderOpen.path, dragFile.typeMove)
							}
						}} 
						onDoubleClick={() => {
							localStorage.setItem('folder', JSON.stringify({path: folder.id, prevPath: folder.parent_id === itemPar.id || folder.parent_id === undefined ? '/' : folder.parent_id, project: itemPar.id}))
							setIsFolderOpen({path: folder.id, prevPath: folder.parent_id === itemPar.id || folder.parent_id === undefined ? '/' : folder.parent_id})
						}}
						ref={folder => optionsDocRef.current[documents.length + index] = folder}
					>
						<div className={"checkbox " + (folder.isSelect ? 'checkbox-active' : '')} onClick={() => {
								selectFolder(setItem, folder.id)
							}}>
							<Check/>
						</div>
						<div className="document-item-title">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M9.17189 3.25001L4.33333 3.25C2.63368 3.25 1.25 4.60708 1.25 6.28847V18.7115C1.25 20.3929 2.63368 21.75 4.33333 21.75H19.6667C21.3663 21.75 22.75 20.3929 22.75 18.7115V8.90385L22.7448 8.72499C22.6508 7.12726 21.3056 5.86539 19.6667 5.86539L12.203 5.865L11.6356 4.74626C11.167 3.82566 10.2126 3.248 9.17189 3.25001ZM4.33333 4.75H9.17333C9.65289 4.74908 10.0879 5.01239 10.2983 5.42562L10.8911 6.5949C11.1369 7.07342 11.6301 7.36903 12.1651 7.36537L19.6667 7.36539C20.5443 7.36539 21.25 8.05748 21.25 8.90385V18.7115C21.25 19.5579 20.5443 20.25 19.6667 20.25H4.33333C3.45566 20.25 2.75 19.5579 2.75 18.7115V6.28847C2.75 5.44209 3.45566 4.75 4.33333 4.75ZM12 15C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15ZM17 14C17 14.5523 16.5523 15 16 15C15.4477 15 15 14.5523 15 14C15 13.4477 15.4477 13 16 13C16.5523 13 17 13.4477 17 14ZM8 15C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15Z" fill="#2E384D"/>
							</svg>

							{!folder.isRename ? (
								<span className={'document-item-name'}>{folder.name}</span>
							) : (
								<input type="text" name={folder.id} className={"document-item-name " + (folder.isRename ? 'document-item-name-rename' : '')} defaultValue={folder.name} 
									readOnly={!folder.isRename}
									onBlur={(event) => {
										renameFolder(setItem, folder.id, event.target.value)
										updateFolderName(itemPar.id, folder.id, event.target.value)
									}}
									onKeyDown={event => {
										if(event.keyCode === 13){
											renameFolder(setItem, folder.id, event.target.value)
											updateFolderName(itemPar.id, folder.id, event.target.value)
										}
									}}
								/>
							)}
						</div>
						{folder.isOpenOptions && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
							if (folder.isOpenOptions) {
								event.stopPropagation()
								changeStatus(setItem, folder.id, 'folders', false, isFolderOpen.path, optionsDocRef.current[index])
							}
						}}/>) : ''}
						<div
							className={"document-item-options " + (folder.isOpenOptions ? 'document-item-options-active ' : '') + (folder.isRevertMenu ? 'document-item-options-revert' : '')}
							onClick={event => {
								event.stopPropagation()
								openOptionsFolder(setItem, folder.id, optionsDocRef.current[documents.length + index], isMobile)
							}}>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
								<path fillRule="evenodd" clipRule="evenodd" d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z" fill={folder.isOpenOptions ? "#2E5BFF" : "#2E384D"}/>
							</svg>
							<div className={"options-list "}>
								<svg width="38" height="21" className="setting-arrow" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.11237 20.5L18.6402 0.753198L36.1679 20.5H1.11237Z" fill="#ffffff" />
								</svg>
								<div className="option-item" onClick={event => {
										event.stopPropagation()
										setIsRenameFolder(setItem, folder.id)
										setTimeout(() => {
											optionsDocRef.current[documents.length + index].querySelector('.document-item-name').focus()
											optionsDocRef.current[documents.length + index].querySelector('.document-item-name').select()
										}, 500)
									}}>Rename</div>
								<div className="option-item" onClick={event => {
									event.stopPropagation()
									if (!folder.deleted_at) {
										deleteFolderFromProject(itemPar.id, folder.id)
											.then(() => {
												deletefolder(setItem, folder.id)
											})
									} else {
										restoreFolderInProject(itemPar.id, folder.id)
											.then(() => {
												restoreFolder(setItem, folder.id);
											})
									}
								}}>{folder.deleted_at ? "Restore" : "Delete"}</div>
								<div className="option-item" onClick={event => {
										event.stopPropagation()
										onMoveDoc(setItem, folder.id, 'folders')
										if(!templates){
											setMoveTo(true)
										}
									}}>Move to</div>
							</div>
						</div>
					</div>
				)
			}) : ''}
			{documents ? documents.map((item, index) => {
				if(!window.location.pathname.includes('templates')){
					let renewing = item.details.filter(detail => detail.field.name === 'Renewal Type')[0]
					let expiration = item.details.filter(detail => detail.field.name === 'Expiration Date')[0]
					if(docFilter === 'renewing' && (renewing === undefined || renewing.value === null || renewing.value === 'non-automatic') && (expiration === undefined || expiration.value === null || expiration.value < Date.now())){
						return false
					} else if (docFilter === 'expiring' && (expiration === undefined || expiration.value === null || expiration.value < Date.now() || expiration.value > Date.now() + 604800000)) {
						return false
					} else if (docFilter === 'expired' && (expiration === undefined || expiration.value === null || expiration.value > Date.now())) {
						return false
					} else if ( docFilter !== null && docFilter !== 'renewing' && docFilter !== 'expiring' && docFilter !== 'expired' && item.status !== docFilter){
						return false
					}
				}
				if(!item.isLinked){
					return (
						<React.Fragment key={index}>
							<div className={"document-item " + (item.onDrag ? 'document-item-dragged ' : '')}
								 onMouseDown={event => {
									 if(type !== 'favDoc'){
										 setDragFile({...item, typeMove: 'documents'})
										 setMouse({
											 x : event.clientX,
											 y : event.clientY
										 })
									 }
								 }}
								 onMouseUp={() => {
									 if(dragFile && dragFile.id !== item.id && dragFile.linkedList.length === 0){
										 linkDocToDoc(item.id, dragFile.id)
											 .then(() => {
												 linkDoc(setItem, itemPar, item.id, dragFile.id, type, isFolderOpen.path,  setPush, setPushText, setLinkedDocs)
											 })
										 // moveToFolder(setList, list, dragFile, folder, setPush, setPushText, isFolderOpen.path, dragFile.typeMove)
										 // console.log('move')
									 }
								 }}
								 onDoubleClick={event => {
								 	if(item.file_type === 'png' || item.file_type === 'jpeg' || item.file_type === 'jpg' || item.file_type === 'svg'){
										getPicture(item.files[0].filename).then(res => {
											window.open(window.URL.createObjectURL(res))
										})
									}else if(!event.target.isEqualNode(window.document.querySelector('.checkbox')) && !event.target.closest('.checkbox') && !event.target.isEqualNode(window.document.querySelector('.document-item-status-select')) && !event.target.closest('.document-item-status-select')  && !event.target.isEqualNode(window.document.querySelector('.document-item-type-select')) && !event.target.closest('.document-item-type-select') &&  !event.target.isEqualNode(window.document.querySelector('.document-item-options')) && !event.target.closest('.document-item-options') && format(item.file) !== 'other'){
										let referer = window.location.pathname.replace('/', '').slice(0, window.location.pathname.replace('/', '').indexOf('/'))
								 		history.push(`/document/${item._id}?referer=${referer}&groupid=${groupTemplateId}&docname=${templates ? item.filename : item.files[item.files.length - 1].filename}`)
									 }
								 }} ref={!isMobile ? (item => optionsDocRef.current[index] = item) : null}
							>
								<div className={"checkbox " + (item.isSelect ? 'checkbox-active' : '')} onClick={event => {
									event.stopPropagation()
									selectDoc(setItem, item.id, type, isFolderOpen.path)
								}}>
									<Check/>
								</div>
								<div className="document-item-title">
									{item.isLinked ? (
										<span
											onClick={() => {
											unlinkDoc(setItem, item.id, item.isLinked)
											unlinkDocFromDoc(item.isLinked, item.id)
										}}
											style={{zIndex: 2}} className="document-dislinked"
										>
									<img src={dislinked} alt=""/>
								</span>
									) : ''}
									{isEdit && !templates && type !== 'favDoc' ? (
										<svg width="15" height="15" className="favorite-doc" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => addToFavorites(setItem, item.id, item, setPush, setPushText, false,  isFolderOpen.path)}>
											<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" stroke="#8798AD" strokeWidth="2" strokeLinejoin="round" fill={item.favorite ? '#8798AD' : '#ffffff'}/>
										</svg>
									) : ''}
									<img src={require(`../../assets/img/files/${item.file}.svg`)} alt={item.file} style={type === 'delDoc' ? {opacity: 0.3} : {}}/>
									{item.picture ? (<img className="document-item-photo-preview" src={item.picture} alt="preview"/>) : (<span className="document-item-photo-preview"></span>)}

									{!item.isRename ? (
										<span className={'document-item-name'}>{item.name}</span>
									) : (
										<input type="text" name={item.id} className={"document-item-name " + (item.isRename ? 'document-item-name-rename' : '')} defaultValue={item.name}
											   readOnly={!item.isRename}
											   onBlur={(event) => {
												   // setIsRename(setList, list, index, type, false)
												   renameDoc(setItem, item.id, type, event.target.value, isFolderOpen.path)
												   if(templates){
													   updateTemplateName(itemPar.id, item.id, {name: event.target.value})
												   } else {
													   updateDocumentType(item.id, null, event.target.value)
												   }
											   }}
											   onKeyDown={event => {
												   if(event.keyCode === 13){
													   renameDoc(setItem, item.id, type, event.target.value, isFolderOpen.path)
													   if(templates){
														   updateTemplateName(itemPar.id, item.id, {name: event.target.value})
													   } else {
														   updateDocumentType(item.id, null, event.target.value)
													   }
												   }
											   }}
											   onDoubleClick={event => {
												   event.stopPropagation()
											   }}
											   onClick={event => {
												   event.stopPropagation()
											   }}
										/>
									)}
									{item.linkedList.length > 0 ? (
										<span className="linked-count">{item.linkedList.length}</span>
									) : ''}
								</div>
								{isEdit || window.location.pathname.includes('/templates') ? (
									<>
										{ item.rights === 'deny' || item.rights === 'viewer' ? (
											<div className={"document-item-status-select-wrapper " + (item.status === 'in tender' ? 'tender' : item.status)}><span></span>{item.status[0].toUpperCase() + item.status.slice(1)}</div>
										) : (
											<div className="document-item-status-select-wrapper">
												<div className="document-item-status-select" ref={selectRef}>
													<div className={"status-select-active " + (item.status === 'in tender' ? 'tender' : item.status.toLowerCase())} onClick={event => {
														event.stopPropagation()
														changeStatus(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
														if(!templates){
															// setDragElem(null)
														}
													}}>
														<span></span>
														<div className="status-name">{item.status[0].toUpperCase() + item.status.slice(1)}</div>
														<Arrow/>
													</div>
													{item.isOpenStatusList && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
														if (item.isOpenStatusList) {
															event.stopPropagation()
															changeStatus(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
														}
													}}/>) : ''}
													<div className={"status-select-list " + (item.isOpenStatusList && !item.isRevertMenu ? 'status-select-list-open ' : '') + (item.isRevertMenu && item.isOpenStatusList ? 'status-select-list-revert' : '')}>
														<div className={'status-list'}>
															{statusList.map((status, i) => {
																if(status === item.status){
																	return false
																}
																return (
																	<div className={"select-list-item " + status} key={i} onClick={event => {
																		event.stopPropagation()
																		changeDocStatus(setItem, item.id, status.toLowerCase(), type, isFolderOpen.path)
																		if(window.location.pathname.includes('/templates')){
																			updateTemplateStatus(item.id, status[0].toUpperCase() + status.slice(1))
																		} else {
																			checkDocumentStatus(status, item);
																		}
																	}}><span></span>{status[0].toUpperCase() + status.slice(1)}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
										)}
									</>
								) : (
									<>
										{window.location.pathname.includes('/companies') ? (
											<div className="document-item-expiry">
												<div className="document-item-expiry-select" ref={selectRef}>
													<div className={"expiry-select-active "} onClick={event => {
														event.stopPropagation()
														openCalendar(setItem, index, type, optionsDocRef.current[index], isMobile)
														if(!templates){
															// setDragElem(null)
														}
													}}>
														<div className="status-name">{dateConvert(item.expiry, 'd')}</div>
														<Arrow/>
													</div>
													<CalendarComponent
														setItem={setItem}
														item={item}
														itemPar={itemPar}
														id={index}
														type={type}
														revert={item.isRevertOpenCalendar}
														changeCalendar={changeExpiry}
														isOpen={item.isOpenCalendar}
													/>
												</div>
											</div>
										) : (
											<div className="document-item-expiry">{item.expiry}</div>
										)}
									</>
								)}
								{!templates ? (
									<>
										{item.rights === 'deny' || item.rights === 'viewer' ? (
											<div className="document-item-type">{item.type}</div>
										) : (
											<div className="document-item-type">
												<div className="document-item-type-select" ref={selectRef}>
													<div className={"type-select-active " + item.type} onClick={event => {
														event.stopPropagation()
														changeType(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
														if(!templates){
															// setDragElem(null)
														}
													}}>
														<div className="status-name">{item.type}</div>
														<Arrow/>
													</div>
													{item.isOpenType && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
														if (item.isOpenType) {
															event.stopPropagation()
															changeType(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
														}
													}}/>) : ''}
													<div className={"type-select-list " + (item.isOpenType && !item.isRevertMenu ? 'type-select-list-open ' : '') + (item.isRevertMenu && item.isOpenType ? 'type-select-list-revert ' : '')}>
														<div className={"type-list"}>
															{actualTypes.map((typeDoc, i) => {
																if(typeDoc === item.type){
																	return false
																}
																return (
																	<div className={"type-list-item " } key={i} onClick={event => {
																		event.stopPropagation()
																		changeDocType(setItem, item.id, typeDoc, type, isFolderOpen.path)
																		updateDocumentType(item.id, typeDoc).then(res => console.log(res))
																	}}>{typeDoc}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
										)}
									</>
								) : ''}
								<div className="document-item-edited-by">
									{item.editedBy.name ? (
										<>
											{item.editedBy.picture ? <img src={item.editedBy.picture} alt={'picture'}/> : (
												<div className="none-avatar" key={index} style={{backgroundColor: item.editedBy.bgc}}>
													{item.editedBy.name[0]?.toUpperCase() + ' ' + item.editedBy.lastName?.charAt(0).toUpperCase()}
												</div>
											)}
										</>
									) : ''}
								</div>
								<div className="document-item-edited">{item.editedDate}</div>
								<div className={"document-overflow " + (item.isOpenOptions && !isMobile ? 'active ' : '')} onClick={(event) => {
									if (item.isOpenOptions) {
										event.stopPropagation()
										openOptions(setItem, item.id, type, optionsDocRef.current[index], isMobile, isFolderOpen.path)
									}
								}}/>
								<div className={"document-item-options " + (item.isOpenOptions && !isMobile ? 'document-item-options-active ' : '') + (item.isRevertMenu ? 'document-item-options-revert' : '')} onClick={event => {
									event.stopPropagation()
									openOptions(setItem, item.id, type, optionsDocRef.current[index], isMobile, isFolderOpen.path)
								}} >
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
										<path fillRule="evenodd" clipRule="evenodd" d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z" fill={item.isOpenOptions ? "#2E5BFF" : "#2E384D"}/>
									</svg>
									<div className={"options-list "}>
										<svg width="38" height="21" className="setting-arrow" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.11237 20.5L18.6402 0.753198L36.1679 20.5H1.11237Z" fill="#ffffff" />
										</svg>
										{item.file === 'pdf' || item.file === 'doc' || item.file === 'docx' || item.file === 'xls' || item.file === 'xlsx' ? (
											<div className="option-item" onClick={event => {
												event.stopPropagation()
												convertDocToSpe(item.id)
												convertDoc(setItem, item.id, isFolderOpen.path === '/' || isFolderOpen.path === undefined ? 'documents' : 'folders', isFolderOpen.path)
											}}>Convert file
											</div>
										) : ''}
										{!window.location.pathname.includes('templates') ? (
											<div className="option-item" onClick={event => {
												event.stopPropagation()
												history.push(`/document/details/${item.id}?back=${window.location.pathname.includes('/projects') ? 'projects' : window.location.pathname.includes('/companies') ? 'companies' : 'templates'}`)
											}}>Details</div>
										) : ''}
										<div className="option-item" onClick={event => {
											event.stopPropagation()
											setIsRename(setItem, item.id, type, undefined, isFolderOpen.path)
											setTimeout(() => {
												optionsDocRef.current[index].querySelector('.document-item-name').focus()
												optionsDocRef.current[index].querySelector('.document-item-name').select()
											}, 500)
										}}>Rename</div>
										<div className="option-item" onClick={event => {
											event.stopPropagation()
											if(window.location.pathname.includes('templates')) {
												cloneTemplate(item).then(({data}) => {
													openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
													addDocuments(setItem, itemPar, [data.data], false, false, false, window.location.pathname, isFolderOpen.path, true)
													setPushText('Copy is created')
													setPush(true)
													setTimeout(() => {
														setPush(false)
													}, 1200)
												})
											}else{
												cloneDocument(item).then(({data}) => {
													openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
													addDocuments(setItem, itemPar, [data.data], false, false, false, window.location.pathname, isFolderOpen.path, true)
													setPushText('Copy is created')
													setPush(true)
													setTimeout(() => {
														setPush(false)
													}, 1200)
												})
											}
										}}>Duplicate</div>
										{window.location.pathname.includes('projects') ? (
											<div className="option-item" onClick={event => {
												event.stopPropagation()
												onMoveDoc(setItem, item.id, 'documents', isFolderOpen.path)
												if(!templates){
													setMoveTo(true)
												}
											}}>Move to</div>
										) : ''}
										<div className="option-item" onClick={(event) => {
											event.stopPropagation()
											if(type === 'delDoc'){
												restoreDocTable(setItem, item)
												restoreDoc(item._id)
											} else {
												openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
												setFileToDelete(item)
												setDeleteFile(true)
											}
										}}>{type === 'delDoc' ? 'Put back' : 'Delete'}</div>
									</div>
								</div>
								{isMobile ? (
									<div  className={"document-menu " + (item.isOpenOptions && isMobile ? 'document-menu-active' : '')} onClick={event => {
										openOptions(setItem, item.id, type, optionsDocRef.current[index], isMobile, isFolderOpen.path)
									}}>
										<div className="document-menu-header">
											<div className="document-item-status-select" ref={selectRef}>
												<div className={"status-select-active " + item.status} onClick={event => {
													event.stopPropagation()
													changeStatus(setItem, item.id, type, true, isFolderOpen.path, optionsDocRef.current[index])
												}}>
													<span> </span>
													<div className="status-name">{item.status[0].toUpperCase() + item.status.slice(1)}</div>
													<Arrow/>
												</div>
												{item.isOpenStatusList && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
													if (item.isOpenStatusList) {
														event.stopPropagation()
														changeStatus(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
													}
												}}/>) : ''}
												<div className={"status-select-list " + (item.isOpenStatusList ? 'status-select-list-open' : '')}>
													{statusList.map((status, i) => {
														if(status === item.status){
															return false
														}
														return (
															<div className={"select-list-item " + status} key={i} onClick={event => {
																event.stopPropagation()
																changeDocStatus(setItem, item.id, status.toLowerCase(), type, isFolderOpen.path)
																if(window.location.pathname.includes('/templates')){
																	updateTemplateStatus(item.id, status[0].toUpperCase() + status.slice(1))
																} else {
																	updateDocumentStatus(item.id, status)
																}
															}}><span></span>{status[0].toUpperCase() + status.slice(1)}</div>
														)
													})}
												</div>
											</div>
										</div>
										<div className="document-menu-body">
											<div className="menu-wrapper">
												{type !== 'favDoc' && !window.location.pathname.includes('templates') ? (
													<div className="menu-wrapper-item" onClick={() => addToFavorites(setItem, item.id, item, setPush, setPushText, false,  isFolderOpen.path)}>
														<svg width="15" height="15" className='is-favorite'  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" stroke="#2E5BFF" strokeWidth="2" strokeLinejoin="round" fill={item.favorite ? '#2E5BFF' : '#ffffff'}/>
														</svg>
														<span>Add to favorites</span>
													</div>
												) : ''}
												{!window.location.pathname.includes('templates') ? (
													<div className="menu-wrapper-item" onClick={() => getDocument(item.files.pop().filename, true)}>
														<Download/>
														<span>{lang.download}</span>
													</div>
												) : ''}
												<div className="menu-wrapper-item" onClick={() => {
													// selectDoc(setList, list, item.id, type, isFolderOpen.path)
													setShare(true)
												}}>
													<Share/>
													<span>{lang.share}</span>
												</div>
												{item.file === 'pdf' || item.file === 'doc' || item.file === 'docx' || item.file === 'xls' || item.file === 'xlsx' ? (
													<div className="menu-wrapper-item" onClick={event => {
														event.stopPropagation()
														convertDocToSpe(item.id)
														convertDoc(setItem, item.id, isFolderOpen.path === '/' || isFolderOpen.path === undefined ? 'documents' : 'folders', isFolderOpen.path)
													}}>
														<Convert/>
														<span>{'Convert file'}</span>
													</div>
												) : ''}
											</div>
											<div className="menu-wrapper">
												<div className="menu-wrapper-item" onClick={event => {
													event.stopPropagation()
													setIsRename(setItem, item.id, type, undefined, isFolderOpen.path)
													setTimeout(() => {
														optionsDocRef.current[index].querySelector('.document-item-name').focus()
														optionsDocRef.current[index].querySelector('.document-item-name').select()
													}, 500)
												}}>
													<span>{lang.rename}</span>
												</div>
												<div className="menu-wrapper-item" onClick={event => {
													event.stopPropagation()
													cloneDocument(item).then(({data}) => {
														openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
														addDocuments(setItem, itemPar, [data.data], false, false , false, window.location.pathname, isFolderOpen.path, true)
														setPushText('Copy is created')
														setPush(true)
														setTimeout(() => {
															setPush(false)
														}, 1200)
													})
												}}>
													<span>{'Dublicate'}</span>
												</div>
												{folders.length > 0 && window.location.pathname.includes('projects')? (
													<div className="menu-wrapper-item" onClick={event => {
														event.stopPropagation()
														onMoveDoc(setItem, item.id, 'documents', isFolderOpen.path)
														if(!templates){
															setMoveTo(true)
														}
													}}>
														<span>{lang.moveTo}</span>
													</div>
												) : ''}
												<div className="menu-wrapper-item" onClick={(event) => {
													event.stopPropagation()
													if(!templates){
														if(type === 'delDoc'){
															restoreDocTable(setItem, item)
															restoreDoc(item._id)
														} else {
															openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
															setFileToDelete(item)
															setDeleteFile(true)
														}
													}
												}}>
													<span>{type === 'delDoc' ? 'Put back' : lang.delete}</span>
												</div>
											</div>
										</div>
									</div>
								) : ''}
							</div>
							{!!!item.isLinked ? (item.linkedList.map((id, index) => {
								const item = linkedDocs.find(doc => doc.id === id)
								if(item){
									return (
										<>
										<div className={"document-item " + (item.onDrag ? 'document-item-dragged ' : '')} key={index}
											 	onDoubleClick={event => {
													 if(!event.target.isEqualNode(window.document.querySelector('.checkbox')) && !event.target.closest('.checkbox') && !event.target.isEqualNode(window.document.querySelector('.document-item-status-select')) && !event.target.closest('.document-item-status-select')  && !event.target.isEqualNode(window.document.querySelector('.document-item-type-select')) && !event.target.closest('.document-item-type-select') &&  !event.target.isEqualNode(window.document.querySelector('.document-item-options')) && !event.target.closest('.document-item-options') && format(item.file) !== 'other'){
														 history.push(`/document/${item._id}?referer=${window.location.pathname.replace('/', '')}&groupid=${groupTemplateId}&docname=${window.location.pathname === '/templates' ? item.filename : item.files[item.files.length - 1].filename}`)
													 }
												 }}
											 ref={!isMobile ? (item => optionsDocRef.current[index] = item) : null}
									>
										<div className={"checkbox " + (item.isSelect ? 'checkbox-active' : '')} onClick={event => {
											event.stopPropagation()
											selectDoc(setItem, item.id, type, isFolderOpen.path)
										}}>
											<Check/>
										</div>
										<div className="document-item-title">
											{item.isLinked ? (
												<span onClick={() => {
													unlinkDoc(setItem, item.id, item.isLinked)
													unlinkDocFromDoc(item.isLinked, item.id)
												}}
												  	style={{zIndex: 2}} className="document-dislinked"
												>
									<img src={dislinked} alt=""/>
								</span>
											) : ''}
											{isEdit && !templates && type !== 'favDoc' ? (
												<svg width="15" height="15" className="favorite-doc" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => addToFavorites(setItem, item.id, item, setPush, setPushText, false,  isFolderOpen.path)}>
													<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" stroke="#8798AD" strokeWidth="2" strokeLinejoin="round" fill={item.favorite ? '#8798AD' : '#ffffff'}/>
												</svg>
											) : ''}
											<img src={require(`../../assets/img/files/${item.file}.svg`)} alt={item.file} style={type === 'delDoc' ? {opacity: 0.3} : {}}/>
											{item.picture ? (<img className="document-item-photo-preview" src={item.picture} alt="preview"/>) : (<span className="document-item-photo-preview">preview</span>)}

											{!item.isRename ? (
												<span className={'document-item-name'}>{item.name}</span>
											) : (
												<input type="text" name={item.id} className={"document-item-name " + (item.isRename ? 'document-item-name-rename' : '')} defaultValue={item.name}
													   readOnly={!item.isRename}
													   onBlur={(event) => {
														   // setIsRename(setList, list, index, type, false)
														   renameDoc(setItem, item.id, type, event.target.value, isFolderOpen.path)
														   if(templates){
															   updateTemplateName(itemPar.id, item.id, {name: event.target.value})
														   } else {
															   updateDocumentType(item.id, null, event.target.value)
														   }
													   }}
													   onKeyDown={event => {
														   if(event.keyCode === 13){
															   renameDoc(setItem, item.id, type, event.target.value, isFolderOpen.path)
															   if(templates){
																   updateTemplateName(itemPar.id, item.id, {name: event.target.value})
															   } else {
																   updateDocumentType(item.id, null, event.target.value)
															   }
														   }
													   }}
													   onDoubleClick={event => {
														   event.stopPropagation()
													   }}
													   onClick={event => {
														   event.stopPropagation()
													   }}
												/>
											)}
											{item.linkedList.length > 0 ? (
												<span className="linked-count">{item.linkedList.length}</span>
											) : ''}
										</div>
										{isEdit || window.location.pathname.includes('/templates') ? (
											<>
												{ item.rights === 'deny' || item.rights === 'viewer' ? (
													<div className={"document-item-status-select-wrapper " + (item.status === 'in tender' ? 'tender' : item.status)}><span></span>{item.status[0].toUpperCase() + item.status.slice(1)}</div>
												) : (
													<div className="document-item-status-select-wrapper">
														<div className="document-item-status-select" ref={selectRef}>
															<div className={"status-select-active " + (item.status === 'in tender' ? 'tender' : item.status.toLowerCase())} onClick={event => {
																event.stopPropagation()
																changeStatus(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
																if(!templates){
																	// setDragElem(null)
																}
															}}>
																<span></span>
																<div className="status-name">{item.status[0].toUpperCase() + item.status.slice(1)}</div>
																<Arrow/>
															</div>
															{item.isOpenStatusList && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
																if (item.isOpenStatusList) {
																	event.stopPropagation()
																	changeStatus(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
																}
															}}/>) : ''}
															<div className={"status-select-list " + (item.isOpenStatusList ? 'status-select-list-open' : '')}>
																{statusList.map((status, i) => {
																	if(status === item.status){
																		return false
																	}
																	return (
																		<div className={"select-list-item " + status} key={i} onClick={event => {
																			event.stopPropagation()
																			changeDocStatus(setItem, item.id, status.toLowerCase(), type, isFolderOpen.path)
																			if(window.location.pathname.includes('/templates')){
																				updateTemplateStatus(item.id, status[0].toUpperCase() + status.slice(1))
																			} else {
																				checkDocumentStatus(status, item);
																			}
																		}}><span></span>{status[0].toUpperCase() + status.slice(1)}</div>
																	)
																})}
															</div>
														</div>
													</div>
												)}
											</>
										) : (
											<>
												{window.location.pathname.includes('/companies') ? (
													<div className="document-item-expiry">
														<div className="document-item-expiry-select" ref={selectRef}>
															<div className={"expiry-select-active "} onClick={event => {
																event.stopPropagation()
																openCalendar(setItem, index, type, optionsDocRef.current[index], isMobile)
																if(!templates){
																	// setDragElem(null)
																}
															}}>
																<div className="status-name">{dateConvert(item.expiry, 'd')}</div>
																<Arrow/>
															</div>
															<CalendarComponent
																setItem={setItem}
																item={item}
																id={index}
																type={type}
																revert={item.isRevertOpenCalendar}
																changeCalendar={changeExpiry}
																isOpen={item.isOpenCalendar}
															/>
														</div>
													</div>
												) : (
													<div className="document-item-expiry">{item.expiry}</div>
												)}
											</>
										)}
										{!templates ? (
											<>
												{item.rights === 'deny' || item.rights === 'viewer' ? (
													<div className="document-item-type">{item.type}</div>
												) : (
													<div className="document-item-type">
														<div className="document-item-type-select" ref={selectRef}>
															<div className={"type-select-active " + item.type} onClick={event => {
																event.stopPropagation()
																changeType(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
																if(!templates){
																	// setDragElem(null)
																}
															}}>
																<div className="status-name">{item.type}</div>
																<Arrow/>
															</div>
															{item.isOpenType && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
																if (item.isOpenType) {
																	event.stopPropagation()
																	changeType(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
																}
															}}/>) : ''}
															<div className={"type-select-list " + (item.isOpenType ? 'type-select-list-open' : '')}>
																{actualTypes.map((typeDoc, i) => {
																	if(typeDoc === item.type){
																		return false
																	}
																	return (
																		<div className={"type-list-item " } key={i} onClick={event => {
																			event.stopPropagation()
																			changeDocType(setItem, item.id, typeDoc, type, isFolderOpen.path)
																			updateDocumentType(item.id, typeDoc).then(res => console.log(res))
																		}}>{typeDoc}</div>
																	)
																})}
															</div>
														</div>
													</div>
												)}
											</>
										) : ''}
										<div className="document-item-edited-by">
											{!window.location.pathname.includes('/templates') && item.editedBy.name ? (
												<>
													{item.editedBy.picture ? <img src={item.editedBy.picture} alt={'picture'}/> : (
														<div className="none-avatar" key={index} style={{backgroundColor: item.editedBy.bgc}}>
															{(item.editedBy.name[0] + (item.editedBy.lastName.length > 0 ? item.editedBy.lastName[0] : '')).toUpperCase()}
														</div>
													)}
												</>
											) : ''}
										</div>
										<div className="document-item-edited">{item.editedDate}</div>
										<div className={"document-overflow " + (item.isOpenOptions && !isMobile ? 'active ' : '')} onClick={(event) => {
											if (item.isOpenOptions) {
												event.stopPropagation()
												openOptions(setItem, item.id, type, optionsDocRef.current[index], isMobile, isFolderOpen.path)
											}
										}}/>
										<div className={"document-item-options " + (item.isOpenOptions && !isMobile ? 'document-item-options-active ' : '') + (item.isRevertMenu ? 'document-item-options-revert' : '')} onClick={event => {
											event.stopPropagation()
											openOptions(setItem, item.id, type, optionsDocRef.current[index], isMobile, isFolderOpen.path)
										}} >
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
												<path fillRule="evenodd" clipRule="evenodd" d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z" fill={item.isOpenOptions ? "#2E5BFF" : "#2E384D"}/>
											</svg>
											<div className={"options-list "}>
												<svg width="38" height="21" className="setting-arrow" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1.11237 20.5L18.6402 0.753198L36.1679 20.5H1.11237Z" fill="#ffffff" />
												</svg>
												{item.file === 'pdf' || item.file === 'doc' || item.file === 'docx' || item.file === 'xls' || item.file === 'xlsx' ? (
													<div className="option-item" onClick={event => {
														event.stopPropagation()
														convertDocToSpe(item.id)
														convertDoc(setItem, item.id, isFolderOpen.path === '/' || isFolderOpen.path === undefined ? 'documents' : 'folders', isFolderOpen.path)
														// openOptions(setList, list, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
													}}>Convert file
													</div>
												) : ''}
												{!window.location.pathname.includes('templates') ? (
													<div className="option-item" onClick={event => {
														event.stopPropagation()
														history.push(`/document/details/${item.id}?back=${window.location.pathname.includes('/projects') ? 'projects' : window.location.pathname.includes('/companies') ? 'companies' : 'templates'}`)
													}}>Details</div>
												) : ''}
												<div className="option-item" onClick={event => {
													event.stopPropagation()
													setIsRename(setItem, item.id, type, undefined, isFolderOpen.path)
													setTimeout(() => {
														optionsDocRef.current[index].querySelector('.document-item-name').focus()
														optionsDocRef.current[index].querySelector('.document-item-name').select()
													}, 500)
												}}>Rename</div>
												<div className="option-item" onClick={event => {
													event.stopPropagation()
													cloneDocument(item).then(({data}) => {
														// console.log(data)
														openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
														addDocuments(setItem, [data.data], false, false , false, window.location.pathname, isFolderOpen.path, true)
														setPushText('Copy is created')
														setPush(true)
														setTimeout(() => {
															setPush(false)
														}, 1200)
													}).catch(error => {
														console.log(error)
													})
												}}>Duplicate</div>
												{window.location.pathname.includes('projects') ? (
													<div className="option-item" onClick={event => {
														event.stopPropagation()
														onMoveDoc(setItem, item.id, 'documents', isFolderOpen.path)
														if(!templates){
															setMoveTo(true)
														}
													}}>Move to</div>
												) : ''}
												<div className="option-item" onClick={(event) => {
													event.stopPropagation()
													if(type === 'delDoc'){
														restoreDocTable(setItem, item)
														restoreDoc(item._id)
													} else {
														openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
														setFileToDelete(item)
														setDeleteFile(true)
													}
												}}>{type === 'delDoc' ? 'Put back' : 'Delete'}</div>
											</div>
										</div>
										{isMobile ? (
											<div  className={"document-menu " + (item.isOpenOptions && isMobile ? 'document-menu-active' : '')} onClick={event => {
												openOptions(setItem, item.id, type, optionsDocRef.current[index], isMobile, isFolderOpen.path)
											}}>
												<div className="document-menu-header">
													<div className="document-item-status-select" ref={selectRef}>
														<div className={"status-select-active " + item.status} onClick={event => {
															event.stopPropagation()
															changeStatus(setItem, item.id, type, true, isFolderOpen.path, optionsDocRef.current[index])
														}}>
															<span> </span>
															<div className="status-name">{item.status[0].toUpperCase() + item.status.slice(1)}</div>
															<Arrow/>
														</div>
														{item.isOpenStatusList && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
															if (item.isOpenStatusList) {
																event.stopPropagation()
																changeStatus(setItem, item.id, type, false, isFolderOpen.path, optionsDocRef.current[index])
															}
														}}/>) : ''}
														<div className={"status-select-list " + (item.isOpenStatusList ? 'status-select-list-open' : '')}>
															{statusList.map((status, i) => {
																if(status === item.status){
																	return false
																}
																return (
																	<div className={"select-list-item " + status} key={i} onClick={event => {
																		event.stopPropagation()
																		changeDocStatus(setItem, item.id, status.toLowerCase(), type, isFolderOpen.path)
																		if(window.location.pathname.includes('/templates')){
																			updateTemplateStatus(item.id, status[0].toUpperCase() + status.slice(1))
																		} else {
																			updateDocumentStatus(item.id, status)
																		}
																	}}><span></span>{status[0].toUpperCase() + status.slice(1)}</div>
																)
															})}
														</div>
													</div>
												</div>
												<div className="document-menu-body">
													<div className="menu-wrapper">
														{type !== 'favDoc' ? (
															<div className="menu-wrapper-item" onClick={() => addToFavorites(setItem, item.id, item, setPush, setPushText, false,  isFolderOpen.path)}>
																<svg width="15" height="15" className='is-favorite'  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" stroke="#2E5BFF" strokeWidth="2" strokeLinejoin="round" fill={item.favorite ? '#2E5BFF' : '#ffffff'}/>
																</svg>
																<span>Add to favorites</span>
															</div>
														) : ''}
														<div className="menu-wrapper-item" onClick={() => getDocument(item.files.pop().filename, true)}>
															<Download/>
															<span>{lang.download}</span>
														</div>
														<div className="menu-wrapper-item" onClick={() => {
															setShare(true)
														}}>
															<Share/>
															<span>{lang.share}</span>
														</div>
														{item.file === 'pdf' || item.file === 'doc' || item.file === 'docx' || item.file === 'xls' || item.file === 'xlsx' ? (
															<div className="menu-wrapper-item" onClick={event => {
																event.stopPropagation()
																convertDocToSpe(item.id)
																convertDoc(setItem, item.id, isFolderOpen.path === '/' || isFolderOpen.path === undefined ? 'documents' : 'folders', isFolderOpen.path)
															}}>
																<Convert/>
																<span>{'Convert file'}</span>
															</div>
														) : ''}
													</div>
													<div className="menu-wrapper">
														<div className="menu-wrapper-item" onClick={event => {
															event.stopPropagation()
															setIsRename(setItem, item.id, type, undefined, isFolderOpen.path)
															setTimeout(() => {
																optionsDocRef.current[index].querySelector('.document-item-name').focus()
																optionsDocRef.current[index].querySelector('.document-item-name').select()
															}, 500)
														}}>
															<span>{lang.rename}</span>
														</div>
														<div className="menu-wrapper-item" onClick={event => {
															event.stopPropagation()
															cloneDocument(item).then(({data}) => {
																openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
																addDocuments(setItem, itemPar, [data.data], false, false , false, window.location.pathname, isFolderOpen.path, true)
																setPushText('Copy is created')
																setPush(true)
																setTimeout(() => {
																	setPush(false)
																}, 1200)
															}).catch(error => {
																console.log(error)
															})
														}}>
															<span>{'Dublicate'}</span>
														</div>
														{folders.length > 0 && window.location.pathname.includes('projects')? (
															<div className="menu-wrapper-item" onClick={event => {
																event.stopPropagation()
																onMoveDoc(setItem, item.id, 'documents', isFolderOpen.path)
																if(!templates){
																	setMoveTo(true)
																}
															}}>
																<span>{lang.moveTo}</span>
															</div>
														) : ''}
														<div className="menu-wrapper-item" onClick={(event) => {
															event.stopPropagation()
															if(!templates){
																if(type === 'delDoc'){
																	restoreDocTable(setItem, item)
																	restoreDoc(item._id)
																} else {
																	openOptions(setItem, item.id, type, optionsDocRef.current[index], false, isFolderOpen.path)
																	setFileToDelete(item)
																	setDeleteFile(true)
																}
															}
														}}>
															<span>{type === 'delDoc' ? 'Put back' : lang.delete}</span>
														</div>
													</div>
												</div>
											</div>
										) : ''}
									</div></>)
								}
							})) : ''}
						</React.Fragment>
					);
				}
			}) : ''}
		</div>
		</>
	)
}

export default DocTable