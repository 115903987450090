// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .profile-nav-wrapper {
    margin-left: 10px;
    display: flex;
    align-items: center; }
    .profile-nav-wrapper .profile-avatar {
      width: 24px;
      height: 24px; }
    .profile-nav-wrapper .profile-popup {
      position: fixed;
      top: 60px;
      right: unset;
      left: 0px;
      width: 100%;
      padding: 0px 15px;
      height: calc(100vh - 60px); }
      .profile-nav-wrapper .profile-popup:after {
        display: none; }
      .profile-nav-wrapper .profile-popup #arrow {
        display: none; }
      .profile-nav-wrapper .profile-popup .user {
        padding: 20px 0px 25px; }
        .profile-nav-wrapper .profile-popup .user .user-info {
          display: flex;
          flex-direction: column; }
          .profile-nav-wrapper .profile-popup .user .user-info .user-edit {
            font-size: 0.75rem;
            line-height: 0.9rem; }
      .profile-nav-wrapper .profile-popup .select-lang {
        height: 54px; }
      .profile-nav-wrapper .profile-popup .user-singout {
        height: 54px; }
    .profile-nav-wrapper-arabic {
      margin-left: 0px;
      margin-right: 10px; } }
`, "",{"version":3,"sources":["webpack://./src/components/Nav/ProfileNav/ProfileNavMedia.scss"],"names":[],"mappings":"AAAA;EAEC;IACC,iBAAiB;IACjB,aAAa;IACb,mBAAmB,EAAA;IAHpB;MAKE,WAAW;MACX,YAAY,EAAA;IANd;MASE,eAAe;MACf,SAAS;MACT,YAAY;MACZ,SAAS;MACT,WAAW;MACX,iBAAiB;MACjB,0BAA0B,EAAA;MAf5B;QAiBG,aAAa,EAAA;MAjBhB;QAoBG,aAAa,EAAA;MApBhB;QAuBG,sBAAsB,EAAA;QAvBzB;UAyBI,aAAa;UACb,sBAAsB,EAAA;UA1B1B;YA4BK,kBAAkB;YAClB,mBAAmB,EAAA;MA7BxB;QAkCG,YAAY,EAAA;MAlCf;QAqCG,YAAY,EAAA;IAGd;MACC,gBAAgB;MAChB,kBAAkB,EAAA,EAClB","sourcesContent":["@media screen and (max-width: 600px){\n\n\t.profile-nav-wrapper{\n\t\tmargin-left: 10px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\t.profile-avatar{\n\t\t\twidth: 24px;\n\t\t\theight: 24px;\n\t\t}\n\t\t.profile-popup{\n\t\t\tposition: fixed;\n\t\t\ttop: 60px;\n\t\t\tright: unset;\n\t\t\tleft: 0px;\n\t\t\twidth: 100%;\n\t\t\tpadding: 0px 15px;\n\t\t\theight: calc(100vh - 60px);\n\t\t\t&:after{\n\t\t\t\tdisplay: none;\n\t\t\t}\n\t\t\t#arrow{\n\t\t\t\tdisplay: none;\n\t\t\t}\n\t\t\t.user{\n\t\t\t\tpadding: 20px 0px 25px;\n\t\t\t\t.user-info{\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tflex-direction: column;\n\t\t\t\t\t.user-edit{\n\t\t\t\t\t\tfont-size: 0.75rem;\n\t\t\t\t\t\tline-height: 0.9rem;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\t.select-lang{\n\t\t\t\theight: 54px;\n\t\t\t}\n\t\t\t.user-singout{\n\t\t\t\theight: 54px;\n\t\t\t}\n\t\t}\n\t\t&-arabic{\n\t\t\tmargin-left: 0px;\n\t\t\tmargin-right: 10px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
