import React, { useEffect, useState, useRef, useContext } from 'react'
import {useHistory} from 'react-router-dom'
import Slider from "react-slick";

import {AppContext} from '../../context/AppContext'

import Loader from '../Loader/Loader'
import ProjectItem from './ProjectItem/ProjectItem'

import {
	getRecentDocuments,
	getRecentProjects,
	getExpiringDocuments,
	getRenewingDocuments
} from '../../utilites/api'

import {
	dateConvert,
	format
} from '../../utilites/appfunctions'

import './Dashboard.scss'
import './DashboardMedia.scss'

function Dashboard(){
	const {isMobile, lang, activeLang} = useContext(AppContext)
	const slider = useRef(null)
	let [projects, setProjects] = useState([])
	let [currentSlide, setCurrentSlide] = useState(activeLang === 'ar' ? projects.length - 4 : 0)
	let [docs, setDocs] = useState([])
	let [expiring, setExpiring] = useState([])
	let [renewing, setRenewing] = useState([])
	let [activeMonit, setActiveMonit] = useState(0)
	let [activeMonitList, setActiveMonitList] = useState([])
	let [sliderIsLoad, setSliderIsLoad] = useState(false)

	const [isLoadComplete, setIsLoadComplete] = useState(true)

	const history = useHistory()

	const docConvert = doc => {
		return {
			name: doc.name,
			id: doc._id,
			type: doc.type || 'Other',
			project: doc.project ? doc.project.name : 'None',
			company: doc.company ? doc.company.name : 'None',
			edited: dateConvert(doc.updated_at || doc.created_at, 'dt'),
			queries: doc.queries ? doc.queries.length : 0,
			comments: doc.comments ? doc.comments.length : 0,
			status: doc.status || 'draft',
			file: format(doc.file_type) || 'spe',
			files: doc.files,
			details: doc.details,
			deleted_at: doc.deleted_at
		}
	}


	useEffect(() => {
		// setExpiring(expiringData)
		// setRenewing(renewingData)
		// setActiveMonitList(expiringData)
		
		async function getRecentData(){
			Promise.all([getRecentProjects(), getRecentDocuments(), getExpiringDocuments(), getRenewingDocuments()])
			.then(res => {
				setProjects(res[0].data)
				// console.log(res[1].data.map(item => docConvert(item)))
				setDocs(res[1].data.map(item => docConvert(item)))
				setExpiring(res[2].data.map(item => docConvert(item)))
				setRenewing(res[3].data.map(item => docConvert(item)))
				setActiveMonitList(res[2].data.map(item => docConvert(item)))
				setIsLoadComplete(false)
			})
		}	

		getRecentData()
		//eslint-disable-next-line
		setTimeout(() => {
			setSliderIsLoad(true)
		}, 400)
		return () => {
			setSliderIsLoad(false)
		}
	}, [])


	const settings = {
		infinite: false,
		speed: 500,
		dots: false,
		draggable: false,
		slidesToShow: isMobile ? 1 : 4,
		slidesToScroll: isMobile ? 1 : 4,
		initialSlide: activeLang === 'ar' ? projects.length - 4 : 0,
		beforeChange: (_, i) => {
			setCurrentSlide(i)
		},
		responsive : [
			{
				breakpoint: 1250,
				settings: {
					slidesToShow: isMobile ? 1 : 3,
					slidesToScroll: isMobile ? 1 : 3,
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: isMobile ? 1 : 2,
					slidesToScroll: isMobile ? 1 : 2,
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	}
	return (
		<>
			<Loader isVisible={isLoadComplete}/>
			{isLoadComplete === false ? (
				<div className={"dashboard " + (activeLang === 'ar' ? 'dashboard-arabic' : '')}>
				<div className="recent-projects">
					<div className="recent-header">
						<span className="header-title">{lang.recentProjects}</span>
						<div className="recent-slider-nav">
							<div className="recent-slider-nav-arrow" onClick={() => currentSlide >= 1 ? slider.current.slickGoTo(currentSlide - (window.document.documentElement.clientWidth <= 1250 ? (window.document.documentElement.clientWidth <= 1000 ? (window.document.documentElement.clientWidth <= 1000 ? 1 : 2 ) : 3 )  : 4)) : false }>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M14.6483 4.72516L7.91521 11.4576C7.61575 11.7574 7.61575 12.2428 7.91521 12.5423L14.6483 19.2754C14.9479 19.5749 15.4333 19.5749 15.7329 19.2754L16.2752 18.7331C16.5749 18.4337 16.5749 17.9478 16.2752 17.6482L10.6267 12L16.2752 6.35161C16.5749 6.05225 16.5749 5.56657 16.2752 5.26706L15.7329 4.72476C15.433 4.42501 14.9479 4.42501 14.6483 4.72516Z" fill={currentSlide === 0 ? "#BFC5D2" : '#2E5BFF'}/>
								</svg>
							</div>
							<div className="recent-slider-nav-arrow" onClick={() => currentSlide < projects.length ? slider.current.slickGoTo(currentSlide + (window.document.documentElement.clientWidth <= 1250 ? (window.document.documentElement.clientWidth <= 1000 ? (window.document.documentElement.clientWidth <= 1000 ? 1 : 2 ) : 3 ) : 4)) : false }>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M9.35166 4.72515L16.0848 11.4576C16.3843 11.7574 16.3843 12.2428 16.0848 12.5423L9.35166 19.2754C9.0521 19.5749 8.56666 19.5749 8.26711 19.2754L7.72481 18.7331C7.42506 18.4337 7.42506 17.9478 7.72481 17.6482L13.3733 12L7.72481 6.35161C7.42506 6.05225 7.42506 5.56657 7.72481 5.26707L8.26711 4.72476C8.56701 4.42501 9.0521 4.42501 9.35166 4.72515Z" fill={currentSlide >= projects.length - 4 ? "#BFC5D2" : '#2E5BFF'}/>
								</svg>
							</div>
						</div>
					</div>
					<div className="recent-body">
						<div className={"recent-body-slider " + (sliderIsLoad ? 'recent-body-slider-load' : '')}>
							<Slider ref={slider} {...settings}>
								{projects.map((item, index) => <ProjectItem project={item} key={index}/>)}
							</Slider>
						</div>
					</div>
				</div>
				<div className="recent-docs">
					<div className="recent-header">
						<span className="header-title">{lang.recentDocuments}</span>
					</div>
					<div className="recent-docs-list">
						{docs.map((item, index) => {
							return (
								<div className="doc-item" key={index} onDoubleClick={() => {
									history.push(`/document/${item.id}?referer=${window.location.pathname.replace('/', '')}&groupid=${'dashboard'}&docname=${item.files.pop().filename}`)
								}}>
									<div className="doc-item-head">
										<img src={require(`../../assets/img/files/${item.file}.svg`)} alt="" className="doc-image" style={item.deleted_at ? {opacity: 0.3} : {}}/>
										<div className="doc-name">{item.name}</div>
										<div className="doc-status">
											<span className={item.status.toLowerCase()}></span>{(item.status[0].toUpperCase() + item.status.slice(1)).replace('_', ' ')}
										</div>
									</div>
									<div className="doc-item-info">
										<div className="doc-info-column">
											<div className="doc-info-item">
												{activeLang === 'ar' ? (
													<><span>&ensp;: {lang.id} </span> {item.id}  </>
												) : (
													<><span> {lang.id} :&ensp; </span> {item.id} </>
												)}
											</div>
											<div className="doc-info-item">
												{activeLang === 'ar' ? (
													<><span>&ensp;: {lang.type} </span> {item.type}  </>
												) : (
													<>
														<span> {lang.type} :&ensp; </span>{item.type} 
													</>
												)}
											</div>
										</div>
										<div className="doc-info-column">
											<div className="doc-info-item">
												{activeLang === 'ar' ? (
													<><span>&ensp;: {lang.project} </span> {item.project}  </>
												) : (
													<>
														<span> {item.project !== 'None' ? lang.project : lang.company} :&ensp;</span> {item.project !== 'None' ? item.project : item.company}
													</>
												)}
											</div>
											<div className="doc-info-item">
												{activeLang === 'ar' ? (
													<><span>&ensp;: {lang.lastEdited} </span> {item.edited}  </>
												) : (
													<>
														<span> {lang.lastEdited} :&ensp;</span>{item.edited} 
													</>
												)}
											</div>
										</div>
										<div className="doc-info-column">
											<div className="doc-info-item">
												{activeLang === 'ar' ? (
													<><span>&ensp;: {lang.openQueries} </span> {item.queries}  </>
												) : (
													<>
														<span> {lang.openQueries} :&ensp;</span>{item.queries} 
													</>
												)}
											</div>
											<div className="doc-info-item">
												{activeLang === 'ar' ? (
													<><span>&ensp;: {lang.comments} </span> {item.comments}  </>
												) : (
													<>
														<span>{lang.comments} :&ensp;</span>{item.comments}
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="monit-docs">
					<div className="monit-docs-header">
						<button className={"monit-docs-header-item " + (activeMonit === 0 ? 'monit-docs-header-item-active' : '')} onClick={() => {
							setActiveMonit(0)
							setActiveMonitList(expiring)
							}}>{lang.expiring}</button>
						<button className={"monit-docs-header-item " + (activeMonit === 1 ? 'monit-docs-header-item-active' : '')} onClick={() => {
							setActiveMonit(1)
							setActiveMonitList(renewing)
							}}>{lang.autoRenewing}</button>
					</div>
					<div className="monit-docs-list">
						{activeMonitList.map((item, index) => {
							return (
								<div className="monit-item" key={index}>
									<div className="monit-item-header">
										<div onClick={() => console.log('heloheloehl',dateConvert(item.details.filter(detail => detail.field.name === 'Expiration Date')[0].value, 'expiry').class)} className="monit-item-date">{dateConvert(item.details.filter(detail => detail.field.name === 'Expiration Date')[0].value, 'dslash')}</div>
										<div className={"monit-item-interval " + dateConvert(item.details.filter(detail => detail.field.name === 'Expiration Date')[0].value, 'expiry').class}>{dateConvert(item.details.filter(detail => detail.field.name === 'Expiration Date')[0].value, 'expiry').value + ' ' + dateConvert(item.details.filter(detail => detail.field.name === 'Expiration Date')[0].value, 'expiry').type}</div>
										<div className="monit-item-type">{item.type}</div>
									</div>
									<div className="monit-item-body">
										<img src={require(`../../assets/img/files/${item.file}.svg`)} alt="" className="monit-item-image" style={item.deleted_at ? {opacity: 0.3} : {}}/>
										<div className="monit-item-name">{item.name}</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		) : ''}
		</>
	)
}


let expiringData = [
	{
		file: 'spe',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'low'
	},
	{
		file: 'pdf',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'middle'
	},
	{
		file: 'doc',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'high'
	},
	{
		file: 'spe',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'low'
	},
	{
		file: 'pdf',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'middle'
	},
	{
		file: 'doc',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'high'
	},
	{
		file: 'spe',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'low'
	},
	{
		file: 'pdf',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'middle'
	},
	{
		file: 'doc',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'high'
	}
]

let renewingData = [
	{
		file: 'spe',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'low'
	},
	{
		file: 'pdf',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'middle'
	},
	{
		file: 'spe',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'low'
	},
	{
		file: 'pdf',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'middle'
	},
	{
		file: 'doc',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'high'
	},
	{
		file: 'spe',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'low'
	},
	{
		file: 'pdf',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'middle'
	},
	{
		file: 'doc',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'high'
	},
	{
		file: 'doc',
		name: 'CRYTEK Project Recontruction Evaluation Propsdfsdfsdfksd;f',
		type: 'NDA',
		date: '12/04/2021',
		interval: '1 day',
		intervalDegree: 'high'
	}
]
export default Dashboard