import React, {useContext} from 'react'

import {AppContext} from '../../context/AppContext'

import './ErrorPage.scss'

function ErrorPage({errorInfo}){

    const {isMobile} = useContext(AppContext)

    return (
        <div className="error">
            <span>Error</span>
        </div>
    )
}

export default ErrorPage