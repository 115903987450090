import React, { useContext, useState, useEffect } from 'react'

import { AppContext } from '../../../../context/AppContext'

import edit from '../../../../assets/img/edit.svg'
import sendComment from '../../../../assets/img/send-comment.svg'

import {ReactComponent as Resolved} from '../../../../assets/img/resolved.svg'
import {ReactComponent as Flagged} from '../../../../assets/img/flagged.svg'
import {ReactComponent as NoneAvatar} from '../../../../assets/img/nonavatar.svg'
import {ReactComponent as Setting} from '../../../../assets/img/setting.svg'



import {
	deleteCommentFromDocument,
	editReplyToComment,
	editComment,
	editQueryStatus,
	userSearch
} from '../../../../utilites/api'

import {
	activeItemManage,
	changeQueryStatus,
	deleteItemManage,
	openSettingManageItem,
	saveDocument,
	setEditReply,
	editReplyComment,
	activateItemInEditor,
	addReplyToCommentItem,
} from '../../../../utilites/document'

import './DocManageItem.scss'
import './DocManageItemMedia.scss'

function DocManageItem({data, index, type, setDocument, document, editor, activeItem, setActiveManage, editorRef, searchString, reloadLine, createAnnotation, setIsOpenFullQueries, setSearch, settings}){

	const { lang, user, activeLang } = useContext(AppContext)
	const [newComment, setNewComment] = useState('')
	const [anonymousId, setAnonymousId] = useState([])
	const [tmpId, setTmpId] = useState(1)
	const checkAnonymousId = userId => {
		const tmp = anonymousId.filter((user) => user.idUser === userId ? user : null);
		if(tmp[0] !== undefined && tmp.length > 0){
			return tmp[0].id;
		}else if(tmp.length === 0){
			setAnonymousId([...anonymousId, {idUser: userId, id: tmpId}])
			setTmpId(tmpId + 1)
			return tmpId;
		}
	}
	const updateMentions = shift => {
		let newActive = 0;
		for(let i = 0; i < mentionsSearch.length; i ++){
			if(mentionsSearch[i].active){
				if(i + shift >= mentionsSearch.length){
					newActive = 0
				} else if (i + shift < 0){
					newActive = mentionsSearch.length - 1
				} else {
					newActive = i + shift
				}
			}
		}
		setMentionsSearch(mentionsSearch => {
			return mentionsSearch.map((item, index) => {
				if(item.active){
					return {
						...item, 
						active: false
					}
				}
				if(index === newActive){
					return {
						...item, 
						active: true
					}
				}
				return item
			})
		})
	}

	const createMention = (inner) => {
		return `@${(inner.first_name !== '' ? inner.first_name[0].toUpperCase() + inner.first_name.slice(1) : '').replace(/ /g, '_')}${(inner.last_name !== '' ? inner.last_name[0].toUpperCase() + inner.last_name.slice(1) : '').replace(/ /g, '_')}`
	}

	const convertSearchString = (inner) => {
		if(/(^|\s)@\w{2,} ?$/ig.test(inner) && inner.match(/(^|\s)@\w{2,} ?$/ig).pop().replace('@', '') !== ''){
			let search = inner.match(/(^|\s)@\w{2,} ?$/ig).pop().replace(/ ?@/ig, '')
	
			return {
				flag: true,
				string: search
			}
		}
		return {
			flag : false
		}
	}
	
	const mentionsHandler = (data, check) => {
		if(data.length > 0 && convertSearchString(check).flag){
			setMentionsSearch(data.map((item, index) => {
				if(index === 0) {
					return {...item, active: true}
				}
				return {...item, active: false}
			}).filter(item => !activeMentions.includes(item._id)))
		} else {
			setMentionsSearch([])
		}
	}
	
	const mentionsDeleteHandler = inner => {
		setActiveMentions(activeMentions.filter((item) => {
			if(!inner.includes(item.mention)){
				return false
			}
			return true
		}))
	}

	const [mentionsSearch, setMentionsSearch] = useState([])
	const [activeMentions, setActiveMentions] = useState([])

	useEffect(() => {
		// console.log('active mantions', activeMentions)
		// console.log('search mantions', mentionsSearch)
		// console.log('========================')
	}, [activeMentions, mentionsSearch])



	return (
		<div className={"manage-item " + (data.active ? 'manage-item-active ' + type : '')} ref={data.active ? activeItem : null} >
			<div className="manage-item-header">
				{type === 'queries' ? (
					<div className="manage-item-flagged">
						{data.status === 'resolved' ? (
							<Resolved/>
						) : (
							<Flagged className={data.status} onClick={() => {
									editQueryStatus(data.id, data.status === 'off' ? 'on' : 'off')
									changeQueryStatus(setDocument, data.id, data.status === 'off' ? 'on' : 'off')
							}}/>
						)}
						<span>{index + 1}</span>
					</div>
				) : (
					<div className="manage-item-data">
						<div className="comment-owner">
							{data.owner.avatar ? <img src={data.owner.avatar} alt="" key={index}/> : (
								<>
									{document.status === 'in tender' ? (
										<NoneAvatar className={'none-avatar-svg'}/>
									) : (
										<div className="none-avatar" key={index} style={{backgroundColor: data.owner.bgc}}>
											{data.owner.name[0]?.toUpperCase() + ' ' + data.owner.lastName[0]?.toUpperCase()}
										</div>
									)}
								</>
							)}
							<span>{data.owner.name} {data.owner.lastName} ({data.reply.length})</span>
						</div>
						<div className="created-date">{data.createdDate}</div>
					</div>
				)}
				<div className="manage-item-date">
					{type === 'queries' ? <span>{data.createdDate}</span> : ''}
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
						activeItemManage(data.id, type, setDocument, editor)
					}}>
						<path fillRule="evenodd" clipRule="evenodd" d="M9.3516 4.72516L16.0847 11.4576C16.3842 11.7574 16.3842 12.2428 16.0847 12.5423L9.3516 19.2754C9.05204 19.5749 8.5666 19.5749 8.26705 19.2754L7.72475 18.7331C7.425 18.4337 7.425 17.9478 7.72475 17.6482L13.3732 12L7.72475 6.35161C7.425 6.05225 7.425 5.56657 7.72475 5.26706L8.26705 4.72476C8.56694 4.42501 9.05204 4.42501 9.3516 4.72516Z" fill="#8798AD"/>
					</svg>
				</div>
			</div>
			<div className="manage-item-body">
				<div className="body-header">
					<span>{type === 'queries' ? lang.linkedTo : ''}</span>
					{document.status !== 'in tender' ? (
						<div className="setting-wrapper">
							<Setting className={data.isOpenMenu ? 'open-menu' : ''} onClick={() => openSettingManageItem(index, type, setDocument)}/>
							<div className={"options-list " + (data.isOpenMenu ? 'options-list-active' : '')}>
								<svg width="38" height="21" className="setting-arrow" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.11237 20.5L18.6402 0.753198L36.1679 20.5H1.11237Z" fill="#ffffff" />
								</svg>
								{type === 'queries' && (data.status === 'on' || data.status === 'off') ? (
									<div className="option-item" onClick={() => {
										editQueryStatus(data.id, 'resolved')
										changeQueryStatus(setDocument, data.id, 'resolved')
									}}>
										{lang.makeIsResolved}
									</div>
								) : ''}
								{type === 'queries' && data.status === 'resolved' ? (
									<div className="option-item" onClick={() => {
										editQueryStatus(data.id, 'on')
										changeQueryStatus(setDocument, data.id, 'on')
									}}>
										{lang.unResolve}
									</div>
								) : ''}
								<div className="option-item" onClick={() => {
									deleteCommentFromDocument(data.id, type)
									.then(() => {
										deleteItemManage(editor, setDocument, data.id, setActiveManage)
										saveDocument(searchString, document.id, editorRef, document)
									})
									.catch(() => activeItemManage(data.id, type, setDocument, editor))
									}}>
									{lang.delete}
								</div>
							</div>
						</div>
					) : ''}
				</div>

				<div className="linked-list">
					{data?.links?.map((link, i) => {
						return (
							<div className="tooltip-wrap">
								<span key={i} onClick={()=> {
									setIsOpenFullQueries(true);
									setSearch(link.name);
								}}>@{link.name}</span>
								<div className="tooltip-popup">{link.value?.length > 15 ? link.value?.substring(0, 60) + "..." : link.value}</div>
							</div>
						)
					})}
				</div>
				<div className="manage-item-comments">
					{data.reply.map((comment, i) => {
						const isOwnerComment = comment.owner.id === user._id;
						const company = settings?.company;
						const userName = comment.owner?.external ? `Anonymous ${checkAnonymousId(user._id)}` : company;
						const isUserHasAvatar = isOwnerComment ? user.picture : comment.owner.avatar;
						let isEditable = true;
						if(document.status === 'in tender') {
							const replies = data.reply.slice(i);
							replies.forEach((reply) => {
								if(reply.owner.id !== user._id) {
									isEditable = false;
								}
							})
						}
						return (
							<div className="manage-item-comment-item" key={comment.id + i}>
								<div className="item-header">
									<div className="owner-data">
										{isUserHasAvatar && document.status !== 'in tender' ? (
											<>
												<img src={isUserHasAvatar} alt=""/>
												<span
													className="anonymous-name">{comment.owner.name + " "+ comment.owner.lastName}</span>
											</>
										) : (
											<>
												{document.status === 'in tender' && user?.external ? (
													<>
														<NoneAvatar className={'none-avatar-svg'}/>
														<span
															className="anonymous-name">{isOwnerComment ? user.first_name + " " + user.last_name : userName}</span>
													</>
												) : (
													<>
														{isUserHasAvatar ? <img src={isUserHasAvatar} alt=""/> : <NoneAvatar className={'none-avatar-svg'}/>}
														<span>{comment.owner.name + " "+ comment.owner.lastName}</span>
													</>
												)}
											</>
										)}

									</div>
									<div className="created-date">
										{comment.createdDate}
									</div>
								</div>
								<div className="item-body">
									{comment.isEdit ? (
										<>
											<textarea 
												className={'comment-text comment-text-input'} 
												defaultValue={comment.text} 
												onChange={event => {
													let value = event.target.value
													if(convertSearchString(value).flag){
														userSearch(convertSearchString(value).string)
														.then(res => mentionsHandler(res, value))
													} else {
														setMentionsSearch([])
													}
													mentionsDeleteHandler(event.target.value)
												}}
												onKeyDown={event => {
													if(event.keyCode === 13){
														event.preventDefault()
														if(mentionsSearch.length === 0){
															let newVal = event.target.value
															editReplyComment(setDocument, index, i, newVal, type)
															if(comment.isFirst){
																editComment(data.id, event.target.value, type, activeMentions)
																.finally(() => setActiveMentions([]))
															} else {
																editReplyToComment(data.id, comment.id, event.target.value, type, activeMentions)
																.finally(() => setActiveMentions([]))
															}
														} else {
															let value = event.target.value.split('@').slice(0, -1).join('@').slice(0, -1)
															setActiveMentions(mentions => mentions.concat({ id : mentionsSearch.filter(item => item.active)[0]._id, mention: createMention(mentionsSearch.filter(item => item.active)[0])}))
															event.target.value = value + ' ' + createMention(mentionsSearch.filter(item => item.active)[0]) + ' '
															setMentionsSearch([])
														}
													}  else if (event.keyCode === 38 && mentionsSearch.length > 0){//up
														event.preventDefault()
														if(mentionsSearch.length > 1){
															updateMentions(-1)
														}
													} else if (event.keyCode === 40  && mentionsSearch.length > 0){//down
														event.preventDefault()
														if(mentionsSearch.length > 1){
															updateMentions(1)
														}
													}
												}}
												onBlur={() => {
													editReplyComment(setDocument, index, i, undefined, type)
													setMentionsSearch([])
													setActiveMentions([])
												}}
											></textarea>
											{mentionsSearch.length > 0 ? (
												<div className="mentions-wrapper">
													{mentionsSearch.map((item, index) => {
														return (
															<div className={"mention-item " + (item.active ? 'mention-item-active' : '')} key={index}>
																{createMention(item)}
															</div>
														)
													})}
												</div>
											) : ''}
										</>
									) : <div className="comment-text"
											 dangerouslySetInnerHTML={{
												 __html: comment.text.replace(/(^|\s)@\w+ ?/ig, match => {
													 return `<span class="mention">${match}</span>`
												 })
											 }}
									/>}
									{user && user._id === comment.owner.id && !comment.isEdit && document.status !== 'in tender'
									|| user && user._id === comment.owner.id && !comment.isEdit && document.status === 'in tender' && isEditable ? (
										<div className="edit-comment-btn">
											<img src={edit} alt="" onClick={() => {
												setEditReply(setDocument, index, i, type)
											}}/>
										</div>
									) : ''}
								</div>
							</div>
						)
					})}
				</div>
				<div className="manage-send-comment">
						<img src={user.picture} className="user-avatar" alt=""/>
						<div className="comment-wrapper">
							<input type="text" className='new-comment-input' dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} placeholder={lang.typeMessage} value={newComment}
								onChange={event => {
									let value = event.target.value
									setNewComment(value)
									if(convertSearchString(event.target.value).flag){
										userSearch(convertSearchString(event.target.value).string)
										.then(res => mentionsHandler(res, value))
									} else {
										setMentionsSearch([])
									}
									mentionsDeleteHandler(event.target.value)
								}} 
								onKeyDown={event => {
									if(event.keyCode === 13){
										event.preventDefault()
										if(/^\s*$/.test(newComment)){
											setNewComment('')
											return
										}
										let isAnnotationTagsAdded = true;
										if (data.reply.length === 0) {
											isAnnotationTagsAdded = type === 'queries' ? createAnnotation('query') : createAnnotation('comment');
										}
										let isTemplate = searchString.referer === 'templates'
										if(mentionsSearch.length === 0){
											addReplyToCommentItem(data, document, newComment, setDocument, editor, setActiveManage, type, activeMentions, isTemplate)
											.then(async res => {
												setNewComment('')
												setActiveMentions([])
												setMentionsSearch([])
												await saveDocument(searchString, document.id, editorRef, document)
												return res
											})
											.then( res => {
												activateItemInEditor(editor, type, res._id)
												reloadLine()
											})
										}
										else {
											let value = event.target.value.split('@').slice(0, -1).join('@').slice(0, -1)
											setActiveMentions(mentions => mentions.concat({ id : mentionsSearch.filter(item => item.active)[0]._id, mention: createMention(mentionsSearch.filter(item => item.active)[0])}))
											setNewComment(value + ' ' + createMention(mentionsSearch.filter(item => item.active)[0]) + ' ')
											setMentionsSearch([])
										}
									} else if (event.keyCode === 38){//up
										event.preventDefault()
										if(mentionsSearch.length > 1){
											updateMentions(-1)
										}
									} else if (event.keyCode === 40){//down
										event.preventDefault()
										if(mentionsSearch.length > 1){
											updateMentions(1)
										}
									}
								}}
							/>
							<img src={sendComment} alt=""
								 onClick={event => {
									 if(/^\s*$/.test(newComment)){
									 	setNewComment('')
										 return
									 }
									 if (mentionsSearch.length === 0) {
										 let isAnnotationTagsAdded = true;
										 if (data.reply.length === 0) {
											 isAnnotationTagsAdded = type === 'queries' ? createAnnotation('query') : createAnnotation('comment');
										 }
										 let isTemplate = searchString.referer === 'templates'
										 if (isAnnotationTagsAdded) {
											 addReplyToCommentItem(data, document, newComment, setDocument, editor, setActiveManage, type, activeMentions, isTemplate)
												 .then(async res => {
													 setNewComment('')
													 setActiveMentions([])
													 setMentionsSearch([])
													 await saveDocument(searchString, document.id, editorRef, document)
													 return res
												 })
												 .then(res => {
													 activateItemInEditor(editor, type, res._id)
													 reloadLine()
												 })
										 }
									 } else {
										 let value = event.target.value.split('@').slice(0, -1).join('@').slice(0, -1)
										 setActiveMentions(mentions => mentions.concat({
											 id: mentionsSearch.filter(item => item.active)[0]._id,
											 mention: createMention(mentionsSearch.filter(item => item.active)[0])
										 }))
										 setNewComment(value + ' ' + createMention(mentionsSearch.filter(item => item.active)[0]) + ' ')
										 setMentionsSearch([])
									 }
								 }}
							/>
						</div>
						{mentionsSearch.length > 0 && data.reply.filter(comment => comment.isEdit).length === 0 ? (
							<div className="mentions-wrapper">
								{mentionsSearch.map((item, index) => {
									return (
										<div className={"mention-item " + (item.active ? 'mention-item-active' : '')} key={index}>
											{createMention(item)}
										</div>
									)
								})}
							</div>
						) : ''}
					</div>
			</div>
		</div>
	)
}

export default DocManageItem