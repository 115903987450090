import {ReactComponent as Close} from "../../../assets/img/close.svg";
import {ReactComponent as Check} from "../../../assets/img/check.svg";
import Button from "../../Button/Button";
import {updateDocumentStatus} from "../../../utilites/api";
import React, {useContext} from "react";
import {AppContext} from "../../../context/AppContext";
import "./TenderSubmitPopup.scss";

export const TenderSubmitPopup = ({isOpenTenderPopup, setIsOpenTenderPopup, setDocument, setDontAsk, dontAsk, document}) => {
    const activeLang = useContext(AppContext).activeLang
    const lang = useContext(AppContext).lang
    return (
    <div className={"tender-popup-wrapper " + (isOpenTenderPopup ? 'tender-popup-wrapper-active' : '')}
         onClick={event => {
             if (event.target.classList.contains('tender-popup-wrapper-active')) {
                 setIsOpenTenderPopup(false)
             }
         }}>
        <div className="tender-popup">
            <div className="tender-header">
                <div className="header-title">{lang.inTender}</div>
                <Close onClick={() => setIsOpenTenderPopup(false)}/>
            </div>
            <div className="tender-popup-body">
                <div className="tender-popup-body-title">{lang.statusToTender}<span>{lang.inTender}? </span>
                    <br/>
                    {lang.statusToTenderPartTwo}</div>
                <div className="tender-nav">
                    <div className="dont-ask" onClick={() => setDontAsk(prev => !prev)}>
                        <div className={"checkbox " + (dontAsk ? 'checkbox-active' : '')}>
                            <Check/>
                        </div>
                        <div className="dont-ask-title">{lang.dontAskTender}</div>
                    </div>
                    <div className="tender-btns">
                        <Button color={'white'} padding={12} margin={10} onClick={() => {
                            setIsOpenTenderPopup(false)
                        }}>{lang.cancel}</Button>
                        <Button color={'blue'} padding={12} onClick={() => {
                            setIsOpenTenderPopup(false)
                            setDocument({
                                ...document,
                                isOpenStatusSelect: false,
                                status: 'in tender',
                                isOpenOptions: false
                            })
                            updateDocumentStatus(document.id, 'in_tender')
                        }}>{lang.confirm}</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}