import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'



import {ReactComponent as Flagged} from '../../assets/img/flagged.svg'
import {ReactComponent as Resolved} from '../../assets/img/resolved.svg'
import {ReactComponent as Search} from '../../assets/img/search.svg'
import {ReactComponent as Setting} from '../../assets/img/setting.svg'
import {ReactComponent as Arrow} from '../../assets/img/arrow.svg'
import {ReactComponent as NoneAvatar} from '../../assets/img/nonavatar.svg'

import edit from '../../assets/img/edit.svg'
import sendComment from '../../assets/img/send-comment.svg'

import { 
	editReplyToComment,
	editComment,
	deleteCommentFromDocument,
	editQueryStatus
} from '../../utilites/api'

import {
	editReplyComment,
	setEditReply,
	activeItemManage,
	openSettingManageItem,
	deleteItemManage,
	saveDocument,
	addReplyToCommentItem,
	activateItemInEditor,
	changeQueryStatus
} from '../../utilites/document'

import './Queries.scss'

function Queries ({document, queries, setDocument, isOpen, setIsOpen, setActiveManage, editorRef, searchString, editor, queriesRef, reloadLine, search, setSearch}){


	const [newComment, setNewComment] = useState('')
	const [inputSearch, setInputSearch] = useState('')
	const { user, activeLang, lang} = useContext(AppContext)

	useEffect(() => {
		setNewComment('')
		setInputSearch(search)
	}, [document])

	useEffect(() => {
		setInputSearch(search)
	}, [isOpen])

	return (
		<div ref={queriesRef} className={"queries-popup " + (isOpen ? 'queries-popup-active ' : '') + (activeLang === 'ar' ? 'queries-popup-arabic' : '')}>
			<div className="queries-popup-header">
				<div className="total-queries">
					<div className="queries-count">
						{lang.totalQueries}: {queries.length}
					</div>
					<div className="queries-flagged">
						<Flagged/>
						<span>{queries.length} {lang.flagged}</span>
					</div>
				</div>
				<div className="queries-nav">
					<div className="queries-search">
						<Search/>
						<input type="text" placeholder={lang.searchQueries} dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={inputSearch} onKeyDown={event => {
							if(event.keyCode === 13) {
								event.preventDefault()
								if(inputSearch !== ''){
									setSearch(inputSearch)
								}
							}}}
							onChange={event => {
								if(event.target.value === ''){
									setInputSearch(event.target.value)
									setSearch(event.target.value)
								}else{
									setInputSearch(event.target.value)
								}
							}}/>
						<button onClick={() => {
							setInputSearch('')
							setSearch('')
						}}>{lang.clearSearch}</button>
					</div>
					<div className="collapse-btn" onClick={() => setIsOpen(false)}>
						<span>{lang.collapseView}</span>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M4.22951 8.45805H7.3959L2.70643 3.74854C2.40583 3.46797 2.40583 2.987 2.70643 2.68639C2.987 2.38579 3.46797 2.38579 3.76858 2.68639L8.45805 7.37586V4.22951C8.45805 3.80866 8.79873 3.46797 9.21958 3.46797C9.64043 3.46797 9.98112 3.80866 9.98112 4.22951V9.21958C9.98112 9.64043 9.64043 9.98112 9.21958 9.98112H4.22951C3.80866 9.98112 3.46797 9.64043 3.46797 9.21958C3.46797 8.79873 3.80866 8.45805 4.22951 8.45805ZM19.811 9.96108H14.8209C14.4 9.96108 14.0594 9.62039 14.0393 9.19954V4.20947C14.0393 3.78862 14.38 3.44793 14.8009 3.44793C15.2217 3.44793 15.5624 3.78862 15.5624 4.20947V7.37586L20.2519 2.70643C20.5324 2.40583 21.0134 2.40583 21.314 2.70643C21.6146 2.987 21.6146 3.46797 21.314 3.76858L16.6446 8.43801H19.811C20.2318 8.43801 20.5725 8.77869 20.5725 9.19954C20.5725 9.62039 20.2318 9.96108 19.811 9.96108ZM8.45805 16.622L3.76858 21.2914C3.46797 21.592 2.987 21.592 2.68639 21.3114C2.38579 21.0309 2.38579 20.5499 2.68639 20.2493L7.37586 15.5598H4.22951C3.80866 15.5598 3.46797 15.2191 3.46797 14.7983C3.46797 14.3774 3.80866 14.0367 4.22951 14.0367H9.21958C9.64043 14.0367 9.98112 14.3774 9.98112 14.7983V19.7884C9.98112 20.2092 9.64043 20.5499 9.21958 20.5499C8.79873 20.5499 8.45805 20.2092 8.45805 19.7884V16.622ZM14.8009 20.5725C15.2217 20.5725 15.5624 20.2318 15.5624 19.811V16.6446L20.2318 21.314C20.5324 21.6146 21.0134 21.6146 21.294 21.314C21.5946 21.0134 21.5946 20.5324 21.294 20.2519L16.6245 15.5624H19.7909C20.2118 15.5624 20.5525 15.2217 20.5525 14.8009C20.5525 14.38 20.2118 14.0393 19.7909 14.0393H14.8009C14.38 14.0393 14.0393 14.38 14.0393 14.8009V19.811C14.0393 20.2318 14.38 20.5725 14.8009 20.5725Z" fill="#BFC5D2"/>
						</svg>
					</div>
				</div>
			</div>
			<div className="queries-popup-body">
				<div className="table-header">
					<span># <Arrow className="arrow"/></span>
					<span>{lang.date} <Arrow className="arrow"/></span>
					<span>{lang.linkedTo} <Arrow className="arrow"/></span>
					<span>{lang.query}<Arrow className="arrow"/></span>
				</div>
				<div className="queries-list">
					{queries.length > 0 ? queries.map((query, index) => {
						return (
							<div className={"query-item " + (query.active ? 'query-item-active' : '')} key={index} onClick={event => {
								if(!event.target.closest('.flag') && !event.target.parentElement.closest('.flag') && !event.target.closest('.comment-wrapper') && !event.target.parentElement.closest('.comment-wrapper') && !event.target.closest('.edit-comment-btn') && !event.target.parentElement.closest('.edit-comment-btn') && !event.target.closest('.comment-text-input')){
									activeItemManage(query.id, 'queries', setDocument, editor)
								}
							}}>
								<div className="query-flagged">
									{query.status === 'resolved' ? (
										<Resolved/>
									) : (
										<Flagged className={query.status + ' flag'} onClick={() => {
											if(query.status === 'off'){
												editQueryStatus(query.id, 'on')
												changeQueryStatus(setDocument, query.id, 'on')
											} else if (query.status === 'on'){
												editQueryStatus(query.id, 'off')
												changeQueryStatus(setDocument, query.id, 'off')
											}
										}}/>
									)}
									<span>{index + 1}</span>
								</div>
								<span className="query-date">{query.createdDate}</span>
								<div className="linked-list">
									 {query?.links?.map((link, i) => {
										return (
											<div className="tooltip-wrap">
												<span key={i} onClick={()=> {
													setInputSearch(link.name)
													setSearch(link.name)
												}}>@{link.name?.length > 15 ? link.name?.substring(0, 15) + "..." : link.name}</span>
												<div className="tooltip-popup">{link.value}</div>
											</div>
										)
									})}
								</div>
								<div className="query-comments">
									{query.reply.map((comment, i) => {
										return (
											<div className="query-comment-item" key={i}>
												<div className="owner-data">
												{comment.owner.avatar ? (
														<img src={comment.owner.avatar} alt=""/>
													) : (
														<>
															{document.status === 'in tender' ? (
																<NoneAvatar className={'none-avatar-svg'}/>
															) : (
																<div className="none-avatar" key={index} style={{backgroundColor: query.owner.id ===  comment.owner.id ? query.owner.bgc : comment.owner.bgc}}>
																	{(comment.owner?.name + (comment.owner.lastName?.length > 0 ? comment.owner?.lastName : '')).toUpperCase()}
																</div>
															)}
														</>
													)}
													<span>{comment.owner.name}</span>
												</div>
												{comment.isEdit ? (
													<textarea name="" id="" className={'comment-text comment-text-input'} defaultValue={comment.text} onKeyDown={event => {
														if(event.keyCode === 13){
															event.preventDefault()
															let newVal = event.target.value
															if(comment.isFirst){
																editComment(comment.id, event.target.value, 'queries')
																.then(res => {
																	editReplyComment(setDocument, index, i, newVal, 'queries')
																})
															} else {
																editReplyToComment(query.id, comment.id, event.target.value, 'queries')
																.then(res => {
																	editReplyComment(setDocument, index, i, newVal, 'queries')
																})
																.catch(() => editReplyComment(setDocument, index, i, newVal, 'queries'))
															}
														}
													}} onBlur={event => {
														let newVal = event.target.value
														if(comment.isFirst){
															editComment(comment.id, event.target.value, 'queries')
															.then(res => {
																editReplyComment(setDocument, index, i, newVal, 'queries')
															})
														} else {
															editReplyToComment(query.id, comment.id, event.target.value, 'queries')
															.then(res => {
																editReplyComment(setDocument, index, i, newVal, 'queries')
															})
															.catch(() => editReplyComment(setDocument, index, i, newVal, 'queries'))
														}
													}}></textarea>
												) : <div className="comment-text">{comment.text}</div>}
												{user._id === comment.owner.id && !comment.isEdit && document.status !== 'in tender' ? (
													<div className="edit-comment-btn">
														<img src={edit} alt="" onClick={() => {
															setEditReply(setDocument, index, i, 'queries')
														}}/>
													</div>
												) : ''}
												<div className="created-date">
													{comment.createdDate}
												</div>
											</div>
										)
									})}
									{document.status !== 'in tender' ? (
										<div className="query-send-comment">
											<img src={user.picture} className="user-avatar" alt=""/>
											<div className="comment-wrapper">
												<input type="text" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} placeholder={lang.typeMessage} value={newComment} onChange={event => setNewComment(event.target.value)} onKeyDown={event => {
													if(event.keyCode === 13){
														addReplyToCommentItem(query, document, newComment, setDocument, editor, setActiveManage, 'queries')
														.then(async res => {
															await saveDocument(searchString, document.id, editorRef, document)
															return res
														})
														.then( res => {
															activateItemInEditor(editor, 'queries', res._id)
															reloadLine()
														})
													}
												}}/>
												<img src={sendComment} alt="" onClick={() => {
													addReplyToCommentItem(query, document, newComment, setDocument, editor, setActiveManage, 'queries')
													.then(async res => {
														await saveDocument(searchString, document.id, editorRef, document)
														return res
													})
													.then( res => {
														activateItemInEditor(editor, 'queries', res._id)
														reloadLine()
													})
												}}/>
											</div>
										</div>
									) : ''}
								</div>
								{document.status !== 'in tender' ? (
									<div className="setting-wrapper">
										<Setting className={query.isOpenMenu ? 'open-menu' : ''} onClick={(event) => {
											event.stopPropagation()
											openSettingManageItem(index, 'queries', setDocument)
											}}/>
										<div className={"options-list " + (query.isOpenMenu ? 'options-list-active' : '')}>
											<svg width="38" height="21" className="setting-arrow" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M1.11237 20.5L18.6402 0.753198L36.1679 20.5H1.11237Z" fill="#ffffff" />
											</svg>
											{query.status === 'on' ? (
												<div className="option-item" onClick={() => {
														editQueryStatus(query.id, 'resolved')
														changeQueryStatus(setDocument, query.id, 'resolved')
													}}>
													{lang.makeIsResolved}
												</div>
											) : ''}
											<div className="option-item" onClick={(event) => {
												event.stopPropagation()
												deleteCommentFromDocument(query.id, 'queries')
												.then(() => {
													deleteItemManage(editor, setDocument, query.id, setActiveManage)
													saveDocument(searchString, document.id, editorRef, document)
												})
												.catch(() => activeItemManage(query.id, 'queries', setDocument, editor))
											}}>
												{lang.delete}
											</div>
										</div>
									</div>
								) : ''}
							</div>
						)
					}) : (
						<div className="manage-empty">
							{lang.noQueries}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Queries