import React, { useContext } from 'react';

import {ReactComponent as Plus} from '../../assets/img/plus.svg'

import { AppContext } from '../../context/AppContext'

import './Button.scss'

function Button ({children, plus, color, margin, padding, onClick, disabled, plusMargin, style}){

	let activeLang = useContext(AppContext).activeLang

	return (
		<button className={color} style={activeLang === 'ar' ? {marginLeft: margin, paddingLeft: padding, paddingRight: padding, flexDirection: 'row-reverse', ...style} : {marginRight: margin, paddingLeft: padding, paddingRight: padding, ...style}} onClick={onClick} disabled={disabled}>
			{plus ? <Plus style={activeLang === 'ar' ? {marginLeft: plusMargin || 10, marginRight: 0} : {marginRight: plusMargin}}/> : ''}
			{children}
		</button>
	)
}

export default Button