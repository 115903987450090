import React, { useState, useEffect, useContext, useRef } from 'react'
import {Link, useHistory, useParams, useLocation} from 'react-router-dom'

import {AppContext} from '../../context/AppContext'
import useFileName from '../../hooks/useFileName'

import { 
	addItemToFavoritesList, dateConvert
} from '../../utilites/appfunctions.js'
import {
	deleteDoc 
} from '../../utilites/table.js'
import {
	getCompanies,
	getCompany,
	updateCompanyDetails,
	addToFavoriteCompany,
	changeNameCompany,
	deleteCompany,
	restoreCompany,
	deleteProject,
	updateProject,
	unlinkDocToCompany,
	createCompany,
	getDocument,
	getSettings,
} from '../../utilites/api'
import {
	companyConvert
} from '../../utilites/essence'

// import {
// 	getConverter
// } from '../../utilites/getconverter'

import {uploader} from '../../utilites/uploader.js'

import PageNav from '../Nav/PageNav/PageNav'
import ProjectItem from '../Dashboard/ProjectItem/ProjectItem'
import Button from '../Button/Button'
import DocTable from '../DocTable/DocTable'
import Push from '../Push/Push'
import PlusNav from '../PlusNav/PlusNav'
import MobileOptions from '../MobileOptions/MobileOptions'
import FileName from '../FileName/FileName'
import Loader from '../Loader/Loader'
import Uploader from '../Projects/Uploader/Uploader'
import CalendarComponent from '../CalendarComponent/CalendarComponent'
import TabSettings from '../TabSettings/TabSettings'

import CreateNewFile from '../Popups/CreateNewFile/CreateNewFile'
import DeleteFile from '../Popups/DeleteFile/DeleteFile'
import UploadFiles from '../Popups/UploadFiles/UploadFiles'
import ShareFiles from '../Popups/ShareFiles/ShareFiles'

import {ReactComponent as CompanyIcon} from '../../assets/img/menu/companies.svg'
import {ReactComponent as Calendar} from '../../assets/img/calendar.svg'
import {ReactComponent as CompanyMenuIcon} from '../../assets/img/company.svg'
import {ReactComponent as Plus} from '../../assets/img/plus.svg'

import './Companies.scss'
import './CompaniesMedia.scss'

function Companies(){
	let {lang, activeLang, user} = useContext(AppContext)

	const [companies, setCompanies] = useState([])
	const [company, setCompany] = useState([])
	const [companiesContent, setCompaniesContent] = useState(1)
	const [isLoadComplete, setIsLoadComplete] = useState(true)

	const history = useHistory()
	const location = useLocation()
	const { id } = useParams()


	const [push, setPush] = useState(false)
	const [pushText, setPushText] = useState("PROJECT ARCHIVED")
	const [pushError, setPushError] = useState(false)
	const [saved, setSaved] = useState(false)


	const filename = useFileName(setCompanies, setCompany, company, changeNameCompany)

	
	const [isVisibleDeleteFiles, setIsVisibleDeleteFiles] = useState(false)

	//popups
	const [share, setShare] = useState(false)


	const [isEdit, setIsEdit] = useState(false)
	const [inputsDetails, setInputsDetails] = useState([])
	const [createFile, setCreateFile] = useState(false)


	const deletedRef = useRef()
	const files = useRef()
	
	let [isOpenCompaniesNavMobile, setIsOpenCompaniesNavMobile] = useState(false)

	const [onUploadFiles, setOnUploadFiles] = useState([])
	const [startUploadList, setStartUploadList] = useState([])
	const [isConvert, setIsConvert] = useState(false)
	const [isVisibleUploader, setIsVisibleUploader] = useState(false)

	const [deleteFile, setDeleteFile] = useState(false)
	const [fileToDelete, setFileToDelete] = useState(null)

	const [actualFormats, setActualFormats] = useState([])
	const [actualTypes, setActualTypes] = useState([])


	const companyCreate = async () => {
		let nameCount = companies.filter(item => item.name.includes('Untitled')).length
		createCompany(
			nameCount === 0 ? 'Untitled' : 'Untitled (' + nameCount + ')',
		)
		.then(async res => {
			history.push(`/companies/${res.data.data._id}`)
			setCompanies(prev => prev.concat(res.data.data))
			setCompany(await companyConvert(res.data.data))
			setTimeout(() => {
				filename.setIsChange(true)
			}, 1200)
		})
	}

	const changeCalendarDate = newValue => {
		setInputsDetails(details => {
			return details.map(item => {
				if(item.isEdit){
					return {
						...item,
						value: newValue ? dateConvert(newValue, 'ts') : item.value,
						isEdit: false
					}
				}
				return item
			})
		})
	}

	const archiveProject = id => {
		setPushText('PROJECT ARCHIVED')
		setPush(true)
		setTimeout(() => {
			setPush(false)
		}, 1200)
		deleteProject(id)
		setCompany(company => {
			return {
				...company,
				projects: company.projects.map(project => {
					if(project._id === id){
						console.log('asdfasdfasdf3242')
						return {
							...project,
							deleted_at: Date.now()
						}
					}
					return project
				})
			}
		})
	}

	const favouritedProject = id => {
		setCompany(company => {
			return {
				...company,
				projects: company.projects.map(project => {
					if(project._id === id){
						updateProject({ favourite: !project.favourite}, id)
						if(!project.favourite){
							setPushText('ADD TO FAVOURITE')
							setPush(true)
							setTimeout(() => {
								setPush(false)
							}, 1200)
						}
						return {
							...project,
							favourite: !project.favourite
						}
					}
					return project
				})
			}
		})
	}

	const renameProject = (id, newVal) => {
		setCompany(company => {
			return {
				...company,
				projects: company.projects.map(project => {
					if(project._id === id){
						return {
							...project,
							name: newVal
						}
					}
					return project
				})
			}
		})
	}

	const openCalendar = id => {
		setInputsDetails(details => {
			return details.map((item, index) => {
				if(id === index){
					return {
						...item,
						isEdit: !item.isEdit
					}
				}
				return {
					...item,
					isEdit: false
				}
			})
		})
	}

	const updateInputs = (id, input) => {
		setInputsDetails(details => {
			return details.map((item, index) => {
				if(id === index){
					return {
						...item,
						value: input.value
					}
				}
				return item
			})
		})
	}

	const updateCompanyDetailsFront = data => {
		setCompany(company => {
			return {
				...company,
				details: data
			}
		})
	}

	useEffect(() => {
		if(isVisibleDeleteFiles){
			window.scrollBy({
				top: deletedRef.current?.offsetTop - 50,
				behavior: "smooth"
			})
		}
	}, [isVisibleDeleteFiles])


	useEffect(() => {
		console.log(company)
		setInputsDetails(company.details)
	}, [company])

	const getCompanyData = id => {
		Promise.all([getCompany(id), getSettings()])
		.then(async res => {
			setCompany(await companyConvert(res[0].data))
			setActualTypes(res[1].document_type_for_upload.filter(item => {
				if(item.active){
					return true
				} else {
					return false
				}
			}).map(item => item.name))
			setIsLoadComplete(false)
		})
	}

	const companyDetect = (companies) => {
		if(id && companies.length > 0){
			getCompanyData(id)
		} else {
			if(companies.length > 0){
				const active = companies.find(item => {
					if(!item.deleted_at){
						return true
					}
					return false
				})
				if(active){
					getCompanyData(active._id)
					history.push(`/companies/${active._id}`)
				} else {
					getCompanyData(companies[0]._id)
					history.push(`/companies/${companies[0]._id}`)
				}
			}
		}
	}

	
	useEffect(() => {
		companyDetect(companies)
	}, [location])

	useEffect(() => {
        async function getAllCompanies(){
            getCompanies()
            .then(res => {
				if(res.data.length === 0){
					setIsLoadComplete(false)
					history.push('/companies')
				} else {
					setCompanies(res.data)
					companyDetect(res.data)
				}
            })
        }
        getAllCompanies()
    }, [])

	return (
		<div className={"companies " + (activeLang === 'ar' ? 'companies-arabic' : '')}>
			<Loader isVisible={isLoadComplete}/>
			{isLoadComplete === false ? (
				<>
					<PageNav list={companies} listIcon={<CompanyMenuIcon/>} page={lang.companies} createListItem={() => companyCreate(companies)} activeItem={company.id}/>
					<CreateNewFile createFile={createFile} setCreateFile={setCreateFile} setItem={setCompany} item={company} tab={'companies'} setPush={setPush} setPushText={setPushText} setPushError={setPushError}/>
					<DeleteFile setPush={setPush} setPushText={setPushText} fileToDelete={fileToDelete} setDeleteFile={setDeleteFile} setItem={setCompany} item={company} deleteDoc={deleteDoc} deleteFile={deleteFile} unlinkFunction={unlinkDocToCompany}/>		
					<UploadFiles onUploadFiles={onUploadFiles} setOnUploadFiles={setOnUploadFiles} actualFormats={actualFormats} setPushError={setPushError} setPush={setPush} setPushText={setPushText} setItem={setCompany} item={company} setIsVisibleUploader={() => false} setIsConvert={setIsConvert} setStartUploadList={setStartUploadList}/>
					{companies?.length ? <ShareFiles 
						share={share} 
						setShare={setShare} 
						setPush={setPush} 
						setPushText={setPushText} 
						pushText={pushText} 
						list={companies} 
						setList={setCompanies} 
						doc={company?.documents?.filter(item => item.isSelect)[0]}/> : ""}
					<Uploader files={startUploadList} convert={isConvert} isVisible={isVisibleUploader} setIsVisible={setIsVisibleUploader}/>
					<div className={"companies-content " + (companies.length === 0 ? 'companies-content-empty' : '')}>
						{companies.length === 0 ? (
							<div className="companies-empty-wrapper">
								<CompanyMenuIcon className="empty-icon"/>
								<div className="empty-title">{lang.welcomeToNewCompany}</div>
								<Button plus={true} padding={16} color={'blue'} onClick={() => {
									companyCreate()
								}}>{lang.newCompany}</Button>
							</div>
						) : (
							<>
								{push ? <Push top={0} padding={8} isError={pushError}>{pushText}</Push> : ''}
								<div className="companies-breadcrumbs">
									<span>{activeLang === 'ar' ? ' < ' + lang.companies : lang.companies + ' > '} </span>
									<span className="breadcrumbs-active">{company.name}  {activeLang === 'ar' ? "\u00A0" : ''} </span>
								</div>
								<div className="companies-content-header">
									<div className="companies-content-header-name">
										<CompanyIcon className="company-icon"/>
										<FileName filename={filename}/>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={async () => {
											addItemToFavoritesList(setCompanies, company.id, setPushText, setPush)
											addToFavoriteCompany(!company.favourite, company.id)
											setCompany(company => ({...company, favourite: !company.favourite}))
										}}>
											<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" fill={company.favourite ? '#8798AD' : '#ffffff'} stroke="#8798AD" strokeWidth="2" strokeLinejoin="round"/>
										</svg>
									</div>
									<TabSettings
										item={company}
										setItem={setCompany}
										setList={setCompanies}
										isVisibleDeleteFiles={isVisibleDeleteFiles}
										setIsVisibleDeleteFiles={setIsVisibleDeleteFiles}
										setPush={setPush}
										setPushText={setPushText}
										setChangeName={filename.setIsChange}
										deleteListItem={deleteCompany}
										restoreListItem={restoreCompany}
									/>
								</div>
								<PlusNav isOpenOptions={isOpenCompaniesNavMobile} setIsOpenOptions={setIsOpenCompaniesNavMobile}/>
								<MobileOptions isOpen={isOpenCompaniesNavMobile} setIsOpen={setIsOpenCompaniesNavMobile} isNewProject={true} isOne={companiesContent === 1 ? false : true} isUpload={companiesContent === 1 ? "project-upload-file" : false} isNewFile={companiesContent === 1 ? setCreateFile : false} isDownload={companiesContent === 1 ? true : false} isShare={companiesContent === 1 ? setShare : false}/>
								<div className="companies-content-nav">
									<div className="nav-wrap">
										<button className={"companies-nav-btn " + (companiesContent === 1 ? 'companies-nav-btn-active' : '')} onClick={() => setCompaniesContent(1)}>{lang.details}</button>
										<button className={"companies-nav-btn " + (companiesContent === 0 ? 'companies-nav-btn-active' : '')} onClick={() => setCompaniesContent(0)}>{lang.projects}</button>
									</div>
									<Link to={`/projects/new?company=${company?.id}`} style={{textDecoration: 'none'}}>
										<Button padding={16} plus={true} color={'blue'}>{lang.newProject}</Button>
									</Link>
								</div>
								<div className="companies-content-body">
									{company?.projects && companiesContent === 0  ? (
										<div className="companies-content-projects">
											{company.projects.filter(project => !project.deleted_at).map((item, index) => {
												return <ProjectItem project={item} nav={true} archiveProject={archiveProject} favouritedProject={favouritedProject} renameProject={renameProject} id={index} key={index}/>
											})}
										</div>
									) : (
										<div className="companies-content-details">
											<div className="general">
												<div className="general-header">
													{saved ? <Push top={0} padding={8}>{pushText}</Push> : ''}
													<div className="general-header-title">{lang.generalInfo}</div>
													<Button color={'white'} padding={10} disabled={isEdit ? true : false} onClick={() => setIsEdit(true)}>{lang.edit}</Button>
												</div>
												<div className="general-body">
													{company?.details?.length > 0 ? (
														<>
															<div className="general-list">
																{inputsDetails?.map((item, index) => {
																	return (
																		<div className="general-list-item" key={index}>
																			<div className="item-name">
																				{item.field.name}
																			</div>
																			{item.field.field_type === 'string' ? (
																				<div className={"item-value-wrapper "  + (isEdit ? 'item-value-edit' : '')}>
																					<input type="text" className="item-value-input" name={item.id} value={item.value || ''}
																						   onChange={(event) => updateInputs(index, event.target)} readOnly={!isEdit ? "readonly" : false}/>
																				</div>
																			) : (
																				<div className={"item-value-wrapper "  + (isEdit ? 'item-value-edit' : '')}>
																					<div className={"item-value-wrapper "} onClick={() => openCalendar(index)}>
																						<div className="item-value-no-edit ">{dateConvert(item.value, 'd') || ''}</div>
																					</div>
																					{isEdit ? <Calendar onClick={() => openCalendar(index)}/> : ''}
																					{item.isEdit ? <CalendarComponent  active={'expiry'} style={{display: item.isEdit ? 'flex' : 'none'}} changeCalendar={changeCalendarDate}/> : ''}
																				</div>
																			)}
																		</div>
																	)
																})}
															</div>
															{isEdit ? (
																<div className="general-btns">
																	<Button color={'white'} padding={12} margin={10} onClick={() => {
																		setInputsDetails(company.details)
																		setIsEdit(false)
																	}}>{lang.cancel}</Button>
																	<Button color={'blue'} padding={12} onClick={async () => {
																		updateCompanyDetails(company.id, inputsDetails)
																		updateCompanyDetailsFront(inputsDetails)
																		setPushText(lang.updateSaved)
																		setSaved(true)
																		setTimeout(() => {
																			setSaved(false)
																		}, 1200)
																		setIsEdit(false)
																	}}>{lang.save}</Button>
																</div>
															) : ''}
														</>
													) : ''}
												</div>
											</div>
											<div className="documents">
												<div className="documents-header">
													{lang.documents}
												</div>
												<div className="documents-body">
													<div className="documents-body-btns">
														<input ref={files} type="file" id="project-upload-file" multiple onChange={async () => {
															let uploadControl = uploader(files.current.files)
															setOnUploadFiles(uploadControl)
														}}/>
														<label htmlFor="project-upload-file" className="upload-btn">
															<Plus/>
															<span>{lang.uploadFiles}</span>
														</label>
														<Button color={'white'} plus={true} padding={13} margin={10} plusMargin={5} onClick={() => {
															setCreateFile(true)
														}}>{lang.newFile}</Button>
														<Button color={'white'} padding={13} margin={10} disabled={company?.documents?.filter(item => item.isSelect).length === 1 ? false : true} onClick={() => {
															setShare(true)
														}}>{lang.share}</Button>
														<Button color={'white'} padding={13} disabled={company?.documents?.filter(doc => doc.isSelect).length === 0 ? true : false} onClick={() => {
															getDocument(company.documents?.filter(doc => doc.isSelect)[0].files.pop().filename, true)
														}}>{lang.download}</Button>
													</div>
													{company.documents?.filter(doc => doc.favorite).length > 0 || company.delDoc?.length > 0 ? (
														<>
															<div className="company-docs-list-header">{lang.allFiles}</div>
															<DocTable
																documents={company.documents}
																setItem={setCompany} itemPar={company}
																groupTemplateId={company.id}
																actualTypes={actualTypes} type={'documents'} folders={[]}
																setFileToDelete={setFileToDelete} setDeleteFile={setDeleteFile}
																setPush={setPush} setPushText={setPushText}/>
															{(company.delDoc.length > 0 && isVisibleDeleteFiles) ? (
																<>
																	<div className="company-docs-list-header"
																		 ref={deletedRef}>{lang.deletedFiles}</div>
																	<DocTable
																		documents={company.delDoc}
																		type={'delDoc'} setItem={setCompany} itemPar={company}
																		groupTemplateId={company.id}
																		actualTypes={actualTypes} folders={[]}
																		setFileToDelete={setFileToDelete}
																		setDeleteFile={setDeleteFile} setPush={setPush}
																		setPushText={setPushText}/>
																</>
															) : ''}
														</>
													) : (
														<DocTable
															documents={company?.documents}
															setItem={setCompany} itemPar={company}
															groupTemplateId={company?.id}
															actualTypes={actualTypes}  type={'documents'}
															folders={[]} setFileToDelete={setFileToDelete}
															setDeleteFile={setDeleteFile} setPush={setPush}
															setPushText={setPushText}/>
													)}
												</div>
											</div>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</>
			) : ''}
		</div>
	)
}


export default Companies