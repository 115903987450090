// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%; }

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }
  *:focus {
    outline: unset; }

.tox-notifications-container {
  display: none; }

@media screen and (max-width: 600px) {
  html, body {
    height: -webkit-fill-available; } }
`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACC,gCAAgC;EAChC,eAAe;EACf,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,8BAA8B,EAAA;;AAM/B;EACC,sBAAsB;EACtB,wCAAwC,EAAA;EAFzC;IAIE,cACD,EAAA;;AAED;EACC,aAAa,EAAA;;AAEd;EACC;IACC,8BAA+B,EAAA,EAC/B","sourcesContent":["html, body{\n\tfont-family: 'Rubik', sans-serif;\n\tfont-size: 20px;\n\tmargin: 0;\n\tpadding: 0;\n\toverflow-x: hidden;\n\t-webkit-text-size-adjust: 100%;\n}\n// html{\n// \toverflow-y: scroll;\n// }\n// img { pointer-events: none; }\n*{\n\tbox-sizing: border-box;\n\t-webkit-tap-highlight-color: transparent;\n\t&:focus{\n\t\toutline : unset\n\t}\n}\n.tox-notifications-container{\n\tdisplay: none;\n}\n@media screen and (max-width: 600px){\n\thtml, body{\n\t\theight:  -webkit-fill-available;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
