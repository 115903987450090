import React, {useContext, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {Editor} from '@tinymce/tinymce-react';
import {io} from 'socket.io-client'
import useFileName from '../../hooks/useFileName'
import useLine from '../../hooks/useLine'
import Button from '../Button/Button'
import Queries from '../Queries/Queries'
import Push from '../Push/Push'
import Loader from '../Loader/Loader'
import ErrorPage from "../ErrorPage/ErrorPage";
import FileName from '../FileName/FileName'
import ShareFiles from '../Popups/ShareFiles/ShareFiles'
import CommentsMobile from './CommentsMobile/CommentsMobile'
import QueriesMobile from './QueriesMobile/QueriesMobile'
import HistoryMobile from './HistoryMobile/HistoryMobile'
import DocMobileNav from './DocMobileNav/DocMobileNav'
import SelectBtns from './SelectBtns/SelectBtns'
import DocManage from './DocManage/DocManage'
import {AppContext} from '../../context/AppContext'
import shareImg from '../../assets/img/share.svg'
import save from '../../assets/img/save.svg'
import {ReactComponent as Check} from '../../assets/img/check.svg'
import {ReactComponent as Close} from '../../assets/img/close.svg'
import {ReactComponent as Plus} from '../../assets/img/plus.svg'
import {ReactComponent as Arrow} from '../../assets/img/arrow.svg'
import './Document.scss'
import './DocumentMedia.scss'
import {
    backupDocument,
    deleteTemplate, documentNotificationChange,
    getDocument,
    getDocumentId, getDocumentNotificationStatus,
    getManage, getSettings,
    getShareDocument,
    getShareTemplate,
    getTemplate, getTemplateManage, searchQuery, searchTemplateQuery,
    unlinkDocToCompany,
    unlinkDocToProject,
    updateDocument,
    updateDocumentFavourite,
    updateDocumentStatus, updateDocumentType,
    updateTemplateName,
    updateTemplateStatus,
    updateTimeoutUser, uploadPicture
} from '../../utilites/api';

import {dateConvert, randomColor} from '../../utilites/appfunctions'

import {getConverter} from '../../utilites/getconverter'

import {
    addEventsToItemToEditor,
    addItemManage,
    convertManageItem,
    removeActiveItemsInEditor,
    saveDocument
} from '../../utilites/document'
import {TenderSubmitPopup} from "./Popups/TenderSubmitPopup";
import {TenderToOtherPopup} from "./Popups/TenderToOtherPopup";
import {TenderConfirmPublishPopup} from "./Popups/TenderConfirmPublishPopup";
const diff = require('node-htmldiff');
const differ = require('diff')

function Document() {
    const searchString = getConverter(window.location.search)
    const user = useContext(AppContext).user
    const activeLang = useContext(AppContext).activeLang
    const lang = useContext(AppContext).lang
    const setIsLoginPage = useContext(AppContext).setIsLoginPage
    let [isLoadComplete, setIsLoadComplete] = useState(true)
    let [isLoadContentComplete, setIsLoadContentComplete] = useState(false)
    let [isCurrentVersion, setIsCurrentVersion] = useState(true);
    let [isErrorPage, setIsErrorPage] = useState(false);

    const [share, setShare] = useState(false)
    // const [push, setPush] = useState(false)
    const [pushText, setPushText] = useState('')
    let [editorContent, setEditorContent] = useState('')
    let [statusList, setStatusList] = useState([
        'review',
        'in tender',
        'approved',
        'signed',
        'draft',
    ])
    let [currentStatusDocument, setCurrentStatusDocument] = useState(null);
    let [isOpenChangeTenderPopup, setIsOpenChangeTenderPopup] = useState(false);
    const {id, link} = useParams()
    const history = useHistory()
    let [search, setSearch] = useState('')
    let [searchQueries, setSearchQueries] = useState(null)
    let [searchDocument, setSearchDocument] = useState('')
    let [document, setDocument] = useState(null)
    let [documentToUpload, setDocumentToUpload] = useState(null)
    let [isActive, setIsActive] = useState(false)
    let [editor, setEditor] = useState(null)
    let [editors, setEditors] = useState(null)
    const isMobile = useContext(AppContext).isMobile

    let [settings, setSettings] = useState(null);
    let activeItem = useRef()
    let manageWrapper = useRef()
    let [isOpenFullQueries, setIsOpenFullQueries] = useState(false)

    const [prevEditorContent, setPrevEditorContent] = useState(null)
    const [isEdited, setIsEdited] = useState(false)

    const filename = useFileName(null, setDocument, document, searchString.referer === 'templates' ? updateTemplateName : updateDocumentType)
    const {
        isVisibleLine,
        lineChars,
        reloadLine,
        setIsVisibleLine
    } = useLine(false, editor, (isMobile ? false : document), activeItem, manageWrapper, activeLang, isOpenFullQueries)

    let [selectTextInEditor, setSelectTextInEditor] = useState('')
    let [isVisibleSelectBtns, setIsVisibleSelectBtns] = useState(false)
    let [mouse, setMouse] = useState({x: 0, y: 0})
    let [selectNewQuery, setSelectNewQuery] = useState(false)
    let [manageFullView, setManageFullView] = useState(true)
    let [activeManage, setActiveManage] = useState(0)

    let [isOpenRevertPopup, setIsOpenRevertPopup] = useState(false)
    let [revertVersion, setRevertVersion] = useState(false)
    let [selectedStatus, setSelectedStatus] = useState(null);
    let [isOpenTenderPopup, setIsOpenTenderPopup] = useState(false)
    let [isOpenSignaturePopup, setIsOpenSignaturePopup] = useState(false)
    let [isOpenConfirmPublishPopup, setIsOpenConfirmPublishPopup] = useState(false)
    let [signatureList, setSignatureList] = useState([])
    let [isOpenInitialsPopup, setIsOpenInitialsPopup] = useState(false)
    let [initialsList, setInitialsList] = useState([])
    let [newInitial, setNewInitial] = useState('')
    let [addInitialToAllPages, setAddInitialsToAllPages] = useState(0)
    let [isOpenSigningPopup, setIsOpenSigningPopup] = useState(false)
    let [copyEmailList, setCopyEmailList] = useState([])
    let [newCopyEmail, setNewCopyEmail] = useState('')
    let [dontAsk, setDontAsk] = useState(false)
    let queriesRef = useRef()
    let [queriesHeight, setQueriesHeight] = useState(0)
    let optionsRef = useRef()
    let [isOpenQueriesMobile, setIsOpenQueriesMobile] = useState(false)
    let [isOpenCommentsMobile, setIsOpenCommentsMobile] = useState(false)
    let [isOpenHistoryMobile, setIsOpenHistoryMobile] = useState(false)
    let editorRef = useRef()
    let [push, setPush] = useState(false)
    let [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)
    let [mode, setMode] = useState('read')
    let [visibleQuery, setVisibleQuery] = useState([])

    let [isNotificationEnabled, setIsNotificationEnabled] = useState(false);


    const getEmbeddedClauses = (parentQuery, startIndex = "") => {
        let clauses = [];
        const childrenList = parentQuery?.querySelector('ol');
        if (childrenList) {
            const childrenLi = childrenList.children;
            for (let i = 0; i < childrenLi.length; i++) {
                if (childrenLi[i].tagName === "LI") {
                    clauses.push(`Clauses ${startIndex + '.'}${i + 1}`);
                    clauses.push(...getEmbeddedClauses(childrenLi[i], `${startIndex + "."}${i + 1}`));
                }
            }
        }
        return clauses;
    }


    const divFormat = (obj) => {
        const styleString = ' style="background-color: #d4d2f5"'
        function insert(str, substr, pos) {
            const array = str.split('');
            array.splice(pos, 0, substr);
            return array.join('');
        }

        const format = [
            "<strong>",
            "<em>"
        ]
        const closeTags = [
            "</strong>",
            "</em>"
        ]

        let result = ''
        obj.forEach(element => {
            if (element.added) {
                result += format.includes(element.value) ?
                    insert(element.value, styleString, element.value.length - 1)
                    : element.value
            } else if (element.removed) {
                result += format.includes(element.value) ?
                    '<span style="background-color: #d4d2f5">'
                    : closeTags.includes(element.value) ? '</span>' : element.value
            }
            else {
                result += element.value
            }
        })
        return result
    }

    const compareFiles = data => {
        const resultContent = divFormat(differ.diffChars(data.content_src, data.content_dst))
        console.log(resultContent)
        let temp_src = window.document.createElement('div')
        temp_src.innerHTML = resultContent
        // temp_src.innerHTML = data.content_src
        let temp_dst = window.document.createElement('div')
        temp_dst.innerHTML = data.content_dst
         // temp_dst.innerHTML = resultContent

        const result2 = diff(temp_src.innerHTML, temp_dst.innerHTML)
        console.log('result2',result2)
        editorRef.current.editor.execCommand('mceSetContent', true, result2 )


    }


    const getOuterListNumber = (listElement) => {
        const parentElement = listElement?.parentNode;
        if (parentElement?.tagName === "OL") {
            let index = Array.prototype.indexOf.call(parentElement.children, listElement);
            const listParent = parentElement.parentNode;
            if (listParent.tagName === "LI") {
                const outerList = getOuterListNumber(listParent);
                return outerList ? outerList + "." + (index + 1) : (index + 1);
            }
            return index + 1;
        }
    }


    const addLinkedTo = () => {

        if (editor && document) {
            document.queries.map((query) => {
                query.links = [];
                const queryElements = editor.querySelectorAll(`#tinymce span[data-mce-annotation-uid="${query.id}"]`);
                for (let i = 0; i < queryElements.length; i++) {
                    if (queryElements[i]) {
                        const linkTags = [...queryElements[i]?.getElementsByTagName("a")];
                        if (linkTags) {
                            linkTags.forEach((link) => {
                                if (link.getAttribute("href") !== link.text) {
                                    query.links = [link.text];
                                }
                            })
                        }
                    }
                    const parentQuery = queryElements[i]?.parentElement;
                    if (parentQuery.tagName === 'A') {
                        if (parentQuery.getAttribute("href") !== parentQuery.text) {
                            const clauses = {name:[parentQuery.text], value: parentQuery.getAttribute("href")};
                            query.links = query.links ? [...query.links, clauses] : [clauses];
                        }
                    }
                    const startIndex = getOuterListNumber(parentQuery);
                    if (startIndex) {
                        const clauses = {name:`Clauses ${startIndex}`, value: queryElements[i].innerText};
                        if (clauses) {
                            query.links = query.links ? [...query.links, clauses] : [clauses];
                        }
                    }
                }
                query.links = query.links.filter(function (item, pos) {
                    return query.links.indexOf(item) === pos;
                })

                return query;
            })
        }
    }

    useEffect(() => {
        addLinkedTo();
    }, [editor, document, activeManage]);

    const [timer,setTimer] = useState(null)
    useEffect(() => {
        if(document && editorContent && isCurrentVersion && mode === 'write' && editorRef.current?.editor?.mode?.get() !== 'readonly'){
            if(!timer){
                setTimer(setTimeout(function tick() {
                    if(getConverter(window.location.search).referer){
                        setTimer(setTimeout(tick, 5000))
                            saveDocument(searchString, id, editorRef, document, editor, false).then(res => {
                            setDocument(document => {
                                return {
                                    ...document,
                                    editedDate: dateConvert(res?.data?.data?.updated_at, 'dt'),
                                    editedBy: {
                                        ...document.editedBy,
                                        name: user.first_name + ' ' + user.last_name
                                    }
                                }
                            })
                        })
                    }
                }, 5000));
            }
        }
    }, [editorContent]);

    useEffect(() => {
        if(!isCurrentVersion){
            clearTimeout(timer)
            setTimer(null)
        }
    }, [isCurrentVersion])


    useEffect( () => {
        const iframeEditor = window?.document?.getElementsByTagName('iframe')[0];
        if (iframeEditor) {
            iframeEditor.height = "";
            if(iframeEditor.contentWindow.document.body.scrollHeight > 1270){
                iframeEditor.style.height = iframeEditor.contentWindow.document.body.scrollHeight + "px";
            }
        }
        if(document && editorContent && isCurrentVersion && document.files.length > 1){
            getDocument(document.files.sort((a, b) => b.created_at - a.created_at)[1].filename).then(res => {
                setPrevEditorContent(res.content.replace(/<p><iframe (.|\n)*? data-html2canvas-ignore="true">(.|\n)*?<\/iframe><\/p>/g, ''))
            })
            let content = editorContent.replace(/<p><iframe (.|\n)*? data-html2canvas-ignore="true">(.|\n)*?<\/iframe><\/p>/g, '')
            setPrevEditorContent(editorContent.replace(/<p><iframe (.|\n)*? data-html2canvas-ignore="true">(.|\n)*?<\/iframe><\/p>/g, ''))
            if(prevEditorContent?.indexOf(content) === -1){
                setIsEdited(true)
            }else{
                setIsEdited(false)
            }
        }else if(document && editorContent && isCurrentVersion){
            setIsEdited(true)
            setPrevEditorContent(editorContent.replace(/<p><iframe (.|\n)*? data-html2canvas-ignore="true">(.|\n)*?<\/iframe><\/p>/g, ''))
        }
    }, [editorContent])


    const openOptions = () => {
        setDocument({
            ...document,
            isOpenOptions: !document.isOpenOptions
        })
    }

    const changeFavorite = () => {
        updateDocumentFavourite(document.id, !document.favorite)
        setDocument({
            ...document,
            favorite: !document.favorite
        })
    }

    const changeStatus = newStatus => {
        if (newStatus.toLowerCase() === 'in tender') {
            setIsOpenTenderPopup(true)
            setDocument({
                ...document,
                isOpenStatusSelect: false,
                isOpenOptions: false
            })
            return false;
        }
        if (currentStatusDocument === 'in tender') {
            setIsOpenChangeTenderPopup(true)
            setDocument({
                ...document,
                isOpenStatusSelect: false,
                isOpenOptions: false
            })
            return false;
        }
        setDocument({
            ...document,
            isOpenStatusSelect: false,
            status: newStatus,
            isOpenOptions: false
        })
    }

    const fieldData = [
        'Title',
        'Company'
    ]

    const openSelect = () => {
        setDocument({
            ...document,
            isOpenStatusSelect: !document.isOpenStatusSelect
        })
    }

    const checkClickHandler = event => {
        console.log('sdf')
        if (optionsRef.current) {
            if (!event.target.isEqualNode(optionsRef.current) && !event.target.closest('.' + optionsRef.current.className) && !isMobile) {
                if (document && document.isOpenOptions) {
                    openOptions()
                }
            }
        }
        if(!event.target.closest('.manage-item') && !event.target.classList.contains('.manage-item') && !event.target.closest('.document-body-select-btns') && !event.target.classList.contains('.document-body-select-btns')){
            setDocument(doc => {
                if((doc?.comments?.filter(item => item.id === null).length > 0 || doc?.queries?.filter(item => item.id === null).length > 0)){
                    return {
                        ...doc,
                        queries: doc.queries.filter(item => !(item.id === null)),
                        comments: doc.queries.filter(item => !(item.id === null))
                    }
                }
                return doc
            })
        }
        window.document.removeEventListener('click', checkClickHandler)
    }

    const disableEditor = () => {
        editor.querySelector('#tinymce').setAttribute('contenteditable', false)
        window.document.querySelector('.tox-editor-header').style.display = 'none'
    }

    const activeEditor = () => {
        editor.querySelector('#tinymce').setAttribute('contenteditable', true)
        window.document.querySelector('.tox-editor-header').style.display = 'block'
        window.document.querySelectorAll('.tox-toolbar__group').forEach((element, index) => {
            if (index === 0) {
                element.classList.add('versions')
            } else if (index === 1) {
                element.classList.add('none')
            }
        })
    }

    const activeHistory = (id) => {
        setDocument(document => {
            return {
                ...document,
                files: document.files.map(item => {
                    if (item._id === id) {
                        return {
                            ...item,
                            active: true,
                        }
                    }
                    return {
                        ...item,
                        active: false
                    }
                })
            }
        })
    }

    const addSignature = () => {
        setSignatureList(signatureList.concat({
            name: '',
            fields: [],
            newfiled: {
                active: false
            }
        }))
    }

    const openSelectNewFiled = id => {
        setSignatureList(signatureList.map((item, index) => {
            if (id === index) {
                return {
                    ...item,
                    fields: item.fields.map((item) => {
                        return {
                            ...item,
                            active: false
                        }
                    }),
                    newfiled: {
                        active: !item.newfiled.active
                    }
                }
            } else {
                return {
                    ...item,
                    fields: item.fields.map((item) => {
                        return {
                            ...item,
                            active: false
                        }
                    }),
                    newfiled: {
                        active: false
                    }
                }
            }
        }))
    }

    const openSelectField = (id, idField) => {
        setSignatureList(signatureList.map((item, index) => {
            if (id === index) {
                return {
                    ...item,
                    fields: item.fields.map((fieldItem, i) => {
                        if (idField === i) {
                            return {
                                ...fieldItem,
                                active: !fieldItem.active
                            }
                        } else {
                            return {
                                ...fieldItem,
                                active: false
                            }
                        }
                    }),
                    newfiled: {
                        active: false
                    }
                }
            }
            return item
        }))
    }

    const addField = (id, field) => {
        setSignatureList(signatureList.map((item, index) => {
            if (id === index) {
                return {
                    ...item,
                    fields: item.fields.concat({
                        name: field,
                        active: false
                    }),
                    newfiled: {
                        active: false
                    }
                }
            }
            return item
        }))
    }

    const createAnnotation = (type) => {
        const selectedString = editors.selection.getContent({format: 'html'});
        const editorDom = window.document.querySelector('iframe').contentWindow.document;
        if (!selectedString.includes('data-mce-annotation-uid')
            && !editorDom.querySelector(`#tinymce span[data-mce-selected="inline-boundary"]`)) {
            editors.annotator.annotate(type, {
                uid: "null",
            });
            editors.focus();
            return true;
        }
        return false;
    }


    const removeField = (id, idFiled) => {
        setSignatureList(signatureList.map((item, index) => {
            if (id === index) {
                return {
                    ...item,
                    fields: item.fields.filter((field, iField) => {
                        if (idFiled === iField) {
                            return false
                        }
                        return field
                    })
                }
            }
            return item
        }))
    }

    const updateField = (id, idField, newValue, oldValue) => {
        setSignatureList(signatureList.map((item, index) => {
            if (id === index) {
                return {
                    ...item,
                    fields: item.fields.map((field, i) => {
                        if (field.name === newValue) {
                            return {
                                name: oldValue,
                                active: false
                            }
                        }
                        if (idField === i) {
                            return {
                                name: newValue,
                                active: false
                            }
                        }
                        return field
                    })
                }
            }
            return item
        }))
    }

    const changeNameSignature = (id, value) => {
        setSignatureList(signatureList.map((item, index) => {
            if (id === index) {
                return {
                    ...item,
                    name: value
                }
            }
            return item
        }))
    }

    const addInitial = newInitial => {
        setInitialsList(initialsList.concat({
            name: newInitial
        }))
    }

    const removeInitial = id => {
        setInitialsList(initialsList.filter((initial, index) => {
            if (id === index) {
                return false
            }
            return initial
        }))
    }


    const removeCopy = id => {
        setCopyEmailList(copyEmailList.filter((item, index) => {
            if (id === index) {
                return false
            } else {
                return item
            }
        }))
    }

    const convertDocument = (documentData, comments, queries) => {
        // documentData.files.map(item => console.log(item))
        const getParamsData = getConverter(window.location.search)
        return {
            ...documentData,
            id: documentData._id,
            file: documentData.file_type || 'spe',//searchString.referer === 'templates' ? documentData.filename.split('.').pop() : documentData.name.split('.').pop(),
            name: documentData.name.split('.')[0],
            expiry: '31 August 2021',
            type: 'Trade Licence',
            owner_id: {
                ...documentData.owner_id,
                bgc: randomColor()
            },
            editedBy: {
                avatar: 'test_avatar.png',
                name: documentData.edited_by ? documentData.edited_by.first_name + ' ' + documentData.edited_by.last_name : documentData.owner_id ? documentData.owner_id.first_name + ' ' + documentData.owner_id.last_name : ''
            },
            favorite: documentData.favorite || null,
            editedDate: documentData.updated_at ? dateConvert(documentData.updated_at, 'dt') : dateConvert(documentData.created_at, 'dt'), //'10:21am • 09/06/2021',
            status: documentData.status ? documentData.status.toLowerCase().replace('_', ' ') : 'draft',
            isOpenOptions: false,
            isLinked: false,
            isFavorite: false,
            isDelete: false,
            isOpenStatusSelect: false,
            linkedList: [],
            files: documentData.files ? documentData.files.map((item, index) => ({
                ...item,
                active: !!(item.filename === getParamsData.docname && !(user?.external && documentData.status === "in_tender") ||
                    documentData.files.length - 2 === index && (user?.external && documentData.status === "in_tender")),
                data: dateConvert(item.created_at, 'version')
            })) : [],
            queries: convertManageItem(queries),
            comments: convertManageItem(comments),
            groupid: getParamsData.referer === 'templates' ? getParamsData.groupid : undefined,
            updateName: (getParamsData.referer === 'templates' ? updateTemplateName : updateDocument)
        }
    }

    useEffect(()=> {
        if (editorRef.current && editorRef.current.editor) {
            if ((activeManage === 2 && !isCurrentVersion) || !isCurrentVersion || mode === 'read' || isOpenHistoryMobile) {
                editorRef.current.editor.setMode('readonly')
            } else {
                if (editorRef.current?.editor?.mode?.isReadOnly() && document.status !== 'in tender') {
                    getDocument(document.files.sort((a, b) => b.created_at - a.created_at)[0].filename)
                        .then(res => {
                            editorRef.current.editor.execCommand('mceSetContent', true, res.content)
                            activeHistory(document.files.sort((a, b) => b.created_at - a.created_at)[0]._id)
                        })
                }
                if (!(user.external && document.status === 'in tender')) {
                    editorRef.current.editor.setMode('design')
                }
            }
        }
    },[activeManage, isOpenHistoryMobile])

    useEffect(() => {
        if(editors){
            if(mode === 'read'){
                window.document.querySelector('.tox-editor-header').style.display = 'none'
            } else {
                window.document.querySelector('.tox-editor-header').style.display = 'block'
            }
            editors.setMode(mode === 'read' ? 'readonly' : 'design')
        }
    }, [mode])

    useEffect(() => {
        if(document?.status) {
            setCurrentStatusDocument(document.status);
        }
    }, [document])

    useEffect(() => {
        if(mode === 'read' && editorRef.current){
            editorRef.current.editor.setMode("readonly")
        }
        if (editor) {
            addEventsToItemToEditor(editor, setDocument, setActiveManage, user)
        }
        // if(editor && (document.file !== 'spe' || isMobile)){
        // 	disableEditor()
        // }
        //eslint-disable-next-line
    }, [editor])

    useEffect(() => {
        setSearchDocument(document);
        setSearchQueries(document?.queries)
    }, [document])

    useEffect(()=> {
        if (document?.id) {
            const isTemplate = searchString.referer === 'templates';
            getDocumentNotificationStatus(document.id, isTemplate).then((data) => {
                    setIsNotificationEnabled(data.data);
            })

            if(document.files.length > 1){
                getDocument(document.files.sort((a, b) => b.created_at - a.created_at)[1].filename).then(res => {
                    setPrevEditorContent(res.content.substr(0, res.content.indexOf("<p><iframe") !== -1 ? res.content.indexOf("<p><iframe") : res.content.length))
                })
            }
        }
    }, [document]);

    useEffect(() => {
        if(document) {
            let isTemplate = searchString.referer === 'templates';
            (isTemplate ? searchTemplateQuery(document?._id, search) : searchQuery(document?._id, search)).then(async res => {
                let comments = await (isTemplate ? getTemplateManage(id, 'comments') : getManage(id, 'comments'))
                    .then(res => res.data)
                    .catch(() => [])
                let queries = await (isTemplate ? getTemplateManage(id, 'queries') : getManage(id, 'queries'))
                    .then(res => res.data)
                    .catch(() => [])

                if(typeof res === "object"){
                    setSearchQueries(convertManageItem(res.queries))
                    //setDocument(convertDocument(document, comments, res.queries))
                }else{
                    setDocument(convertDocument(document, comments, queries))
                }
            })
        }
    }, [search])

    useEffect(() => {
        setQueriesHeight(queriesRef.current ? queriesRef.current.offsetHeight : 0)
        if (isOpenFullQueries) {
            setIsVisibleLine(false)
        } else {
            if(!isMobile){
                reloadLine()
            }
        }
        // setIsVisibleLine(false)
        //eslint-disable-next-line
    }, [isOpenFullQueries])

    useEffect(() => {
        if (editor) {
            if (selectNewQuery) {
                editor.body.classList.add('yellow-select')
            } else {
                editor.body.classList.remove('yellow-select')
            }
        }
        //eslint-disable-next-line
    }, [selectNewQuery])

    useEffect(() => {
        if (editor && isVisibleSelectBtns) {
            // resetActiveItems()
            // setIsVisibleLine(false)
            // activeQuery(null)
            // activeComment(null)
        }
        //eslint-disable-next-line
    }, [isVisibleSelectBtns])

    useEffect(() => {
        setSelectNewQuery(false)
        if(isMobile && selectTextInEditor){
            setIsVisibleSelectBtns(true)
        }
        if (!selectTextInEditor) {
            setIsVisibleSelectBtns(false)
        }
        //eslint-disable-next-line
    }, [selectTextInEditor])

    useEffect(() => {
        if(user){
            const isTemplate = searchString.referer === 'templates';
            updateTimeoutUser(id, user._id, isTemplate);
        }
        setIsActive(document !== null ? true : false)
        setQueriesHeight(queriesRef.current ? queriesRef.current.offsetHeight : 0)
        window.document.addEventListener('click', checkClickHandler)
        if(editor){
            window.document.querySelector('iframe').contentWindow.document.body.addEventListener('click', checkClickHandler)
            window.document.querySelector('.tox-editor-container').addEventListener('click', checkClickHandler)
        }
    }, [document])



    const getSetting = async () => {
        setSettings(await getSettings());
    }

    //request get document
    const getDocumentData = async () => {
        let isTemplate = searchString.referer === 'templates';
        (isTemplate ? getTemplate(searchString.groupid, searchString.docname, false) : getDocumentId(id))
            .then(async res => {
                if (isTemplate) {
                    let comments = await getTemplateManage(id, 'comments')
                        .then(res => res.data)
                        .catch(() => [])
                    let queries = await getTemplateManage(id, 'queries')
                        .then(res => res.data)
                        .catch(() => [])
                    setEditorContent(res.content)
                    setMode(res.mode);
                    setDocument(convertDocument(res.template, comments, queries))
                    setIsLoadContentComplete(true)
                    setStatusList([
                        'draft',
                        'published'
                    ])
                } else {
                    //if(!isLoadContentComplete ){
                        let comments = await getManage(id, 'comments')
                            .then(res => res.data)
                            .catch(() => [])
                        let queries = await getManage(id, 'queries')
                            .then(res => res.data)
                            .catch(() => [])
                        setDocument(convertDocument(res.data, comments, queries))
                        let currentVersionDocument = res.data.files.filter(item => item.filename === searchString.docname)[0] ? res.data.files.filter(item => item.filename === searchString.docname)[0].filename : res.data.files[res.data.files.length - 1].filename
                        // if(res.data.status === "in_tender" && user.external) {
                        //     currentVersionDocument = res.data.files[res.data.files.length - 2].filename;
                        // }
                        setDocumentToUpload(currentVersionDocument)
                        getDocument(currentVersionDocument)
                            .then(res => {
                                setMode(res.mode)
                                setEditorContent(res.content)
                                setIsLoadContentComplete(true)
                            })
                            .catch(error => {
                                console.log(error)
                                setIsErrorPage(true)
                                setIsLoadContentComplete(true)
                                setIsLoadComplete(false)
                            })
                    //}

                }
            })
            .catch((error) => {
                console.log(error)
                setIsErrorPage(true)
                setIsLoadContentComplete(true)
                setIsLoadComplete(false)
            })
    };
    useEffect(() => {

        if (link) {
            if (searchString.password) {
                history.push(`/protection/${link}${searchString.isTemplate ? '?isTemplate=true' : ''}`)
            } else {
                let pass = localStorage.getItem('pass')
                if(searchString.isTemplate){
                    getShareTemplate(link, pass ? pass : null)
                    .then(res => {
                        setIsLoginPage(false)
                        setDocument(convertDocument(res.template))
                        // setDocumentToUpload(res.document.files[res.document.files.length - 1].filename)
                        setEditorContent(res.content)
                        setIsLoadContentComplete(true)
                    })
                    .catch(error => {
                        console.log(error.response.data.error)
                        setIsErrorPage(true)
                        setIsLoadContentComplete(true)
                        setIsLoadComplete(false)
                    })
                } else {
                    getShareDocument(link, pass ? pass : null)
                    .then(res => {
                        setIsLoginPage(false)
                        setDocument(convertDocument(res.document))
                        setDocumentToUpload(res.document.files[res.document.files.length - 1].filename)
                        setEditorContent(res.content)
                        setIsLoadContentComplete(true)
                    })
                    .catch(error => {
                        console.log(error.response.data.error)
                        setIsErrorPage(true)
                        setIsLoadContentComplete(true)
                        setIsLoadComplete(false)
                    })
                }
            }
        } else {
            if (user) {
                getDocumentData()
                getSetting()
            }
        }
        window.document.addEventListener('click', checkClickHandler)
        // if(editor){
            // window.document.querySelector('iframe').contentWindow.document
        // }
        // window.document.addEventListener("beforeunload", forwardHandler)
        // getDocumentRights(id)
        // .then(res => {
        // 	console.log(res)
        // })
        // const socket = io('https://stage.teplin.dev', {
        //     extraHeaders: {
        //         Authorization: `Bearer ${localStorage.Authorization}`
        //     }
        // });
        // socket.on('connect', () => {
        //     console.log('connection')
        // })
        // if(user){
        //     socket.on('editor_changed', data => {
        //         console.log(user)
        //     })
        // }
        return () => {
            window.document.removeEventListener('click', checkClickHandler)
            if(editor){
                editor.document.removeEventListener('click', checkClickHandler)
            }
        }
        //eslint-disable-next-line
    }, [user])

    useEffect(() => {
        if(isOpenQueriesMobile){
            setIsOpenCommentsMobile(false)
        } else if(isOpenCommentsMobile){
            setIsOpenQueriesMobile(false)
        }
    }, [isOpenCommentsMobile, isOpenQueriesMobile])



    return (
        <>
            {isErrorPage ? <ErrorPage error={"error"}/> : ''}
            {isLoadComplete ? <Loader isVisible={isLoadComplete} isFull={true}/> : ''}
            <div
                className={"document " + (isOpenFullQueries ? 'document-full ' : '') + (!manageFullView ? 'document-full-manage ' : '') + (document && document.file !== 'spe' ? 'document-read ' : '') + (activeLang === 'ar' ? 'document-arabic ' : '')}>
                {isOpenTenderPopup ?
                    <TenderSubmitPopup
                        isOpenTenderPopup={isOpenTenderPopup}
                        setIsOpenTenderPopup={setIsOpenTenderPopup}
                        setDocument={setDocument}
                        setDontAsk={setDontAsk}
                        dontAsk={dontAsk}
                        document={document}
                    /> : ""}
                {isOpenConfirmPublishPopup ?
                    <TenderConfirmPublishPopup
                        isOpenConfirmPublishPopup={isOpenConfirmPublishPopup}
                        setIsOpenConfirmPublishPopup={setIsOpenConfirmPublishPopup}
                        setDocument={setDocument}
                        setDontAsk={setDontAsk}
                        dontAsk={dontAsk}
                        document={document}
                        editorRef={editorRef}
                        getDocumentData={getDocumentData}
                        user={user}
                    /> : ""}
                {isOpenChangeTenderPopup ?
                    <TenderToOtherPopup
                        isOpenChangeTenderPopup={isOpenChangeTenderPopup}
                        setIsOpenChangeTenderPopup={setIsOpenChangeTenderPopup}
                        setDocument={setDocument}
                        setDontAsk={setDontAsk}
                        dontAsk={dontAsk}
                        document={document}
                        selectedStatus={selectedStatus}
                    /> : ""}
                <div className={"revert-popup-wrapper " + (isOpenRevertPopup ? 'revert-popup-wrapper-active' : '')}
                     onClick={event => {
                         if (event.target.classList.contains('revert-popup-wrapper-active')) {
                             setIsOpenRevertPopup(false)
                         }
                     }}>
                    <div className="revert-popup">
                        <div className="revert-header">
                            <div className="revert-title">{lang.revert}</div>
                            <Close onClick={() => setIsOpenRevertPopup(false)}/>
                        </div>
                        <div className="revert-popup-body">
                            <div className="revert-popup-body-title">{lang.wantRevert}{revertVersion.number}?</div>
                            <div className="revert-btns">
                                <Button color={'white'} padding={12} margin={10} onClick={() => {
                                    setIsOpenRevertPopup(false)
                                }}>{lang.cancel}</Button>
                                <Button color={'blue'} padding={12} onClick={() => {
                                    setIsOpenRevertPopup(false)
                                    saveDocument(searchString, id, editorRef, document, true).then((res)=> {
                                        if(res.status === 200 && res.data?.data?.files.length > 0) {
                                            const files = res.data.data.files;
                                            const queryParams = new URLSearchParams(window.location.search);
                                            queryParams.set("docname", files[files.length-1].filename);
                                            window.history.pushState(null, null, "?"+queryParams.toString());
                                            getDocumentData();
                                            setDocument(document => {
                                                return {
                                                    ...document,
                                                    editedBy: {
                                                        ...document.editedBy,
                                                        name: user.first_name + ' ' + user.last_name
                                                    },
                                                    files: files
                                                }
                                            })
                                        }

                                    })
                                }}>{lang.confirm}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"signature-popup-wrapper " + (isOpenSignaturePopup ? 'signature-popup-wrapper-active' : '')}
                    onClick={event => {
                        if (event.target.classList.contains('signature-popup-wrapper-active')) {
                            setIsOpenSignaturePopup(false)
                        }
                    }}>
                    <div className="signature-popup">
                        <div className="signature-header">
                            <div className="signature-title">Add signature</div>
                            <Close onClick={() => setIsOpenSignaturePopup(false)}/>
                        </div>
                        <div className="signature-body">
                            <div className="signature-list">
                                {signatureList.map((signature, index) => {
                                    return (
                                        <div className="signature-item" key={index}>
                                            <div className="name-header">NAME</div>
                                            <input type="text" className="name" value={signature.name}
                                                   onChange={event => changeNameSignature(index, event.target.value)}/>
                                            <div className="additional-header">Additional fields</div>
                                            <div className="additional">
                                                {signature.fields.map((item, i) => {
                                                    return (
                                                        <div className="filed-select-wrapper">
                                                            <div className="field-select" key={i}>
                                                                <div className="select-active" onClick={() => {
                                                                    openSelectField(index, i)
                                                                }}>
                                                                    <span className="field-value">{item.name}</span>
                                                                    <Arrow/>
                                                                </div>
                                                                <div
                                                                    className={"select-list " + (item.active ? 'select-list-active' : '')}>
                                                                    {fieldData.map((fieldDataItem, iField) => {
                                                                        return (
                                                                            <>
                                                                                {item.name !== fieldDataItem ? (
                                                                                    <div className="select-item"
                                                                                         key={iField} onClick={() => {
                                                                                        updateField(index, i, fieldDataItem, item.name)
                                                                                    }}>{fieldDataItem}</div>
                                                                                ) : ''}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <span className="remove"
                                                                  onClick={() => removeField(index, i)}></span>
                                                        </div>
                                                    )
                                                })}
                                                <div className="field-select">
                                                    <div className="select-active"
                                                         onClick={() => openSelectNewFiled(index)}>
                                                        <span>Please select</span>
                                                        <Arrow/>
                                                    </div>
                                                    <div
                                                        className={"select-list " + (signature.newfiled.active ? 'select-list-active' : '')}>
                                                        {fieldData.map((fieldDataItem, iField) => {
                                                            return (
                                                                <>
                                                                    <div className="select-item" key={iField}
                                                                         onClick={() => {
                                                                             if (!(signature.fields.map(field => field.name).indexOf(fieldDataItem) !== -1)) {
                                                                                 addField(index, fieldDataItem)
                                                                             } else {
                                                                                 openSelectNewFiled(index)
                                                                             }
                                                                         }}>{fieldDataItem}</div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="add-signature" onClick={() => {
                                addSignature()
                            }}>
                                <Plus/>
                                <span>Add new signature</span>
                            </div>
                            <div className="signature-btns">
                                <Button color={'white'} padding={12} margin={10} onClick={() => {
                                    setIsOpenSignaturePopup(false)
                                    setSignatureList([])
                                }}>{lang.cancel}</Button>
                                <Button color={'blue'} padding={12} onClick={() => {
                                    setIsOpenSignaturePopup(false)
                                }}>{lang.confirm}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"initials-popup-wrapper " + (isOpenInitialsPopup ? 'initials-popup-wrapper-active' : '')}
                    onClick={event => {
                        if (event.target.classList.contains('initials-popup-wrapper-active')) {
                            setIsOpenInitialsPopup(false)
                        }
                    }}>
                    <div className="initials-popup">
                        <div className="initials-header">
                            <div className="initials-title">Add initials</div>
                            <Close onClick={() => setIsOpenInitialsPopup(false)}/>
                        </div>
                        <div className="initials-body">
                            <div className="name-header">POSITION</div>
                            <div className="initials-select">
                                <div className="select-active">
                                    <span>Bottom margin</span>
                                    <Arrow/>
                                </div>
                                <div className={"select-list "}>

                                </div>
                            </div>
                            <div className="name-header">NAME</div>
                            <input type="text" className="name"
                                   defaultValue={user ? user.first_name + ' ' + user.last_name : ''}/>
                            {initialsList.map((initial, index) => {
                                return (
                                    <>
                                        <div className="name-header">NAME</div>
                                        <div className="initial-wrapper">
                                            <input type="text" className="name" defaultValue={initial.name}/>
                                            <span className="remove" onClick={() => removeInitial(index)}></span>
                                        </div>
                                    </>
                                )
                            })}
                            <div className="name-header">NAME</div>
                            <div className="initial-wrapper">
                                <input type="text" className="name" value={newInitial}
                                       onChange={event => setNewInitial(event.target.value)}
                                       placeholder={'Please enter name'}/>
                                <Plus className="plus" onClick={() => {
                                    if (newInitial.length > 0) {
                                        addInitial(newInitial)
                                        setNewInitial('')
                                    }
                                }}/>
                            </div>
                            <div className="initials-nav">
                                <div className="add-initials">
                                    <div className={"runner " + (addInitialToAllPages === 1 ? 'runner-active' : '')}
                                         onClick={() => setAddInitialsToAllPages(addInitialToAllPages === 1 ? 0 : 1)}>
                                        <div className="runner-circle"></div>
                                    </div>
                                    <span>Add initials to all pages</span>
                                </div>
                                <div className="initials-btns">
                                    <Button color={'white'} padding={12} margin={10} onClick={() => {
                                        setIsOpenInitialsPopup(false)
                                    }}>{lang.cancel}</Button>
                                    <Button color={'blue'} padding={12} onClick={() => {
                                        setIsOpenInitialsPopup(false)
                                    }}>{lang.confirm}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"signing-popup-wrapper " + (isOpenSigningPopup ? 'signing-popup-wrapper-active' : '')}
                     onClick={event => {
                         if (event.target.classList.contains('signing-popup-wrapper-active')) {
                             setIsOpenSigningPopup(false)
                         }
                     }}>
                    <div className="signing-popup">
                        <div className="signing-header">
                            <div className="signing-title">Send for signing</div>
                            <Close onClick={() => setIsOpenSigningPopup(false)}/>
                        </div>
                        <div className="signing-body">
                            <div className="signers-header">
                                <div className="signers-header-item">SIGNERS</div>
                                <div className="signers-header-item">email</div>
                            </div>
                            <div className="signers-list">
                                {signatureList.map((signature, index) => {
                                    return (
                                        <div className="signature-item" key={index}>
                                            <div className="item-name">{signature.name}</div>
                                            <input type="text" className="item-email"/>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="email-message">
                                <div className="email-message-header">email MESSAGE (OPTIONAL)</div>
                                <textarea name="" id="" cols="30" rows="10" className="email-message-input"
                                          placeholder="Enter your message"></textarea>
                            </div>
                            <div className="copy">
                                <div className="copy-header">Does anyone else need to receive a copy?</div>
                                <div className="copy-title">email address</div>
                                {copyEmailList.map((item, index) => {
                                    return (
                                        <div className="copy-input-wrapper" key={index}>
                                            <input type="text" defaultValue={item}/>
                                            <div className="remove" onClick={() => removeCopy(index)}></div>
                                        </div>
                                    )
                                })}
                                <div className="copy-input-wrapper">
                                    <input type="text" value={newCopyEmail}
                                           onChange={event => setNewCopyEmail(event.target.value)}
                                           placeholder="email@example.com"/>
                                    <Plus onClick={() => {
                                        if (newCopyEmail.length > 0) {
                                            setCopyEmailList(copyEmailList.concat(newCopyEmail))
                                            setNewCopyEmail('')
                                        }
                                    }}/>
                                </div>
                            </div>
                            <div className="signing-btns">
                                <Button color={'white'} padding={12} margin={10} onClick={() => {
                                    setIsOpenSigningPopup(false)
                                }}>{lang.cancel}</Button>
                                <Button color={'blue'} padding={12} onClick={() => {
                                    setIsOpenSigningPopup(false)
                                    setPush(true)
                                    setTimeout(() => {
                                        setPush(false)
                                    }, 1200)
                                }}>Send for signing</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"delete-file-popup-wrapper " + (isOpenDeletePopup ? 'delete-file-popup-wrapper-active ' : '') + (activeLang === 'ar' ? 'delete-file-popup-wrapper-arabic' : '')}
                    onClick={(event) => {
                        if (event.target.classList.contains('delete-file-popup-wrapper-active')) {
                            setIsOpenDeletePopup(false)
                        }
                    }}>
                    <div className="delete-file-popup">
                        <div className="delete-file-popup-header">
                            <div className="header-title">{lang.deleteFile}</div>
                            <Close onClick={() => setIsOpenDeletePopup(false)}/>
                        </div>
                        <div className="delete-file-popup-body">
                            <div className="delete-file-question">{lang.wantDeleteFile}</div>
                            <div className="delete-file-item">
                                {isActive ? (
                                    <>
                                        <img src={require(`../../assets/img/files/${document.file}.svg`)} alt=""/>
                                        <div className="delete-file-name">{document.name}</div>
                                    </>
                                ) : ''}
                            </div>
                            <div className="delete-btns">
                                <Button color={'white'} padding={12} margin={10}
                                        onClick={() => setIsOpenDeletePopup(false)}>{lang.cancel}</Button>
                                <Button color={'blue'} padding={12} onClick={() => {

                                    if (searchString.referer === 'companies') {
                                        unlinkDocToCompany(searchString.groupid, id)
                                    } else if (searchString.referer === 'templates') {
                                        deleteTemplate(null, id)
                                    } else if (searchString.referer === 'projects') {
                                        unlinkDocToProject(searchString.groupid, id)
                                    }
                                    history.push('/' + searchString.referer)
                                }}>{lang.delete}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {document ? (
                    <ShareFiles share={share} setShare={setShare} setPush={setPush} setPushText={setPushText}
                                pushText={pushText} isActive={isActive} document={document} setDocument={setDocument}/>
                ) : ''}
                {isActive ? (
                    <>
                        {/* mobile nav				 */}
                        {isMobile ? <DocMobileNav
                            document={document}
                            isOpenCommentsMobile={isOpenCommentsMobile}
                            isOpenQueriesMobile={isOpenQueriesMobile}
                            isOpenHistoryMobile={isOpenHistoryMobile}
                            setIsOpenQueriesMobile={setIsOpenQueriesMobile}
                            setIsOpenCommentsMobile={setIsOpenCommentsMobile}
                            setIsOpenHistoryMobile={setIsOpenHistoryMobile}
                            mode={mode}
                            setMode={setMode}
                            openOptions={openOptions}
                            openSelect={openSelect}
                            changeStatus={changeStatus}
                            searchString={searchString}
                            filename={filename}
                            setIsOpenDeletePopup={setIsOpenDeletePopup}
                            onClickPublish={() => {
                                saveDocument(searchString, id, editorRef, document, true).then((res)=> {
                                    if(res.status === 200 && res.data?.data?.files.length > 0) {
                                        const files = res.data.data.files;
                                        const queryParams = new URLSearchParams(window.location.search);
                                        queryParams.set("docname", files[files.length-1].filename);
                                        window.history.pushState(null, null, "?"+queryParams.toString());
                                        getDocumentData();
                                        setDocument(document => {
                                            return {
                                                ...document,
                                                files: files,
                                                editedBy: {
                                                    ...document.editedBy,
                                                    name: user.first_name + ' ' + user.last_name
                                                }
                                            }
                                        })
                                    }
                                })
                            }}
                            changeFavorite={changeFavorite}
                            documentToUpload={documentToUpload}
                            link={link}
                            setShare={setShare}
                        /> : ''}
                        <div className="document-header">
                            {push ? <Push>{'Document sent for signing'.toUpperCase()}</Push> : ''}
                            <div className="document-header-info">
                                <div className="info-data">
                                    <Link className="back-btn" onClick={() => {
                                        clearTimeout(timer)
                                        removeActiveItemsInEditor(editor)
                                        saveDocument(searchString, id, editorRef, document, editor, false)
                                        history.push('/' + searchString.referer + `/${searchString.groupid ? searchString.groupid : ''}`)
                                    }}>
                                        <svg width="25" height="19" viewBox="0 0 25 19" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.45446 9.64586L24.0908 9.64586" stroke="#2E384D"
                                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M11.3408 18.0908L1.29537 9.59082L11.3408 1.09082" stroke="#2E384D"
                                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                    <img src={require(`../../assets/img/files/${document.file}.svg`)} alt="type-file"
                                         className="type-file"/>
                                    <FileName filename={filename} doc={true}/>
                                    {searchString.referer === 'templates' || link || mode === 'read' ? '' : (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" onClick={() => changeFavorite()}
                                             style={{cursor: 'pointer'}}>
                                            <path
                                                d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z"
                                                stroke="#8798AD" strokeWidth="2" strokeLinejoin="round"
                                                fill={document.favorite ? '#8798AD' : '#ffffff'}/>
                                        </svg>
                                    )}
                                    {!link ? (
                                        <div className="document-status-select">
                                            <div className={"status-select-active " + document.status}
                                                 onClick={event => {
                                                     openSelect()
                                                 }}>
                                                <span></span>
                                                <div
                                                    className="status-name">{document.status[0].toUpperCase() + document.status.slice(1)}</div>
                                                <Arrow/>
                                            </div>
                                            <div
                                                className={"status-select-list " + (document.isOpenStatusSelect ? 'status-select-list-open' : '')}>
                                                {statusList.map((status, i) => {
                                                    if (status === document.status) {
                                                        return false
                                                    }
                                                    return (
                                                        <div className={"select-list-item " + status} key={i}
                                                             onClick={event => {
                                                                 setSelectedStatus(status.toLowerCase());
                                                                 changeStatus(status.toLowerCase())
                                                                 if (searchString.referer === 'templates') {
                                                                     updateTemplateStatus(document.id, status[0].toUpperCase() + status.slice(1)).then(res => {
                                                                         setDocument(convertDocument(res.data.data))
                                                                     })
                                                                 } else {
                                                                     if (status.toLowerCase() === 'in tender' || currentStatusDocument === 'in tender') {
                                                                         return false
                                                                     } else {
                                                                         updateDocumentStatus(document.id, status)
                                                                     }
                                                                 }
                                                             }}><span></span>{status[0].toUpperCase() + status.slice(1)}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>
                                {activeLang === 'ar' ? (
                                    <span className="info-last-edit">{document.editedDate} • {document.editedBy.name}
                                        <span> :{lang.lastEdited} </span></span>
                                ) : (
                                    <span
                                        className="info-last-edit"><span>{lang.lastEdited}: </span> {document.editedDate} • {document.editedBy.name}</span>
                                )}
                            </div>
                            <div className="document-header-manage">
                                {/* {searchString.referer !== 'templates' ? (
										<>
											{document.owner_id.picture ? <img src={document.owner_id.picture} alt="" className="owner-avatar"/> : (
												<div className="none-avatar" style={{backgroundColor: document.owner_id.bgc}}>
													{(document.owner_id.first_name[0] + (document.owner_id.last_name.length > 0 ?document.owner_id.last_name[0] : '')).toUpperCase()}
												</div>
											)}
										</>
									) : ''} */}

                                {signatureList.length > 0 ? (
                                    <button className="send-signing" onClick={() => setIsOpenSigningPopup(true)}>Send
                                        for signing</button>
                                ) : ''}
                                {searchString.referer !== 'templates' && isEdited && isCurrentVersion? (
                                    <Button padding={10} margin={10} disabled={true}>Unpublished changes</Button>
                                ) : ''}
                                {searchString.referer === 'templates' || link ? '' : (
                                    <Button padding={10} color={'blue'} margin={10} onClick={() => {
                                        if(document?.status === "in tender"){
                                            setIsOpenConfirmPublishPopup(true)
                                        }else{
                                            saveDocument(searchString, id, editorRef, document, true).then((res)=> {
                                                if(res.status === 200 && res.data?.data?.files.length > 0) {
                                                    const files = res.data.data.files;
                                                    const queryParams = new URLSearchParams(window.location.search);
                                                    queryParams.set("docname", files[files.length-1].filename);
                                                    window.history.pushState(null, null, "?"+queryParams.toString());
                                                    getDocumentData();
                                                    setDocument(document => {
                                                        return {
                                                            ...document,
                                                            files: files,
                                                            editedBy: {
                                                                ...document.editedBy,
                                                                name: user.first_name + ' ' + user.last_name
                                                            }
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                        setIsEdited(false)
                                    }} disabled={(mode === 'read' || !isEdited || !isCurrentVersion)}>{lang.publish}</Button>
                                )}
                                <div style={{cursor: 'pointer'}} className="file-notification" onClick={() => {
                                    const isTemplate = searchString.referer === 'templates';
                                    documentNotificationChange( document.id, !isNotificationEnabled, isTemplate).then(() => {
                                        setIsNotificationEnabled(!isNotificationEnabled);
                                    });
                                }}>
                                    {isNotificationEnabled ?
                                        <svg width="23" height="24" viewBox="0 0 23 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M22.3052 19.6179C19.6374 18.4661 19.5359 13.5221 19.534 13.3974V10.0806C19.534 6.88835 17.4936 4.14335 14.5941 2.97456C14.5879 1.33334 13.1897 0 11.4718 0C9.75376 0 8.35562 1.33342 8.34934 2.97449C5.45002 4.14328 3.4096 6.88827 3.4096 10.0805V13.3973C3.40771 13.5221 3.30615 18.466 0.638302 19.6178C0.334391 19.7491 0.167361 20.0654 0.237627 20.377C0.307811 20.6888 0.596135 20.9114 0.929662 20.9114H7.69291C7.83155 21.6406 8.1922 22.3189 8.74378 22.8684C9.47638 23.5981 10.4452 24 11.4716 24C12.4981 24 13.467 23.5981 14.1995 22.8684C14.7512 22.3189 15.1118 21.6405 15.2504 20.9114H22.0136C22.3471 20.9114 22.6354 20.6888 22.7057 20.377C22.776 20.0654 22.6091 19.7491 22.3052 19.6179ZM18.791 17.2493C19.0714 18.0621 19.4685 18.8785 20.0316 19.5577H2.91187C3.4749 18.8785 3.872 18.0623 4.15245 17.2493H18.791ZM13.1113 2.53321C12.9057 1.85273 12.2486 1.35352 11.4715 1.35352C10.6945 1.35352 10.0374 1.85277 9.83179 2.53321C10.3614 2.42815 10.9099 2.37281 11.4715 2.37281C12.0332 2.37281 12.5816 2.42819 13.1113 2.53321ZM4.82512 13.4017V10.0807C4.82512 6.57701 7.80664 3.72656 11.4715 3.72656C15.1364 3.72656 18.1179 6.57701 18.1179 10.0807V13.4072C18.1188 13.509 18.1354 14.5789 18.4174 15.896H4.52539C4.8076 14.5777 4.82426 13.5059 4.82512 13.4017ZM9.14551 20.9114C9.44819 21.9017 10.3912 22.6465 11.4717 22.6465C12.5522 22.6465 13.4951 21.9017 13.7977 20.9114H9.14551Z"
                                                  fill="#2E5BFF"/>
                                        </svg> :
                                        <svg width="23" height="24" viewBox="0 0 23 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M22.3052 19.6179C19.6374 18.4661 19.5359 13.5221 19.534 13.3974V10.0806C19.534 6.88835 17.4936 4.14335 14.5941 2.97456C14.5879 1.33334 13.1897 0 11.4718 0C9.75376 0 8.35562 1.33342 8.34934 2.97449C5.45002 4.14328 3.4096 6.88827 3.4096 10.0805V13.3973C3.40771 13.5221 3.30615 18.466 0.638302 19.6178C0.334391 19.7491 0.167361 20.0654 0.237627 20.377C0.307811 20.6888 0.596135 20.9114 0.929662 20.9114H7.69291C7.83155 21.6406 8.1922 22.3189 8.74378 22.8684C9.47638 23.5981 10.4452 24 11.4716 24C12.4981 24 13.467 23.5981 14.1995 22.8684C14.7512 22.3189 15.1118 21.6405 15.2504 20.9114H22.0136C22.3471 20.9114 22.6354 20.6888 22.7057 20.377C22.776 20.0654 22.6091 19.7491 22.3052 19.6179ZM18.791 17.2493C19.0714 18.0621 19.4685 18.8785 20.0316 19.5577H2.91187C3.47489 18.8785 3.872 18.0623 4.15245 17.2493H18.791ZM13.1113 2.53321C12.9057 1.85273 12.2486 1.35352 11.4715 1.35352C10.6945 1.35352 10.0374 1.85277 9.83179 2.53321C10.3614 2.42815 10.9099 2.37281 11.4715 2.37281C12.0332 2.37281 12.5816 2.42819 13.1113 2.53321ZM4.82512 13.4017V10.0807C4.82512 6.57701 7.80664 3.72656 11.4715 3.72656C15.1364 3.72656 18.1179 6.57701 18.1179 10.0807V13.4072C18.1188 13.509 18.1354 14.5789 18.4174 15.896H4.52539C4.8076 14.5777 4.82426 13.5059 4.82512 13.4017ZM9.14551 20.9114C9.44819 21.9017 10.3912 22.6465 11.4717 22.6465C12.5522 22.6465 13.4951 21.9017 13.7977 20.9114H9.14551Z"
                                                  fill="#8798AD"/>
                                        </svg>
                                    }
                                </div>
                                {!link ?
                                    <img src={shareImg} alt="" onClick={() => setShare(true)}/> : ''}
                                {searchString.referer !== 'templates' ? (
                                    <img src={save} alt="" onClick={() => {
                                        getDocument(documentToUpload, true)
                                    }}/>
                                ) : ''}
                                {!link && mode !== 'read' ? (
                                    <div className="setting-wrapper" ref={optionsRef}>
                                        <svg width="25" height="25" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" onClick={() => openOptions()}>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z"
                                                  fill={document.isOpenOptions ? "#2E5BFF" : "#8798ad"}/>
                                        </svg>
                                        <div
                                            className={"document-setting " + (document.isOpenOptions ? 'document-setting-active' : '')}>
                                            <svg version="1.1" id="setting-arrow" viewBox="0 0 490.787 490.787">
                                                <path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006
													c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083
													c-0.086,0.089-0.173,0.176-0.262,0.262L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085
													C368.236,489.664,365.511,490.792,362.671,490.787z"/>
                                                <path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256
													c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
													L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C368.236,489.664,365.511,490.792,362.671,490.787z"/>
                                            </svg>
                                            <div className="document-setting-item" onClick={() => {
                                                openOptions()
                                                filename.setIsChange(true)
                                            }}>{lang.rename}</div>
                                            <div className="document-setting-item" onClick={() => {
                                                openOptions()
                                                setIsOpenDeletePopup(true)
                                            }}>{lang.delete}</div>
                                            {searchString.referer !== 'templates' ? (
                                                <>
                                                    <div className="document-setting-item" onClick={() => {
                                                        history.push('/document/details/' + id + window.location.search)
                                                    }}>{lang.viewDetails}</div>
                                                    <div className="document-setting-item" onClick={() => {
                                                        history.push('/document/timeline/' + id + window.location.search)
                                                    }}>{lang.timeline}
                                                    </div>
                                                </>
                                            ) : ''}
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                        <div className="document-body">
                            {!isMobile ? (
                                <span className={"line " + (isVisibleLine ? 'line-active' : '')}
                                      style={lineChars}></span>
                            ) : ''}
                            <SelectBtns
                                isMobile={isMobile}
                                isVisibleSelectBtns={isVisibleSelectBtns}
                                setIsVisibleSelectBtns={setIsVisibleSelectBtns}
                                mouse={mouse}
                                setManageFullView={setManageFullView}
                                editor={editor}
                                setDocument={setDocument}
                                document={document}
                                setActiveManage={setActiveManage}
                                selectTextInEditor={selectTextInEditor}
                                setSelectNewQuery={setSelectNewQuery}
                                setIsVisibleLine={setIsVisibleLine}
                                setIsOpenQueriesMobile={setIsOpenQueriesMobile}
                                setIsOpenCommentsMobile={setIsOpenCommentsMobile}
                                editors={editors}
                            />

                            <div className="editor-wrapper" onScroll={() => reloadLine()}
                                 style={isOpenFullQueries ? {maxHeight: `calc(100vh - 140px - ${queriesHeight}px)`} : {}}>
                                {isLoadContentComplete ? (
                                    <Editor
                                        ref={editorRef}
                                        initialValue={`<p>${editorContent}</p>`}
                                        init={{
                                            height: isMobile ? 'calc(100vh - 60px)' : 1270,
                                            menubar: false,
                                            directionality: activeLang === 'ar' ? 'rtl' : 'ltr',
                                            plugins: [
                                                `advlist autolink lists link image charmap print preview anchor`,
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount importcss directionality pagebreak'
                                            ],
                                            content_css: ['document','/editor.css'],
                                            toolbar:
                                            //eslint-disable-next-line
                                                'undo redo | formatselect | bold italic strikethrough forecolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | link image | bubbleBarOptionsButton pagebreak',
                                            contextmenu: `image imagetools table spellchecker configurepermanentpen`,
                                            contextmenu_never_use_native: isMobile,

                                            image_uploadtab: true,
                                            images_file_types: 'jpeg,jpg,svg,webp,png',
                                            images_upload_handler: (blobInfo, success, failure, progress) => {
                                                uploadPicture(document._id,
                                                    {
                                                        picture: "data:image/png;base64,"+blobInfo.base64(),
                                                        name: blobInfo.filename(),
                                                        type: blobInfo.blob().type
                                                     }).then(res => {
                                                            if(res.status === 200){
                                                                success(res.data.pictureUrl)
                                                                failure('The picture is successfully', { remove: true });
                                                            }else{
                                                                failure('Error', { remove: true });
                                                            }
                                                            return;
                                                     })
                                            },
                                            setup: function (editor) {
                                                if (document.file !== 'spe' || link || mode === 'read') {
                                                    editor.setMode('readonly')
                                                }
                                                    setEditors(editor);
                                                editor.on('init', function () {
                                                    editor.annotator.register('comment', {
                                                        persistent: true,
                                                        decorate: function (uid, data) {
                                                            return {
                                                                attributes: {
                                                                    'data-mce-comment': data.comment ? data.comment : '',
                                                                    'data-mce-author': data.author ? data.author : 'anonymous'
                                                                }
                                                            };
                                                        }
                                                    });
                                                    editor.annotator.register('query', {
                                                        persistent: true,
                                                        decorate: function (uid, data) {
                                                            return {
                                                                attributes: {
                                                                    'data-mce-comment': data.comment ? data.comment : '',
                                                                    'data-mce-author': data.author ? data.author : 'anonymous'
                                                                }
                                                            };
                                                        }
                                                    });
                                                });

                                                editor.ui.registry.addIcon('signature', '<svg height="19" viewBox="0 0 512 512.00004" width="19" xmlns="http://www.w3.org/2000/svg"><path d="m511.132812 79.929688c-.019531-21.390626-8.367187-41.488282-23.507812-56.59375-31.226562-31.15625-81.992188-31.113282-113.183594.117187l-322.207031 323.503906c-10.480469 10.472657-18.480469 23.4375-23.136719 37.496094l-.300781.914063-28.796875 126.632812 126.984375-28.429688.945313-.3125c14.0625-4.65625 27.035156-12.648437 37.542968-23.152343l322.25-323.542969c15.113282-15.132812 23.429688-35.246094 23.410156-56.632812zm-440.714843 375.34375-13.464844-13.472657 9.722656-42.765625 46.613281 46.640625zm389.003906-346.9375-312.847656 314.105468-56.652344-56.6875 214.300781-215.160156 32.632813 32.632812 28.261719-28.261718-32.691407-32.691406 30.402344-30.519532 32.75 32.75 28.261719-28.261718-32.808594-32.808594 11.707031-11.753906c15.605469-15.625 41.023438-15.648438 56.65625-.050782 7.578125 7.5625 11.757813 17.625 11.769531 28.332032.007813 10.710937-4.152343 20.777343-11.742187 28.375zm-249.164063 363.261718h300.875v39.96875h-340.707031zm0 0"/></svg>');
                                                editor.ui.registry.addSplitButton('signature', {
                                                    icon: 'signature',
                                                    onAction: () => {
                                                        return false
                                                    },
                                                    onItemAction: function (api, value) {
                                                        if (value === 'signature') {
                                                            setIsOpenSignaturePopup(true)
                                                        } else if (value === 'initials') {
                                                            setIsOpenInitialsPopup(true)
                                                        }
                                                    },
                                                    fetch: callback => {
                                                        let items = [
                                                            {
                                                                text: 'Add signature',
                                                                type: 'choiceitem',
                                                                value: 'signature'
                                                            },
                                                            {
                                                                text: 'Add initials',
                                                                type: 'choiceitem',
                                                                value: 'initials'
                                                            }
                                                        ];
                                                        callback(items);
                                                    }
                                                })
                                                editor.on('keydown', event => {
                                                    if (event.keyCode === 9) {
                                                        editor.execCommand('mceInsertContent', false, `&nbsp; &nbsp; &nbsp; &nbsp; `)	// editor.insertContent('<p>You clicked the main button</p>');
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        return false;
                                                    }
                                                })
                                                if (user?.external && document?.status === "in tender") {
                                                    editor.setMode('readonly')
                                                }
                                            }
                                        }}
                                        onInit={() => {
                                            editors.contentDocument.querySelectorAll('a').forEach(item => {
                                                item.addEventListener('click', (e) => {
                                                    e.stopPropagation();
                                                    window.open(item.getAttribute('data-mce-href'))
                                                })
                                            })
                                            if(window.document.querySelectorAll('[aria-label="Insert/edit image"]:last-of-type')[0]){
                                                window.document.querySelectorAll('[aria-label="Insert/edit image"]:last-of-type')[0].addEventListener("click", () => {
                                                    setTimeout(() => {
                                                        window.document.querySelectorAll('[role="tab"]:last-of-type')[0].click()
                                                    }, 300)
                                                })
                                            }
                                            setIsLoadComplete(false)
                                            setEditor(window.document.querySelector('iframe').contentWindow.document)
                                            const iframeEditor = window?.document?.getElementsByTagName('iframe')[0];
                                            if (iframeEditor) {
                                                iframeEditor.height = "";
                                                iframeEditor.style.height = iframeEditor.contentWindow.document.body.scrollHeight + "px";
                                            }
                                        }}
                                        onSelectionChange={(test, strings) => {
                                            setSelectTextInEditor(editor.getSelection().toString() !== '' ? editor.getSelection() : false)
                                        }}
                                        onEditorChange={() => {
                                            if(user) {
                                                const isTemplate = searchString.referer === 'templates';
                                                updateTimeoutUser(id, user._id, isTemplate)
                                            }
                                            if(!isCurrentVersion && !(user?.external && document?.status === "in tender")) {
                                            editorRef.current.editor.setMode('readonly');
                                        }
                                            setEditorContent(editorRef.current.editor.getContent())
                                            reloadLine()
                                        }}
                                        onBlur={() => {
                                            if(!selectTextInEditor){
                                                clearTimeout(timer)
                                                setTimer(null)
                                                setIsVisibleSelectBtns(false)
                                            }
                                            removeActiveItemsInEditor(editor)
                                            saveDocument(searchString, id, editorRef, document, editor).then(res => {
                                                setDocument(document => {
                                                    return {
                                                        ...document,
                                                        editedDate: dateConvert(res?.data?.data?.updated_at, 'dt'),
                                                        editedBy: {
                                                            ...document.editedBy,
                                                            name: user.first_name + ' ' + user.last_name
                                                        }
                                                    }
                                                })
                                            })
                                        }}
                                        onMouseMove={event => {
                                            if(isMobile && !selectTextInEditor && !isVisibleSelectBtns){
                                                setMouse({x: event.pageX, y: event.pageY})
                                            }
                                        }}
                                        onMouseUp={event => {
                                            if (selectTextInEditor && !isMobile) {
                                                setIsVisibleSelectBtns(true)
                                                setMouse({x: event.pageX, y: event.pageY})
                                            } else {
                                                setIsVisibleSelectBtns(false)
                                            }
                                        }}
                                        />
                                ) : ''}
                            </div>
                            {!isMobile && document.file === 'spe' ? (
                                <>
                                    <DocManage
                                        user={user}
                                        activeManage={activeManage}
                                        manageFullView={manageFullView}
                                        isOpenFullQueries={isOpenFullQueries}
                                        setIsOpenFullQueries={setIsOpenFullQueries}
                                        setManageFullView={setManageFullView}
                                        activeItem={activeItem}
                                        setActiveManage={setActiveManage}
                                        setIsOpenRevertPopup={setIsOpenRevertPopup}
                                        setRevertVersion={setRevertVersion}
                                        document={document}
                                        setDocument={setDocument}
                                        manageWrapper={manageWrapper}
                                        reloadLine={reloadLine}
                                        editor={editor}
                                        searchString={searchString}
                                        editorContent={editorContent}
                                        editorRef={editorRef}
                                        setIsCurrentVersion={setIsCurrentVersion}
                                        isCurrentVersion={isCurrentVersion}
                                        link={!!link}
                                        setSearch={setSearch}
                                        getDocumentData={getDocumentData}
                                        createAnnotation={createAnnotation}
                                        mode={mode}
                                        activeHistory={activeHistory}
                                        settings={settings}
                                        compareFiles={compareFiles}
                                        setPrevEditorContent={setPrevEditorContent}
                                        setIsEdited={setIsEdited}
                                    />
                                </>
                            ) : (
                                <>
                                    <HistoryMobile 
                                        isOpen={isOpenHistoryMobile} 
                                        setIsOpen={setIsOpenHistoryMobile}  
                                        document={document}
                                        setIsOpenRevertPopup={setIsOpenRevertPopup} 
                                        setRevertVersion={setRevertVersion}
                                        setIsCurrentVersion={setIsCurrentVersion}
                                        isCurrentVersion={isCurrentVersion}
                                        searchString={searchString}
                                        activeHistory={activeHistory}
                                        editorRef={editorRef}
                                        compareFiles={compareFiles}
                                        setMode={setMode}
                                    />
                                    <QueriesMobile search={search} setSearch={setSearch} isOpen={isOpenQueriesMobile} setIsOpen={setIsOpenQueriesMobile}
                                                   activeItem={activeItem} document={searchDocument}
                                                   setDocument={setDocument}
                                                   editor={editor}
                                                   editorRef={editorRef}
                                                   setActiveManage={setActiveManage}
                                                   searchString={searchString}
                                                   reloadLine={() => false}
                                                   createAnnotation={createAnnotation}
                                                   setIsOpenFullQueries={setIsOpenFullQueries}
                                                   />
                                    <CommentsMobile isOpen={isOpenCommentsMobile} setIsOpen={setIsOpenCommentsMobile}
                                                    activeItem={activeItem} document={document}
                                                    setDocument={setDocument}
                                                    editor={editor}
                                                    editorRef={editorRef}
                                                    setActiveManage={setActiveManage}
                                                    searchString={searchString}
                                                    reloadLine={() => false}
                                                    createAnnotation={createAnnotation}
                                                    setIsOpenFullQueries={setIsOpenFullQueries}
                                                    setSearch={setSearch}
                                                    />
                                </>
                            )}

                        </div>
                        <Queries search={search} setSearch={setSearch} isOpen={isOpenFullQueries} setIsOpen={setIsOpenFullQueries} editor={editor}
                                 document={searchDocument} queries={searchQueries} setDocument={setDocument} queriesRef={queriesRef}
                                 setActiveManage={setActiveManage} editorRef={editorRef} searchString={searchString}
                                 reloadLine={reloadLine} visibleQuery={visibleQuery}/>
                    </>
                ) : ''}
            </div>
        </>
    )
}


export default Document