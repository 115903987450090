import {
	format
} from './appfunctions'

export function uploader(files){
	files = Array.from(files).map(file => file)
	return files.map(file => {
		// console.log(format(file.name.split('.').pop()))
		// console.log(file.name.split('.').pop())
		let type =  format(file.name.split('.').pop())
		return {
			fileData: file,
			name: file.name,
			file: type,
			type: type,
			fileType: file.name.split('.').pop()
		}
	})
}
