import { useState, useEffect } from 'react'


function determineItemType(document){
	let type = null
	if(document){
		if(document.comments.filter(item => item.active).length > 0 && document.comments.filter(item => item.active)[0].id !== null){
			type = 'comment'
		} else if(document.queries.filter(item => item.active).length > 0 && document.queries.filter(item => item.active)[0].id !== null) {
			type = 'query'
		}	
	}

	return type
}


export default function useLine (init = false, editor, document, activeItem, manageWrapper, activeLang, isOpenFullQueries){
	const [isVisibleLine, setIsVisibleLine] = useState(init)
	const [lineChars, setLineChars] = useState({})
	const [reload, setReload] = useState(false)

	function reloadLine(){
		setReload(prev => !prev)
	}

	useEffect(() => {
		let lineController = determineItemType(document);

		if(lineController && activeItem.current && isOpenFullQueries === false){
			let linePoint = activeItem.current.getBoundingClientRect().top < manageWrapper.current.getBoundingClientRect().top + 20 ? manageWrapper.current.getBoundingClientRect().top + 20 : activeItem.current.getBoundingClientRect().top
			let activeItemInEditor = editor.querySelector(`#tinymce .${lineController}-active`)
			if(activeItemInEditor){
				let angle =  Math.asin( (linePoint - (activeItemInEditor.getBoundingClientRect().top + activeItemInEditor.offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) /  Math.sqrt(  ((linePoint - (activeItemInEditor.getBoundingClientRect().top + activeItemInEditor.offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) ** 2) +  (((activeItem.current.getBoundingClientRect().left + (activeLang === 'ar' ? activeItem.current.offsetWidth : '')) - ( activeItemInEditor.getBoundingClientRect().left + (activeLang === 'ar' ? 0 : activeItemInEditor.offsetWidth ) + window.document.querySelector('iframe').getBoundingClientRect().left)) ** 2)  )) * 180/Math.PI
				setLineChars({
					left: activeItemInEditor.getBoundingClientRect().left + (activeLang === 'ar' ? 0 : activeItemInEditor.offsetWidth ) + window.document.querySelector('iframe').getBoundingClientRect().left,
					top: activeItemInEditor.getBoundingClientRect().top + activeItemInEditor.offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3,
					backgroundColor: lineController === 'comment' ? '#2E5BFF' : '#F7C137',
					width: Math.sqrt(  ((linePoint - (activeItemInEditor.getBoundingClientRect().top + activeItemInEditor.offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) ** 2) +  (((activeLang === 'ar' ? activeItem.current.getBoundingClientRect().left + activeItem.current.offsetWidth : activeItem.current.getBoundingClientRect().left) - ( activeItemInEditor.getBoundingClientRect().left + (activeLang === 'ar' ? 0 : activeItemInEditor.offsetWidth ) + window.document.querySelector('iframe').getBoundingClientRect().left)) ** 2)  ),
					transform: `rotateZ(${activeLang === 'ar' ? - 180 - angle : angle}deg)`
				})
				setIsVisibleLine(true)
			}
		} else {
			setIsVisibleLine(false)
		}
	}, [document, reload])

	return {
		isVisibleLine,
		setIsVisibleLine,
		lineChars,
		reloadLine
	}
}


// useEffect(() => {
// 	if(isVisibleLine ){
// 		let linePoint;
// 		if(editor.querySelector('#tinymce .comment-active') && document.comments.filter(item => item.active).length > 0){
// 			linePoint = activeItem.current.getBoundingClientRect().top < manageWrapper.current.getBoundingClientRect().top + 20 ? manageWrapper.current.getBoundingClientRect().top + 20 : activeItem.current.getBoundingClientRect().top
// 			setLineXY({
// 				x: editor.querySelector('#tinymce .comment-active').getBoundingClientRect().left + (activeLang === 'ar' ? 0 : editor.querySelector('#tinymce .comment-active').offsetWidth ) + window.document.querySelector('iframe').getBoundingClientRect().left,
// 				y: editor.querySelector('#tinymce .comment-active').getBoundingClientRect().top + editor.querySelector('#tinymce .comment-active').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3,
// 				color: '#2E5BFF',// ((activeItem.current.getBoundingClientRect().top - (editor.querySelector('#tinymce > p > .query').getBoundingClientRect().top + editor.querySelector('#tinymce > p > .query').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) * 2) + (activeItem.current.getBoundingClientRect().left - ( editor.querySelector('#tinymce > p > .query').getBoundingClientRect().left + editor.querySelector('#tinymce > p > .query').offsetWidth + window.document.querySelector('iframe').getBoundingClientRect().left)) * 2
// 				width: Math.sqrt(  ((linePoint - (editor.querySelector('#tinymce .comment-active').getBoundingClientRect().top + editor.querySelector('#tinymce .comment-active').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) ** 2) +  (((activeLang === 'ar' ? activeItem.current.getBoundingClientRect().left + activeItem.current.offsetWidth : activeItem.current.getBoundingClientRect().left) - ( editor.querySelector('#tinymce .comment-active').getBoundingClientRect().left + (activeLang === 'ar' ? 0 : editor.querySelector('#tinymce .comment-active').offsetWidth ) + window.document.querySelector('iframe').getBoundingClientRect().left)) ** 2)  ),
// 				angle: Math.asin( (linePoint - (editor.querySelector('#tinymce .comment-active').getBoundingClientRect().top + editor.querySelector('#tinymce .comment-active').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) /  Math.sqrt(  ((linePoint - (editor.querySelector('#tinymce .comment-active').getBoundingClientRect().top + editor.querySelector('#tinymce .comment-active').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) ** 2) +  (((activeItem.current.getBoundingClientRect().left + (activeLang === 'ar' ? activeItem.current.offsetWidth : '')) - ( editor.querySelector('#tinymce .comment-active').getBoundingClientRect().left + (activeLang === 'ar' ? 0 : editor.querySelector('#tinymce .comment-active').offsetWidth ) + window.document.querySelector('iframe').getBoundingClientRect().left)) ** 2)  )) * 180/Math.PI
// 			})
// 		} else if (editor.querySelector('#tinymce > p > .query')){
// 			linePoint = activeItem.current.getBoundingClientRect().top < manageWrapper.current.getBoundingClientRect().top + 20 ? manageWrapper.current.getBoundingClientRect().top + 20 : activeItem.current.getBoundingClientRect().top
// 			setLineXY({
// 				x: editor.querySelector('#tinymce > p > .query').getBoundingClientRect().left +  (activeLang === 'ar' ? 0 : editor.querySelector('#tinymce > p > .query').offsetWidth) + window.document.querySelector('iframe').getBoundingClientRect().left,
// 				y: editor.querySelector('#tinymce > p > .query').getBoundingClientRect().top + editor.querySelector('#tinymce > p > .query').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3,
// 				color: '#F7C137',// ((activeItem.current.getBoundingClientRect().top - (editor.querySelector('#tinymce > p > .query').getBoundingClientRect().top + editor.querySelector('#tinymce > p > .query').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) * 2) + (activeItem.current.getBoundingClientRect().left - ( editor.querySelector('#tinymce > p > .query').getBoundingClientRect().left + editor.querySelector('#tinymce > p > .query').offsetWidth + window.document.querySelector('iframe').getBoundingClientRect().left)) * 2
// 				width: Math.sqrt(  ((linePoint - (editor.querySelector('#tinymce > p > .query').getBoundingClientRect().top + editor.querySelector('#tinymce > p > .query').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) ** 2) +  (( (activeLang === 'ar' ? activeItem.current.getBoundingClientRect().left + activeItem.current.offsetWidth : activeItem.current.getBoundingClientRect().left) - ( editor.querySelector('#tinymce > p > .query').getBoundingClientRect().left + (activeLang === 'ar' ? 0 : editor.querySelector('#tinymce > p > .query').offsetWidth) + window.document.querySelector('iframe').getBoundingClientRect().left)) ** 2)  ),
// 				angle: Math.asin( (linePoint - (editor.querySelector('#tinymce > p > .query').getBoundingClientRect().top + editor.querySelector('#tinymce > p > .query').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) /  Math.sqrt(  ((linePoint - (editor.querySelector('#tinymce > p > .query').getBoundingClientRect().top + editor.querySelector('#tinymce > p > .query').offsetHeight + window.document.querySelector('iframe').getBoundingClientRect().top - 3)) ** 2) +  (((activeItem.current.getBoundingClientRect().left +  (activeLang === 'ar' ? activeItem.current.offsetWidth : '')) - ( editor.querySelector('#tinymce > p > .query').getBoundingClientRect().left + (activeLang === 'ar' ? 0 : editor.querySelector('#tinymce > p > .query').offsetWidth) + window.document.querySelector('iframe').getBoundingClientRect().left)) ** 2)  )) * 180/Math.PI
// 			})
// 		}
// 	} 
	//eslint-disable-next-line