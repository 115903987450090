// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
  border: none;
  outline: none;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center; }
  button:disabled {
    border: 1px solid #BFC5D2;
    color: #BFC5D2;
    background-color: #fff; }

.white {
  border: 1px solid #2E5BFF;
  background-color: #fff;
  color: #2E5BFF;
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap; }
  .white svg {
    margin-right: 10px; }
    .white svg path {
      fill: #2E5BFF; }

.blue {
  color: #fff;
  background-color: #2E5BFF;
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  border: 1px solid #2E5BFF; }
  .blue svg {
    margin-right: 10px; }
    .blue svg path {
      fill: #fff; }
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.scss"],"names":[],"mappings":"AAAA;EACC,gCAAgC;EAChC,eAAe;EACf,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EARxB;IAUE,yBAAyB;IACzB,cAAc;IACd,sBAAsB,EAAA;;AAGxB;EACC,yBAAyB;EACzB,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB,EAAA;EATpB;IAWE,kBAAkB,EAAA;IAXpB;MAaG,aAAa,EAAA;;AAIhB;EACC,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;EACnB,yBAAyB,EAAA;EAT1B;IAWE,kBAAkB,EAAA;IAXpB;MAaG,UAAU,EAAA","sourcesContent":["button{\n\tfont-family: 'Rubik', sans-serif;\n\tcursor: pointer;\n\tborder: none;\n\toutline: none;\n\theight: 34px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\t&:disabled{\n\t\tborder: 1px solid #BFC5D2;\n\t\tcolor: #BFC5D2;\n\t\tbackground-color: #fff;\n\t}\n}\n.white{\n\tborder: 1px solid #2E5BFF;\n\tbackground-color: #fff;\n\tcolor: #2E5BFF;\n\tborder-radius: 4px;\n\tfont-size: 0.75rem;\n\tline-height: 0.9rem;\n\tfont-weight: 500;\n\ttext-decoration: none;\n\twhite-space: nowrap;\n\tsvg{\n\t\tmargin-right: 10px;\n\t\tpath {\n\t\t\tfill: #2E5BFF;\n\t\t}\n\t}\n}\n.blue{\n\tcolor: #fff;\n\tbackground-color: #2E5BFF;\n\tborder-radius: 4px;\n\tfont-size: 0.75rem;\n\tline-height: 0.9rem;\n\tfont-weight: 500;\n\ttext-decoration: none;\n\twhite-space: nowrap;\n\tborder: 1px solid #2E5BFF;\n\tsvg{\n\t\tmargin-right: 10px;\n\t\tpath {\n\t\t\tfill: #fff;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
