// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  height: calc(100vh);
  background-color: #fff;
  z-index: 13; }
`, "",{"version":3,"sources":["webpack://./src/components/ErrorPage/ErrorPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,MAAM;EACN,mBAAmB;EACnB,sBAAsB;EACtB,WAAW,EAAA","sourcesContent":[".error {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  width: 100vw;\n  left: 0;\n  top: 0;\n  height: calc(100vh);\n  background-color: #fff;\n  z-index: 13;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
