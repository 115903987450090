import Cookies from 'universal-cookie';

const axios = require('axios').default;



export async function isLogin () {
	// return localStorage.getItem('login') === 'false' ? false : false
	return await axios.get('/api/v1/ping')
	.then(async (response) => {
		if(response.status === 200){
			return true;
		} else {
			await logout();
			return false
		}
	})
	.catch(async(error) => {
		await logout();
		return false
	})
}

export async function login (email, password, redirect = '/dashboard'){
	return axios.post('/signin', {
		login: 'admin@test.com',
		password: 'admin',
		// redirect
	})
	.then((response) => {
		const cookies = new Cookies();
		cookies.set('SESSION', 'true');
		localStorage.setItem("Authorization", response.data?.token);
		localStorage.setItem('login', true)
		return 200
	})
	.catch(() => {
		return 401
	});
}

export async function logout () {
	//
}
