import React, { useState } from 'react'
import {useParams, useHistory} from 'react-router-dom'

import Button from '../Button/Button'

import {ReactComponent as Lock} from '../../assets/img/lock.svg'

import {
	getShareDocument,
	getShareTemplate
} from '../../utilites/api'

import './Protection.scss'

function Protection(){
	const {link} = useParams()
	const history = useHistory()
	const [password, setPassword] = useState('')

	return (
		<div className="protection">
			<Lock/>
			<div className="title">Please enter the password to view this document.</div>
			<input type="text" value={password} onChange={event => setPassword(event.target.value)}/>
			<Button color={'blue'} padding={30} onClick={() => {
				const search = window.location.search.split('=')[1]
				if(search){
					getShareTemplate(link, password)
					.then(() => {
						localStorage.setItem('pass', password)
						history.push(`/share/${link}?isTemplate=true`)
					})
					.catch(error => {
						console.log(error)
					})
				} else {
					getShareDocument(link, password)
					.then(() => {
						localStorage.setItem('pass', password)
						history.push(`/share/${link}`)
					})
					.catch(error => {
						console.log(error)
					})
				}
			}}>Send</Button>
		</div>
	)
}

export default Protection