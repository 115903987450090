// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .push {
    position: fixed;
    background: #DCF4DF;
    border-radius: 0px 0px 6px 6px;
    height: 35px;
    left: calc(50% - 74px);
    top: 0 !important;
    transform: translateY(-30px);
    z-index: 20; }
    .push-error {
      background-color: #fdd1d1;
      color: #ff7d7d; }
      .push-error .check {
        display: none; }
    .push-active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px); } }
`, "",{"version":3,"sources":["webpack://./src/components/Push/PushMedia.scss"],"names":[],"mappings":"AAAA;EACC;IACC,eAAe;IACf,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,sBAAsB;IACtB,iBAAiB;IACjB,4BAA4B;IAC5B,WAAW,EAAA;IACX;MACC,yBAAwB;MAIxB,cAAc,EAAA;MALd;QAGC,aAAa,EAAA;IAIf;MACC,UAAU;MACV,mBAAmB;MACnB,0BAA0B,EAAA,EAC1B","sourcesContent":["@media screen and (max-width: 600px){\n\t.push{\n\t\tposition: fixed;\n\t\tbackground: #DCF4DF;\n\t\tborder-radius: 0px 0px 6px 6px;\n\t\theight: 35px;\n\t\tleft: calc(50% - 74px);\n\t\ttop: 0 !important;\n\t\ttransform: translateY(-30px);\n\t\tz-index: 20;\n\t\t&-error{\n\t\t\tbackground-color:#fdd1d1;\n\t\t\t.check{\n\t\t\t\tdisplay: none;\n\t\t\t}\n\t\t\tcolor: #ff7d7d;\n\t\t}\n\t\t&-active{\n\t\t\topacity: 1;\n\t\t\tvisibility: visible;\n\t\t\ttransform: translateY(0px);\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
