// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application {
  width: 100vw;
  min-height: calc(var(--vh) * 100); }
  .application-arabic {
    font-family: 'Cairo', sans-serif; }
    .application-arabic * {
      font-family: 'Cairo', sans-serif !important; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACC,YAAY;EACZ,iCAAiC,EAAA;EAGjC;IACC,gCAAgC,EAAA;IADhC;MAGC,2CAA2C,EAAA","sourcesContent":[".application{\n\twidth: 100vw;\n\tmin-height: calc(var(--vh) * 100);\n\t// height: 100vh;\n\t// padding-top: 38px;\n\t&-arabic{\n\t\tfont-family: 'Cairo', sans-serif;\n\t\t*{\n\t\t\tfont-family: 'Cairo', sans-serif !important;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
