// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .delete-file-popup-wrapper .delete-file-popup {
    height: calc(var(--vh, 1vh) * 100);
    height: -webkit-fill-available;
    width: 100%; }
    .delete-file-popup-wrapper .delete-file-popup .delete-file-popup-header {
      padding-left: 18px;
      padding-right: 18px; }
      .delete-file-popup-wrapper .delete-file-popup .delete-file-popup-header .header-title {
        font-size: 0.75rem;
        line-height: 0.9rem; }
    .delete-file-popup-wrapper .delete-file-popup .delete-file-popup-body {
      padding: 25px 15px; }
      .delete-file-popup-wrapper .delete-file-popup .delete-file-popup-body .delete-btns {
        position: absolute;
        bottom: 20px;
        left: 15px;
        width: calc(100% - 30px); }
        .delete-file-popup-wrapper .delete-file-popup .delete-file-popup-body .delete-btns button {
          width: 50%; }
  .delete-file-popup-wrapper-arabic .delete-file-popup .delete-file-popup-header {
    padding-left: 20px;
    padding-right: 30px;
    flex-direction: row-reverse; }
  .delete-file-popup-wrapper-arabic .delete-file-popup .delete-file-popup-body {
    align-items: flex-end; }
    .delete-file-popup-wrapper-arabic .delete-file-popup .delete-file-popup-body .delete-file-item {
      flex-direction: row-reverse; }
      .delete-file-popup-wrapper-arabic .delete-file-popup .delete-file-popup-body .delete-file-item .delete-file-name {
        margin-left: 0px;
        margin-right: 5px; }
    .delete-file-popup-wrapper-arabic .delete-file-popup .delete-file-popup-body .delete-btns {
      display: flex;
      align-items: center;
      align-self: flex-start;
      flex-direction: row-reverse; } }
`, "",{"version":3,"sources":["webpack://./src/components/Popups/DeleteFile/DeleteFileMedia.scss"],"names":[],"mappings":"AAAA;EACC;IAGE,kCAAkC;IAClC,8BAA+B;IAC/B,WAAW,EAAA;IALb;MAOG,kBAAkB;MAClB,mBAAmB,EAAA;MARtB;QAUI,kBAAkB;QAClB,mBAAmB,EAAA;IAXvB;MAeG,kBAAkB,EAAA;MAfrB;QAiBI,kBAAkB;QAClB,YAAY;QACZ,UAAU;QACV,wBAAwB,EAAA;QApB5B;UAsBK,UAAU,EAAA;EAKb;IAGE,kBAAkB;IAClB,mBAAmB;IACnB,2BAA2B,EAAA;EAL7B;IAQE,qBAAqB,EAAA;IARvB;MAUG,2BAA2B,EAAA;MAV9B;QAYI,gBAAgB;QAChB,iBAAiB,EAAA;IAbrB;MAiBG,aAAa;MACb,mBAAmB;MACnB,sBAAsB;MACtB,2BAA2B,EAAA,EAC3B","sourcesContent":["@media screen and (max-width: 600px){\n\t.delete-file-popup-wrapper{\n\t\t// z-index: 4;\n\t\t.delete-file-popup{\n\t\t\theight: calc(var(--vh, 1vh) * 100);\n\t\t\theight:  -webkit-fill-available;\n\t\t\twidth: 100%;\n\t\t\t.delete-file-popup-header{\n\t\t\t\tpadding-left: 18px;\n\t\t\t\tpadding-right: 18px;\n\t\t\t\t.header-title{\n\t\t\t\t\tfont-size: 0.75rem;\n\t\t\t\t\tline-height: 0.9rem;\n\t\t\t\t}\n\t\t\t}\n\t\t\t.delete-file-popup-body{\n\t\t\t\tpadding: 25px 15px;\n\t\t\t\t.delete-btns{\n\t\t\t\t\tposition: absolute;\n\t\t\t\t\tbottom: 20px;\n\t\t\t\t\tleft: 15px;\n\t\t\t\t\twidth: calc(100% - 30px);\n\t\t\t\t\tbutton{\n\t\t\t\t\t\twidth: 50%;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\t&-arabic{\n\t\t\t.delete-file-popup{\n\t\t\t\t.delete-file-popup-header{\n\t\t\t\t\tpadding-left: 20px;\n\t\t\t\t\tpadding-right: 30px;\n\t\t\t\t\tflex-direction: row-reverse;\n\t\t\t\t}\n\t\t\t\t.delete-file-popup-body{\n\t\t\t\t\talign-items: flex-end;\n\t\t\t\t\t.delete-file-item{\n\t\t\t\t\t\tflex-direction: row-reverse;\n\t\t\t\t\t\t.delete-file-name{\n\t\t\t\t\t\t\tmargin-left: 0px;\n\t\t\t\t\t\t\tmargin-right: 5px;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\t.delete-btns{\n\t\t\t\t\t\tdisplay: flex;\n\t\t\t\t\t\talign-items: center;\n\t\t\t\t\t\talign-self: flex-start;\n\t\t\t\t\t\tflex-direction: row-reverse;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\t\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
