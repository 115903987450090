import React, { useState, useEffect, useContext } from 'react'
import {Link, useHistory} from 'react-router-dom'

import Push from '../Push/Push'

import { login } from '../../utilites/auth.js'

import {AppContext} from '../../context/AppContext'

import {
	resetToken
} from '../../utilites/api'


import './Login.scss'
import './LoginMedia.scss'
import checkUserToken from "../../utilites/token/checkUserToken";

function Login ({header, title, mode, setIsLoginPage}){
	let [isSubmitRecovery, setIsSubmitRecovery] = useState(false)
	let [email, setEmail] = useState('')
	let [password, setPassword] = useState('')


	let [push, setPush] = useState(false)
	let [pushText, setPushText] = useState(false)

	let history = useHistory();
	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang

	useEffect(() => {
		if(!localStorage.getItem('login')){
			setIsLoginPage(true)
		}
		return () => {
			setIsSubmitRecovery(false)
		}
	}, [])
	if(checkUserToken()) {
		history.push("dashboard");
	}


	const emailValidate = (email) => {
		if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
			return false
		}
		return true
	}

	document.onkeydown = ((e) => {

		let keyCode = e.code;
		if (keyCode === "Enter") {
			if(mode === 'login'){
				if(email.length === 0){
					setPushText('Please enter an email address')
					setPush(true)
					setTimeout(() => {
						setPush(false)
					}, 1200)
					return false
				} else if (password.length === 0){
					setPushText('Please enter a password')
					setPush(true)
					setTimeout(() => {
						setPush(false)
					}, 1200)
					return false
				} else {
					login(email, password)
					.then(response => {
						if(response === 200){
							setIsLoginPage(false)
							history.push("/dashboard")
						} else {
							setPushText('Incorrect email or password')
							setPush(true)
							setTimeout(() => {
								setPush(false)
							}, 1200)
						}
					})
				}
			} else {
				if(email.length === 0){
					setPushText('Please enter an email address')
					setPush(true)
					setTimeout(() => {
						setPush(false)
					}, 1200)
					return false
				} else if (emailValidate(email)){
					setPushText('Enter valid email')
					setPush(true)
					setTimeout(() => {
						setPush(false)
					}, 1200)
					return false
				} else {
					resetToken(email)
				}
				setIsSubmitRecovery(true)
				setTimeout(() => {
					setIsSubmitRecovery(false)
				}, 1200)	
			}
		}
	})

	return (
		<div className="login-content" onKeyDown={event => {
			if(event.keyCode === 13){
				console.log(mode)
				if(mode === 'login'){
					if(email.length === 0){
						setPushText('Please enter an email address')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
						return false
					} else if (password.length === 0){
						setPushText('Please enter a password')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
						return false
					} else {
						login(email, password)
						.then(response => {
							if(response === 200){
								setIsLoginPage(false)
								history.push("/dashboard")
							} else {
								setPushText('Incorrect email or password')
								setPush(true)
								setTimeout(() => {
									setPush(false)
								}, 1200)
							}
						})
					}
				} else {
					if(email.length === 0){
						setPushText('Please enter an email address')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
						return false
					} else if (emailValidate(email)){
						setPushText('Enter valid email')
						setPush(true)
						setTimeout(() => {
							setPush(false)
						}, 1200)
						return false
					} else {
						resetToken(email)
					}
					setIsSubmitRecovery(true)
					setTimeout(() => {
						setIsSubmitRecovery(false)
					}, 1200)	
				}
			}
		}}>
			<div className={"login-body " + (activeLang === 'ar' ? 'login-body-arabic' : '')}>
				{push ? <Push top={0} padding={8} isError={true}>{pushText}</Push> : ''}
				<h2 className="login-body-header">{header}</h2>
				<span className="login-body-title">{title}</span>
				<div className="input-wrapper">
					<div className="input-wrapper-header">
						<span className="input-title">{lang.emailAddress}</span>
					</div>
					<input type="text" className="app-input" autoComplete="off" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={email}  onChange={event => {
						setEmail(event.target.value)
						// event.target.setSelectionRange(0,0);
					}} onBlur={(event) => {
						if(email.length > 0){
							event.target.style.backgroundColor = '#F9FAFF'
						} else {
							event.target.style.backgroundColor = '#ffffff'
						}
					}}
					tabIndex={1}
					//  onKeyPress={event => {
					// 	event.target.setSelectionRange(0,0);
					// 	console.log(event.key)
					// 	setEmail(event.key += email)
						// console.log(email + ' = ' + event.target.value)
						// if(event.keyCode === 8){
						// 	// console.log(event.target.value.replace(event.target.value.substring(1, 0), ''))
						// 	setEmail(event.target.value.replace(event.target.value.substring(1, 0), ''))
						// }
					// }}
					// unicodeBidi:'bidi-override', textAlign: 'start !important'
					/>
				</div>
				{mode === 'login' ? (
					<div className="input-wrapper">
						<div className="input-wrapper-header">
							<span className="input-title">{lang.password}</span>
							<Link to='/recovery' className="input-title-forgot" tabIndex={-1}>{lang.forgot}</Link>
						</div>
						<input type="password" className="app-input" dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={password} onChange={event => {
							setPassword(event.target.value)
						}} onBlur={(event) => {
							if(password.length > 0){
								event.target.style.backgroundColor = '#F9FAFF'
							} else {
								event.target.style.backgroundColor = '#ffffff'
							}
						}}
						tabIndex={2}/>
					</div>
				) : ''}
				{mode === 'login' ? (
					<button className="sing-in" onClick={() => {
						if(email.length === 0){
							setPushText('Please enter an email address')
							setPush(true)
							setTimeout(() => {
								setPush(false)
							}, 1200)
							return false
						} else if (password.length === 0){
							setPushText('Please enter a password')
							setPush(true)
							setTimeout(() => {
								setPush(false)
							}, 1200)
							return false
						} else {
							login(email, password)
							.then(response => {
								if(response === 200){
									setIsLoginPage(false)
									history.push("/dashboard")
								} else {
									setPushText('Incorrect email or password')
									setPush(true)
									setTimeout(() => {
										setPush(false)
									}, 1200)
								}
							})
						}
					}}>{lang.signIn}</button>
				) : (
					<>
						<div className="recovery-btns-wrapper">
							<Link to='/login' className="back-btn">{lang.backToLogin}</Link>
							<button className="submit-btn" onClick={() => {
								if(email.length === 0){
									setPushText('Please enter an email address')
									setPush(true)
									setTimeout(() => {
										setPush(false)
									}, 1200)
									return false
								} else if (emailValidate(email)){
									setPushText('Enter valid email')
									setPush(true)
									setTimeout(() => {
										setPush(false)
									}, 1200)
									return false
								} else {
									resetToken(email)
								}
								setIsSubmitRecovery(true)
								setTimeout(() => {
									setIsSubmitRecovery(false)
								}, 1200)	
								setIsSubmitRecovery(true)
								setTimeout(() => {
									setIsSubmitRecovery(false)
								}, 1200)	
							}} disabled={isSubmitRecovery ? true : false}>{lang.submit}</button>
						</div>
						<div className={"recovery-submited " + (isSubmitRecovery ? 'recovery-submited-active' : '')}>
							<span className="recovery-submited-check">
								<svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M8.53509 1.51907L3.51095 6.92089C3.18737 7.26879 2.66352 7.26879 2.34077 6.92089L0.242063 4.66531C-0.0806876 4.31741 -0.0806876 3.75418 0.242063 3.40628C0.565641 3.05927 1.08949 3.05927 1.41224 3.40628L2.92586 5.03368L7.36492 0.260926C7.68767 -0.0869755 8.21152 -0.0869755 8.53509 0.260926C8.85867 0.607939 8.85867 1.17117 8.53509 1.51907Z" fill="white"/>
								</svg>
							</span>
							{lang.recoveryPush}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default Login