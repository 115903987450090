import React, {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import { AppContext } from '../../../context/AppContext'

import folder from '../../../assets/img/folder.svg'

import {ReactComponent as DraftIcon} from '../../../assets/img/project/draft.svg'
import {ReactComponent as ReviewIcon} from '../../../assets/img/project/review.svg'
import {ReactComponent as ApprovedIcon} from '../../../assets/img/project/approved.svg'
import {ReactComponent as SignedIcon} from '../../../assets/img/project/signed.svg'

import {
	dateConvert
} from '../../../utilites/appfunctions'
import {
	updateProject
} from '../../../utilites/api'

import './ProjectItem.scss'
import './ProjectItemMedia.scss'

function ProjectItem({project, nav = false, archiveProject, favouritedProject, renameProject}) {
	let [settingIsOpen, setSettingIsOpen] = useState(false)

	const {lang, activeLang, isMobile} = useContext(AppContext)
	
	const history = useHistory()

	const [isRename, setIsRename] = useState(false)



	return (
		<div className={"project-item " + (activeLang === 'ar' ? 'project-item-arabic' : '')} onDoubleClick={event => {
			event.preventDefault()
			history.push(`/projects/${project._id}`)
		}}>
			<div className="project-item-content">
				<div className="project-name-wrapper">
					<img src={folder} alt="folder"/>
					{!isRename ? (
						<div className="project-name">{project.name}</div>
					) : (
						<input type="text"  className={"project-name " + (isRename ? 'project-name-rename' : '')} defaultValue={project.name} 
							readOnly={!isRename}
							onBlur={(event) => {
								setIsRename(false)
								renameProject(project._id, event.target.value)
								updateProject({name: event.target.value}, project._id)
								// setIsRename(setList, list, index, type, false)
								// renameDoc(setList, list, item.id, type, event.target.value, isFolderOpen.path)
								// if(templates){
								// 	updateTemplateName(list.filter(item => item.active)[0].id, item.id, {name: event.target.value})
								// } else {
								// 	updateDocument({name: event.target.value}, item.id)
								// }
							}}
							onKeyDown={event => {
								if(event.keyCode === 13){
									setIsRename(false)
									renameProject(project._id, event.target.value)
									updateProject({name: event.target.value}, project._id)
									// renameDoc(setList, list, item.id, type, event.target.value, isFolderOpen.path)
									// if(templates){
									// 	updateTemplateName(list.filter(item => item.active)[0].id, item.id, {name: event.target.value})
									// } else {
									// 	updateDocument({name: event.target.value}, item.id)
									// }
								}
							}}
						/>
					)}
				</div>
				{activeLang === 'ar' ? (
					<div className="project-edited">{lang.lastEdited}: {dateConvert(project.updated_at || project.created_at, 'dt')}</div>
				) : (
					<div className="project-edited">{lang.lastEdited}: {dateConvert(project.updated_at || project.created_at, 'dt')}</div>	
				)}
				<div className="project-count-data">
					<div className="count-data-item">
						<DraftIcon/>
						<div className="count-data-item-name">{lang.draft}</div>
						<div className="count-data-item-value">{project.documents.filter(item => item.status === 'draft').length}</div>
					</div>
					<div className="count-data-item">
						<ReviewIcon/>
						<div className="count-data-item-name">{lang.review}</div>
						<div className="count-data-item-value">{project.documents.filter(item => item.status === 'review').length}</div>
					</div>
					<div className="count-data-item">
						<ApprovedIcon/>
						<div className="count-data-item-name">{lang.approved}</div>
						<div className="count-data-item-value">{project.documents.filter(item => item.status === 'approved').length}</div>
					</div>
					<div className="count-data-item">
						<SignedIcon/>
						<div className="count-data-item-name">{lang.signed}</div>
						<div className="count-data-item-value">{project.documents.filter(item => item.status === 'signed').length}</div>
					</div>
				</div>
			</div>
			{nav ? (
				<div className="project-item-nav">
					{!isMobile ? (
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" stroke="#8798AD" fill={project.favourite ? '#8798AD' : '#ffffff'} strokeWidth="2" strokeLinejoin="round" onClick={() => {
								favouritedProject(project._id)
							}}/>
						</svg>
					) : ''}
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setSettingIsOpen(prev => !prev)}>
						<path fillRule="evenodd" clipRule="evenodd" d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z" fill={settingIsOpen ? "#2E5BFF" : "#8798AD"}/>
					</svg>
					<div className={"projects-setting " + (settingIsOpen ? 'projects-setting-active' : '')} onClick={event => {
						if(event.clientY < document.querySelector('.projects-setting-active').getBoundingClientRect().top){
							setSettingIsOpen(false)
						}
					}}>
						<svg version="1.1" id="setting-arrow" viewBox="0 0 490.787 490.787">
							<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006
								c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083
								c-0.086,0.089-0.173,0.176-0.262,0.262L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085
								C368.236,489.664,365.511,490.792,362.671,490.787z"/>
							<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256
								c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
								L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C368.236,489.664,365.511,490.792,362.671,490.787z"/>
						</svg>
						{isMobile ? (
							<div className="project-setting-item favorite"  onClick={() => {
								setSettingIsOpen(false)
							}}>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12.9285 18.3686C12.347 18.0637 11.653 18.0637 11.0715 18.3686L6.27146 20.8847L7.18114 15.5421C7.29134 14.8948 7.07686 14.2347 6.60727 13.7759L2.73103 9.98833L8.09324 9.20253C8.74285 9.10734 9.30437 8.69937 9.59565 8.11097L12 3.25403L14.4044 8.11097C14.6956 8.69937 15.2571 9.10734 15.9068 9.20253L21.269 9.98833L17.3927 13.7759C16.9231 14.2347 16.7087 14.8948 16.8189 15.5421L17.8047 15.3742L16.8189 15.5421L17.7285 20.8847L12.9285 18.3686Z" stroke="#2E5BFF" strokeWidth="2" strokeLinejoin="round"/>
								</svg> Add to favourites
							</div>
						) : ''}
						<div className="projects-setting-item" onClick={() => {
							setIsRename(true)
							setSettingIsOpen(false)
						}}>{lang.rename}</div>
						<div className="projects-setting-item" onClick={() => {
								archiveProject(project._id)
								setSettingIsOpen(false)
							}}>{lang.archive}</div>
					</div>
				</div>
			) : ''}
		</div>
	)
}

export default ProjectItem