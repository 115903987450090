import React, {useContext, useState, useEffect} from 'react'
import {AppContext} from '../../../context/AppContext'
import DocManageItem from './DocManageItem/DocManageItem'
import {resetActiveItems} from '../../../utilites/document'
import {
    getDocument,
    compareDocumentFiles
} from '../../../utilites/api'
import {ReactComponent as Help} from '../../../assets/img/help.svg'
import {ReactComponent as Chat} from '../../../assets/img/chat.svg'
import {ReactComponent as Copy} from '../../../assets/img/copy.svg'
import {ReactComponent as Expand} from '../../../assets/img/expand.svg'
import {ReactComponent as Flagged} from '../../../assets/img/flagged.svg'
import {ReactComponent as History} from '../../../assets/img/history.svg'
import {ReactComponent as Backup} from '../../../assets/img/backup.svg'
import {ReactComponent as Link} from '../../../assets/img/link.svg'
import './DocManage.scss'
import './DocManageMedia.scss'



function DocManage({
                       user,
                       activeManage,
                       manageFullView,
                       isOpenFullQueries,
                       setManageFullView,
                       manageWrapper,
                       editorRef,
                       mode,
                       editor,
                       activeItem,
                       reloadLine,
                       setActiveManage,
                       setIsOpenFullQueries,
                       setIsOpenRevertPopup,
                       setRevertVersion,
                       document,
                       setDocument,
                       searchString,
                       link,
                       setIsCurrentVersion,
                       isCurrentVersion,
                       getDocumentData,
                       createAnnotation,
                       setSearch,
                       activeHistory,
                       settings,
                       compareFiles,
                       setPrevEditorContent,
                       setIsEdited
                   }) {


    const {lang, activeLang} = useContext(AppContext)

    const [type, setType] = useState('queries')

    useEffect(() => {
        setType(prev => {

            if (activeManage === 0) {
                return 'queries'
            } else if (activeManage === 1) {
                return 'comments'
            } else {
                return prev
            }
        })
    }, [activeManage])

    return (
        <div
            className={"document-manage " + (!manageFullView ? 'document-manage-hide ' : '') + (isOpenFullQueries ? 'document-manage-none' : '')}>
            <div className="document-manage-header">
                <div className={"manage-open " + (!manageFullView ? 'manage-open-hide' : '')}
                     onClick={() => setManageFullView(prev => !prev)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M9.3516 4.72516L16.0847 11.4576C16.3842 11.7574 16.3842 12.2428 16.0847 12.5423L9.3516 19.2754C9.05204 19.5749 8.5666 19.5749 8.26705 19.2754L7.72475 18.7331C7.425 18.4337 7.425 17.9478 7.72475 17.6482L13.3732 12L7.72475 6.35161C7.425 6.05225 7.425 5.56657 7.72475 5.26706L8.26705 4.72476C8.56694 4.42501 9.05204 4.42501 9.3516 4.72516Z"
                              fill="#8798AD"/>
                    </svg>
                </div>
                <button onClick={() => {
                    setActiveManage(0)
                    resetActiveItems(editor, setDocument)
                    if(!isCurrentVersion){
                        getDocument(document.files[0].filename)
                            .then(res => {
                                setIsCurrentVersion(true)
                                editorRef.current.editor.execCommand('mceSetContent', true, res.content)
                                activeHistory(document.files[0]._id)
                            })
                    }
                }} className={activeManage === 0 ? 'active' : ''}><Help/></button>
                {!link && !user?.external ? (
                    <button onClick={() => {
                        setActiveManage(1)
                        resetActiveItems(editor, setDocument)
                        if(!isCurrentVersion){
                            getDocument(document.files[0].filename)
                                .then(res => {
                                    setIsCurrentVersion(true)
                                    editorRef.current.editor.execCommand('mceSetContent', true, res.content)
                                    activeHistory(document.files[0]._id)
                                })
                        }
                    }} className={activeManage === 1 ? 'active' : ''}><Chat/></button>
                ) : ''}
                {(searchString.referer !== 'templates' && !link) ? (
                    <button onClick={() => {
                        setActiveManage(2)
                        resetActiveItems(editor, setDocument)
                        getDocumentData();
                        if(!isCurrentVersion){
                            getDocument(document.files[0].filename)
                                .then(res => {
                                    setIsCurrentVersion(true)
                                    editorRef.current.editor.execCommand('mceSetContent', true, res.content)
                                    activeHistory(document.files[0]._id)
                                })
                        }
                    }} className={activeManage === 2 ? 'active' : ''}><Copy/></button>
                ) : ''}
            </div>
            <div className="document-manage-body" ref={manageWrapper} onScroll={() => reloadLine()}>
                {activeManage !== 2 ? (
                    <>
                        {activeManage === 0 ? (
                            <>
                                <button className="fullscreen-btn" onClick={() => {
                                    setIsOpenFullQueries(window.document.documentElement.clientWidth >= 950)
                                }}>
                                    {lang.expandView}
                                    <Expand/>
                                </button>
                                <div className="total-queries">
                                    {activeLang === 'ar' ? (
                                        <div className="queries-count">
                                            {document.queries.length} :{lang.totalQueries}
                                        </div>
                                    ) : (
                                        <div className="queries-count">
                                            {lang.totalQueries}: {document.queries.length}
                                        </div>
                                    )}
                                    <div className="queries-flagged">
                                        <Flagged/>
                                        <span>{document.queries.filter(item => item.status === 'on').length} {lang.flagged}</span>
                                    </div>
                                </div>
                            </>
                        ) : ''}
                        {document[type].length > 0 ? document[type].map((item, index) =>
                            <DocManageItem
                                data={item}
                                index={index}
                                type={type}
                                setDocument={setDocument}
                                document={document}
                                editor={editor}
                                activeItem={activeItem}
                                setActiveManage={setActiveManage}
                                editorRef={editorRef}
                                searchString={searchString}
                                key={index}
                                reloadLine={reloadLine}
                                createAnnotation={createAnnotation}
                                setIsOpenFullQueries={setIsOpenFullQueries}
                                setSearch={setSearch}
                                settings={settings}
                            />
                        ) : (
                            <div className="manage-empty">
                                {type === 'queries' ? lang.noQueries : lang.noComments}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="compare-key-info">
                            <div className="info-title">
                                {lang.compareKey}
                            </div>
                            <div>
                                <div className="info-example info-example-green"><span>This line was added to the current version.</span></div>
                                <div className="info-example info-example-red"><span>This line was removed to the current version.</span></div>
                                <div className="info-example info-example-purple"><span>Formatting was changed to the current version.</span></div>
                            </div>
                        </div>

                        {document.files.sort((a, b) => b.created_at - a.created_at).map((version, index) => {
                            const external = user?.external && document.status === 'in tender';
                            if (index === 0 && external) {
                                return;
                            }

                            return (
                                <div className={"version-item " + (version.active ? 'version-item-active' : '')}
                                     key={index} onClick={() => {
                                    if (index === 0 && !external || index === 1 && external) {
                                        editorRef.current.editor.setMode('design')
                                        setIsCurrentVersion(true);
                                    } else {
                                        setPrevEditorContent(null)
                                        setIsEdited(false)
                                        setIsCurrentVersion(false);
                                    }
                                    if (version.active) {
                                        getDocument(external ? document.files[1].filename : document.files.sort((a, b) => b.created_at - a.created_at)[0].filename)
                                            .then(res => {
                                                editorRef.current.editor.execCommand('mceSetContent', true, res.content)
                                                activeHistory(external ? document.files[1]._id : document.files.sort((a, b) => b.created_at - a.created_at)[0]._id)
                                            })
                                    } else {
                                        getDocument(version.filename)
                                            .then(res => {
                                                editorRef.current.editor.execCommand('mceSetContent', true, res.content)
                                                activeHistory(version._id)
                                            })
                                    }
                                }}>
                                    <span>V{document.files.length - index}.{version.date} {index === 0 && !external || index === 1 && external ? '(Current)' : ''}</span>
                                    {index !== 0 ? (
                                        <div className="version-nav">
                                            {/*<Link onClick={event => {*/}
                                            {/*    setIsCurrentVersion(false);*/}
                                            {/*    event.stopPropagation()*/}
                                            {/*    navigator.clipboard.writeText(window.location.host + `/document/${document.id}?referer=${searchString.referer}&docname=${version.filename}`)*/}
                                            {/*}}/>*/}
                                            <div className="tooltip-wrap">
                                                <History onClick={event => {
                                                    setIsCurrentVersion(false);
                                                    event.stopPropagation()
                                                    compareDocumentFiles(document.id, document.files.sort((a, b) => b.created_at - a.created_at)[0].filename, version.filename)
                                                        .then(res => {
                                                            compareFiles(res.data.data)
                                                        })
                                                }}/>
                                                <div className="tooltip-popup">Compare</div>
                                            </div>
                                            <div className="tooltip-wrap">
                                                <Backup onClick={event => {
                                                    setIsCurrentVersion(false);
                                                    event.stopPropagation()
                                                    getDocument(version.filename)
                                                        .then(res => {
                                                            editorRef.current.editor.execCommand('mceSetContent', true, res.content)
                                                            activeHistory(version._id)
                                                            setIsOpenRevertPopup(true)
                                                            setRevertVersion({
                                                                number: document.files.length - index,
                                                                filename: version.filename
                                                            })
                                                        })

                                                }}/>
                                                <div className="tooltip-popup">Restore</div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>
                            )
                        })}
                    </>
                )}
                <div id={"scrollItem"}></div>
            </div>
        </div>
    )
}

export default DocManage