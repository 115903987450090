import React, {useState, useEffect, useContext} from 'react'
import { useLocation } from 'react-router-dom'

import { AppContext } from '../../context/AppContext'
import { NavContext } from '../../context/NavContext'

import Button from '../Button/Button'

import SearchBar from './SearchBar/SearchBar'
import ProfileNav from './ProfileNav/ProfileNav'
import NotificationsNav from './NotificationsNav/NotificationsNav'
import Menu from './Menu/Menu'

import logo from '../../assets/logo.png'
import {ReactComponent as Check} from '../../assets/img/check.svg'

import './Nav.scss'
import './NavMedia.scss'

function Nav ({children, isLoginPage, inputSearch}){

	let [profilePopupIsOpen, setProfilePopupIsOpen] = useState(false)
	let [notificationsIsOpen, setNotificationsIsOpen] = useState(false)
	let [navView, setNavView] = useState(true)

	let [isDocumentPage, setIsDocumentPage] = useState(false)
	let [isDetailsPage, setIsDetailsPage] = useState(false)
	let [isSigningPage, setIsSigningPage] = useState(false)
	let [isAgree, setIsAgree] = useState(false)
	let [isOpenMySignaturePopup, setIsOpenMySignaturePopup] = useState(false)
	let [mySignature, setMySignature] = useState(false)

	const location = useLocation()

	const activeLang = useContext(AppContext).activeLang
	const lang = useContext(AppContext).lang
	const isMobile = useContext(AppContext).isMobile
	useEffect(() => {
		
		if((location.pathname.includes('/document') || location.pathname.includes('/share')) && !location.pathname.includes('/details')  && !location.pathname.includes('/timeline')){
			setIsDocumentPage(true)
			setIsDetailsPage(false)
		} else if(location.pathname.includes('/details') || location.pathname.includes('/timeline')) {
			setIsDetailsPage(true)
			setIsDocumentPage(true)
		} else if (location.pathname.includes('/signing')) {
			setIsSigningPage(true)
		} else {
			setIsDocumentPage(false)
			setIsDetailsPage(false)
		}
	}, [location])
	return (
		<>
			<header className={"application-header " + (isLoginPage ? 'application-header-login ' : '') + (isDocumentPage || isDetailsPage ? 'application-header-document ' : '') + (isSigningPage ? 'application-header-signing ' : '') + (activeLang === 'ar' ? 'application-header-arabic' : '')}>
				<div className="application-logo-wrapper">{isLoginPage !== null ? (
					<>
						{isMobile ? (
							<>
								{!window.location.pathname.includes('/projects') && !window.location.pathname.includes('/companies') && !window.location.pathname.includes('/templates') ? <img src={logo} alt="logo" className="logo"/> : ''}
								{/* <div className="application-name">{lang.documentManager}</div> */}
							</>
						) : <img src={logo} alt="logo" className="logo"/>}
					</>
				) : ''}
				</div>
				{!isLoginPage && isLoginPage !== null && !isSigningPage ? (
					<>
						<SearchBar setNotificationsIsOpen={setNotificationsIsOpen} setProfilePopupIsOpen={setProfilePopupIsOpen} inputSearch={inputSearch}/>
						<nav className="header-nav">
							<NotificationsNav notificationsIsOpen={notificationsIsOpen} setNotificationsIsOpen={setNotificationsIsOpen} setProfilePopupIsOpen={setProfilePopupIsOpen}/>
							<ProfileNav profilePopupIsOpen={profilePopupIsOpen} setProfilePopupIsOpen={setProfilePopupIsOpen} setNotificationsIsOpen={setNotificationsIsOpen}/>
						</nav>
					</>
				) : ''}
				{isSigningPage ? (
					<>
						<div className="read-wrapper">
							<div className="read-electronic">
								<span>Please read the</span>
								<a href="">Electronic Record and Signature Disclosure</a>
							</div>
							<div className="agree" onClick={() => setIsAgree(prev => !prev)}>
								<div className={"checkbox " + (isAgree ? 'checkbox-active' : '')}>
									<Check/>
								</div>
								<div className="checkbox-span">I agree to use electronic records and signatures</div>
							</div>
						</div>
						<div className="signing-btns">
							<Button color={'white'} padding={10} margin={10} disabled={mySignature ? false : true} onClick={() => {
								setIsOpenMySignaturePopup(true)
							}}>My Signature</Button>
							<Button color={'blue'} padding={10} disabled={isAgree ? false : true} onClick={() => {
								setIsOpenMySignaturePopup(true)
							}}>{mySignature ? lang.submit : 'Start'}</Button>
						</div>
					</>
				) : ''}
			</header>
			<section className={"application-body " + (isLoginPage ? 'application-body-login ' : '') + (activeLang === 'ar' ? 'application-body-arabic' : '')}>
				{!isLoginPage && isLoginPage !== null && !isSigningPage ? (
					<>
						<Menu navView={navView} setNavView={setNavView} isDocumentPage={isDocumentPage}/>
						<div className={"application-content " + (!navView ? 'application-content-reduced ' : '') + (isDocumentPage ? 'application-content-document ' : '') + (isDetailsPage ? 'application-content-details ' : '') + (activeLang === 'ar' ? 'application-content-arabic' : '')}>
							{children}
						</div>
					</>
				) : ''}
				{isLoginPage ? (
					<>
						{children}
					</>
				) : ''}
				{isSigningPage && !isLoginPage ? (
					<NavContext.Provider value={{isOpenMySignaturePopup: isOpenMySignaturePopup, setIsOpenMySignaturePopup: setIsOpenMySignaturePopup, setMySignature: setMySignature}}>
						{children}
					</NavContext.Provider>
				) : ''}
			</section>
		</>
	)
}

export default Nav