import React, { useContext, useState, useEffect, useRef } from 'react'

import {
	changeName
} from '../../utilites/appfunctions'

import { AppContext } from '../../context/AppContext'




import './FileName.scss'
import './FileNameMedia.scss'
import {updateDocumentType, updateTemplateName} from "../../utilites/api";
import {getConverter} from "../../utilites/getconverter";


function FileName({filename, doc = false}) {

	const { setList, setItem, item, isChange, setIsChange, updateFileNameServer, updateFileNameClient } = filename


	const activeLang = useContext(AppContext).activeLang
	const searchString = getConverter(window.location.search)

	const buffer = useRef()
	const input = useRef()

	useEffect(() => {
		if(isChange){
			input.current.focus()
			input.current.select()
		}
	}, [isChange])

	return (
			<div className={"file-name " + (activeLang === 'ar' ? 'file-name-arabic' : '')}>
				<span  className={"file-name-static " + (isChange ? 'file-name-static-none' : '')}  onDoubleClick={() => {
					setIsChange(true)
				}} >{item?.name}</span>
				<input 
					ref={input}
					type="text" className={"file-name-input " + (isChange ? 'file-name-input-active' : '')} 
					value={item?.name}
					dir={activeLang === 'ar' ? 'rtl' : 'ltr'}
					style={{width: buffer.current ?  buffer.current.clientWidth + 20 : 100}}
					onChange={event => {
						changeName(setList, setItem, item, event.target.value)
					}}


					onKeyDown={event => {
						if(event.keyCode === 13){
							setIsChange(false)
							// if(list.groupid === undefined){
							// 	updateFileName({name: active.name}, active.id)
							// } else {
							// 	updateFileName(list.groupid, active.id, {name: active.name})
							// }
						}
					}}
					onBlur={(event) => {
						setIsChange(false)
						if(searchString.referer === 'templates'){
							updateFileNameServer(item.groupid, item.id, {name: item.name})
						}else if(searchString.referer){
							updateFileNameServer(item.id, null, event.target.value)
						}else{
							updateFileNameServer({name:item.name}, item.id)
						}
						changeName(setList, setItem, item, event.target.value)
						return false

						//updateFileNameServer({name: item?.name}, item?.id)
						// if(list.groupid === undefined){
						// 	updateFileName({name: item.name}, item.id)
						// } else {
						// 	updateFileName(list.groupid, active.id, {name: active.name})
						// }
					}}
				/>
				<div className="input-buffer" ref={buffer}>{item?.name}</div>
			</div>
	)
}
export default FileName