import printJS from 'print-js';
const axios = require('axios').default;
const qs = require('qs');


const url = ''
export async function updateProfile(data, file, control = true){
	let formdata = new FormData();
	//error
	// if(data.new_password !== '' && data.repeat_new_password === data.new_password){
	// 	formdata.append('password', data.new_password)
	// }
	delete data['new_password']
	delete data['repeat_new_password']
	delete data['picture']
	for(let key in data){
		formdata.append(key, data[key])
	}
	if(file && control) {
		formdata.append("picture", file, file.name);
	}
	return await axios({
		method: 'put',
		url: `${url}/api/v1/account`,
		data: formdata,
		headers: {'Content-Type': 'multipart/form-data' }
	})
	.then(result => {
		return result.data.data
	})
	.catch(() => false);
}

export async function getUsers(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/users`
	})
	.then(result => result.data)
}

export async function deleteUser(id){
	return await axios({
		method: 'delete',
		url: `/api/v1/users/${id}`
	})
}

export async function createUser(data){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/users`, 
		data: qs.stringify(data)
	})
}

export async function getCompanies(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/companies`
	})
	.then(result => result.data)
}

export async function getCompany(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/companies/${id}`
	})
	.then(result => result.data)
}

export async function createCompany(name){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/companies`, 
		data: qs.stringify({
			'name' : name,
			'favourite' : false
		})
	})
}


export async function addToFavoriteCompany(favVal, id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/companies/${id}`, 
		data: qs.stringify({
			favourite: favVal
		})
	})
}
export async function changeNameCompany(data, id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/companies/${id}`, 
		data: qs.stringify(data)
	})
}

export async function deleteCompany(id){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/companies/${id}`
	})
	.then(result => result.data)
}

export async function restoreCompany(id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/companies/${id}?restore=true`
	})
		.then(result => result.data)
}

export async function linkProjectToCompany(id, projectId){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/companies/${id}/projects`, 
		data: qs.stringify({project_id: projectId})
	})
}

export async function unlinkProjectFromCompany(id, projectId){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/companies/${id}/projects/${projectId}`
	})
	.then(result => result.data)
}

export async function getProjects(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/projects`
	})
	.then(result => result.data)
}

export async function getProject(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/projects/${id}`
	})
	.then(result => result.data)
}

export async function createProject(data){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/projects`, 
		data: qs.stringify(data)
	})
}

export async function deleteProject(id){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/projects/${id}`
	})
	.then(result => result.data)
}

export async function restoreProject(id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/projects/${id}?restore=true`
	})
		.then(result => result.data)
}

export async function updateProject(data, id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/projects/${id}`, 
		data: qs.stringify(data)
	})
}

export async function getFolders(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/projects/${id}/folders`
	})
	.then(result => result.data)
}

export async function createFolderInProject(id, name, parent = undefined){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/projects/${id}/folders`, 
		data: qs.stringify({name : name, parent_id: parent})
	})
}

export async function deleteFolderFromProject(id, folderId){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/projects/${id}/folders/${folderId}`
	})
	.then(result => result.data)
}

export async function restoreFolderInProject(id, folderId) {
	return await axios({
		method: 'put',
		url: `${url}/api/v1/projects/${id}/folders/${folderId}?restore=true`
	})
		.then(result => result.data)
}

export async function updateFolderName(id, folderId, name){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/projects/${id}/folders/${folderId}`, 
		data: qs.stringify({name: name})
	})
}

export async function linkDocToFolder(id, folderId, documentId){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/projects/${id}/folders/${folderId}/documents/${documentId}`
	})
}

export async function unlinkDocFromFolder(id, folderId, documentId){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/projects/${id}/folders/${folderId}/documents/${documentId}`
	})
}

export async function moveFolder(id, folderId, parent){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/projects/${id}/folders/${folderId}`, 
		data: qs.stringify({parent_id: parent})
	})
}


export async function getTemplates(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/templates`
	})
	.then(result => result.data)
}

export async function getTemplateGroup(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/templates/${id}`
	})
	.then(result => result.data)
}

export async function createTemplateGroup(name){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/templates/groups`, 
		data: qs.stringify({name: name})
	})
}

export async function restoreTemplateGroup(id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/templates/groups/${id}?restore=true`
	})
		.then(result => result.data)
}

export async function updateTemplateGroup(data, id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/templates/groups/${id}`, 
		data: qs.stringify(data)
	})
}

export async function getDocumentTemplates(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/templates/document_templates`
	})
		.then(result => result.data)
}

export async function getTemplatesWithFiles(group_id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/templates/${group_id}/with_files`
	})
		.then(result => result.data)
}

export async function deleteTemplateGroup(id){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/templates/groups/${id}`
	})
	.then(result => result.data)
}

export async function createTemplate(idGroup, name){
	let formdata = new FormData();
		formdata.append('name', name)
	return await axios({
		method: 'post',
		url: `${url}/api/v1/templates/${idGroup}`, 
		data: formdata
	})
}

export async function deleteTemplate(idGroup, idTemplate){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/templates/${idTemplate}`, 
	})
	.then(result => result.data)
}

export async function getTemplate (idGroup, idDoc, download = false){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/templates/${idGroup}/${idDoc}${download ? '?download=true' : ''}`
	})
	.then(result => {
		if(download){
			window.open(`${url}/templates/${idGroup}/${idDoc}?download=true`)
		}
		return result.data
	})
}

export async function updateTemplate(idGroup, idTemplate, content, picture){
	let formData = new FormData()
	function urltoFile(url, filename, mimeType){
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }
	if(picture) {
		let file = await urltoFile(picture, 'screen.png', "image/png")
			.then(function (file) {
				return file
			});
		formData.append('picture', file)
	}
	
	// console.log(file)
	formData.append('content', content)
	return await axios({
		method: 'put',
		url: `${url}/api/v1/templates/${idGroup}/${idTemplate}`, 
		data: formData,
		headers: {'Content-Type': 'multipart/form-data'}
	})
}

export async function updateTemplateStatus(idTemplate, status){
	let formData = new FormData()
	formData.append('status', status)
	return await axios({
		method: 'put',
		url: `${url}/api/v1/templates/status/${idTemplate}`, 
		data: formData,
		headers: {'Content-Type': 'multipart/form-data'}
	})
}

export async function shareTemplate(id, filename, password = null) {
	console.log(id, filename, password)
	return await axios({
		method: 'post',
		url: `${url}/api/v1/templates/${id}/share?password=${password ? true : false}`,
		data: qs.stringify({filename: filename, password: password})
	})
		.then(res => res.data)
}

export async function getShareTemplate(link, password = null) {
	return await axios({
		method: 'post',
		url: `${url}/api/v1/templates/share/${link}`,
		data: qs.stringify(password ? {password: password} : {})
	})
	.then(res => res.data)
}

export async function updateTemplateName(idGroup, idTemplate, data){
	let formData = new FormData()
	formData.append('name', data.name)
	return await axios({
		method: 'put',
		url: `${url}/api/v1/templates/${idGroup}/${idTemplate}`, 
		data: formData,
		headers: {'Content-Type': 'multipart/form-data'}
	})
}

export async function uploadDocument(data, convert = false){
	let formdata = new FormData();
	data.map(doc => {
		formdata.append('files', doc, doc.name)
	})
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/upload${convert ? '?convert=true': ''}`,
		data: formdata
	}).then(res => {
		console.log(res)
		return res
	})
}

export async function getDocuments(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents`
	})
	.then(result => result.data)
}

export async function deleteDocument(id){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/documents/${id}`
	})
	.then(result => result.data)
}

export async function getDocument(docName, download = false){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents/${docName}${download ? '?download=true' : '?edit=true'}`
	})
	.then(result => {
		if(download){
			window.open(`${url}/api/v1/documents/${docName}?download=true`)
		}
		return result.data
	})
}
export async function getPicture(docName){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents/${docName}?download=true`,
		responseType: 'blob',
	})
		.then(result => {
			return result.data
		})
}

// export async function getDocumentRights(id){
// 	return await axios({
// 		method: 'get',
// 		url: `${url}/api/v1/documents/${}?edit=true`
// 	})
// 	.then(result => result.data)
// }


export async function getDocumentId(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents/getById/${id}`
	})
	.then(result => result.data)
}


export async function createDocument(id, name, picture = null) {
	const data = {name: name + '.spe'};
	if (id) {
		data.template_id = id;
	}
	if(picture){
		data.picture = picture;
	}
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents`,
		data: qs.stringify(data)
	})
		.then(res => {
			return res
		})
}

export async function updateDocument(data, id, picture, history = false){
	//console.log({...data, new_file: history === true ? history : false})
	let formData = new FormData()
	function urltoFile(url, filename, mimeType){
		return (fetch(url)
				.then(function(res){return res.arrayBuffer();})
				.then(function(buf){return new File([buf], filename,{type:mimeType});})
		);
	}

	if(picture){
		let file = await urltoFile(picture, 'screen.png', "image/png")
			.then(function(file){ return file });
		formData.append('picture', file)
	}

	formData.append('content', data.content)
	formData.append('new_file', history === true ? history : false)
	return await axios({
		method: 'put',
		url: `${url}/api/v1/documents/${id}?new_file=${history === true ? history : false}`, 
		data: formData,
 		headers: {'Content-Type': 'multipart/form-data'}
	})
	.then(res => {
		return  res
	})
}

export async function backupDocument(id, filename){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/${id}/rollback-to-file`, 
		data: qs.stringify({filename: filename})
	})
}



export async function updateDocumentStatus(id, status){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/documents/status/${id}`, 
		data: qs.stringify({status: status})
	})
}

export async function updateDocumentType(id, type = null, name = null){
	let formData = new FormData()
	if(type){
		formData.append('type', type)
	}
	if(name){
		formData.append('name', name)
	}
	return await axios({
		method: 'put',
		url: `${url}/api/v1/documents/${id}`,
		data: formData,
		headers: {'Content-Type': 'multipart/form-data'}
	})
}

export async function updateDocumentFavourite(id, favourite){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/documents/favorite/${id}`, 
		data: qs.stringify({favorite: favourite})
	})
}


export async function compareDocumentFiles(id, file1, file2){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/${id}/compare-files`, 
		data: qs.stringify({src_filename: file1, dst_filename : file2})
	})
}

export async function convertDocToSpe(id){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/documents/${id}/convert`
	})
}

export async function getDocumentDetails(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents/${id}/details`
	})
	.then(result => result.data)
}


export async function updateDocumentDetails(id, data){
	console.log(data)
	return await axios({
		method: 'put',
		url: `${url}/api/v1/documents/${id}/details`, 
		headers: {
            'Content-Type': 'application/json',
        },
		data: JSON.stringify({details : data.map(item => ({field: item.field._id, value: item.value}))})
	})
}

export async function getDocumentTimeline(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents/${id}/timeline`
	})
	.then(result => result.data)
}

export async function downloadArchive(documents){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/download-archive`, 
		headers: {
            'Content-Type': 'application/json',
        },
		data: JSON.stringify({documents: documents}),
		responseType: 'blob',
	})
	.then(res => {
		let url = window.URL.createObjectURL(new Blob([res.data]))
		let a = document.createElement('a')
		a.href = url
		a.download = "archive.zip"
		document.body.appendChild(a)
		a.click()
		a.remove()
	})
} 

export async function linkUserToDoc(id, user_id, permission){
	console.log(user_id)
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/${id}/users`, 
		data: qs.stringify({user_id: user_id, permission : permission})
	})
}


export async function unlinkUserFromDoc(id, user){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/documents/${id}/users/${user}`
	})
}

export async function getManage(id, type){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/${type}/${id}`
	})
	.then(result => result.data)
}

export async function getTemplateManage(id, type){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/${type}/template/${id}`
	})
		.then(result => result.data)
}


export async function addCommentToDocument(id, comment, type, mentions){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/${type}/${id}`,
		data: qs.stringify({text: comment, mentions : mentions})
	}).then(res => res)
}
export async function addCommentToTemplate(id, comment, type, mentions){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/${type}/template/${id}`,
		data: qs.stringify({text: comment, mentions : mentions})
	}).then(res => res)
}

export async function deleteCommentFromDocument(id, type){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/${type}/${id}`
	})
}

export async function addReplyToComment(id, reply, type, mentions){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/${type}/${id}/replies`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: JSON.stringify({text: reply, mentions: mentions})
	}).then(res => res)
}
 

export async function editComment(id, newText, type, mentions){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/${type}/${id}`, 
		data: qs.stringify({text: newText, mentions : mentions})
	})
	.then(res => res.data)
}

export async function editReplyToComment(id, idReply, newReply, type, mentions){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/${type}/${id}/replies/${idReply}`, 
		data: qs.stringify({text: newReply, mentions: mentions})
	})
	.then(res => res.data)
}

export async function editQueryStatus(id, status){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/queries/${id}`, 
		data: qs.stringify({status: status})
	})
	.then(res => res.data)
}

export async function searchQuery(document_id, text){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/queries/${document_id}/search/${text}`,
		data: qs.stringify({document_id: document_id, text: text})
	})
		.then(res => res.data)
}

export async function searchTemplateQuery(template_id, text){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/queries/template/${template_id}/search/${text}`,
		data: qs.stringify({template_id: template_id, text: text})
	})
		.then(res => res.data)
}

export async function linkUserToProject(projectId, userId, permission = 'viewer'){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/projects/${projectId}/users`, 
		data: qs.stringify({user_id: userId, permission : permission})
	})
}

export async function unlinkUserToProject(projectId, userId){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/projects/${projectId}/users/${userId}`
	})
}

export async function linkDocToProject(projectId, documentId, name){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/projects/${projectId}/documents/${documentId}`, 
		data: qs.stringify({name: name || ''})
	})
}

export async function unlinkDocToProject(projectId, documentId){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/projects/${projectId}/documents/${documentId}`
	})
	.then(result => result.data)
}

export async function linkDocToCompany(companyId, documentId, name){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/companies/${companyId}/documents/${documentId}`, 
		data: qs.stringify({name: name || ''})
	})
}

export async function unlinkDocToCompany(companyId, documentId){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/companies/${companyId}/documents/${documentId}`
	})
	.then(result => result.data)
}

export async function linkDocToDoc(id, linkedId){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/${id}/link/${linkedId}`,
	})
}

export async function unlinkDocFromDoc(id, linkedId){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/documents/${id}/link/${linkedId}`,
	})
}

export async function getSettings(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/settings`
	})
	.then(result => result.data.data)
}

export async  function cloneDocument({_id}) {
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/${_id}/clone`
	})
}

export async  function cloneTemplate({_id}) {
	return await axios({
		method: 'post',
		url: `${url}/api/v1/templates/${_id}/clone`
	})
}

export async function updateSettings(data){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/settings`,
		headers: {
            'Content-Type': 'application/json',
        },
		data: JSON.stringify(data)
	})
	.then(result => result.data.data)
}

export async function getSettingsDetails(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/settings/details`
	})
	.then(result => result.data.data)
}
 
export async function createSettingsDetailsField(type, name, group, fieldType = 'string'){
	return await axios({
		method: 'post',
		url: `${url}/api/v1/settings/details`, 
		data: qs.stringify({
			sub_group : type,
			name: name,
			field_type : fieldType === 'Free Text' ? 'string' : 'date',
			group: group
		})
	})
}

export async function updateSettingsDetailsField(id, data){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/settings/details/${id}`, 
		data: qs.stringify({
			sub_group : data.sub_group,
			name: data.name,
			field_type : data.field_type,
			group: data.group
		})
	})
}

export async function deleteSettingsDetailsField(id){
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/settings/details/${id}`
	})
}

export async function getCompanyDetails(id){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/companies/${id}/details`
	})
	.then(result => result.data)
}

export async function updateCompanyDetails(id, data){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/companies/${id}/details`, 
		headers: {
            'Content-Type': 'application/json',
        },
		data: JSON.stringify({details : data.map(item => ({field: item.field._id, value: item.value}))})
	})
}

export async function userSearch(string){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/users/${string}`
	})
	.then(res => res.data.users)
}


export async function getRecentProjects(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/projects?recentEdit=8`
	})
	.then(res => res.data)
}
export async function getRecentDocuments(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents?recentEdit=10`
	})
	.then(res => res.data)
}

export async function getExpiringDocuments(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents?expired=9`
	})
	.then(res => res.data)
}

export async function getRenewingDocuments(){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents?renewal=10`
	})
	.then(res => res.data)
}


export async function documentSearch(search){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/documents/search/${search}`
	})
	.then(res => res.data)
}

export async function getNotifications(limit, viewed = false){
	return await axios({
		method: 'get',
		url: `${url}/api/v1/notifications?limit=${limit}${viewed === true ? '&viewed=false' : ''}`
	})
	.then(res => res.data)
}

export async function viewNotifications(){
	return await axios({
		method: 'put',
		url: `${url}/api/v1/notifications/user/viewed_all`
	})
	.then(res => res.data)
}

export async function shareDocument(id, filename, password = null) {
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/${id}/share?password=${password ? true : false}`,
		data: qs.stringify({filename: filename, password: password})
	})
		.then(res => res.data)
}

export async function getShareDocument(link, password = null) {
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/share/${link}`,
		data: qs.stringify(password ? {password: password} : {})
	})
		.then(res => res.data)
}

export async function downloadTimelineDocument(documentId, referer, groupid, documentName, lang = "ar", download = false) {
	let requestUrl = `${url}/api/v1/documents/${documentId}/timeline/pdf`
	requestUrl = download ? requestUrl + "?download=true" : requestUrl;
	return await axios({
		url: `${requestUrl}`,
		method: 'post',
		responseType: 'blob',
		data: {
			"url": `/document/timeline/${documentId}?referer=${referer}&groupid=${groupid}&docname=${documentName}`,
			"lang": lang
		}
	},).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		if (download) {
			const link = document.createElement('a');
			let filename = ""
			const disposition = response.headers['content-disposition']
			if (disposition && disposition.indexOf('attachment') !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, '');
				}
			}
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			printJS(url, "pdf");
		}
	});
}

export async function getDocumentNotificationStatus(documentId, isTemplate) {
	const queryUrl = isTemplate ? `${url}/api/v1/templates/${documentId}/send-notification-to-user` :`${url}/api/v1/documents/${documentId}/send-notification-to-user`;
	return await axios({
		method: 'get',
		url: queryUrl
	})
		.then(res => res.data)
}

export async function documentNotificationChange(documentId, sendNotificationToUser, isTemplate) {
	const queryUrl = isTemplate ? `${url}/api/v1/templates/${documentId}/send-notification-to-user` :`${url}/api/v1/documents/${documentId}/send-notification-to-user`;
	return await axios({
		method: 'put',
		url: queryUrl,
		data: {sendNotificationToUser},
	})
		.then(result => {
			return result.data
		})
		.catch((err) => err);
}

export async function updateTimeoutUser(id, user, isTemplate){
	const queryUrl = isTemplate ? `${url}/api/v1/templates/update-editor-timer` :`${url}/api/v1/documents/update-editor-timer`;
	const data = {user_id : user};
	if(isTemplate) {
		data.template_id = id;
	} else {
		data.document_id = id;
	}
	return await axios({
		method: 'put',
		url: queryUrl,
		data: qs.stringify(data)
	})
	.then(res => res.data)
}

export async function sendEmailNotification(name, value) {
	let formdata = new FormData();
	formdata.append(name, value);
	return await axios({
		method: 'put',
		url: `${url}/api/v1/account`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: formdata
	}).then(result => result)
}

export async function resetToken(email) {
	return await axios({
		method: 'post',
		url: `${url}/api/v1/account/send-reset-password-token`,
		data: qs.stringify({
			login: email
		})
	})
	.then(res => res.data)
}

export async function resetPassword(password, token) {
	return await axios({
		method: 'post',
		url: `${url}/api/v1/account/reset-password`,
		data: qs.stringify({
			token: token,
			password: password
		})
	})
	.then(res => res.data)
}

export async function getResetToken(token) {
	return await axios({
		method: 'get',
		url: `${url}/account/reset-password/${token}`
	})
	.then(res => res.data)
}

export async function restoreDoc(id) {
	return await axios({
		method: 'put',
		url: `${url}/api/v1/documents/${id}/restore`
	})
	.then(res => res.data)
}

export async function updateUser(id, data) {
	return await axios({
		method: 'put',
		url: `${url}/api/v1/users/${id}`,
		data: qs.stringify(data)
	})
	.then(res => res.data)
}
export async function deleteUserAvatar() {
	return await axios({
		method: 'delete',
		url: `${url}/api/v1/account/avatar`
	})
	.then(res => res.data)
}

export async function test () {
	return await axios({
		method: 'get',
		url: `${url}/api/v1/companies/5ffaceff9d3df87dd58cfef4`
	})
}

export async function uploadPicture(document_id, picture_info){
	let formData = new FormData()
	function urltoFile(url, filename, mimeType){
		return (fetch(url)
				.then(function(res){return res.arrayBuffer();})
				.then(function(buf){return new File([buf], filename,{type:mimeType});})
		);
	}
	let file = await urltoFile(picture_info.picture, picture_info.name, picture_info.type)
		.then(function(file){ return file });

	formData.append('picture', file)
	return await axios({
		method: 'post',
		url: `${url}/api/v1/documents/${document_id}/upload-image`,
		data: formData,
		headers: {'Content-Type': 'multipart/form-data'}
	})
}