import React, { useContext, useState, useEffect } from 'react'

import Slider from "react-slick";

import Button from '../../Button/Button'

import {AppContext} from '../../../context/AppContext'

import {ReactComponent as Close} from '../../../assets/img/close.svg'


import {
	getDocumentTemplates,
	getTemplates,
} from '../../../utilites/api'
import {addDocuments} from '../../../utilites/table'

import './CreateNewFile.scss'
import './CreateNewFileMedia.scss'



function CreateNewFile({createFile, setCreateFile, setItem, item, tab = '', setPushText, setPush, setPushError, folder}){


	let [createFileTemplates, setCreateFileTemplates] = useState([])
	let [toCreate, setToCreate] = useState(null)
	let [selectedGroup, setSelectedGroup] = useState(null);
	let [selectNewFile, setSelectNewFile] = useState(false);

	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang

	const settings = {
		infinite: false,
		speed: 500,
		dots: false,
		draggable: true,
		slidesToShow: 4,
		slidesToScroll: 2,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 400, 
				settings: {
					slidesToShow: 2
				}
			}
		]
	}


	let isMobile = useContext(AppContext).isMobile


	const selectCreateFile = (groupId, id) => {
		setCreateFileTemplates(createFileTemplates.map((group, index) => {
			if(groupId === group._id){
				return {
					...group,
					templates: group.templates.map((item, i) => {
						if(id === item._id){
							console.log(item);
							setToCreate(item)
							return {
								...item,
								isSelect: true
							}
						} else {
							return {
								...item,
								isSelect: false
							}
						}
					})
				}
			}
			return  {
				...group,
				templates: group.templates.map((item, i) => {
					return {
						...item,
						isSelect: false
					}
				})
			}
		}))
	}

	const convertTemplates = (data) => {
		return data.map(group => {
			return {
				...group,
				templates: group?.templates?.map(template => {
					return {
						...template,
						isSelect: false
					}
				})
			}
		})
	}

	useEffect(() => {
		async function getTemplatesData(){
			getDocumentTemplates()
			.then(res => {
				setCreateFileTemplates(convertTemplates(res.data))
			})
		}
		getTemplatesData()
	}, [])


	return (
		<div className={"new-file-popup-wrapper " + (activeLang === 'ar' ? 'new-file-popup-wrapper-arabic ' : '') + (createFile ? "new-file-popup-wrapper-active" : '')} onClick={(event) => {
			if(event.target.classList.contains('new-file-popup-wrapper-active')){
				setCreateFile(false)
			}
		}}>
			<div className="new-file-popup">
				<div className="new-file-popup-header">
					<div className="header-title">{lang.createNewFile}</div>
					<Close onClick={() => setCreateFile(false)}/>
				</div>
				<div className="new-file-popup-body">
					<div className="body-wrapper">
						<div className="templates-list">
							<div
								className={"template-item template-new-document" + (selectNewFile ? " template-item-active" : '')}
								onClick={() => {
									selectCreateFile(null, null)
									setSelectNewFile(true);
									setToCreate(true);
								}}>
								<div className="template-img"></div>
								<div className="template-name">{lang.newDocument}</div>
							</div>
						</div>
						{createFileTemplates.map((group, i) => {
							if(group?.templates?.length === 0){
								return false
							}
							const isNotEmpty = group?.templates?.findIndex((template) => !template.deleted_at && template.status.toLowerCase() === 'published');
							if (isNotEmpty >= 0 && !group.deleted_at) {
								return (
									<React.Fragment key={i}>
										<div className="body-header">{group.name}</div>
										<div className="templates-list">
											<Slider {...settings}>
												{group.templates.filter(template => !template.deleted_at && template.status.toLowerCase() === 'published').map((item, index) => {
													return (
														<div
															className={"template-item " + (item.isSelect ? "template-item-active" : '')}
															key={index} onClick={() => {
															setSelectNewFile(false);
															setSelectedGroup(true);
															selectCreateFile(group._id, item._id)
														}}>
															<div className="template-img">
																{item.picture ? (
																	<img src={item.picture} alt=""/>
																) : ''}
															</div>
															<div className="template-name">{item.name}</div>
														</div>
													)
												})}
											</Slider>
										</div>
									</React.Fragment>
								)
							}
						})}
					</div>
				</div>
				<div className="new-file-btns">
					<Button color={'white'} padding={12} margin={10} onClick={() => setCreateFile(false)}>{lang.cancel}</Button>
					<Button color={'blue'} padding={12} disabled={!toCreate} onClick={async () => {
						setCreateFile(false);
						if(toCreate){
							addDocuments(setItem, item, [toCreate], false, true, selectNewFile, tab, folder)
							.then(res => {
								if(res.error){
									setPushText(res.text)
									setPush(true)
									setPushError(true)
									setTimeout(() => {
										setPush(false)
										setPushError(false)
									}, 1200)
								}
								let activeInput = window.document.getElementsByClassName('document-item-name-rename')[0]
								if(activeInput){
									activeInput.focus();
									activeInput.select();
								}
							})
						}
						setSelectNewFile(false);
					}}>{lang.create}</Button>
				</div>
			</div>
		</div>
	)
}

export default CreateNewFile