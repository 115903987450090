import React, { useContext, useState } from 'react'

import { AppContext } from '../../../context/AppContext'

import edit from '../../../assets/img/edit.svg'
import sendComment from '../../../assets/img/send-comment.svg'

import {ReactComponent as Flagged} from '../../../assets/img/flagged.svg'
import {ReactComponent as Resolved} from '../../../assets/img/resolved.svg'
import {ReactComponent as Setting} from '../../../assets/img/setting.svg'
import {ReactComponent as Close} from '../../../assets/img/close.svg'
import {ReactComponent as Help} from '../../../assets/img/help.svg'
import {ReactComponent as Search} from '../../../assets/img/search.svg'

import DocManageItem from '../DocManage/DocManageItem/DocManageItem'

import './QueriesMobile.scss'

function QueriesMobile({isOpen, setIsOpen, activeItem, activeQuery, openMenuQuery, makeResolved, deleteQuery, document, search, setSearch, setDocument, editor, editorRef, setActiveManage, searchString, reloadLine, createAnnotation, setIsOpenFullQueries}){


	const user = useContext(AppContext).user
	const activeLang = useContext(AppContext).activeLang
	const lang = useContext(AppContext).lang


	return (
		<>
			<div className={"queries-mobile-header " + (isOpen ? 'queries-mobile-header-active ' : '') + (activeLang === 'ar' ? 'queries-mobile-header-arabic' : '')}>
				<div className="name-popup-wrapper">
					<Help/>
					<span>{lang.totalQueries}: {document.queries.length}</span>
				</div>
				<Close onClick={() => setIsOpen(false)}/>
			</div>
			<div className={"queries-mobile-body " + (search.length > 0 ? 'queries-mobile-body-full ' : '') + (isOpen ? 'queries-mobile-body-active ' : '') + (activeLang === 'ar' ? 'queries-mobile-body-arabic' : '')}>
				<div className="queries-search">
					<Search/>
					<input type="text" placeholder={lang.searchQueries} dir={activeLang === 'ar' ? 'rtl' : 'ltr'} style={activeLang === 'ar' ? {direction: 'RTL'} : {}} value={search} onChange={event => setSearch(event.target.value)}/>
					<button onClick={() => setSearch('')}>{lang.clearSearch}</button>
				</div>
				<div className="queries-list">
					{document.queries.length ? document.queries.map((item, index) => {
						return (
							<DocManageItem
								data={item}
								index={index}
								type={'queries'}
								setDocument={setDocument}
								document={document}
								editor={editor}
								activeItem={activeItem}
								setActiveManage={setActiveManage}
								editorRef={editorRef}
								searchString={searchString}
								key={index}
								reloadLine={reloadLine}
								createAnnotation={createAnnotation}
								setIsOpenFullQueries={setIsOpenFullQueries}
								setSearch={setSearch}
							/>
						)
					}) : (
						<div className="manage-empty">
							{lang.noQueries}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default QueriesMobile