// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .notifications-all-wrapper .notifications-all {
    width: 100%; }
    .notifications-all-wrapper .notifications-all .notifications-all-header {
      height: 70px;
      padding: 0px 15px;
      font-size: 0.75rem;
      line-height: 0.9rem;
      border-top: 1px solid #EEF3F5; }
    .notifications-all-wrapper .notifications-all .notifications-all-list {
      display: flex;
      flex-direction: column;
      padding: 0px 15px; }
      .notifications-all-wrapper .notifications-all .notifications-all-list .notifications-list-item .notification-new {
        right: -8px; }
  .notifications-all-wrapper-arabic .notifications-all .notifications-all-header {
    flex-direction: row-reverse; }
  .notifications-all-wrapper-arabic .notifications-all .notifications-all-list .notifications-list-item .notification-new {
    top: 8px;
    right: unset;
    left: 0px; } }
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Notifications/NotificationsMedia.scss"],"names":[],"mappings":"AAAA;EACC;IAEE,WAAW,EAAA;IAFb;MAIG,YAAY;MACZ,iBAAiB;MACjB,kBAAkB;MAClB,mBAAmB;MACnB,6BAA6B,EAAA;IARhC;MAWG,aAAa;MACb,sBAAsB;MACtB,iBAAiB,EAAA;MAbpB;QAgBK,WAAW,EAAA;EAKd;IAGE,2BAA2B,EAAA;EAH7B;IAQI,QAAQ;IACR,YAAY;IACZ,SAAS,EAAA,EACT","sourcesContent":["@media screen and (max-width: 600px){\n\t.notifications-all-wrapper{\n\t\t.notifications-all{\n\t\t\twidth: 100%;\n\t\t\t.notifications-all-header{\n\t\t\t\theight: 70px;\n\t\t\t\tpadding: 0px 15px;\n\t\t\t\tfont-size: 0.75rem;\n\t\t\t\tline-height: 0.9rem;\n\t\t\t\tborder-top: 1px solid #EEF3F5;\n\t\t\t}\n\t\t\t.notifications-all-list{\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tpadding: 0px 15px;\n\t\t\t\t.notifications-list-item{\n\t\t\t\t\t.notification-new{\n\t\t\t\t\t\tright: -8px;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\t&-arabic{\n\t\t\t.notifications-all{\n\t\t\t\t.notifications-all-header{\n\t\t\t\t\tflex-direction: row-reverse;\n\t\t\t\t}\n\t\t\t\t.notifications-all-list{\n\t\t\t\t\t.notifications-list-item{\n\t\t\t\t\t\t.notification-new{\n\t\t\t\t\t\t\ttop: 8px;\n\t\t\t\t\t\t\tright: unset;\n\t\t\t\t\t\t\tleft: 0px;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
