import React, { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import {AppContext} from '../../context/AppContext'




import Button from '../Button/Button'
import CalendarComponent from '../CalendarComponent/CalendarComponent'

import {
	getDocumentDetails,
	getSettings,
	updateDocumentDetails,
	getDocumentId
} from '../../utilites/api'

import {
	dateConvert
} from '../../utilites/appfunctions'

import {
	convertDetails
} from '../../utilites/essence'

import {
	getConverter
} from '../../utilites/getconverter'

import useSelect from '../../hooks/useSelect'

import {ReactComponent as Calendar} from '../../assets/img/calendar.svg'
import {ReactComponent as Arrow} from '../../assets/img/arrow.svg'

import './Details.scss'
import './DetailsMedia.scss'

function Details(){
	const {id} = useParams()

	let [isEdit, setIsEdit] = useState({
		general: false,
		operational: false,
		content: false
	})

	let lang = useContext(AppContext).lang
	let activeLang = useContext(AppContext).activeLang


	let [inputsDetails, setInputsDetails] = useState([])
	let [inputsDetailsDefault, setInputsDetailsDefault] = useState([])
	const [rights, setRights] = useState(true)

	const renewal = useSelect('non-automatic', ['automatic', 'non-automatic'])
	const contract = useSelect('', [])

	const updateInputs = (id, input, flag) => {
		setInputsDetails(details => {
			return details.map((item) => {
				if(id === item.id){
					return {
						...item,
						value: flag === 'days' ? (input.value < 0 ? 0 : 86400000 * (+input.value)) : input.value
					}
				}
				return item
			})
		})
	}

	const openCalendar = id => {
		setInputsDetails(details => {
			return details.map((item) => {
				if(id === item.id){
					return {
						...item,
						isEdit: !item.isEdit
					}
				}
				return {
					...item,
					isEdit: false
				}
			})
		})
	}

	const changeCalendarDate = newValue => {
		setInputsDetails(details => {
			return details.map(item => {
				if(item.isEdit){
					return {
						...item,
						value: newValue ? dateConvert(newValue, 'ts') : item.value,
						isEdit: false
					}
				}
				return item
			})
		})
	}

	let [documentDetails, setDocumentDetails] = useState(null)


	useEffect(() => {
		console.log(documentDetails)
		if(documentDetails){
			setInputsDetailsDefault(documentDetails)
			setInputsDetails(documentDetails)
			renewal.changeValue(documentDetails.filter(item => item.field.name === 'Renewal Type')[0].value)
			contract.changeValue(documentDetails.filter(item => item.field.name === 'Contract Type')[0].value)
		}
	}, [documentDetails])

	useEffect(() => {
		async function getDetailsData(){
			Promise.all([getDocumentDetails(id), getSettings(), getDocumentId(id)])
			.then(res => {
				setDocumentDetails(convertDetails(res[0].details))
				contract.setValues(res[1].document_type_for_upload.filter(item => {
					if(item.active){
						return true
					} else {
						return false
					}
				}).map(item => item.name))
				setRights(res[2].data.rights)
			})
		}	
		getDetailsData()
	}, [])

	return (
		<>
			{documentDetails ? (
				<div className={"details " + (activeLang === 'ar' ? 'details-arabic' : '')}>
					<div className="details-header">
						<Link to={getConverter(window.location.search).back ? `/${getConverter(window.location.search).back}` : '/document/' + id + window.location.search} className="back-btn">
							<svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.45446 9.64586L24.0908 9.64586" stroke="#2E384D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M11.3408 18.0908L1.29537 9.59082L11.3408 1.09082" stroke="#2E384D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
						</Link>
						<span>{lang.details}</span>
					</div>
					<div className="details-body">
						<div className="edit-wrapper">
							<div className="edit-wrapper-header">
								<span>{lang.generalInfo}</span>
								{rights === 'owner' || rights === 'editor' ? (
									<Button color={'white'} padding={10} disabled={isEdit.general ? true : false} onClick={() => setIsEdit({...isEdit, general: true, operational: false, content: false})}>Edit</Button>
								) : ''}
							</div>
							<div className="edit-wrapper-body">
								{inputsDetails.filter(item => item.field.sub_group === 'general').map((item, index) => {
									return (
										<div className="edit-list-item" key={index}>
											<div className="item-name">
												{item.field.name}
											</div>
											{item.field.field_type === 'string' ? (
												<div className={"item-value-wrapper "  + (isEdit.general && item.editable ? 'item-value-edit' : '')}>
													{item.field.name === 'Linked Documents' ? (
														<div className="item-value-wrapper-doc">
															{item.value.map((item, index) => {
																return (
																		<Link index={index} to={`/document/${item._id}?referer=${'dashboard'}&groupid=${inputsDetails.filter(item => item.field.name === 'Project')[0].length > 0 ? inputsDetails.filter(item => item.field.name === 'Project')[0].field._id : inputsDetails.filter(item => item.field.name === 'Company')[0].field._id}&docname=${item.files[item.files.length - 1].filename}`}>{item.name} ,</Link>
																)
															})}
														</div>
													) : <input type="text" className="item-value-input" name={item.id} value={item.value || ''} onChange={(event) => updateInputs(item.id, event.target)} readOnly={isEdit.general && !item.editable ? "readonly" : false}/>}
												</div>
											) : ''}
											{item.field.field_type === 'date' ?  (
												<div className={"item-value-wrapper "  + (isEdit.general ? 'item-value-edit' : '')}>
													<div className={"item-value-wrapper "} onClick={() => openCalendar(item.id)}>
														<div className="item-value-no-edit ">{dateConvert(item.value, 'd') || ''}</div>
													</div>
													{isEdit.general ? <Calendar onClick={() => openCalendar(item.id)}/> : ''}
													{item.isEdit ? <CalendarComponent  active={'expiry'} style={{display: item.isEdit ? 'flex' : 'none'}} changeCalendar={changeCalendarDate}/> : ''}
												</div>
											) : ''}
											{item.field.field_type === 'dropdown' ?  (
												<>
													{isEdit.general ? (
														<>
															<div className="dropdown">
																<div className="dropdown-active" onClick={() => contract.openClose()}> <span>{contract.value}</span><Arrow/></div>
																<div className={"dropdown-list " + (contract.active ? 'dropdown-list-active' : '')}>
																	{contract.valuesList.map(itemList => {
																		return <div className="dropdown-list-item" key={itemList} onClick={() => {
																			updateInputs(item.id, {value : itemList}, 'dropdown')
																			contract.changeValue(itemList)
																			// updateDocumentType(id, itemList)
																		}}>{itemList}</div>
																	})}
																</div>
															</div>
														</>
													) : (
														<div className={"item-value-wrapper "  + (isEdit.general ? 'item-value-edit' : '')}>
															<input type="text" className="item-value-input" defaultValue={item.value} readOnly={!isEdit.general ? "readonly" : false}/>
														</div>
													)}
												</>
											) : ''}
										</div>
									)
								})}
							</div>
							{isEdit.general ? (
								<div className="body-btns">
									<Button color={'white'} padding={8} margin={10} onClick={() => {
										setIsEdit({...isEdit, general: false, operational: false, content: false})
										setInputsDetails(inputsDetailsDefault)
									}}>{lang.cancel}</Button>
									<Button color={'blue'} padding={8} onClick={() => {
										setIsEdit({...isEdit, general: false, operational: false, content: false})
										setInputsDetailsDefault(inputsDetails)
										updateDocumentDetails(id, inputsDetails)
									}}>{lang.save}</Button>
								</div>
							) : ''}
						</div>
						<div className="edit-wrapper">
							<div className="edit-wrapper-header">
								<span>{lang.operInfo}</span>
								{rights === 'owner' || rights === 'editor' ? (
									<Button color={'white'} padding={10} disabled={isEdit.operational ? true : false} onClick={() => setIsEdit({...isEdit, general: false, operational: true, content: false})}>Edit</Button>
								) : ''}
								</div>
							<div className="edit-wrapper-body">
								{inputsDetails.filter(item => item.field.sub_group === 'operational').map((item, index) => {
									return (
										<div className="edit-list-item" key={index}>
											<div className="item-name">
												{item.field.name}
											</div>
											{item.field.field_type === 'string' ? (
												<div className={"item-value-wrapper "  + (isEdit.operational ? 'item-value-edit' : '')}>
													<input type="text" className="item-value-input" name={item.id} value={item.value || ''} onChange={(event) => updateInputs(item.id, event.target)} readOnly={!isEdit.operational ? "readonly" : false}/>
												</div>
											) : ''}
											{item.field.field_type === 'date' ?  (
												<div className={"item-value-wrapper "  + (isEdit.operational ? 'item-value-edit' : '')}>
													<div className={"item-value-wrapper "} onClick={() => openCalendar(item.id)}>
														<div className="item-value-no-edit ">{dateConvert(item.value, 'd') || ''}</div>
													</div>
													{isEdit.operational ? <Calendar onClick={() => openCalendar(item.id)}/> : ''}
													{item.isEdit ? <CalendarComponent  active={'expiry'} style={{display: item.isEdit ? 'flex' : 'none'}} changeCalendar={changeCalendarDate}/> : ''}
												</div>
											) : ''}
											{item.field.field_type === 'days' ?  (
												<div className={"item-value-wrapper "  + (isEdit.operational ? 'item-value-edit' : '')}>
													<input type="number"
														   className="item-value-input"
														   name={item.id}
														   min={0}
														   value={item.value / 86400000 || ''}
														   onChange={(event) => updateInputs(item.id, event.target, 'days')}
														   onKeyDown={event => {
														   		if(event.key === '-'){
														   			event.stopPropagation()
																	event.preventDefault();
																}
														   		else if (event.key === '+') {
														   			event.preventDefault()
																	event.stopPropagation()
																}
																else if (event.key === '.') {
																	event.stopPropagation()
																	event.preventDefault()
																}
														   }}
														   readOnly={!isEdit.operational ? "readonly" : false}/>
												</div>
											) : ''}
											{item.field.field_type === 'dropdown' ?  (
												<>
													{isEdit.operational ? (
														<>
															<div className="dropdown">
																<div className="dropdown-active" onClick={() => renewal.openClose()}> <span>{renewal.value}</span><Arrow/></div>
																<div className={"dropdown-list " + (renewal.active ? 'dropdown-list-active' : '')}>
																	{renewal.valuesList.map(itemList => {
																		return <div className="dropdown-list-item" key={itemList} onClick={() => {
																			updateInputs(item.id, {value : itemList}, 'dropdown')
																			renewal.changeValue(itemList)
																		}}>{itemList}</div>
																	})}
																</div>
															</div>
														</>
													) : (
														<div className={"item-value-wrapper "  + (isEdit.operational ? 'item-value-edit' : '')}>
															<input type="text" className="item-value-input" defaultValue={item.value} readOnly={!isEdit.operational ? "readonly" : false}/>
														</div>
													)}
												</>
											) : ''}
										</div>
									)
								})}
							</div>
							{isEdit.operational ? (
								<div className="body-btns">
									<Button color={'white'} padding={8} margin={10} onClick={() => {
										setIsEdit({...isEdit, general: false, operational: false, content: false})
										setInputsDetails(inputsDetailsDefault)
									}}>{lang.cancel}</Button>
									<Button color={'blue'} padding={8} onClick={() => {
										setIsEdit({...isEdit, general: false, operational: false, content: false})
										setInputsDetailsDefault(inputsDetails)
										updateDocumentDetails(id, inputsDetails)
									}}>{lang.save}</Button>
								</div>
							) : ''}
						</div>
						<div className="edit-wrapper">
							<div className="edit-wrapper-header">
								<span>{lang.contentInfo}</span>
								{rights === 'owner' || rights === 'editor' ? (
									<Button color={'white'} padding={10}  disabled={isEdit.content ? true : false} onClick={() => setIsEdit({...isEdit, general: false, operational: false, content: true})}>Edit</Button>
								) : ''}
							</div>
							<div className="edit-wrapper-body">
								{inputsDetails.filter(item => item.field.sub_group === 'content').map((item, index) => {
									return (
										<div className="edit-list-item" key={index}>
											<div className="item-name">
												{item.field.name}
											</div>
											{item.field.field_type === 'string' ? (
												<div className={"item-value-wrapper "  + (isEdit.content ? 'item-value-edit' : '')}>
													<input type="text" className="item-value-input" name={item.id} value={item.value || ''} onChange={(event) => updateInputs(item.id, event.target)} readOnly={!isEdit.content ? "readonly" : false}/>
												</div>
											) : (
												<div className={"item-value-wrapper "  + (isEdit.content ? 'item-value-edit' : '')}>
													<div className={"item-value-wrapper "} onClick={() => openCalendar(item.id)}>
														<div className="item-value-no-edit ">{dateConvert(item.value, 'd') || ''}</div>
													</div>
													{isEdit.content ? <Calendar onClick={() => openCalendar(item.id)}/> : ''}
													{item.isEdit ? <CalendarComponent  active={'expiry'} style={{display: item.isEdit ? 'flex' : 'none'}} changeCalendar={changeCalendarDate}/> : ''}
												</div>
											)}
										</div>
									)
								})}
							</div>
							{isEdit.content ? (
								<div className="body-btns">
									<Button color={'white'} padding={8} margin={10} onClick={() => {
										setIsEdit({...isEdit, general: false, operational: false, content: false})
										setInputsDetails(inputsDetailsDefault)
									}}>{lang.cancel}</Button>
									<Button color={'blue'} padding={8} onClick={() => {
										setIsEdit({...isEdit, general: false, operational: false, content: false})
										setInputsDetailsDefault(inputsDetails)
										updateDocumentDetails(id, inputsDetails)
									}}>{lang.save}</Button>
								</div>
							) : ''}
						</div>
					</div>
				</div>
			) : ''}
		</>
	)
}


export default Details