import React, {useState} from 'react'
import Calendar from 'react-calendar'

import {ReactComponent as ArrowPrev} from '../../assets/img/arrow-prev.svg'
import {ReactComponent as ArrowNext} from '../../assets/img/arrow-next.svg'


import './Calendar.scss'
import './CalendarMedia.scss'

function CalendarComponent({changeCalendar, active, setItem, item, itemPar, id = undefined, type, revert = false, isOpen = true}){

	const [valueDate, setValueDate] = useState(new Date())

	return (
		<>
		{isOpen ? (<div className={"document-overflow active"} onClick={(event) => {
			changeCalendar(setItem, id, null, type)
		}}/>) : ''}
		<Calendar className={"calendar " + (revert ? 'calendar-revert ' : '') + (!isOpen ? 'calendar-hide' : '')} nextLabel={<ArrowNext/>} prevLabel={<ArrowPrev/>} locale={'en'} formatShortWeekday={(_, date) => {
			return date.toString().split(' ')[0][0] +  date.toString().split(' ')[0][1] 
		}} 
		value={valueDate}
		view={'month'}
		onClickDay={(date) => {
			if(id !== undefined){
				changeCalendar(setItem, id, date.toString(), type)
			}  else {
				changeCalendar(date.toString())
			}
			setValueDate(date)
		}}
		/>
		</>
	)
}

export default CalendarComponent