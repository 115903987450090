import React, { useContext, useState } from 'react'
import {Link} from 'react-router-dom'

import { AppContext } from '../../../context/AppContext'



import {
	getDocument,
	compareDocumentFiles
} from '../../../utilites/api'

import {ReactComponent as Close} from '../../../assets/img/close.svg'
import {ReactComponent as Copy} from '../../../assets/img/copy.svg'
import {ReactComponent as History} from '../../../assets/img/history.svg'
import {ReactComponent as Backup} from '../../../assets/img/backup.svg'
import {ReactComponent as Arrow} from '../../../assets/img/arrow.svg'


import './HistoryMobile.scss'

function HistoryMobile({
						   isOpen,
						   setIsOpen,
						   setIsOpenRevertPopup,
						   setRevertVersion,
						   document,
						   setIsCurrentVersion,
						   isCurrentVersion,
						   searchString,
						   activeHistory,
						   editorRef,
						   compareFiles,
						   setMode
					   }) {

	const lang = useContext(AppContext).lang
	const activeLang = useContext(AppContext).activeLang

	let [isFullHistory, setIsFullHistory] = useState(true)

	return (
		<>
			<div className={"history-mobile-header " + (isOpen ? 'history-mobile-header-active ' : '') + (activeLang === 'ar' ? 'history-mobile-header-arabic' : '')}>
				<div className="name-popup-wrapper">
					<Copy/>
					<span>History</span>
				</div>
				<Close onClick={() => {
					setIsOpen(false)
					if (isCurrentVersion) {
						setMode("write")
					}
				}
				}/>
			</div>
			<div className={"history-mobile-body " + (isFullHistory ? 'history-mobile-body-full ' : '') + (activeLang === 'ar' ? 'history-mobile-body-arabic ' : '') + (isOpen ? 'history-mobile-body-active' : '')}>
				<div className="history-full-btn" onClick={() => setIsFullHistory(prev => !prev)}>
					<Arrow style={!isFullHistory ? {transform : 'rotateZ(180deg)'} : {}}/>
				</div>

				{document && document.files.sort((a, b) => b.created_at - a.created_at).map((version, index) => {
					return (
						<div className={"version-item " + (version.active ? 'version-item-active' : '')} key={index} onClick={() => {
							if(index === 0) {
								setIsCurrentVersion(true);
							} else {
								setIsCurrentVersion(false);
							}
							if(version.active){
								getDocument(document.files.sort((a, b) => b.created_at - a.created_at)[0].filename)
									.then(res => {
										editorRef.current.editor.execCommand('mceSetContent', true, res.content)
										activeHistory(document.files.sort((a, b) => b.created_at - a.created_at)[0]._id)
									})
							} else {

								getDocument(version.filename)
									.then(res => {
										editorRef.current.editor.execCommand('mceSetContent', true, res.content)
										activeHistory(version._id)
									})
							}
						}}>
							<span>V{document.files.length - index}.{version.date} {index === 0 ? '(Current)' : ''}</span>
							{index !== 0 ? (
								<div className="version-nav">
									{/*<Link onClick={event => {*/}
									{/*	event.stopPropagation()*/}
									{/*	navigator.clipboard.writeText(window.location.host + `/document/${document.id}?referer=${searchString.referer}&docname=${version.filename}`)*/}
									{/*}}/>*/}
									<History onClick={event => {
										event.stopPropagation()
										compareDocumentFiles(document.id, document.files.sort((a, b) => b.created_at - a.created_at)[0].filename, version.filename)
											.then(res => {
												compareFiles(res.data.data)

											})
									}}/>
									<Backup onClick={event => {
										event.stopPropagation()
										setIsOpenRevertPopup(true)
										setRevertVersion({number : document.files.length - index, filename: version.filename})
									}}/>
								</div>
							) : ''}
						</div>
					)
				})}
			</div>
		</>
	)
}

export default HistoryMobile