import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {AppContext } from '../../../context/AppContext'

import {
	updateTemplateStatus,
	updateDocumentStatus,
	getDocument
} from '../../../utilites/api'

import {ReactComponent as EditDoc} from '../../../assets/img/edit-doc.svg'
import {ReactComponent as Complete} from '../../../assets/img/complete.svg'
import {ReactComponent as Upload} from '../../../assets/img/download.svg'
import {ReactComponent as Share} from '../../../assets/img/share.svg'
import {ReactComponent as DownloadMobile} from '../../../assets/img/downloadmobile.svg'
import {ReactComponent as Linked} from '../../../assets/img/linked.svg'
import {ReactComponent as Favourite} from '../../../assets/img/favorite.svg'
import {ReactComponent as Arrow} from '../../../assets/img/arrow.svg'
import {ReactComponent as Help} from '../../../assets/img/help.svg'
import {ReactComponent as Chat} from '../../../assets/img/chat.svg'
import {ReactComponent as Copy} from '../../../assets/img/copy.svg'
import {ReactComponent as Plus} from '../../../assets/img/plus.svg'

import './DocMobileNav.scss'

function DocMobileNav ({document, isOpenCommentsMobile, isOpenHistoryMobile, isOpenQueriesMobile, mode, openOptions, openSelect, changeStatus, searchString, setMode, filename, setIsOpenDeletePopup, onClickPublish, changeFavorite, documentToUpload, link, setShare, setIsOpenQueriesMobile, setIsOpenCommentsMobile, setIsOpenHistoryMobile}){
	const history = useHistory()
	const {id} = useParams()

	const {activeLang, lang} = useContext(AppContext)

	let statusList = [
        'review',
        'in tender',
        'approved',
        'signed',
        'draft',
    ]

	return (
		<>
			<div className={"edit-btn " + (mode === 'write' || isOpenCommentsMobile || isOpenQueriesMobile || isOpenHistoryMobile ? 'edit-btn-disable' : '')} onClick={() => {
				// setIsActiveEditor(true)
				// activeEditor()
				setMode('write')
			}}>
				<EditDoc/>
			</div>
			<div className={"edit-header " + (mode === 'read' ? 'edit-header-disable' : '')}>
				<Complete onClick={() => {
					setMode('read')
					// setIsActiveEditor(false)
					// disableEditor()
				}}/>
			</div>
			<div className={"editor-navigations " + (document.isOpenOptions ? 'editor-navigations-active' : '')} onClick={event => {
				// setIsOpenQueriesMobile(false)
				if(activeLang === 'ar'){
					if(event.clientX > window.document.querySelector('.editor-navigations').getBoundingClientRect().left + window.document.querySelector('.editor-navigations').offsetWidth){
						openOptions()
					}
				} else {
					if(event.clientX < window.document.querySelector('.editor-navigations').getBoundingClientRect().left){
						openOptions()
					}
				}
			}}>
				<div className="editor-navigations-header">
					<div className="document-status-select">
						<div className={"status-select-active " + document.status} onClick={event => {
							openSelect()
						}}>
							<span></span>
							<div className="status-name">{document.status[0].toUpperCase() + document.status.slice(1)}</div>
							<Arrow/>
						</div>
						<div className={"status-select-list " + (document.isOpenStatusSelect ? 'status-select-list-open' : '')}>
							{statusList.map((status, i) => {
								if(status === document.status){
									return false
								}
								return (
									<div className={"select-list-item " + status} key={i} onClick={event => {
										changeStatus(status.toLowerCase())
										if (searchString.referer === 'templates') {
											updateTemplateStatus(document.id, status[0].toUpperCase() + status.slice(1))
										} else {
											if (status.toLowerCase() === 'in tender') {
												return false
											} else {
												updateDocumentStatus(document.id, status)
											}
										}
									}}><span></span>{status[0].toUpperCase() + status.slice(1)}</div>
								)
							})}
						</div>
					</div>
					<svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
						openOptions()
						// setIsActiveEditor(false)
						// disableEditor()
					}}>
						<path fillRule="evenodd" clipRule="evenodd" d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z" fill={document.isOpenOptions ? "#2E5BFF" : "#8798ad"}/>
					</svg>
				</div>
				<div className="editor-navigations-body">
					{searchString.referer !== 'templates' ? (
						<div className="nav-item" onClick={onClickPublish}>
							<Upload className="publish"/> Publish
						</div>
					) : ''}
					{/* <div className="nav-item">
						<Plus className="plus"/> Add collaborators
					</div> */}
					{searchString.referer !== 'templates' ? (
						<div className="nav-item" onClick={() => {
							changeFavorite()
						}}>
							<Favourite/> {document.favorite ? 'Remove from ' : 'Add to '}  favourites
						</div>
					) : ''}
					{searchString.referer !== 'templates' ? (
						<div className="nav-item" onClick={() => {
							getDocument(documentToUpload, true)
						}}>
							<DownloadMobile/> Download
						</div>
					) : ''}
					{!link && searchString.referer !== 'templates' ? (
						<div className="nav-item" onClick={() => {
							openOptions()
							setShare(true)
							setMode('read')
						}}>
							<Share className="share"/> Share
						</div>
					) : ''}
					{/* <div className="nav-item line">
						<Linked/> Link files
					</div> */}
					<div className="nav-item" onClick={() => {
						setIsOpenQueriesMobile(true)
						openOptions()
						setMode('read')
					}}>
						<Help/> Queries
					</div>
					<div className="nav-item" onClick={() => {
						setIsOpenCommentsMobile(true)
						openOptions()
						setMode('read')
					}}>
						<Chat/> Comments
					</div>
					{searchString.referer !== 'templates' ? (
						<div className="nav-item line" onClick={() => {
							setIsOpenHistoryMobile(true)
							openOptions()
							setMode('read')
						}}>
							<Copy className="history"/> History
						</div>
					) : ''}
					<div className="nav-item" onClick={() => {
						filename.setIsChange(true)
						openOptions()
					}}>
						Rename
					</div>
					<div className="nav-item" onClick={() => {
						openOptions()
						setIsOpenDeletePopup(true)
						setMode('read')
					}}>
						Delete
					</div>
					{searchString.referer !== 'templates' ? (
						<>
							<div className="nav-item" onClick={() => {
								history.push('/document/details/' + id + window.location.search)
							}}>{lang.viewDetails}</div>
							<div className="nav-item" onClick={() => {
								history.push('/document/timeline/' + id + window.location.search)
							}}>{lang.timeline}
							</div>
						</>
					) : ''}
				</div>
			</div>
		</>
	)
}

export default DocMobileNav