import { useState } from 'react'

export default function useFileName (setList, setItem, item, updateFileNameServer, updateFileNameClient){
	
	const [isChange, setIsChange] = useState(false)
	
	return {
		setList: setList,
		setItem: setItem,
		item: item,
		isChange: isChange,
		setIsChange: setIsChange,
		updateFileNameServer: updateFileNameServer,
		updateFileNameClient : updateFileNameClient
	}
	
	// return doc ? {
	// 	setList: setList,
	// 	setItem: setItem,
	// 	isChange: isChange,
	// 	setIsChange: setIsChange,
	// 	// updateFileName: list ? list.updateName : false
	// } : {
	// 	list : list,
	// 	setList: setList,
	// 	isChange: isChange,
	// 	setIsChange: setIsChange,
	// 	updateFileName: updateFileName
	// }
}