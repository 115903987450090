import React, { useContext } from 'react'

import { AppContext } from '../../context/AppContext'

import {ReactComponent as Plus} from '../../assets/img/plus.svg'


import './PlusNav.scss'


function PlusNav({isOpenOptions, setIsOpenOptions}){
	let isMobile = useContext(AppContext).isMobile
	let activeLang = useContext(AppContext).activeLang
	return (
		<>
			{isMobile && !isOpenOptions ? (
				<div className={"nav-mobile-btn " + (activeLang === 'ar' ? 'nav-mobile-btn-arabic' : '')} onClick={() => {
					setIsOpenOptions(true)
				}}>
					<Plus/>
				</div>
			) : ''}
		</>
	)
}

export default PlusNav