import React, {useContext} from 'react'
import {Link, useHistory} from 'react-router-dom'

import { AppContext } from '../../../context/AppContext'

import {logout} from '../../../utilites/auth'

import './ProfileNav.scss'
import './ProfileNavMedia.scss'
import {openOptions} from "../../../utilites/table";

function ProfileNav({profilePopupIsOpen, setProfilePopupIsOpen, setNotificationsIsOpen}){
	const setIsLoginPage = useContext(AppContext).setIsLoginPage
	const user = useContext(AppContext).user
	let lang = useContext(AppContext).lang
	let history = useHistory();
	let activeLang = useContext(AppContext).activeLang
	let setActiveLang = useContext(AppContext).setActiveLang
	const isMobile = useContext(AppContext).isMobile

	return (
		<>
			{user ? (
				<>
				<div className={"profile-nav-wrapper " + (activeLang === 'ar' ? 'profile-nav-wrapper-arabic' : '')}>
					{user.picture ? (
						<img src={user.picture} alt="avatar" className="profile-avatar" onClick={() => {
							setProfilePopupIsOpen(prev => !prev)
							setNotificationsIsOpen(false)
						}}/>
					) : (
						<div className="none-avatar profile-avatar" style={{backgroundColor: user.bgc}} onClick={() => {
							setProfilePopupIsOpen(prev => !prev)
							setNotificationsIsOpen(false)
						}}>
							{((user.first_name && user.first_name.length > 0 ? user.first_name[0] : '') + (user.last_name && user.last_name.length > 0 ? user.last_name[0] : '')).toUpperCase()}
						</div>
					)}
					<div className={"profile-popup " + (profilePopupIsOpen ? 'profile-popup-active' : '')}>
						<svg version="1.1" id="arrow" viewBox="0 0 490.787 490.787">
							<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006
								c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083
								c-0.086,0.089-0.173,0.176-0.262,0.262L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085
								C368.236,489.664,365.511,490.792,362.671,490.787z"/>
							<path d="M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256
								c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
								L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C368.236,489.664,365.511,490.792,362.671,490.787z"/>
						</svg>

						<div className="user">
							{user.picture ? (
								<img src={user.picture} alt="user avatar" className="user-avatar"/>
							) : (
								<div className="none-avatar user-avatar" style={{backgroundColor: user.bgc}}>
									{(user.first_name[0] + (user.last_name && user.last_name.length > 0 ? user.last_name[0] : '')).toUpperCase()}
								</div>
							)}
							<div className="user-info">
								<div className="user-name">{user.first_name} {user.last_name}</div>
								<div className="user-email">{user.login}</div>
								<Link to='/dashboard/profile' className="user-edit" onClick={() => setProfilePopupIsOpen(false)}>{lang.editProfile}</Link>
							</div>
						</div>

						<div className="select-lang" onClick={() => {
							setActiveLang(activeLang === 'ar' ? 'en' : 'ar')
							localStorage.setItem('lang', activeLang === 'ar' ? 'en' : 'ar')
							setProfilePopupIsOpen(false)
							}}>
								{activeLang === 'ar' ? (
									<>
										 &nbsp;:{lang.language}<span>English</span>
									</>
								) : (
									<>
										{lang.language}:&nbsp; <span>Arabic</span>
									</>
								)}
						</div>

						<button className="user-singout" onClick={async () => {
							setProfilePopupIsOpen(false)
							if(await logout() === 200){
								setIsLoginPage(true)
									history.push("/login");
							}
						}}>{lang.signOut}</button>
					</div>
				</div>
				{profilePopupIsOpen && !isMobile ? (<div className={"document-overflow active"} onClick={(event) => {
					if (profilePopupIsOpen) {
						event.stopPropagation()
						setProfilePopupIsOpen(false)
					}
				}}/>) : ''}
				</>
			) : ''}
		</>
	)
}

export default ProfileNav
