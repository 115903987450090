// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.protection {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .protection svg {
    margin-bottom: 20px; }
  .protection .title {
    font-size: 0.75rem;
    line-height: 0.9rem;
    color: #8798AD;
    margin-bottom: 30px; }
  .protection input {
    margin-bottom: 20px;
    width: 320px;
    height: 40px;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    outline: none;
    padding: 0px 16px;
    color: #2E384D;
    font-size: 0.75rem;
    font-family: 'Rubik', sans-serif; }
`, "",{"version":3,"sources":["webpack://./src/components/Protection/Protection.scss"],"names":[],"mappings":"AAAA;EACC,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB,EAAA;EAXvB;IAaE,mBAAmB,EAAA;EAbrB;IAgBE,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,mBAAmB,EAAA;EAnBrB;IAsBE,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,gCAAgC,EAAA","sourcesContent":[".protection{\n\twidth: 100vw;\n\theight: 100vh;\n\tbackground-color: #fff;\n\tposition: absolute;\n\ttop: 0px;\n\tleft: 0px;\n\tz-index: 15;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tflex-direction: column;\n\tsvg{\n\t\tmargin-bottom: 20px;\n\t}\n\t.title{\n\t\tfont-size: 0.75rem;\n\t\tline-height: 0.9rem;\n\t\tcolor: #8798AD;\n\t\tmargin-bottom: 30px;\n\t}\n\tinput{\n\t\tmargin-bottom: 20px;\n\t\twidth: 320px;\n\t\theight: 40px;\n\t\tborder: 1px solid #E0E7FF;\n\t\tborder-radius: 5px;\n\t\toutline: none;\n\t\tpadding: 0px 16px;\n\t\tcolor: #2E384D;\n\t\tfont-size: 0.75rem;\n\t\tfont-family: 'Rubik', sans-serif;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
