import {ReactComponent as Close} from "../../../assets/img/close.svg";
import {ReactComponent as Check} from "../../../assets/img/check.svg";
import Button from "../../Button/Button";
import {updateDocumentStatus} from "../../../utilites/api";
import React, {useContext} from "react";
import {AppContext} from "../../../context/AppContext";
import "./TenderSubmitPopup.scss";
import {saveDocument} from "../../../utilites/document";
import {getConverter} from "../../../utilites/getconverter";

export const TenderConfirmPublishPopup = ({isOpenConfirmPublishPopup, setIsOpenConfirmPublishPopup, setDocument, setDontAsk, dontAsk, document, editorRef, getDocumentData, user}) => {
    const activeLang = useContext(AppContext).activeLang
    const lang = useContext(AppContext).lang
    const searchString = getConverter(window.location.search)
    return (
        <div className={"tender-popup-wrapper " + (isOpenConfirmPublishPopup ? 'tender-popup-wrapper-active' : '')}
             onClick={event => {
                 if (event.target.classList.contains('tender-popup-wrapper-active')) {
                     setIsOpenConfirmPublishPopup(false)
                 }
             }}>
            <div className="tender-popup">
                <div className="tender-header">
                    <div className="header-title">{lang.publish}</div>
                    <Close onClick={() => setIsOpenConfirmPublishPopup(false)}/>
                </div>
                <div className="tender-popup-body">
                    <div className="tender-popup-body-title">
                        {lang.statusInTenderConfirm}
                    </div>
                    <div className="tender-nav">
                        <div className="dont-ask" onClick={() => setDontAsk(prev => !prev)}>
                            <div className={"checkbox " + (dontAsk ? 'checkbox-active' : '')}>
                                <Check/>
                            </div>
                            <div className="dont-ask-title">{lang.dontAskTender}</div>
                        </div>
                        <div className="tender-btns">
                            <Button color={'white'} padding={12} margin={10} onClick={() => {
                                setIsOpenConfirmPublishPopup(false)
                            }}>{lang.cancel}</Button>
                            <Button color={'blue'} padding={12} onClick={() => {
                                setIsOpenConfirmPublishPopup(false)
                                saveDocument(searchString, document.id, editorRef, document, true).then((res)=> {
                                    if(res.status === 200 && res.data?.data?.files.length > 0) {
                                        const files = res.data.data.files;
                                        const queryParams = new URLSearchParams(window.location.search);
                                        queryParams.set("docname", files[files.length-1].filename);
                                        window.history.pushState(null, null, "?"+queryParams.toString());
                                        getDocumentData();
                                        setDocument(document => {
                                            return {
                                                ...document,
                                                files: files,
                                                editedBy: {
                                                    ...document.editedBy,
                                                    name: user.first_name + ' ' + user.last_name
                                                }
                                            }
                                        })
                                    }
                                })
                            }}>{lang.confirm}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}