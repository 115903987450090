import React, {useState, useEffect, useContext} from 'react'
import {useParams, useLocation, useHistory} from 'react-router-dom'
 
import {Selecter} from '../../../utilites/selecter'

import {AppContext} from '../../../context/AppContext'

import Loader from '../../Loader/Loader'

import {ReactComponent as Filters} from '../../../assets/img/filters.svg'
import {ReactComponent as Arrow} from '../../../assets/img/arrow.svg'

import useSelect from '../../../hooks/useSelect'

import {
	documentSearch, 
	getSettings,
	getProjects,
	getCompanies
} from '../../../utilites/api'

import './Search.scss'
import './SearchMedia.scss'

function Search({inputSearch}){

	let [search, setSearch] = useState([])
	let [initialSearch, setInitialSearch] = useState([])

	const {lang, activeLang, isMobile} = useContext(AppContext)

	let [isActiveFilters, setIsActiveFilters] = useState(isMobile ? false : true)

	const [activeFiltersList, setActiveFiltersList] = useState({})

	const projectsSelect = useSelect('')
	const companySelect = useSelect('')
	const documentsSelect = useSelect('')

	const {searchstring} = useParams()
	const location = useLocation()
	const history = useHistory()

	let [isLoadComplete, setIsLoadComplete] = useState(true)

	const essenseConvert = data => {
		return data.map(item => {
			return {
				name: item.name,
				id: item._id,
			}
		})
	}

	const searchConvert = data => {
		console.log(data)
		return data.data.filter(item => item).map(item => {

			return {...item, company: item.details.filter(detail => detail.field.name === 'Company')[0] ? item.details.filter(detail => detail.field.name === 'Company')[0].value : null, type: item.type, project: item.details.filter(detail => detail.field.name === 'Project')[0] ? item.details.filter(detail => detail.field.name === 'Project')[0].value : null,  name: item.name, file: item.file_type || 'other', html_name: Selecter(searchstring, item.name)}
		}).filter(item => {
			if(!item.details.filter(detail => detail.field.name === 'Company')[0]?.value && !item.details.filter(detail => detail.field.name === 'Project')[0]?.value){
				return false
			}
			return true
		}).slice(0, 10)
	}

	useEffect(() => {
		// console.log(activeFiltersList)
		setSearch(() => {
			let tempData = initialSearch.concat()
			if(activeFiltersList.project){
				tempData = tempData.filter(item => {
					if(item.project === activeFiltersList.project){
						return item
					} else {
						return false
					}
				})
			}
			if(activeFiltersList.company){
				tempData = tempData.filter(item => {
					if(item.company === activeFiltersList.company){
						return item
					} else {
						return false
					}
				})
			}
			if(activeFiltersList.type){
				tempData = tempData.filter(item => {
					if(item.type === activeFiltersList.type){
						return item
					} else {
						return false
					}
				})
			}
			// console.log(tempData)
			return tempData
		})
	}, [activeFiltersList])

	useEffect(() => {
		setIsActiveFilters(isMobile ? false : true)
	}, [isMobile])

	useEffect(() => {
		Promise.all([documentSearch(searchstring), getProjects(), getCompanies(), getSettings()])
		.then(res => {
			console.log(res[0])
			setSearch(searchConvert(res[0]))
			projectsSelect.setValues(essenseConvert(res[1].data))
			companySelect.setValues(essenseConvert(res[2].data))
			documentsSelect.setValues(essenseConvert(res[3].document_type_for_upload))
			setIsLoadComplete(false)
		})
		// setSearch(Selecter(searchstring, searchData))
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		setIsLoadComplete(true)
		documentSearch(searchstring)
		.then(res => {
			setSearch(searchConvert(res))
			setInitialSearch(searchConvert(res))
			setIsLoadComplete(false)
		})
	}, [location])

	return (
		<div className={"search " + (activeLang === 'ar' ? 'search-arabic' : '')}>
			<Loader isVisible={isLoadComplete}/>
			{isLoadComplete === false ? (
				<>
					<div className="search-header">
						{lang.searchResultsOnPage}
						{isMobile ? <Filters onClick={() => setIsActiveFilters(prev => !prev)} className={isActiveFilters ? 'filter-active' : ''}/> : ''}
					</div>
					<div className="search-body">
						{isActiveFilters ? (
							<div className="search-filters">
								<div className="search-filters-header">
									{lang.filters}
								</div>
								<div className="search-filter-item">
									<div className="search-filter-item-title">{lang.project}</div>
									<div className="search-filter-item-select">
										<div className="search-filter-item-select-active" onClick={() => {
											projectsSelect.openClose()
											companySelect.openClose(false)
											documentsSelect.openClose(false)
										}}>
											<span>{projectsSelect.value}</span>
											<Arrow/>
										</div>
										<div className={"search-filter-item-select-list " + (projectsSelect.active ? 'search-filter-item-select-list-active' : '')}>
											<div className="search-filter-item-select-list-item" onClick={() => {
												projectsSelect.changeValue(undefined)
												setActiveFiltersList(prev => {
													return {
														...prev,
														project: undefined
													}
												})
											}}>-</div>
											{projectsSelect.valuesList.filter(item => !(item.name === projectsSelect.value)).map((item, index) => {
												return <div className="search-filter-item-select-list-item" key={index} onClick={() => {
													projectsSelect.changeValue(item.name)
													setActiveFiltersList(prev => {
														return {
															...prev,
															project: item.name
														}
													})
												}}>{item.name}</div>
											})}
										</div>
									</div>
								</div>
								<div className="search-filter-item">
									<div className="search-filter-item-title">{lang.company}</div>
									<div className="search-filter-item-select">
										<div className="search-filter-item-select-active" onClick={() => {
											projectsSelect.openClose(false)
											companySelect.openClose()
											documentsSelect.openClose(false)
										}}>
											<span>{companySelect.value}</span>
											<Arrow/>
										</div>
										<div className={"search-filter-item-select-list " + (companySelect.active ? 'search-filter-item-select-list-active' : '')}>
											<div className="search-filter-item-select-list-item" onClick={() => {
												companySelect.changeValue(undefined)
												setActiveFiltersList(prev => {
													return {
														...prev,
														company: undefined
													}
												})
											}}>-</div>
											{companySelect.valuesList.filter(item => !(item.name === companySelect.value)).map((item, index) => {
												return <div className="search-filter-item-select-list-item" key={index} onClick={() => {
													companySelect.changeValue(item.name)
													setActiveFiltersList(prev => {
														return {
															...prev,
															company: item.name
														}
													})
												}}>{item.name}</div>
											})}
										</div>
									</div>
								</div>
								<div className="search-filter-item">
									<div className="search-filter-item-title">{lang.documentType}</div>
									<div className="search-filter-item-select">
										<div className="search-filter-item-select-active" onClick={() => {
											projectsSelect.openClose(false)
											companySelect.openClose(false)
											documentsSelect.openClose()
										}}>
											<span>{documentsSelect.value}</span>
											<Arrow/>
										</div>
										<div className={"search-filter-item-select-list " + (documentsSelect.active ? 'search-filter-item-select-list-active' : '')}>
											<div className="search-filter-item-select-list-item" onClick={() => {
												documentsSelect.changeValue(undefined)
												setActiveFiltersList(prev => {
													return {
														...prev,
														type: undefined
													}
												})
											}}>-</div>
											{documentsSelect.valuesList.filter(item => !(item.name === documentsSelect.value)).map((item, index) => {
												return <div className="search-filter-item-select-list-item" key={index} onClick={() => {
													documentsSelect.changeValue(item.name)
													setActiveFiltersList(prev => {
														return {
															...prev,
															type: item.name
														}
													})
												}}>{item.name}</div>
											})}
										</div>
									</div>
								</div>
							</div>
						) : ''}
						<div className="search-results">
							{search.map((item, index) => {
								return (
									<div className="search-result-item" key={index} onDoubleClick={() => {
										history.push(`/document/${item._id}?referer=${window.location.pathname.replace('/', '')}&groupid=${'dashboard'}&docname=${item.files.pop().filename}`)
									}}>
										<div className="search-result-item-header">
											<img src={require(`../../../assets/img/files/${item.file}.svg`)} alt={item.file}/>
											<div className="search-result-item-name" dangerouslySetInnerHTML={{__html: item.html_name}}></div>
										</div>
										<div className="search-result-item-body">
											<div className="result-info-item ">
												<div className="result-info-item-name ">{activeLang === 'ar' ? ':' + lang.project : lang.project + ':'}</div>
												<div className="result-info-item-value result-info-item-value-bg">{item.project}</div>
											</div>
											<div className="result-info-item">
												<div className="result-info-item-name">{activeLang === 'ar' ? ':' + lang.company : lang.company + ':'}</div>
												<div className="result-info-item-value result-info-item-value-bg">{item.company}</div>
											</div>
										</div>
									</div>	
								)
							})}
						</div>
					</div>
				</>
			) : ''}
			
		</div>
	)
}

export default Search